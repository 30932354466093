import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"570px","width":"100%","display":"flex"}},[_c('div',{staticStyle:{"width":"60%"},style:(("background:" + (_vm.landing.branding.sidebar_menu.background) + ";"))},[_c(VList,{staticStyle:{"background":"transparent"},attrs:{"nav":"","dense":""}},[_c(VListItemGroup,_vm._l((_vm.landing.menu.list.filter(function (x) { return x.active; })),function(menu){return _c(VListItem,{key:menu.id,staticStyle:{"border-radius":"0"},style:(("border-bottom: 0.5px solid " + (_vm.landing.branding.sidebar_menu.border_color) + ";"))},[(menu.styles.show_icon&&_vm.landing.branding.sidebar_menu.show_icons)?_c(VListItemIcon,[_c('div',{style:(("width:24px;height:24px;background-color:" + (_vm.landing.branding.sidebar_menu.icon_colors) + ";mask: url(" + (menu.icon[_vm.landing.advanced.default_lang]) + ") no-repeat center / contain;-webkit-mask: url(" + (menu.icon[_vm.landing.advanced.default_lang]) + ") no-repeat center / contain;"))})]):_vm._e(),_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"font-size":"1rem","line-height":"1.5rem"}},[_vm._v(" "+_vm._s(menu.text[_vm.landing.current_lang])+" ")])],1)],1)}),1)],1)],1),_c('div',{staticStyle:{"width":"40%","min-height":"100%","opacity":"0.46","background-color":"rgb(33, 33, 33)"}})])}
var staticRenderFns = []

export { render, staticRenderFns }