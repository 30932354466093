<template>
  <div v-if="bottomMenu">
    <div>
      <label class="label" style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;">Bottom menu settings</label>
      <div
        class="control"
        style="display: flex; "
      >
        <label class="label" style="width:200px;margin-bottom:0"
          >Turn on page</label
        >
        <v-switch color="#6ab64a" v-model="bottomMenu.show" hide-details @change="changeBottomMenu"></v-switch>
      </div>
    </div>
    <div v-if="bottomMenu.show">
<div style="display:flex;align-items:center;margin-bottom:15px;">
      <label class="label" style="margin-bottom:0;min-width:250px;"
        >Font size <b>{{ bottomMenu.styles.fontSize }} px</b></label
      >
      <div class="slider" style="width: 100%;">
        <v-slider
          hide-details
          :max="40"
          :min="2"
          v-model="bottomMenu.styles.fontSize"
          @change="changeBottomMenu"
        ></v-slider>
      </div>
</div>
      <div style="display:flex;align-items:center;margin-bottom:15px;">
        <label class="label" style="margin-bottom:0;min-width:250px;"
        >Icon size <b>{{ bottomMenu.styles.iconSize }} px</b></label
      >
      <div class="slider" style="width: 100%;">
        <v-slider
            hide-details
          :max="40"
          :min="2"
          v-model="bottomMenu.styles.iconSize"
          @change="changeBottomMenu"
        ></v-slider>
      </div>
      </div>
      <div style="display:flex;align-items:center;margin-bottom:15px;">
        <label class="label" style="margin-bottom:0;min-width:250px;"
        >Font weight <b>{{ bottomMenu.styles.fontWeight }} %</b></label
      >
      <div class="slider" style="width: 100%;">
        <v-slider
          hide-details
          :max="100"
          :min="0"
          :step="10"
          v-model="bottomMenu.styles.fontWeight"
          @change="changeBottomMenu"
        ></v-slider>
      </div>
      </div>
      <div style="display: flex; align-items: center; margin-bottom: 30px">
        <label
          class="label"
          style="margin-bottom: 0;margin-right:8px;  min-width: 250px"
        >
          Font Color
        </label>
        <div class="control">
          <v-menu
            top
            nudge-left="16"
            nudge-bottom="98"
            :close-on-content-click="false"
            v-model="show_text_color"
          >
            <template v-slot:activator="{ on }">
              <div
                class="custom__menu--color"
                :style="`background-color:${bottomMenu.styles.color}`"
                v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker v-model="bottomMenu.styles.color" flat
                                @change="changeBottomMenu"/>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </div>
      <div style="display: flex; align-items: center; margin-bottom: 30px">
        <label
          class="label"
          style="margin-bottom: 0;margin-right:8px; min-width: 250px"
        >
          Background Color
        </label>
        <div class="control">
          <v-menu
            top
            nudge-left="16"
            nudge-bottom="98"
            :close-on-content-click="false"
            v-model="show_background_color"
          >
            <template v-slot:activator="{ on }">
              <div
                class="custom__menu--color"
                :style="`background-color:${bottomMenu.styles.background}`"
                v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker v-model="bottomMenu.styles.background" flat
                                @change="changeBottomMenu"/>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </div>
      <div style="display: flex; align-items: center; margin-bottom: 30px">
        <label
          class="label"
          style="margin-bottom: 0;margin-right:8px; min-width: 250px"
        >
          Active Color
        </label>
        <div class="control">
          <v-menu
            top
            nudge-left="16"
            nudge-bottom="98"
            :close-on-content-click="false"
            v-model="show_active_text_color"
          >
            <template v-slot:activator="{ on }">
              <div
                class="custom__menu--color"
                :style="`background-color:${bottomMenu.styles.activeColor}`"
                v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker v-model="bottomMenu.styles.activeColor" flat
                                @change="changeBottomMenu"/>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </div>
      <div style="display: flex; align-items: center; margin-bottom: 30px">
        <label
          class="label"
          style="margin-bottom: 0;margin-right:8px; min-width: 250px"
        >
          Active Background Color
        </label>
        <div class="control">
          <v-menu
            top
            nudge-left="16"
            nudge-bottom="98"
            :close-on-content-click="false"
            v-model="show_activeBackground_color"
          >
            <template v-slot:activator="{ on }">
              <div
                class="custom__menu--color"
                :style="`background-color:${bottomMenu.styles.activeBackground}`"
                v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker
                  v-model="bottomMenu.styles.activeBackground"
                  flat
                  @change="changeBottomMenu"
                />
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </div>
    </div>
    <div class="field" v-if="bottomMenu.show">
      <v-tabs class="langs__tabs margin__tabs">
        <v-tab v-for="(menu, menuIndex) in bottomMenu.items" :key="menuIndex">
          Menu {{ menuIndex + 1 }}
        </v-tab>
        <v-tab-item
          v-for="(menu, menuIndex) in bottomMenu.items"
          :key="menuIndex"
        >
          <div class="bottom_icons">
            <div style="display:flex;align-items:center;margin-bottom: 15px" @click="showIcons = !showIcons">
              <label class="label" style="margin-bottom: 0;margin-right:8px;" :style="`min-width:${$store.state.isMobile?'150px':'250px'}`">Change icon</label>
              <v-select
                  outlined
                  solo
                  hide-details
                  flat
                  dense
                  :menu-props="{ contentClass: 'my-awesome-list' }"
                  :items="icons.data.map((item) => item.url)"
                  :value="menu.icon"
                  v-model="menu.icon"
                  @change="changeBottomMenu"
              >
                <template v-slot:selection="{ item }">
                  <img class="icon" :src="item" style="width:32px;height:32px;"/>
                </template>
                <template v-slot:item="{ item }">
                  <img class="icon" :src="item"  style="width:32px;height:32px;"/>
                </template>
              </v-select>
            </div>
              <div v-if="showIcons" style="width: 15vw;height:150px;background:#fff;border:1px solid #ccc;position: absolute;left:250px;top:55px;z-index: 100;display:flex;flex-wrap: wrap;overflow-y:scroll;overflow-x:hidden;">
                <div v-for="icon in icons.data.map((item) => item.url)" :key="icon"  style="height: 40px;width: 40px;padding:5px;" :style="`${menu.icon === icon?'border:2px solid #007db5;background:#ddd;':'' };`" @click="handleChange(menu,icon)">
                  <img :src="icon" :alt="icon" style="height: 100%;width: 100%;cursor: pointer;">
                </div>
              </div>
            <v-tabs class="langs__tabs" v-model="activeLang">
              <v-tab
                v-for="lang in selected_langs"
                :key="lang"
                @change="changeLang(lang)"
              >
                <img
                  :src="
                    landing.advanced.langs.find((x) => x.value === lang).flag
                  "
                  alt=""
                  style="width: 20px; height: 15px; margin-right: 5px"
                />
                {{ lang }}
              </v-tab>
              <v-tab-item v-for="lang in selected_langs" :key="lang">

                <div style="display:flex;align-items:center;margin-bottom: 15px">
                  <label class="label" style="margin-bottom: 0;margin-right:8px; " :style="`min-width:${$store.state.isMobile?'150px':'250px'}`">Change text</label>
                  <v-text-field outlined hide-details v-model="menu.text[lang]" dense @change="changeBottomMenu">
                  </v-text-field>
                </div>
              </v-tab-item>
            </v-tabs>

            <div style="display:flex;align-items:center;margin-bottom: 15px">
              <label class="label" style="margin-bottom: 0;margin-right:8px;" :style="`min-width:${$store.state.isMobile?'150px':'250px'}`">Change link</label>
              <v-combobox
                outlined
                dense
                hide-details
                placeholder="Link"
                :items="links"
                v-model="menu.link"
                @change="changeBottomMenu"
              >
              </v-combobox>
            </div>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
export default {
  name: "BottomMenu",
  data() {
    return {
      showIcons:false,
      iconsArr: [
        "mdi-access-point",
        "mdi-access-point-network",
        "mdi-access-point",
        "mdi-account-alert",
        "mdi-account-box",
        "mdi-account",
        "mdi-account-box-outline",
        "mdi-account-card-details",
        "mdi-account-check",
        "mdi-account-circle",
        "mdi-account-convert",
        "mdi-account-edit",
        "mdi-account-key",
        "mdi-account-location",
        "mdi-account-minus",
        "mdi-account-multiple",
        "mdi-account-multiple-minus",
        "mdi-account-multiple-outline",
        "mdi-account-multiple-plus",
        "mdi-account-network",
        "mdi-account-off",
        "mdi-account-outline",
        "mdi-account-plus",
        "mdi-account-remove",
        "mdi-account-search",
        "mdi-account-settings",
        "mdi-account-settings-variant",
        "mdi-account-star",
        "mdi-account-switch",
        "mdi-adjust",
        "mdi-air-conditioner",
        "mdi-airballoon",
        "mdi-airplane",
        "mdi-airplane-landing",
        "mdi-airplane-off",
        "mdi-airplane-takeoff",
        "mdi-airplay",
        "mdi-alarm",
        "mdi-alarm-bell",
        "mdi-alarm-check",
        "mdi-alarm-light",
        "mdi-alarm-multiple",
        "mdi-alarm-off",
        "mdi-alarm-plus",
        "mdi-alarm-snooze",
        "mdi-album",
        "mdi-alert",
        "mdi-alert-box",
        "mdi-alert-circle",
        "mdi-alert-circle",
        "mdi-alert-circle-outline",
        "mdi-alert-decagram",
        "mdi-alert-octagon",
        "mdi-alert-octagram",
        "mdi-alert-outline",
        "mdi-all-inclusive",
        "mdi-alpha",
        "mdi-alphabetical",
        "mdi-altimeter",
        "mdi-amazon",
        "mdi-amazon-clouddrive",
        "mdi-ambulance",
        "mdi-amplifier",
        "mdi-anchor",
        "mdi-android",
        "mdi-android-debug-bridge",
        "mdi-android-head",
        "mdi-android-studio",
        "mdi-angular",
        "mdi-angularjs",
        "mdi-animation",
        "mdi-apple",
        "mdi-apple-finder",
        "mdi-apple-ios",
        "mdi-apple-keyboard-caps",
        "mdi-apple-keyboard-command",
        "mdi-apple-keyboard-control",
        "mdi-apple-keyboard-option",
        "mdi-apple-keyboard-shift",
        "mdi-apple-mobileme",
        "mdi-apple-safari",
        "mdi-application",
        "mdi-approval",
        "mdi-apps",
        "mdi-archive",
        "mdi-arrange-bring-forward",
        "mdi-arrange-bring-to-front",
        "mdi-arrange-send-backward",
        "mdi-arrange-send-to-back",
        "mdi-arrow-all",
        "mdi-arrow-bottom-left",
        "mdi-arrow-collapse-right",
        "mdi-arrow-collapse",
        "mdi-arrow-collapse-all",
        "mdi-arrow-collapse-down",
        "mdi-arrow-collapse-left",
        "mdi-arrow-collapse-right",
        "mdi-arrow-collapse-up",
        "mdi-arrow-down",
        "mdi-arrow-down-bold",
        "mdi-arrow-down-bold-box",
        "mdi-arrow-down-bold-circle",
        "mdi-arrow-down-bold-circle-outline",
        "mdi-arrow-down-bold-hexagon-outline",
        "mdi-arrow-down-box",
        "mdi-arrow-down-drop-circle",
        "mdi-arrow-down-drop-circle-outline",
        "mdi-arrow-down-thick",
        "mdi-arrow-expand",
        "mdi-arrow-expand-all",
        "mdi-arrow-expand-down",
        "mdi-arrow-expand-left",
        "mdi-arrow-expand-right",
        "mdi-arrow-expand-up",
        "mdi-arrow-left",
        "mdi-arrow-left-bold",
        "mdi-arrow-left-bold-box",
        "mdi-arrow-left-bold-box-outline",
        "mdi-arrow-left-bold-circle",
        "mdi-arrow-left-bold-circle-outline",
        "mdi-arrow-left-bold-hexagon-outline",
        "mdi-arrow-left-box",
        "mdi-arrow-left-drop-circle",
        "mdi-arrow-left-thick",
        "mdi-arrow-right",
        "mdi-arrow-right-bold-box",
        "mdi-arrow-right-bold",
        "mdi-arrow-right-bold-box-outline",
        "mdi-arrow-right-bold-circle",
        "mdi-arrow-right-bold-circle-outline",
        "mdi-arrow-right-bold-hexagon-outline",
        "mdi-arrow-right-box",
        "mdi-arrow-right-drop-circle",
        "mdi-arrow-right-drop-circle-outline",
        "mdi-arrow-right-thick",
        "mdi-arrow-top-left",
        "mdi-arrow-top-right",
        "mdi-arrow-up",
        "mdi-arrow-up-bold",
        "mdi-arrow-up-bold-box",
        "mdi-arrow-up-bold-box-outline",
        "mdi-arrow-up-bold-circle",
        "mdi-arrow-up-bold-circle-outline",
        "mdi-arrow-up-bold-hexagon-outline",
        "mdi-arrow-up-box",
        "mdi-arrow-up-drop-circle",
        "mdi-arrow-up-drop-circle-outline",
        "mdi-arrow-up-thick",
        "mdi-assistant",
        "mdi-asterisk",
        "mdi-at",
        "mdi-atom",
        "mdi-attachment",
        "mdi-audiobook",
        "mdi-auto-fix",
        "mdi-auto-upload",
        "mdi-autorenew",
        "mdi-av-timer",
        "mdi-baby",
        "mdi-baby-buggy",
        "mdi-backburger",
        "mdi-backspace",
        "mdi-backup-restore",
        "mdi-bandcamp",
        "mdi-bank",
        "mdi-barcode",
        "mdi-barcode-scan",
        "mdi-barley",
        "mdi-barrel",
        "mdi-basecamp",
        "mdi-basket",
        "mdi-basket-fill",
        "mdi-basket-unfill",
        "mdi-battery",
        "mdi-battery-10",
        "mdi-battery-20",
        "mdi-battery-30",
        "mdi-battery-40",
        "mdi-battery-50",
        "mdi-battery-60",
        "mdi-battery-70",
        "mdi-battery-80",
        "mdi-battery-90",
        "mdi-battery-alert",
        "mdi-battery-charging",
        "mdi-battery-charging-100",
        "mdi-battery-charging-20",
        "mdi-battery-charging-30",
        "mdi-battery-charging-40",
        "mdi-battery-charging-60",
        "mdi-battery-charging-80",
        "mdi-battery-charging-90",
        "mdi-battery-minus",
        "mdi-battery-negative",
        "mdi-battery-outline",
        "mdi-battery-plus",
        "mdi-battery-positive",
        "mdi-battery-unknown",
        "mdi-beach",
        "mdi-beaker",
        "mdi-beats",
        "mdi-beer",
        "mdi-behance",
        "mdi-bell",
        "mdi-bell-off",
        "mdi-bell-outline",
        "mdi-bell-plus",
        "mdi-bell-ring",
        "mdi-bell-ring-outline",
        "mdi-bell-sleep",
        "mdi-beta",
        "mdi-beta",
        "mdi-bible",
        "mdi-bike",
        "mdi-bing",
        "mdi-binoculars",
        "mdi-bio",
        "mdi-biohazard",
        "mdi-bitbucket",
        "mdi-black-mesa",
        "mdi-blender",
        "mdi-blackberry",
        "mdi-blender",
        "mdi-blinds",
        "mdi-block-helper",
        "mdi-blogger",
        "mdi-bluetooth",
        "mdi-bluetooth-audio",
        "mdi-bluetooth-connect",
        "mdi-bluetooth-off",
        "mdi-bluetooth-settings",
        "mdi-bluetooth-transfer",
        "mdi-blur",
        "mdi-blur-linear",
        "mdi-blur-off",
        "mdi-blur-radial",
        "mdi-bomb",
        "mdi-bomb-off",
        "mdi-bone",
        "mdi-book",
        "mdi-book-minus",
        "mdi-book-multiple",
        "mdi-book-multiple-variant",
        "mdi-book-open",
        "mdi-book-open-page-variant",
        "mdi-book-open-variant",
        "mdi-book-plus",
        "mdi-book-secure",
        "mdi-book-unsecure",
        "mdi-book-variant",
        "mdi-bookmark",
        "mdi-bookmark-check",
        "mdi-bookmark-music",
        "mdi-bookmark-music",
        "mdi-bookmark-outline",
        "mdi-bookmark-plus",
        "mdi-bookmark-remove",
        "mdi-bookmark-plus-outline",
        "mdi-bookmark-remove",
        "mdi-boombox",
        "mdi-bootstrap",
        "mdi-border-all",
        "mdi-border-bottom",
        "mdi-border-color",
        "mdi-border-horizontal",
        "mdi-border-inside",
        "mdi-border-left",
        "mdi-border-none",
        "mdi-border-outside",
        "mdi-border-right",
        "mdi-border-style",
        "mdi-border-top",
        "mdi-border-vertical",
        "mdi-bow-tie",
        "mdi-bowl",
        "mdi-bowling",
        "mdi-box",
        "mdi-box-cutter",
        "mdi-box-shadow",
        "mdi-bridge",
        "mdi-briefcase",
        "mdi-briefcase-check",
        "mdi-briefcase-download",
        "mdi-briefcase-upload",
        "mdi-brightness-1",
        "mdi-brightness-2",
        "mdi-brightness-3",
        "mdi-brightness-4",
        "mdi-brightness-5",
        "mdi-brightness-6",
        "mdi-brightness-7",
        "mdi-brightness-auto",
        "mdi-broom",
        "mdi-brush",
        "mdi-buffer",
        "mdi-bug",
        "mdi-bulletin-board",
        "mdi-bullhorn",
        "mdi-bullseye",
        "mdi-burst-mode",
        "mdi-bus",
        "mdi-bus-articulated-end",
        "mdi-bus-articulated-front",
        "mdi-bus-double-decker",
        "mdi-bus-school",
        "mdi-bus-side",
        "mdi-cached",
        "mdi-cake",
        "mdi-cake-layered",
        "mdi-cake-variant",
        "mdi-calculator",
        "mdi-calendar",
        "mdi-calendar-blank",
        "mdi-calendar-check",
        "mdi-calendar-clock",
        "mdi-calendar-multiple",
        "mdi-calendar-multiple-check",
        "mdi-calendar-plus",
        "mdi-calendar-question",
        "mdi-calendar-range",
        "mdi-calendar-remove",
        "mdi-calendar-text",
        "mdi-calendar-today",
        "mdi-call-made",
        "mdi-call-merge",
        "mdi-call-missed",
        "mdi-call-received",
        "mdi-call-split",
        "mdi-camcorder",
        "mdi-camcorder-box",
        "mdi-camcorder-box-off",
        "mdi-camcorder-off",
        "mdi-camera",
        "mdi-camera-burst",
        "mdi-camera-enhance",
        "mdi-camera-front",
        "mdi-camera-front-variant",
        "mdi-camera-gopro",
        "mdi-camera-iris",
        "mdi-camera-metering-center",
        "mdi-camera-metering-matrix",
        "mdi-camera-metering-partial",
        "mdi-camera-metering-spot",
        "mdi-camera-off",
        "mdi-camera-party-mode",
        "mdi-camera-rear",
        "mdi-camera-rear-variant",
        "mdi-camera-switch",
        "mdi-camera-timer",
        "mdi-cancel",
        "mdi-candle",
        "mdi-candycane",
        "mdi-cannabis",
        "mdi-car",
        "mdi-car-battery",
        "mdi-car-connected",
        "mdi-car-convertable",
        "mdi-car-estate",
        "mdi-car-hatchback",
        "mdi-car-pickup",
        "mdi-car-side",
        "mdi-car-sports",
        "mdi-car-wash",
        "mdi-caravan",
        "mdi-cards",
        "mdi-cards-outline",
        "mdi-cards-playing-outline",
        "mdi-cards-variant",
        "mdi-carrot",
        "mdi-cart",
        "mdi-cart-off",
        "mdi-cart-outline",
        "mdi-cart-plus",
        "mdi-case-sensitive-alt",
        "mdi-cash",
        "mdi-cash-100",
        "mdi-cash-multiple",
        "mdi-cash-usd",
        "mdi-cast",
        "mdi-cast-connected",
        "mdi-cast-off",
        "mdi-castle",
        "mdi-cat",
        "mdi-cctv",
        "mdi-ceiling-light",
        "mdi-cellphone",
        "mdi-cellphone-android",
        "mdi-cellphone-basic",
        "mdi-cellphone-dock",
        "mdi-cellphone-iphone",
        "mdi-cellphone-link",
        "mdi-cellphone-link-off",
        "mdi-cellphone-settings",
        "mdi-certificate",
        "mdi-chair-school",
        "mdi-chart-arc",
        "mdi-chart-areaspline",
        "mdi-chart-bar",
        "mdi-chart-bar-stacked",
        "mdi-chart-bubble",
        "mdi-chart-donut",
        "mdi-chart-donut-variant",
        "mdi-chart-gantt",
        "mdi-chart-histogram",
        "mdi-chart-line",
        "mdi-chart-line-stacked",
        "mdi-chart-pie",
        "mdi-chart-scatterplot-hexbin",
        "mdi-chart-timeline",
        "mdi-check",
        "mdi-check-all",
        "mdi-check-circle",
        "mdi-check-circle-outline",
        "mdi-checkbox-blank",
        "mdi-checkbox-blank-circle",
        "mdi-checkbox-blank-circle-outline",
        "mdi-checkbox-blank-outline",
        "mdi-checkbox-marked",
        "mdi-checkbox-marked-circle",
        "mdi-checkbox-marked-circle-outline",
        "mdi-checkbox-marked-outline",
        "mdi-checkbox-multiple-blank",
        "mdi-checkbox-multiple-blank-circle",
        "mdi-checkbox-multiple-blank-circle-outline",
        "mdi-checkbox-multiple-blank-outline",
        "mdi-checkbox-multiple-marked",
        "mdi-checkbox-multiple-marked-circle",
        "mdi-checkbox-multiple-marked-circle-outline",
        "mdi-checkbox-multiple-marked-outline",
        "mdi-checkerboard",
        "mdi-chemical-weapon",
        "mdi-chevron-double-down",
        "mdi-chevron-double-left",
        "mdi-chevron-double-right",
        "mdi-chevron-double-up",
        "mdi-chevron-down",
        "mdi-chevron-left",
        "mdi-chevron-right",
        "mdi-chevron-right",
        "mdi-chevron-up",
        "mdi-chili-hot",
        "mdi-chili-medium",
        "mdi-chili-mild",
        "mdi-chip",
        "mdi-chip",
        "mdi-church",
        "mdi-circle",
        "mdi-circle-outline",
        "mdi-cisco-webex",
        "mdi-city",
        "mdi-clipboard",
        "mdi-clipboard-account",
        "mdi-clipboard-alert",
        "mdi-clipboard-arrow-down",
        "mdi-clipboard-check",
        "mdi-clipboard-flow",
        "mdi-clipboard-outline",
        "mdi-clipboard-plus",
        "mdi-clipboard-text",
        "mdi-clippy",
        "mdi-clock",
        "mdi-clock-alert",
        "mdi-clock-end",
        "mdi-clock-fast",
        "mdi-clock-in",
        "mdi-clock-out",
        "mdi-clock-start",
        "mdi-close",
        "mdi-close-box",
        "mdi-close-box-outline",
        "mdi-close-circle",
        "mdi-close-circle-outline",
        "mdi-close-network",
        "mdi-close-octagon",
        "mdi-close-octagon-outline",
        "mdi-close-outline",
        "mdi-closed-caption",
        "mdi-cloud",
        "mdi-cloud-braces",
        "mdi-cloud-check",
        "mdi-cloud-circle",
        "mdi-cloud-download",
        "mdi-cloud-off-outline",
        "mdi-cloud-print",
        "mdi-cloud-print-outline",
        "mdi-cloud-sync",
        "mdi-cloud-tags",
        "mdi-cloud-upload",
        "mdi-code-array",
        "mdi-code-braces",
        "mdi-code-brackets",
        "mdi-code-equal",
        "mdi-code-greater-than",
        "mdi-code-greater-than-or-equal",
        "mdi-code-not-equal",
        "mdi-code-not-equal-variant",
        "mdi-code-parentheses",
        "mdi-code-string",
        "mdi-code-tags",
        "mdi-code-tags-check",
        "mdi-codepen",
        "mdi-coffee",
        "mdi-coffee-outline",
        "mdi-coffee-to-go",
        "mdi-coin",
        "mdi-coins",
        "mdi-collage",
        "mdi-color-helper",
        "mdi-comment",
        "mdi-comment-account",
        "mdi-comment-account-outline",
        "mdi-comment-alert",
        "mdi-comment-alert-outline",
        "mdi-comment-check",
        "mdi-comment-check-outline",
        "mdi-comment-multiple-outline",
        "mdi-comment-outline",
        "mdi-comment-plus-outline",
        "mdi-comment-processing",
        "mdi-comment-processing-outline",
        "mdi-comment-question-outline",
        "mdi-comment-remove-outline",
        "mdi-comment-text",
        "mdi-comment-text-outline",
        "mdi-compare",
        "mdi-compass",
        "mdi-compass-outline",
        "mdi-console",
        "mdi-console-line",
        "mdi-contact-mail",
        "mdi-contacts",
        "mdi-content-copy",
        "mdi-content-cut",
        "mdi-content-duplicate",
        "mdi-content-paste",
        "mdi-content-save",
        "mdi-content-save-all",
        "mdi-content-save-settings",
        "mdi-contrast",
        "mdi-contrast-box",
        "mdi-contrast-circle",
        "mdi-cookie",
        "mdi-copyright",
        "mdi-corn",
        "mdi-counter",
        "mdi-cow",
        "mdi-creation",
        "mdi-credit-card",
        "mdi-credit-card-multiple",
        "mdi-credit-card-off",
        "mdi-credit-card-plus",
        "mdi-credit-card-scan",
        "mdi-crop",
        "mdi-crop-free",
        "mdi-crop-landscape",
        "mdi-crop-portrait",
        "mdi-crop-rotate",
        "mdi-crop-square",
        "mdi-crosshairs",
        "mdi-crosshairs-gps",
        "mdi-crown",
        "mdi-cube",
        "mdi-cube-outline",
        "mdi-cube-send",
        "mdi-cube-unfolded",
        "mdi-cup",
        "mdi-cup-off",
        "mdi-cup-water",
        "mdi-currency-btc",
        "mdi-currency-chf",
        "mdi-currency-cny",
        "mdi-currency-eth",
        "mdi-currency-eur",
        "mdi-currency-gbp",
        "mdi-currency-inr",
        "mdi-currency-jpy",
        "mdi-currency-krw",
        "mdi-currency-ngn",
        "mdi-currency-rub",
        "mdi-currency-sign",
        "mdi-currency-try",
        "mdi-currency-twd",
        "mdi-currency-usd",
        "mdi-currency-usd-off",
        "mdi-cursor-default",
        "mdi-cursor-default-outline",
        "mdi-cursor-move",
        "mdi-cursor-pointer",
        "mdi-cursor-text",
        "mdi-database",
        "mdi-database-minus",
        "mdi-database-plus",
        "mdi-debug-step-into",
        "mdi-debug-step-out",
        "mdi-debug-step-over",
        "mdi-decagram",
        "mdi-decagram-outline",
        "mdi-decimal-decrease",
        "mdi-decimal-increase",
        "mdi-delete",
        "mdi-delete-circle",
        "mdi-delete-empty",
        "mdi-delete-forever",
        "mdi-delete-sweep",
        "mdi-delete-variant",
        "mdi-delta",
        "mdi-deskphone",
        "mdi-desktop-classic",
        "mdi-desktop-mac",
        "mdi-desktop-tower",
        "mdi-details",
        "mdi-developer-board",
        "mdi-deviantart",
        "mdi-dialpad",
        "mdi-diamond",
        "mdi-dice-1",
        "mdi-dice-2",
        "mdi-dice-3",
        "mdi-dice-4",
        "mdi-dice-5",
        "mdi-dice-6",
        "mdi-dice-d10",
        "mdi-dice-d20",
        "mdi-dice-d4",
        "mdi-dice-d6",
        "mdi-dice-d8",
        "mdi-dice-multiple",
        "mdi-dictionary",
        "mdi-dip-switch",
        "mdi-directions",
        "mdi-dip-switch",
        "mdi-directions-fork",
        "mdi-discord",
        "mdi-disk",
        "mdi-disk-alert",
        "mdi-disqus",
        "mdi-disqus-outline",
        "mdi-division",
        "mdi-division-box",
        "mdi-dna",
        "mdi-dns",
        "mdi-do-not-disturb",
        "mdi-do-not-disturb-off",
        "mdi-dolby",
        "mdi-domain",
        "mdi-donkey",
        "mdi-dots-horizontal",
        "mdi-dots-horizontal-circle",
        "mdi-dots-vertical",
        "mdi-dots-vertical-circle",
        "mdi-douban",
        "mdi-download",
        "mdi-download-network",
        "mdi-drag",
        "mdi-drag-horizontal",
        "mdi-drag-vertical",
        "mdi-drawing",
        "mdi-drawing-box",
        "mdi-dribbble",
        "mdi-dribbble-box",
        "mdi-drone",
        "mdi-dropbox",
        "mdi-drupal",
        "mdi-duck",
        "mdi-dumbbell",
        "mdi-ear-hearing",
        "mdi-earth",
        "mdi-earth-box",
        "mdi-earth-box-off",
        "mdi-earth-off",
        "mdi-edge",
        "mdi-eject",
        "mdi-elephant",
        "mdi-elevation-decline",
        "mdi-elevation-rise",
        "mdi-elevator",
        "mdi-email",
        "mdi-email-alert",
        "mdi-email-open",
        "mdi-email-open-outline",
        "mdi-email-outline",
        "mdi-email-secure",
        "mdi-email-variant",
        "mdi-emby",
        "mdi-emoticon",
        "mdi-emoticon-cool",
        "mdi-emoticon-dead",
        "mdi-emoticon-devil",
        "mdi-emoticon-excited",
        "mdi-emoticon-happy",
        "mdi-emoticon-neutral",
        "mdi-emoticon-poop",
        "mdi-emoticon-sad",
        "mdi-emoticon-tongue",
        "mdi-engine",
        "mdi-engine-outline",
        "mdi-equal",
        "mdi-equal-box",
        "mdi-eraser",
        "mdi-eraser-variant",
        "mdi-escalator",
        "mdi-ethernet",
        "mdi-ethernet-cable",
        "mdi-ethernet-cable-off",
        "mdi-etsy",
        "mdi-ev-station",
        "mdi-eventbrite",
        "mdi-eventbrite",
        "mdi-evernote",
        "mdi-exclamation",
        "mdi-exit-to-app",
        "mdi-export",
        "mdi-eye",
        "mdi-eye-off",
        "mdi-eye-off-outline",
        "mdi-eye-outline",
        "mdi-eyedropper",
        "mdi-eyedropper-variant",
        "mdi-face",
        "mdi-face-profile",
        "mdi-facebook",
        "mdi-facebook-box",
        "mdi-facebook-messenger",
        "mdi-factory",
        "mdi-fan",
        "mdi-fast-forward",
        "mdi-fast-forward-outline",
        "mdi-fax",
        "mdi-feather",
        "mdi-ferry",
        "mdi-file",
        "mdi-file-account",
        "mdi-file-chart",
        "mdi-file-check",
        "mdi-file-cloud",
        "mdi-file-delimited",
        "mdi-file-document",
        "mdi-file-document-box",
        "mdi-file-excel",
        "mdi-file-excel-box",
        "mdi-file-export",
        "mdi-file-find",
        "mdi-file-hidden",
        "mdi-file-image",
        "mdi-file-import",
        "mdi-file-lock",
        "mdi-file-multiple",
        "mdi-file-music",
        "mdi-file-outline",
        "mdi-file-pdf",
        "mdi-file-pdf-box",
        "mdi-file-plus",
        "mdi-file-powerpoint",
        "mdi-file-powerpoint-box",
        "mdi-file-presentation-box",
        "mdi-file-restore",
        "mdi-file-send",
        "mdi-file-tree",
        "mdi-file-video",
        "mdi-file-word",
        "mdi-file-word-box",
        "mdi-file-xml",
        "mdi-film",
        "mdi-filmstrip",
        "mdi-filmstrip-off",
        "mdi-filter",
        "mdi-filter-outline",
        "mdi-filter-remove",
        "mdi-filter-remove-outline",
        "mdi-filter-variant",
        "mdi-find-replace",
        "mdi-fingerprint",
        "mdi-fire",
        "mdi-firefox",
        "mdi-fish",
        "mdi-flag",
        "mdi-flag-checkered",
        "mdi-flag-outline",
        "mdi-flag-outline-variant",
        "mdi-flag-triangle",
        "mdi-flag-variant",
        "mdi-flash",
        "mdi-flash-auto",
        "mdi-flash-off",
        "mdi-flash-outline",
        "mdi-flash-outline",
        "mdi-flash-red-eye",
        "mdi-flashlight",
        "mdi-flashlight-off",
        "mdi-flask",
        "mdi-flask-empty",
        "mdi-flask-empty-outline",
        "mdi-flattr",
        "mdi-flip-to-back",
        "mdi-flip-to-front",
        "mdi-floppy",
        "mdi-flower",
        "mdi-folder",
        "mdi-folder-account",
        "mdi-folder-download",
        "mdi-folder-google-drive",
        "mdi-folder-image",
        "mdi-folder-lock",
        "mdi-folder-lock-open",
        "mdi-folder-move",
        "mdi-folder-multiple",
        "mdi-folder-multiple-image",
        "mdi-folder-multiple-outline",
        "mdi-folder-open",
        "mdi-folder-outline",
        "mdi-folder-plus",
        "mdi-folder-remove",
        "mdi-folder-star",
        "mdi-folder-upload",
        "mdi-font-awesome",
        "mdi-food",
        "mdi-food-apple",
        "mdi-food-croissant",
        "mdi-food-fork-drink",
        "mdi-food-off",
        "mdi-food-variant",
        "mdi-football",
        "mdi-football-australian",
        "mdi-football-helmet",
        "mdi-forklift",
        "mdi-format-align-bottom",
        "mdi-format-align-center",
        "mdi-format-align-justify",
        "mdi-format-align-left",
        "mdi-format-align-middle",
        "mdi-format-align-right",
        "mdi-format-align-top",
        "mdi-format-annotation-plus",
        "mdi-format-bold",
        "mdi-format-clear",
        "mdi-format-color-fill",
        "mdi-format-color-text",
        "mdi-format-float-center",
        "mdi-format-float-left",
        "mdi-format-float-none",
        "mdi-format-float-right",
        "mdi-format-font",
        "mdi-format-header-1",
        "mdi-format-header-2",
        "mdi-format-header-3",
        "mdi-format-header-4",
        "mdi-format-header-5",
        "mdi-format-header-6",
        "mdi-format-header-decrease",
        "mdi-format-header-equal",
        "mdi-format-header-increase",
        "mdi-format-header-pound",
        "mdi-format-horizontal-align-center",
        "mdi-format-horizontal-align-left",
        "mdi-format-horizontal-align-right",
        "mdi-format-indent-decrease",
        "mdi-format-indent-increase",
        "mdi-format-italic",
        "mdi-format-line-spacing",
        "mdi-format-line-style",
        "mdi-format-line-weight",
        "mdi-format-list-bulleted",
        "mdi-format-list-bulleted-type",
        "mdi-format-list-checks",
        "mdi-format-list-numbers",
        "mdi-format-page-break",
        "mdi-format-paint",
        "mdi-format-paragraph",
        "mdi-format-pilcrow",
        "mdi-format-quote-close",
        "mdi-format-quote-open",
        "mdi-format-rotate-90",
        "mdi-format-rotate-90",
        "mdi-format-section",
        "mdi-format-size",
        "mdi-format-strikethrough",
        "mdi-format-strikethrough-variant",
        "mdi-format-subscript",
        "mdi-format-superscript",
        "mdi-format-text",
        "mdi-format-textdirection-l-to-r",
        "mdi-format-textdirection-r-to-l",
        "mdi-format-title",
        "mdi-format-underline",
        "mdi-format-vertical-align-bottom",
        "mdi-format-vertical-align-center",
        "mdi-format-vertical-align-top",
        "mdi-format-wrap-inline",
        "mdi-format-wrap-square",
        "mdi-format-wrap-tight",
        "mdi-format-wrap-top-bottom",
        "mdi-forum",
        "mdi-forward",
        "mdi-foursquare",
        "mdi-fridge",
        "mdi-fridge-filled",
        "mdi-fridge-filled-bottom",
        "mdi-fridge-filled-top",
        "mdi-fuel",
        "mdi-fullscreen",
        "mdi-fullscreen-exit",
        "mdi-function",
        "mdi-gamepad",
        "mdi-gamepad-variant",
        "mdi-garage",
        "mdi-garage-open",
        "mdi-gas-cylinder",
        "mdi-gas-station",
        "mdi-gate",
        "mdi-gauge",
        "mdi-gavel",
        "mdi-gender-female",
        "mdi-gender-male",
        "mdi-gender-male-female",
        "mdi-gender-transgender",
        "mdi-gesture",
        "mdi-gesture-double-tap",
        "mdi-gesture-swipe-down",
        "mdi-gesture-swipe-left",
        "mdi-gesture-swipe-right",
        "mdi-gesture-swipe-up",
        "mdi-gesture-tap",
        "mdi-gesture-two-double-tap",
        "mdi-gesture-two-tap",
        "mdi-ghost",
        "mdi-gift",
        "mdi-git",
        "mdi-github-box",
        "mdi-github-circle",
        "mdi-github-face",
        "mdi-glass-flute",
        "mdi-glass-mug",
        "mdi-glass-stange",
        "mdi-glass-tulip",
        "mdi-glassdoor",
        "mdi-glasses",
        "mdi-gmail",
        "mdi-gnome",
        "mdi-gondola",
        "mdi-google",
        "mdi-google-analytics",
        "mdi-google-assistant",
        "mdi-google-cardboard",
        "mdi-google-chrome",
        "mdi-google-circles",
        "mdi-google-circles-communities",
        "mdi-google-circles-extended",
        "mdi-google-circles-group",
        "mdi-google-controller",
        "mdi-google-controller-off",
        "mdi-google-drive",
        "mdi-google-earth",
        "mdi-google-glass",
        "mdi-google-keep",
        "mdi-google-maps",
        "mdi-google-nearby",
        "mdi-google-pages",
        "mdi-google-photos",
        "mdi-google-physical-web",
        "mdi-google-play",
        "mdi-google-plus",
        "mdi-google-plus-box",
        "mdi-google-translate",
        "mdi-google-wallet",
        "mdi-gradient",
        "mdi-grease-pencil",
        "mdi-grid",
        "mdi-grid-large",
        "mdi-grid-off",
        "mdi-group",
        "mdi-guitar-acoustic",
        "mdi-guitar-electric",
        "mdi-guitar-pick",
        "mdi-guitar-pick-outline",
        "mdi-hackernews",
        "mdi-hamburger",
        "mdi-hand-pointing-right",
        "mdi-hanger",
        "mdi-hangouts",
        "mdi-harddisk",
        "mdi-headphones",
        "mdi-headphones-box",
        "mdi-headphones-off",
        "mdi-headphones-settings",
        "mdi-headset",
        "mdi-headset-dock",
        "mdi-headset-off",
        "mdi-heart",
        "mdi-heart-box",
        "mdi-heart-box-outline",
        "mdi-heart-broken",
        "mdi-heart-half",
        "mdi-heart-half-full",
        "mdi-heart-half-outline",
        "mdi-heart-off",
        "mdi-heart-outline",
        "mdi-heart-pulse",
        "mdi-help",
        "mdi-help-box",
        "mdi-help-circle",
        "mdi-help-circle-outline",
        "mdi-help-network",
        "mdi-hexagon",
        "mdi-hexagon-multiple",
        "mdi-hexagon-outline",
        "mdi-high-definition",
        "mdi-highway",
        "mdi-history",
        "mdi-hololens",
        "mdi-home",
        "mdi-home-assistant",
        "mdi-home-automation",
        "mdi-home-circle",
        "mdi-home-map-marker",
        "mdi-home-modern",
        "mdi-home-outline",
        "mdi-home-variant",
        "mdi-hook",
        "mdi-hook-off",
        "mdi-hops",
        "mdi-hospital",
        "mdi-hospital-building",
        "mdi-hospital-marker",
        "mdi-hotel",
        "mdi-houzz",
        "mdi-houzz-box",
        "mdi-human",
        "mdi-human-child",
        "mdi-human-female",
        "mdi-human-greeting",
        "mdi-human-handsdown",
        "mdi-human-handsup",
        "mdi-human-male",
        "mdi-human-male-female",
        "mdi-human-pregnant",
        "mdi-humble-bundle",
        "mdi-image",
        "mdi-image-album",
        "mdi-image-area",
        "mdi-image-area-close",
        "mdi-image-broken",
        "mdi-image-broken-variant",
        "mdi-image-filter",
        "mdi-image-filter-black-white",
        "mdi-image-filter-center-focus",
        "mdi-image-filter-center-focus-weak",
        "mdi-image-filter-drama",
        "mdi-image-filter-frames",
        "mdi-image-filter-hdr",
        "mdi-image-filter-none",
        "mdi-image-filter-tilt-shift",
        "mdi-image-filter-vintage",
        "mdi-image-multiple",
        "mdi-import",
        "mdi-inbox",
        "mdi-inbox-arrow-down",
        "mdi-inbox-arrow-up",
        "mdi-incognito",
        "mdi-infinity",
        "mdi-information",
        "mdi-information-outline",
        "mdi-information-variant",
        "mdi-instagram",
        "mdi-instapaper",
        "mdi-internet-explorer",
        "mdi-invert-colors",
        "mdi-itunes",
        "mdi-jeepney",
        "mdi-jira",
        "mdi-jsfiddle",
        "mdi-json",
        "mdi-keg",
        "mdi-kettle",
        "mdi-key",
        "mdi-key-change",
        "mdi-key-minus",
        "mdi-key-plus",
        "mdi-key-remove",
        "mdi-key-variant",
        "mdi-keyboard",
        "mdi-keyboard-backspace",
        "mdi-keyboard-caps",
        "mdi-keyboard-close",
        "mdi-keyboard-off",
        "mdi-keyboard-return",
        "mdi-keyboard-tab",
        "mdi-keyboard-tab",
        "mdi-keyboard-variant",
        "mdi-kickstarter",
        "mdi-kodi",
        "mdi-label",
        "mdi-label-outline",
        "mdi-lambda",
        "mdi-lamp",
        "mdi-lan",
        "mdi-lan-connect",
        "mdi-lan-disconnect",
        "mdi-lan-pending",
        "mdi-language-c",
        "mdi-language-cpp",
        "mdi-language-csharp",
        "mdi-language-css3",
        "mdi-language-go",
        "mdi-language-html5",
        "mdi-language-javascript",
        "mdi-language-php",
        "mdi-language-python",
        "mdi-language-python-text",
        "mdi-language-r",
        "mdi-language-swift",
        "mdi-language-typescript",
        "mdi-laptop",
        "mdi-laptop-chromebook",
        "mdi-laptop-mac",
        "mdi-laptop-off",
        "mdi-laptop-windows",
        "mdi-lastfm",
        "mdi-launch",
        "mdi-lava-lamp",
        "mdi-layers",
        "mdi-layers-off",
        "mdi-lead-pencil",
        "mdi-leaf",
        "mdi-led-off",
        "mdi-led-on",
        "mdi-led-outline",
        "mdi-led-strip",
        "mdi-led-variant-off",
        "mdi-led-variant-on",
        "mdi-led-variant-outline",
        "mdi-library",
        "mdi-library-books",
        "mdi-library-music",
        "mdi-library-plus",
        "mdi-lightbulb",
        "mdi-lightbulb-on",
        "mdi-lightbulb-on-outline",
        "mdi-lightbulb-outline",
        "mdi-link",
        "mdi-link-off",
        "mdi-link-variant",
        "mdi-link-variant-off",
        "mdi-linkedin",
        "mdi-linkedin-box",
        "mdi-linux",
        "mdi-loading",
        "mdi-lock",
        "mdi-lock-open",
        "mdi-lock-open-outline",
        "mdi-lock-outline",
        "mdi-lock-pattern",
        "mdi-lock-plus",
        "mdi-lock-reset",
        "mdi-locker",
        "mdi-locker-multiple",
        "mdi-login",
        "mdi-login-variant",
        "mdi-logout",
        "mdi-logout-variant",
        "mdi-looks",
        "mdi-loop",
        "mdi-loupe",
        "mdi-lumx",
        "mdi-magnet",
        "mdi-magnet-on",
        "mdi-magnify",
        "mdi-magnify-minus",
        "mdi-magnify-minus-outline",
        "mdi-magnify-plus",
        "mdi-magnify-plus-outline",
        "mdi-mail-ru",
        "mdi-mailbox",
        "mdi-map",
        "mdi-map-marker",
        "mdi-map-marker-circle",
        "mdi-map-marker-minus",
        "mdi-map-marker-multiple",
        "mdi-map-marker-off",
        "mdi-map-marker-outline",
        "mdi-map-marker-plus",
        "mdi-map-marker-radius",
        "mdi-margin",
        "mdi-markdown",
        "mdi-marker",
        "mdi-marker-check",
        "mdi-martini",
        "mdi-material-ui",
        "mdi-math-compass",
        "mdi-matrix",
        "mdi-maxcdn",
        "mdi-medical-bag",
        "mdi-medium",
        "mdi-memory",
        "mdi-menu",
        "mdi-menu-down",
        "mdi-menu-down-outline",
        "mdi-menu-left",
        "mdi-menu-right",
        "mdi-menu-up",
        "mdi-menu-up-outline",
        "mdi-message",
        "mdi-message-alert",
        "mdi-message-bulleted",
        "mdi-message-bulleted-off",
        "mdi-message-draw",
        "mdi-message-image",
        "mdi-message-outline",
        "mdi-message-plus",
        "mdi-message-processing",
        "mdi-message-reply",
        "mdi-message-reply-text",
        "mdi-message-settings",
        "mdi-message-settings-variant",
        "mdi-message-text",
        "mdi-message-video",
        "mdi-meteor",
        "mdi-metronome",
        "mdi-metronome-tick",
        "mdi-micro-sd",
        "mdi-microphone",
        "mdi-microphone-off",
        "mdi-microphone-outline",
        "mdi-microphone-settings",
        "mdi-microphone-variant",
        "mdi-microphone-variant-off",
        "mdi-microscope",
        "mdi-microsoft",
        "mdi-minecraft",
        "mdi-minus",
        "mdi-minus-box",
        "mdi-minus-box-outline",
        "mdi-minus-circle",
        "mdi-minus-circle-outline",
        "mdi-minus-network",
        "mdi-mixcloud",
        "mdi-mixer",
        "mdi-monitor",
        "mdi-monitor-multiple",
        "mdi-more",
        "mdi-motorbike",
        "mdi-mouse",
        "mdi-mouse-off",
        "mdi-mouse-variant",
        "mdi-mouse-variant-off",
        "mdi-move-resize",
        "mdi-move-resize-variant",
        "mdi-movie",
        "mdi-movie-roll",
        "mdi-multiplication",
        "mdi-multiplication-box",
        "mdi-mushroom",
        "mdi-mushroom-outline",
        "mdi-music",
        "mdi-music-box",
        "mdi-music-box-outline",
        "mdi-music-circle",
        "mdi-music-note",
        "mdi-music-note-bluetooth",
        "mdi-music-note-bluetooth-off",
        "mdi-music-note-eighth",
        "mdi-music-note-half",
        "mdi-music-note-off",
        "mdi-music-note-quarter",
        "mdi-music-note-sixteenth",
        "mdi-music-note-whole",
        "mdi-music-off",
        "mdi-nature",
        "mdi-nature-people",
        "mdi-navigation",
        "mdi-near-me",
        "mdi-needle",
        "mdi-nest-protect",
        "mdi-nest-thermostat",
        "mdi-netflix",
        "mdi-network",
        "mdi-new-box",
        "mdi-newspaper",
        "mdi-nfc",
        "mdi-nfc-tap",
        "mdi-nfc-variant",
        "mdi-ninja",
        "mdi-nintendo-switch",
        "mdi-nodejs",
        "mdi-note",
        "mdi-note-multiple",
        "mdi-note-multiple-outline",
        "mdi-note-outline",
        "mdi-note-plus",
        "mdi-note-plus-outline",
        "mdi-note-text",
        "mdi-notification-clear-all",
        "mdi-npm",
        "mdi-nuke",
        "mdi-null",
        "mdi-numeric",
        "mdi-numeric-0-box",
        "mdi-numeric-0-box-multiple-outline",
        "mdi-numeric-0-box-outline",
        "mdi-numeric-1-box",
        "mdi-numeric-1-box-multiple-outline",
        "mdi-numeric-1-box-outline",
        "mdi-numeric-2-box",
        "mdi-numeric-2-box-multiple-outline",
        "mdi-numeric-2-box-outline",
        "mdi-numeric-3-box",
        "mdi-numeric-3-box-multiple-outline",
        "mdi-numeric-3-box-outline",
        "mdi-numeric-4-box",
        "mdi-numeric-4-box-multiple-outline",
        "mdi-numeric-4-box-outline",
        "mdi-numeric-5-box",
        "mdi-numeric-5-box-multiple-outline",
        "mdi-numeric-5-box-outline",
        "mdi-numeric-6-box",
        "mdi-numeric-6-box-multiple-outline",
        "mdi-numeric-6-box-outline",
        "mdi-numeric-7-box",
        "mdi-numeric-7-box-multiple-outline",
        "mdi-numeric-7-box-outline",
        "mdi-numeric-8-box",
        "mdi-numeric-8-box-multiple-outline",
        "mdi-numeric-8-box-outline",
        "mdi-numeric-9-box",
        "mdi-numeric-9-box-multiple-outline",
        "mdi-numeric-9-box-outline",
        "mdi-numeric-9-plus-box",
        "mdi-numeric-9-plus-box-multiple-outline",
        "mdi-numeric-9-plus-box-outline",
        "mdi-nut",
        "mdi-nutrition",
        "mdi-oar",
        "mdi-octagon",
        "mdi-octagon-outline",
        "mdi-octagram",
        "mdi-octagram-outline",
        "mdi-odnoklassniki",
        "mdi-office",
        "mdi-oil",
        "mdi-oil-temperature",
        "mdi-omega",
        "mdi-onedrive",
        "mdi-onenote",
        "mdi-opacity",
        "mdi-open-in-app",
        "mdi-open-in-new",
        "mdi-openid",
        "mdi-opera",
        "mdi-orbit",
        "mdi-ornament",
        "mdi-ornament-variant",
        "mdi-owl",
        "mdi-package",
        "mdi-package-down",
        "mdi-package-up",
        "mdi-package-variant",
        "mdi-package-variant-closed",
        "mdi-page-first",
        "mdi-page-last",
        "mdi-page-layout-body",
        "mdi-page-layout-footer",
        "mdi-page-layout-header",
        "mdi-page-layout-sidebar-left",
        "mdi-page-layout-sidebar-right",
        "mdi-palette",
        "mdi-palette-advanced",
        "mdi-panda",
        "mdi-pandora",
        "mdi-panorama",
        "mdi-panorama-fisheye",
        "mdi-panorama-horizontal",
        "mdi-panorama-vertical",
        "mdi-panorama-wide-angle",
        "mdi-paper-cut-vertical",
        "mdi-paperclip",
        "mdi-parking",
        "mdi-passport",
        "mdi-pause",
        "mdi-pause-circle",
        "mdi-pause-circle-outline",
        "mdi-pause-octagon",
        "mdi-pause-octagon-outline",
        "mdi-paw",
        "mdi-paw-off",
        "mdi-pen",
        "mdi-pencil",
        "mdi-pencil-box",
        "mdi-pencil-box-outline",
        "mdi-pencil-circle",
        "mdi-pencil-circle-outline",
        "mdi-pencil-lock",
        "mdi-pencil-off",
        "mdi-pentagon",
        "mdi-percent",
        "mdi-periodic-table-co2",
        "mdi-periscope",
        "mdi-pharmacy",
        "mdi-phone",
        "mdi-phone-bluetooth",
        "mdi-phone-classic",
        "mdi-phone-forward",
        "mdi-phone-hangup",
        "mdi-phone-in-talk",
        "mdi-phone-incoming",
        "mdi-phone-locked",
        "mdi-phone-log",
        "mdi-phone-minus",
        "mdi-phone-missed",
        "mdi-phone-outgoing",
        "mdi-phone-paused",
        "mdi-phone-plus",
        "mdi-phone-settings",
        "mdi-phone-voip",
        "mdi-pi",
        "mdi-pi-box",
        "mdi-piano",
        "mdi-pig",
        "mdi-pill",
        "mdi-pillar",
        "mdi-pin",
        "mdi-pin-off",
        "mdi-pine-tree",
        "mdi-pine-tree-box",
        "mdi-pinterest",
        "mdi-pinterest-box",
        "mdi-pipe",
        "mdi-pipe-disconnected",
        "mdi-pistol",
        "mdi-pizza",
        "mdi-plane-shield",
        "mdi-play",
        "mdi-play-box-outline",
        "mdi-play-circle",
        "mdi-play-circle-outline",
        "mdi-play-pause",
        "mdi-play-protected-content",
        "mdi-playlist-check",
        "mdi-playlist-minus",
        "mdi-playlist-play",
        "mdi-playlist-plus",
        "mdi-playlist-remove",
        "mdi-playstation",
        "mdi-plex",
        "mdi-plus",
        "mdi-plus-box",
        "mdi-plus-box-outline",
        "mdi-plus-circle",
        "mdi-plus-circle-multiple-outline",
        "mdi-plus-circle-outline",
        "mdi-plus-network",
        "mdi-plus-one",
        "mdi-plus-outline",
        "mdi-pocket",
        "mdi-pokeball",
        "mdi-polaroid",
        "mdi-poll",
        "mdi-poll-box",
        "mdi-polymer",
        "mdi-pool",
        "mdi-popcorn",
        "mdi-pot",
        "mdi-pot-mix",
        "mdi-pound",
        "mdi-pound-box",
        "mdi-power",
        "mdi-power-plug",
        "mdi-power-plug-off",
        "mdi-power-settings",
        "mdi-power-socket",
        "mdi-power-socket-eu",
        "mdi-power-socket-uk",
        "mdi-power-socket-us",
        "mdi-prescription",
        "mdi-presentation",
        "mdi-presentation-play",
        "mdi-printer",
        "mdi-printer-3d",
        "mdi-printer-alert",
        "mdi-printer-settings",
        "mdi-priority-high",
        "mdi-priority-low",
        "mdi-professional-hexagon",
        "mdi-projector",
        "mdi-projector-screen",
        "mdi-publish",
        "mdi-pulse",
        "mdi-puzzle",
        "mdi-qqchat",
        "mdi-qrcode",
        "mdi-qrcode-scan",
        "mdi-quadcopter",
        "mdi-quality-high",
        "mdi-quicktime",
        "mdi-radar",
        "mdi-radiator",
        "mdi-radio",
        "mdi-radio-handheld",
        "mdi-radio-tower",
        "mdi-radioactive",
        "mdi-radiobox-blank",
        "mdi-radiobox-marked",
        "mdi-raspberrypi",
        "mdi-ray-end",
        "mdi-ray-end-arrow",
        "mdi-ray-start",
        "mdi-ray-start-arrow",
        "mdi-ray-start-end",
        "mdi-ray-vertex",
        "mdi-rdio",
        "mdi-react",
        "mdi-read",
        "mdi-readability",
        "mdi-receipt",
        "mdi-record",
        "mdi-record-rec",
        "mdi-recycle",
        "mdi-reddit",
        "mdi-redo",
        "mdi-redo-variant",
        "mdi-refresh",
        "mdi-regex",
        "mdi-relative-scale",
        "mdi-reload",
        "mdi-remote",
        "mdi-rename-box",
        "mdi-reorder-horizontal",
        "mdi-reorder-vertical",
        "mdi-repeat",
        "mdi-repeat-off",
        "mdi-repeat-once",
        "mdi-replay",
        "mdi-reply-all",
        "mdi-reproduction",
        "mdi-resize-bottom-right",
        "mdi-responsive",
        "mdi-restart",
        "mdi-restore",
        "mdi-rewind",
        "mdi-rewind-outline",
        "mdi-rhombus",
        "mdi-rhombus-outline",
        "mdi-ribbon",
        "mdi-rice",
        "mdi-ring",
        "mdi-road",
        "mdi-road-variant",
        "mdi-robot",
        "mdi-rocket",
        "mdi-roomba",
        "mdi-rotate-3d",
        "mdi-rotate-left",
        "mdi-rotate-left-variant",
        "mdi-rotate-right",
        "mdi-rotate-right-variant",
        "mdi-rounded-corner",
        "mdi-router-wireless",
        "mdi-routes",
        "mdi-rowing",
        "mdi-rss",
        "mdi-rss-box",
        "mdi-ruler",
        "mdi-run",
        "mdi-run-fast",
        "mdi-sale",
        "mdi-sass",
        "mdi-satellite",
        "mdi-satellite-variant",
        "mdi-saxophone",
        "mdi-scale",
        "mdi-scale-balance",
        "mdi-scale-bathroom",
        "mdi-scanner",
        "mdi-school",
        "mdi-screen-rotation",
        "mdi-screen-rotation-lock",
        "mdi-screwdriver",
        "mdi-script",
        "mdi-sd",
        "mdi-seal",
        "mdi-search-web",
        "mdi-seat-flat",
        "mdi-seat-flat-angled",
        "mdi-seat-individual-suite",
        "mdi-seat-legroom-extra",
        "mdi-seat-legroom-normal",
        "mdi-seat-legroom-reduced",
        "mdi-seat-recline-extra",
        "mdi-seat-recline-normal",
        "mdi-security",
        "mdi-security-home",
        "mdi-security-network",
        "mdi-select",
        "mdi-select-all",
        "mdi-select-inverse",
        "mdi-select-off",
        "mdi-selection",
        "mdi-selection-off",
        "mdi-send",
        "mdi-send-secure",
        "mdi-serial-port",
        "mdi-server",
        "mdi-server-minus",
        "mdi-server-network",
        "mdi-server-network-off",
        "mdi-server-off",
        "mdi-server-plus",
        "mdi-server-remove",
        "mdi-server-security",
        "mdi-set-all",
        "mdi-set-center",
        "mdi-set-center-right",
        "mdi-set-left",
        "mdi-set-left-center",
        "mdi-set-left-right",
        "mdi-set-none",
        "mdi-set-right",
        "mdi-settings",
        "mdi-settings-box",
        "mdi-shape-circle-plus",
        "mdi-shape-plus",
        "mdi-shape-polygon-plus",
        "mdi-shape-rectangle-plus",
        "mdi-shape-square-plus",
        "mdi-share",
        "mdi-share-variant",
        "mdi-shield",
        "mdi-shield-half-full",
        "mdi-shield-outline",
        "mdi-shopping",
        "mdi-shopping-music",
        "mdi-shovel",
        "mdi-shovel-off",
        "mdi-shredder",
        "mdi-shuffle",
        "mdi-shuffle-disabled",
        "mdi-shuffle-variant",
        "mdi-sigma",
        "mdi-sigma-lower",
        "mdi-sign-caution",
        "mdi-sign-direction",
        "mdi-sign-text",
        "mdi-signal",
        "mdi-signal-2g",
        "mdi-signal-3g",
        "mdi-signal-4g",
        "mdi-signal-hspa",
        "mdi-signal-hspa-plus",
        "mdi-signal-off",
        "mdi-signal-variant",
        "mdi-silverware",
        "mdi-silverware-fork",
        "mdi-silverware-spoon",
        "mdi-silverware-variant",
        "mdi-sim",
        "mdi-sim-alert",
        "mdi-sim-off",
        "mdi-sitemap",
        "mdi-skip-backward",
        "mdi-skip-forward",
        "mdi-skip-next",
        "mdi-skip-next-circle",
        "mdi-skip-next-circle-outline",
        "mdi-skip-previous",
        "mdi-skip-previous-circle",
        "mdi-skip-previous-circle-outline",
        "mdi-skull",
        "mdi-skype",
        "mdi-skype-business",
        "mdi-slack",
        "mdi-sleep",
        "mdi-sleep-off",
        "mdi-smoking",
        "mdi-smoking-off",
        "mdi-snapchat",
        "mdi-snowflake",
        "mdi-snowman",
        "mdi-soccer",
        "mdi-sofa",
        "mdi-solid",
        "mdi-sort",
        "mdi-sort-alphabetical",
        "mdi-sort-ascending",
        "mdi-sort-descending",
        "mdi-sort-numeric",
        "mdi-sort-variant",
        "mdi-soundcloud",
        "mdi-source-branch",
        "mdi-source-commit",
        "mdi-source-commit-end",
        "mdi-source-commit-end-local",
        "mdi-source-commit-local",
        "mdi-source-commit-next-local",
        "mdi-source-commit-start",
        "mdi-source-commit-start-next-local",
        "mdi-source-fork",
        "mdi-source-merge",
        "mdi-source-pull",
        "mdi-soy-sauce",
        "mdi-speaker",
        "mdi-speaker-off",
        "mdi-speaker-wireless",
        "mdi-speedometer",
        "mdi-spellcheck",
        "mdi-spotify",
        "mdi-spotlight",
        "mdi-spotlight-beam",
        "mdi-spray",
        "mdi-square",
        "mdi-square-inc",
        "mdi-square-inc-cash",
        "mdi-square-outline",
        "mdi-square-root",
        "mdi-stackexchange",
        "mdi-stackoverflow",
        "mdi-stadium",
        "mdi-stairs",
        "mdi-standard-definition",
        "mdi-star",
        "mdi-star-circle",
        "mdi-star-half",
        "mdi-star-off",
        "mdi-star-outline",
        "mdi-steam",
        "mdi-steering",
        "mdi-step-backward",
        "mdi-step-backward-2",
        "mdi-step-forward",
        "mdi-step-forward-2",
        "mdi-stethoscope",
        "mdi-sticker",
        "mdi-sticker-emoji",
        "mdi-stocking",
        "mdi-stop",
        "mdi-stop-circle",
        "mdi-stop-circle-outline",
        "mdi-store",
        "mdi-store-24-hour",
        "mdi-stove",
        "mdi-subdirectory-arrow-left",
        "mdi-subdirectory-arrow-right",
        "mdi-subway",
        "mdi-subway-variant",
        "mdi-summit",
        "mdi-sunglasses",
        "mdi-surround-sound",
        "mdi-surround-sound-2-0",
        "mdi-surround-sound-3-1",
        "mdi-surround-sound-5-1",
        "mdi-surround-sound-7-1",
        "mdi-svg",
        "mdi-swap-horizontal",
        "mdi-swap-vertical",
        "mdi-swim",
        "mdi-switch",
        "mdi-sword",
        "mdi-sword-cross",
        "mdi-sync",
        "mdi-sync-alert",
        "mdi-sync-off",
        "mdi-tab",
        "mdi-tab-plus",
        "mdi-tab-unselected",
        "mdi-table",
        "mdi-table-column-plus-after",
        "mdi-table-column-plus-before",
        "mdi-table-column-remove",
        "mdi-table-column-width",
        "mdi-table-edit",
        "mdi-table-large",
        "mdi-table-row-height",
        "mdi-table-row-plus-after",
        "mdi-table-row-plus-before",
        "mdi-table-row-remove",
        "mdi-tablet",
        "mdi-tablet-android",
        "mdi-tablet-ipad",
        "mdi-taco",
        "mdi-tag",
        "mdi-tag-faces",
        "mdi-tag-heart",
        "mdi-tag-multiple",
        "mdi-tag-outline",
        "mdi-tag-plus",
        "mdi-tag-remove",
        "mdi-tag-text-outline",
        "mdi-target",
        "mdi-taxi",
        "mdi-teamviewer",
        "mdi-telegram",
        "mdi-television",
        "mdi-television-classic",
        "mdi-television-guide",
        "mdi-temperature-celsius",
        "mdi-temperature-fahrenheit",
        "mdi-temperature-kelvin",
        "mdi-tennis",
        "mdi-tent",
        "mdi-terrain",
        "mdi-test-tube",
        "mdi-text-shadow",
        "mdi-text-to-speech",
        "mdi-text-to-speech-off",
        "mdi-textbox",
        "mdi-textbox-password",
        "mdi-texture",
        "mdi-theater",
        "mdi-theme-light-dark",
        "mdi-thermometer",
        "mdi-thermometer-lines",
        "mdi-thought-bubble",
        "mdi-thought-bubble-outline",
        "mdi-thumb-down",
        "mdi-thumb-down-outline",
        "mdi-thumb-up",
        "mdi-thumb-up-outline",
        "mdi-thumbs-up-down",
        "mdi-ticket",
        "mdi-ticket-account",
        "mdi-ticket-confirmation",
        "mdi-ticket-percent",
        "mdi-tie",
        "mdi-tilde",
        "mdi-timelapse",
        "mdi-timer",
        "mdi-timer-10",
        "mdi-timer-3",
        "mdi-timer-off",
        "mdi-timer-sand",
        "mdi-timer-sand-empty",
        "mdi-timer-sand-full",
        "mdi-timetable",
        "mdi-toggle-switch",
        "mdi-toggle-switch-off",
        "mdi-tooltip",
        "mdi-tooltip-edit",
        "mdi-tooltip-image",
        "mdi-tooltip-outline",
        "mdi-tooltip-outline-plus",
        "mdi-tooltip-text",
        "mdi-tooth",
        "mdi-tor",
        "mdi-tower-beach",
        "mdi-tower-fire",
        "mdi-trackpad",
        "mdi-traffic-light",
        "mdi-train",
        "mdi-tram",
        "mdi-transcribe",
        "mdi-transcribe-close",
        "mdi-transfer",
        "mdi-transit-transfer",
        "mdi-translate",
        "mdi-treasure-chest",
        "mdi-tree",
        "mdi-trello",
        "mdi-trending-down",
        "mdi-trending-neutral",
        "mdi-trending-up",
        "mdi-triangle",
        "mdi-triangle-outline",
        "mdi-trophy",
        "mdi-trophy-award",
        "mdi-trophy-outline",
        "mdi-trophy-variant",
        "mdi-trophy-variant-outline",
        "mdi-truck",
        "mdi-truck-delivery",
        "mdi-truck-fast",
        "mdi-truck-trailer",
        "mdi-tshirt-crew",
        "mdi-tshirt-v",
        "mdi-tumblr",
        "mdi-tumblr-reblog",
        "mdi-tune",
        "mdi-tune-vertical",
        "mdi-twitch",
        "mdi-twitter",
        "mdi-twitter-box",
        "mdi-twitter-circle",
        "mdi-twitter-retweet",
        "mdi-uber",
        "mdi-ubuntu",
        "mdi-ultra-high-definition",
        "mdi-umbraco",
        "mdi-umbrella",
        "mdi-umbrella-outline",
        "mdi-undo",
        "mdi-undo-variant",
        "mdi-unfold-less-horizontal",
        "mdi-unfold-less-vertical",
        "mdi-unfold-more-horizontal",
        "mdi-unfold-more-vertical",
        "mdi-ungroup",
        "mdi-unity",
        "mdi-untappd",
        "mdi-update",
        "mdi-upload",
        "mdi-upload-network",
        "mdi-usb",
        "mdi-van-passenger",
        "mdi-van-utility",
        "mdi-vanish",
        "mdi-vector-arrange-above",
        "mdi-vector-arrange-below",
        "mdi-vector-circle",
        "mdi-vector-circle-variant",
        "mdi-vector-combine",
        "mdi-vector-curve",
        "mdi-vector-difference",
        "mdi-vector-difference-ab",
        "mdi-vector-difference-ba",
        "mdi-vector-intersection",
        "mdi-vector-line",
        "mdi-vector-point",
        "mdi-vector-polygon",
        "mdi-vector-polyline",
        "mdi-vector-radius",
        "mdi-vector-rectangle",
        "mdi-vector-selection",
        "mdi-vector-square",
        "mdi-vector-triangle",
        "mdi-vector-union",
        "mdi-verified",
        "mdi-vibrate",
        "mdi-video",
        "mdi-video-3d",
        "mdi-video-off",
        "mdi-video-switch",
        "mdi-view-agenda",
        "mdi-view-array",
        "mdi-view-carousel",
        "mdi-view-column",
        "mdi-view-dashboard",
        "mdi-view-day",
        "mdi-view-grid",
        "mdi-view-headline",
        "mdi-view-list",
        "mdi-view-module",
        "mdi-view-parallel",
        "mdi-view-quilt",
        "mdi-view-sequential",
        "mdi-view-stream",
        "mdi-view-week",
        "mdi-vimeo",
        "mdi-vine",
        "mdi-violin",
        "mdi-visualstudio",
        "mdi-vk",
        "mdi-vk-box",
        "mdi-vk-circle",
        "mdi-vlc",
        "mdi-voice",
        "mdi-voicemail",
        "mdi-volume-high",
        "mdi-volume-low",
        "mdi-volume-medium",
        "mdi-volume-minus",
        "mdi-volume-mute",
        "mdi-volume-off",
        "mdi-volume-plus",
        "mdi-vpn",
        "mdi-walk",
        "mdi-wall",
        "mdi-wallet",
        "mdi-wallet-giftcard",
        "mdi-wallet-membership",
        "mdi-wallet-travel",
        "mdi-wan",
        "mdi-washing-machine",
        "mdi-watch",
        "mdi-watch-export",
        "mdi-watch-import",
        "mdi-watch-vibrate",
        "mdi-water",
        "mdi-water-off",
        "mdi-water-percent",
        "mdi-water-pump",
        "mdi-watermark",
        "mdi-waves",
        "mdi-weather-cloudy",
        "mdi-weather-fog",
        "mdi-weather-hail",
        "mdi-weather-lightning",
        "mdi-weather-lightning-rainy",
        "mdi-weather-snowy",
        "mdi-weather-snowy-rainy",
        "mdi-weather-sunny",
        "mdi-weather-sunset",
        "mdi-weather-sunset-down",
        "mdi-weather-sunset-up",
        "mdi-weather-windy",
        "mdi-weather-windy-variant",
        "mdi-web",
        "mdi-webcam",
        "mdi-webhook",
        "mdi-wechat",
        "mdi-weight",
        "mdi-weight-kilogram",
        "mdi-whatsapp",
        "mdi-wheelchair-accessibility",
        "mdi-white-balance-auto",
        "mdi-white-balance-incandescent",
        "mdi-white-balance-iridescent",
        "mdi-white-balance-sunny",
        "mdi-widgets",
        "mdi-wifi",
        "mdi-wifi-off",
        "mdi-wii",
        "mdi-wiiu",
        "mdi-wikipedia",
        "mdi-window-close",
        "mdi-window-closed",
        "mdi-window-maximize",
        "mdi-window-minimize",
        "mdi-window-open",
        "mdi-window-restore",
        "mdi-windows",
        "mdi-wordpress",
        "mdi-worker",
        "mdi-wrap",
        "mdi-wrench",
        "mdi-wunderlist",
        "mdi-xaml",
        "mdi-xbox",
        "mdi-xbox-controller",
        "mdi-xbox-controller-battery-alert",
        "mdi-xbox-controller-battery-empty",
        "mdi-xbox-controller-battery-full",
        "mdi-xbox-controller-battery-low",
        "mdi-xbox-controller-battery-medium",
        "mdi-xbox-controller-battery-unknown",
        "mdi-xbox-controller-off",
        "mdi-xda",
        "mdi-xing",
        "mdi-xing-box",
        "mdi-xing-circle",
        "mdi-xda",
        "mdi-xing",
        "mdi-xing-box",
        "mdi-xing-circle",
        "mdi-xml",
        "mdi-xmpp",
        "mdi-yammer",
        "mdi-yeast",
        "mdi-yelp",
        "mdi-yin-yang",
        "mdi-youtube-play",
        "mdi-zip-box",
        "mdi-blank",
      ],
      links: [],
      show_text_color: false,
      show_background_color: false,
      show_activeBackground_color: false,
      show_active_text_color: false,
    };
  },
  methods: {
    ...mapMutations('landing',['setLandingBottomMenu']),
    ...mapMutations('branding',['setBottomMenu']),
    handleChange(menu,value) {
        menu.icon = value;
        this.showIcons = false;
        this.changeBottomMenu();
    },
    changeLang(lang) {
      this.$store.commit("landing/setCurrentLang", lang);
    },
    changeBottomMenu(){
      this.setLandingBottomMenu(JSON.parse(JSON.stringify(this.bottomMenu)));
    }
  },
  mounted() {
    for (let i = 0; i < this.landing.menu.list.length; i++) {
      let currentMenu = this.landing.menu.list[i];
      let startLink = `${process.env.VUE_APP_LINK}/${this.$route.params.id}/`;
      if (currentMenu.type === 1) {
        let link = startLink + "menu/" + currentMenu.page;
        this.links.push(link);
      } else if (currentMenu.type === 4) {
        let link = startLink + "menu/" + currentMenu.page;
        this.links.push(link);
        for (
          let ind = 0;
          ind <
          currentMenu.content[this.landing.current_lang][3].content.listing
            .length;
          ind++
        ) {
          for (
            let index = 0;
            index <
            currentMenu.content[this.landing.current_lang][3].content.listing[
              ind
            ].content.length;
            index++
          ) {
            let listingLink = `${startLink}menu/${currentMenu.page}/${
              ind + 1
            }/${index + 1}`;
            this.links.push(listingLink);
          }
        }
      } else if (currentMenu.type === 5) {
        let link = startLink + "submenu/" + currentMenu.page;
        this.links.push(link);
      } else if (currentMenu.type === 6) {
        let link = startLink + "submenu/" + currentMenu.page + "/faq";
        this.links.push(link);
      } else if (currentMenu.type === 7) {
        let link = startLink + "submenu/" + currentMenu.page + "/contact";
        this.links.push(link);
      } else if (currentMenu.type === 8) {
        let link = startLink + "submenu/" + currentMenu.page + "/events";
        this.links.push(link);
      }
    }
  },
  computed: {
    ...mapState(["landing"]),
    ...mapState("landing", ["selected_langs","icons"]),
    ...mapState('branding',['bottomMenu']),
    activeLang: {
      set: function (val) {},
      get: function () {
        return this.landing.advanced.selected_langs.indexOf(
          this.landing.current_lang
        );
      },
    },
    menuItems() {
      return this.landing.branding.bottomMenu.items;
    },
  },
};
</script>

<style lang="scss">
.menuable__content__active.v-autocomplete__content{
  z-index:204 !important;
}
.bottom_icons {
   & .dropzone {
     & .dz-preview {
       & .dz-image {
         & img {
           width: 50px;
           height: 50px;
           object-fit: contain;
         }
       }
       & .dz-error-message{
         width:200px;
         top:90px;
       }
       & .dz-remove{
         margin-left: 9px;
         padding: 5px 10px;
         bottom: 8px;
       }
     }
   }
 }
</style>
