<template>
  <div>
    <v-dialog :fullscreen="$store.state.isMobile" max-width="600" v-model="dialog"  @click:outside="$emit('closeDialog')">
        <div class="popup__content">
          <v-btn class="popup__close" small icon @click="$emit('closeDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h5 class="popup__title">
            {{ $t('popup["Вы уверены, что хотите сохранить данные?"]') }}
          </h5>
          <div class="popup__actions">
            <v-btn
              class="popup__action btn btn_primary"
              color="primary"
              depressed
              @click="$emit('closeDialog')"
            >
              {{ $t('popup["Закрыть"]') }}
            </v-btn>
            <v-btn
              class="popup__action btn btn_primary"
              color="primary"
              depressed
              @click="save"
            >
              {{ $t('popup["Сохранить"]') }}
            </v-btn>
          </div>
        </div>
    </v-dialog>
    <v-dialog :fullscreen="$store.state.isMobile" max-width="600" v-model="showAnotherVersion" @click="$emit('closeDialog')">
        <div class="popup__content">
          <v-btn class="popup__close" small icon @click="$emit('closeDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h5 class="popup__title">
            Your current version is older than the one you have.
          </h5>
          <div class="popup__actions">
            <v-btn
                class="popup__action btn btn_primary"
                color="primary"
                depressed
                @click="reload"
            >
              Update version
            </v-btn>
            <v-btn
                class="popup__action btn btn_primary"
                color="primary"
                depressed
                @click="save"
            >
              {{ $t('popup["Сохранить"]') }}
            </v-btn>
          </div>
        </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import PWAService from "../../../services/pwa.service";
export default {
  name:"SaveLanding",
  data() {
    return {
      showAnotherVersion:false,
    };
  },
  props:{
    dialog:{
      type:Boolean,
      default:false,
    }
  },
  computed: {
    ...mapState(["landing"]),
    ...mapState('menu',['selectedMenuItem']),
    ...mapState('mainPageStyles',['list','globalSettings','stylesOpen']),
    ...mapState('reviews',['reviews']),
    ...mapState('orders',['order','order_form']),
    ...mapState('banners',['selectedBanner','saveBanners']),
    ...mapState('intro',['introBanner','saveIntro']),
    ...mapState('passcode',['passcode']),
  },
  methods: {
    ...mapMutations("landing", ["setIsDirty", "setIsAbleToWatch",'setReviews','setOrder','setOrderForm']),
    ...mapActions("banners",['editBanner']),
    ...mapActions('intro',['putIntroBanner']),
    ...mapActions('passcode',['putPasscode']),
    reload(){
      window.location.reload();
    },
    async save() {
      this.$store.state.constructorLoader = true;
      this.$emit('closeDialog');
      const version = await this.$axios.get(`version?id=${this.$route.params.id}`);
      if(this.$store.state.objects.item.custom_id){
        await this.$store.dispatch('objects/edit',this.$store.state.objects.item);
      }
      if(+version.data.version !== +this.landing.version && !this.showAnotherVersion){
        this.$store.state.constructorLoader = false;
        this.showAnotherVersion = true;
        return;
      }
      this.showAnotherVersion = false;
      if(this.passcode.id){
        await this.putPasscode(this.passcode);
      }
      if(this.landing.advanced.global.pwa){
        await PWAService.putBannerPWA(this.$store.state.advanced.install_settings)
      }
      if(this.saveBanners.length){
        for(let  i = 0;i<this.saveBanners.length;i++){
          await this.editBanner(this.saveBanners[i]);
        }
      }
      if(this.selectedBanner){
        await this.editBanner(this.selectedBanner);
      }
      if(this.saveIntro.length){
        for(let i = 0;i<this.saveIntro.length;i++){
          await this.putIntroBanner(this.saveIntro[i]);
        }
      }
      if(this.introBanner){
        await this.putIntroBanner(this.introBanner);
      }
      if(this.stylesOpen){
        for (let i = 0; i < this.list.length; i++) {
          for (let key in this.list[i].styles) {
            this.landing.menu.list[i].styles[key] = this.list[i].styles[key];
          }
        }
        this.landing.menu.shadowSize = this.globalSettings.shadow_size;
        this.landing.menu.shadowColor = this.globalSettings.shadow_color;
        this.landing.menu.radius = this.globalSettings.radius;
        this.landing.menu.icon_size = this.globalSettings.icon_size;
      }
      if(this.$store.state.loader.loaderState.show){
        await this.$store.dispatch('loader/toServerLoader',this.$store.state.loader.loaderState);
      }
      if(this.selectedMenuItem){
        const candidate = this.landing.menu.list.find(item => item.id === this.selectedMenuItem.id);
        if(candidate){
          const candidateIndex = this.landing.menu.list.indexOf(candidate);
          if(candidateIndex >= 0){
            this.landing.menu.list.splice(candidateIndex,1,JSON.parse(JSON.stringify(this.selectedMenuItem)));
          }
        }
      }
      if(this.order){
        this.setOrder(JSON.parse(JSON.stringify(this.order)));
      }
      if(this.order_form){
        this.setOrderForm(JSON.parse(JSON.stringify(this.order_form)));
      }
      if(this.reviews){
        this.setReviews(JSON.parse(JSON.stringify(this.reviews)));
      }
      await this.$store.dispatch("landing/saveLanding", this.$route.params.id);
      this.setIsDirty(false);
      this.setIsAbleToWatch(true);
      this.$emit("save");
      this.$emit('closeDialog');
      this.$store.state.constructorLoader = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.popup__title {
  margin-bottom: 50px;
}
.popup__actions {
  display: flex;
  margin: 0 -10px;
}
.popup__action {
  width: calc(50% - 20px);
  margin: 0 10px;
}

.loading {
  position: fixed;
  top: 0;
  z-index:9999;
  left: 0;
  width: 100%;
  height: 100%;
  color: #7f7f7f;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__icon {
    animation: 1s linear 0s normal none infinite running circle;
  }
  @keyframes circle {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
