<template>
  <div class="upload-banner">
    <label class="label" style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;">Intro banner settings</label>
    <div v-if="introList.length">
      <div style="display: flex;align-items: center;margin-bottom:15px;">
        <label class="label" style="display: block;margin-bottom:0;margin-right:23px;width:200px;">Choose banner</label>
        <v-select hide-details
                  style="max-width:250px;" outlined dense :items="introList" item-value="id" v-model="selectOption" @change="changeSelectedIntro" return-object>
          <template v-slot:selection="{ item }">
            {{item.name}}
          </template>
          <template v-slot:item="{ item }">
            {{item.name}}
          </template>
        </v-select>
      </div>

      <div v-if="introBanner">
        <div style="display: flex;align-items: center;">
          <label class="label" style="display: block;margin-bottom:0;margin-right:23px;width:200px;">Name</label>
          <v-text-field
              outlined
              dense
              style="max-width:250px;"
              hide-details
              v-model="introBanner.name"
          ></v-text-field>
        </div>
        <v-tabs v-model="selected_lang">
          <v-tab v-for="(selected_lang, i) in selected_langs" :key="i" @click="changeLang(selected_lang)">
            <img
                :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                alt=""
                style="width: 20px; height: 15px; margin-right: 5px"
            />
            {{ selected_lang }}
          </v-tab>
          <v-tab-item v-for="lang in selected_langs" :key="lang" style="padding-top:15px;">
          </v-tab-item>
        </v-tabs>
          <div style="padding:15px 0;" v-if="introBanner.type === 0">
            <div class="mb-4">
              <label class="label" style="width:150px;display:block;margin-bottom:15px;">Top Image</label>
              <div>
                <vue-dropzone
                    id="uploadType0"
                    ref="uploadType0"
                    :options="dropzoneOptions"
                    :destroyDropzone="false"
                    @vdropzone-mounted="mountType0"
                    @vdropzone-sending='customEvent'
                    @vdropzone-removed-file="handleRemove"
                    @vdropzone-success="handleSuccess"
                >
                </vue-dropzone>
              </div>
            </div>
          </div>
          <div style="padding:15px 0;" v-if="introBanner.type === 1">
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="margin-bottom:0;width:200px">Upload video</label>
              <v-file-input
                  accept="video/*"
                  show-size
                  ref="videoUpload"
                  prepend-icon=""
                  outlined
                  hide-details
                  dense
                  @change="onFileChange"
              ></v-file-input></div>
          </div>
          <div style="padding:15px 0;" v-if="introBanner.type === 2">
            <div class="mb-4">
              <label class="label" style="width:150px;display:block;margin-bottom:15px;">Image</label>
              <div>
                <vue-dropzone
                    id="uploadTopPro"
                    ref="uploadTopPro"
                    :options="dropzoneOptionsTopPro"
                    :destroyDropzone="false"
                    @vdropzone-mounted="mountTopPro"
                    @vdropzone-sending='customEvent'
                    @vdropzone-removed-file="handleRemoveTopPro"
                    @vdropzone-success="handleSuccessTopPro"
                >
                </vue-dropzone>
              </div>
            </div>
            <div class="mb-4">
              <label class="label" style="width:150px;display:block;margin-bottom:15px;">Logo Image</label>
              <div>
                <vue-dropzone
                    id="uploadTopLogo"
                    ref="uploadTopLogo"
                    :options="dropzoneOptionsTopLogo"
                    :destroyDropzone="false"
                    @vdropzone-mounted="mountTopLogo"
                    @vdropzone-sending='customEvent'
                    @vdropzone-removed-file="handleRemoveTopLogo"
                    @vdropzone-success="handleSuccessTopLogo"
                >
                </vue-dropzone>
              </div>
            </div>
            <div class="mb-4">
              <div>
                <label class="label" style="width:200px;margin-bottom:15px;display:block;">Write your intro text</label>
                <Editor
                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                    :init="options"
                    v-model="introBanner.pro[landing.current_lang].main_description">
                </Editor>
              </div>
            </div>
            <div class="mb-4">
              <div>
                <label style="font-weight:bold;" class="label">Next Button Settings:</label>
              </div>
              <div style="padding:15px;">
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="width:200px;margin-bottom:0;">Next button</label>
                  <v-select
                      style="max-width: 100px;"
                      flat
                      dense
                      outlined
                      v-model="introBanner.pro[landing.current_lang].btn_next"
                      hide-details
                      :items="next_icons"
                      class="canvas__select"
                  >
                    <template v-slot:selection="{ item }">
                      <v-icon>{{ item }}</v-icon>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-icon>{{ item }}</v-icon>
                    </template>
                  </v-select>
                </div>
                <label class="label" style="display:block;margin-bottom:15px;">Write your next text</label>
                <Editor
                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                    :init="options"
                    v-model="introBanner.pro[landing.current_lang].text_next">
                </Editor>
              </div>
            </div>
            <div>
              <div>
                <label style="font-weight:bold;" class="label">Logo Settings:</label>
              </div>
              <div style="padding:15px;">
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="width:200px;margin-bottom:0;">Height <b>{{introBanner.pro.logo_height}} px</b></label>
                  <div class="control" style="width:100%">
                    <div class="slider">
                      <v-slider
                          hide-details
                          :min="30"
                          :max="500"
                          v-model="introBanner.pro.logo_height"
                      ></v-slider>
                    </div>
                  </div>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="width:200px;margin-bottom:0;">Width <b>{{introBanner.pro.logo_width}} px</b></label>
                  <div class="control" style="width:100%">
                    <div class="slider">
                      <v-slider
                          hide-details
                          :min="30"
                          :max="500"
                          v-model="introBanner.pro.logo_width"
                      ></v-slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div>
          <label style="font-weight:bold" class="label">General Settings:</label>
          <div style="padding:0 15px;">
            <div class="mb-4" style="display:flex;align-items:center;justify-content:space-between;">
              <div style="display:flex;align-items:center;margin-right:15px;">
                <label class="label" style="margin-bottom:0;width:200px;margin-right:8px;">Show seconds</label>
                <v-text-field outlined hide-details dense style="max-width:250px;" v-model.number="introBanner.show_time" type="number"></v-text-field>
              </div>
              <div style="display:flex;align-items:center;">
                <label class="label" style="margin-bottom:0;margin-right:15px;">Repeat video?</label>
                <v-switch color="#6ab64a" outlined :value="introBanner.repeat?1:0" @change="changeRepeat" dense style="max-width:150px;margin-top:0;padding-top:0;" hide-details ></v-switch>
              </div>
            </div>
            <div class="mb-4" style="display:flex;align-items:center;justify-content: space-between;">
              <div style="display: flex;align-items: center;">
                <label class="label" style="margin-bottom: 0;width:200px;margin-right:8px;">Background color</label>
                <v-menu
                    v-model="addIntroBannerModelBg"
                    top
                    nudge-bottom="98"
                    nudge-left="16"
                    :close-on-content-click="false"
                    z-index="212"
                >
                  <template v-slot:activator="{ on }">
                    <div :style="`background-color:${introBanner.styles.bg}`" class="color" v-on="on" />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                          v-model="introBanner.styles.bg"
                          flat
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </div>
          </div>
          </div>
          <div>
            <label class="label" style="font-weight:bold;">Exit Icon Settings:</label>
            <div style="padding:15px;">
              <div style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="margin-bottom: 0;width:200px;margin-right:8px;">Icon color</label>
                <v-menu
                    v-model="addIntroBannerModelIconColor"
                    top
                    nudge-bottom="98"
                    nudge-left="16"
                    :close-on-content-click="false"
                    z-index="212"
                >
                  <template v-slot:activator="{ on }">
                    <div :style="`background-color:${introBanner.styles.icon_color}`" class="color" v-on="on" />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                          v-model="introBanner.styles.icon_color"
                          flat
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
              <div style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="margin-bottom: 0;width:200px;margin-right:8px;">Icon</label>
                <v-select
                    style="max-width: 250px;"
                    flat
                    dense
                    outlined
                    v-model="introBanner.styles.icon"
                    hide-details
                    :items="icons"
                    class="canvas__select"
                >
                  <template v-slot:selection="{ item }">
                    <v-icon>{{ item }}</v-icon>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-icon>{{ item }}</v-icon>
                  </template>
                </v-select>
              </div>
              <div style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="margin-bottom: 0;min-width:200px">Icon size</label>
                <div style="width:100%;">
                  <v-slider
                      :max="50"
                      hide-details
                      :min="20"
                      v-model="introBanner.styles.icon_size"
                  ></v-slider>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div v-else>
      You don`t have any intro banners, to create them <router-link :to="`/banners?id=${$route.params.id}`">go here</router-link>.
    </div>
    <PopupSuccess ref="PopupSuccess" :title="'Success!'" :content="successMessage" />
    <PopupCrop title='Change image before upload?' ref="PopupCrop" @confirm="openEditor" @save="saveImg" @close="closeCrop"/>
    <PopupCrop title='Change image before upload?' ref="PopupCropTopPro" @confirm="openEditorTopPro" @save="saveImgTopPro" @close="closeCropTopPro"/>
    <PopupCrop title='Change image before upload?' ref="PopupCropTopLogo" @confirm="openEditorTopLogo" @save="saveImgTopLogo" @close="closeCropTopLogo"/>
    <PopupSuccess ref="PopupSuccess" :title="'Success!'" :content="successMessage" />
  </div>
</template>
<script>
import {mapState,mapActions,mapMutations} from "vuex";

import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Compressor from "compressorjs";
import Cropper from "cropperjs";
export default {
  name:"Intro",
  props:['lang'],
  components:{
    vueDropzone:() => import("vue2-dropzone"),
    PopupCrop:() => import("@/components/Popup/PopupCrop"),
    PopupSuccess:() => import("@/components/Popup/PopupSuccess"),
  },
  data(){
    return{
      selectOption:null,
      addIntroBannerModelIconColor:false,
      addIntroBannerModelBg:false,
      selected_lang:0,
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 3,
        maxFiles: 1,
        destroyDropzone: false,
        dictFileTooBig:"File is too big ({{filesize}}mb). Max file size: 3mb",
        autoProcessQueue: false,
        thumbnail: (file, dataUrl) => {
          if (file.cropped) {
            return;
          }else{
            this.compress(file)
          }
        },
      },
      dropzoneOptionsTopPro: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 3,
        maxFiles: 1,
        destroyDropzone: false,
        dictFileTooBig:"File is too big ({{filesize}}mb). Max file size: 3mb",
        autoProcessQueue: false,
        thumbnail: (file, dataUrl) => {
          if (file.cropped) {
            return;
          }else{
            this.compressTopPro(file)
          }
        },
      },
      dropzoneOptionsTopLogo: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 3,
        maxFiles: 1,
        destroyDropzone: false,
        dictFileTooBig:"File is too big ({{filesize}}mb). Max file size: 3mb",
        autoProcessQueue: false,
        thumbnail: (file, dataUrl) => {
          if (file.cropped) {
            return;
          }else{
            this.compressTopLogo(file)
          }
        },
      },
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: 'jpg,svg,webp,gif,png',
          images_upload_handler: async  (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
            formData.append("lending_settings_id", this.$store.state.landing.settings_id);
            formData.append("image", _file);
            formData.append("file", _file);
            const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
            success(data);
          },
          plugins:
              "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
                '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 200,
        image_caption: true,
        quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

        setup:function(editor){
          editor.ui.registry.addButton('custom_button', {
            text: 'Add Button',
            onAction: function() {
              // Open a Dialog
              editor.windowManager.open({
                title: 'Add custom button',
                body: {
                  type: 'panel',
                  items: [{
                    type: 'input',
                    name: 'button_label',
                    label: 'Button label',
                    flex: true
                  },{
                    type: 'input',
                    name: 'button_href',
                    label: 'Button href',
                    flex: true
                  },{
                    type: 'selectbox',
                    name: 'button_target',
                    label: 'Target',
                    items: [
                      {text: 'None', value: ''},
                      {text: 'New window', value: '_blank'},
                      {text: 'Self', value: '_self'},
                      {text: 'Parent', value: '_parent'}
                    ],
                    flex: true
                  },{
                    type: 'selectbox',
                    name: 'button_style',
                    label: 'Style',
                    items: [
                      {text: 'Success', value: 'success'},
                      {text: 'Info', value: 'info'},
                      {text: 'Warning', value: 'warning'},
                      {text: 'Error', value: 'error'}
                    ],
                    flex: true
                  }]
                },
                onSubmit: function (api) {

                  var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                  // insert markup
                  editor.insertContent(html);

                  // close the dialog
                  api.close();
                },
                buttons: [
                  {
                    text: 'Close',
                    type: 'cancel',
                    onclick: 'close'
                  },
                  {
                    text: 'Insert',
                    type: 'submit',
                    primary: true,
                    enabled: false
                  }
                ]
              });
            }
          });
        },
        content_css: ["/custom_tinymce.css"],
      },
      successMessage:"",
      icons:['mdi-close','mdi-close-box','mdi-close-box-outline','mdi-close-circle',
        'mdi-close-circle-outline','mdi-close-octagon','mdi-close-octagon-outline','mdi-close-outline','mdi-close-network'],
      next_icons:[
        'mdi-arrow-right',
        'mdi-arrow-right-bold',
        'mdi-arrow-right-bold-box',
        'mdi-arrow-right-bold-box-outline',
        'mdi-arrow-right-bold-circle',
        'mdi-arrow-right-bold-circle-outline',
        'mdi-arrow-right-bold-hexagon-outline',
        'mdi-arrow-right-bold-outline',
        'mdi-arrow-right-box',
        'mdi-arrow-right-circle',
        'mdi-arrow-right-circle-outline',
        'mdi-arrow-right-drop-circle',
        'mdi-arrow-right-drop-circle-outline',
        'mdi-arrow-right-thin-circle-outline',
        'mdi-chevron-right',
        'mdi-menu-right',
      ],
    }
  },
  computed:{
    ...mapState('intro',['introList','introBanner']),
    ...mapState(['landing']),
    ...mapState('landing',['selected_langs']),
  },
  methods:{
    ...mapActions('intro',['getIntroBanners']),
    ...mapMutations('intro',['setIntroBanner','setPreviewImage','setPreviewTop','setPreviewLogo']),
    changeLang(lang){
      this.landing.current_lang = lang;
      if(this.introBanner.type === 0){
        this.mountType0();
      }
      if(this.introBanner.type === 2){
        this.mountTopLogo();
        this.mountTopPro();
      }
    },
    changeRepeat(){
      if(this.addIntroBannerModel.repeat){
        this.addIntroBannerModel.repeat = 0;
      }else{
        this.addIntroBannerModel.repeat = 1;
      }
    },
    compress(file){
      new Compressor(file, {
        quality: 0.6,
        mimeType:'image/webp',
        convertSize:0,
        convertTypes:['image/jpeg','image/png','image/jpg'],
        success:(result) => {
          if(this.$store.state.isMobile){
            this.saveImg(result)
          }else{
            this.$refs.PopupCrop.open(result)
          }
        },
        error(e) {
          console.log(e);
        }
      })
    },
    compressTopPro(file){
      new Compressor(file, {
        quality: 0.6,
        mimeType:'image/webp',
        convertSize:0,
        convertTypes:['image/jpeg','image/png','image/jpg'],
        success:(result) => {
          this.$refs.PopupCropTopPro.open(result)
        },
        error(e) {
          console.log(e);
        }
      })
    },
    compressTopLogo(file){
      new Compressor(file, {
        quality: 0.6,
        mimeType:'image/webp',
        convertSize:0,
        convertTypes:['image/jpeg','image/png','image/jpg'],
        success:(result) => {
          this.$refs.PopupCropTopLogo.open(result)
        },
        error(e) {
          console.log(e);
        }
      })
    },
    blobToDataURL(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = _e => resolve(reader.result);
        reader.onerror = _e => reject(reader.error);
        reader.onabort = _e => reject(new Error("Read aborted"));
        reader.readAsDataURL(blob);
      });
    },
    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    async saveImg(file){
      file.cropped = true;
      this.$refs.uploadType0.dropzone.removeAllFiles();
      this.$refs.uploadType0.dropzone.addFile(file);
      this.$refs.uploadType0.dropzone.processQueue();
      this.$refs.uploadType0.dropzone[
          "previewsContainer"
          ].children[1].children[0].children[0].src = await this.blobToDataURL(file);
      this.$refs.uploadType0.dropzone[
          "previewsContainer"
          ].children[1].children[0].style.borderRadius = 0;
      this.$refs.uploadType0.dropzone[
          "previewsContainer"
          ].children[1].children[1].style.background = "none";
    },
    openEditor(file){
      let editor = document.createElement("div");
      editor.style.position = "fixed";
      editor.style.left = 0;
      editor.style.right = 0;
      editor.style.top = 0;
      editor.style.bottom = 0;
      editor.style.width = "66%";
      editor.style.zIndex = 9999;
      editor.style.backgroundColor = "#000";
      document.body.appendChild(editor);
      if (this.introBanner.image[this.landing.current_lang]) {
        this.$refs.uploadType0.dropzone.removeFile(file);
      }
      let buttonConfirm = document.createElement("button");
      buttonConfirm.style.position = "absolute";
      buttonConfirm.style.right = "10px";
      buttonConfirm.style.top = "10px";
      buttonConfirm.style.zIndex = 9999;
      buttonConfirm.textContent = "Save image";
      buttonConfirm.className =
          "v-btn v-size--small confirm-control";
      editor.appendChild(buttonConfirm);
      let buttonCancel = document.createElement("button");
      buttonCancel.style.position = "absolute";
      buttonCancel.style.right = "135px";
      buttonCancel.style.top = "10px";
      buttonCancel.style.zIndex = 9999;
      buttonCancel.textContent = "Cancel";
      buttonCancel.className =
          "v-btn v-size--small confirm-control";
      editor.appendChild(buttonCancel);
      buttonCancel.addEventListener("click", () => {
        this.$refs.uploadType0.dropzone.removeAllFiles();
        document.body.removeChild(editor);
        cropper.destroy();
      });
      let buttonRotateLeft = document.createElement("button");
      buttonRotateLeft.className = "cropper-controls";
      buttonRotateLeft.style.left = "24px";
      buttonRotateLeft.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M13,4.07V1L8.45,5.55L13,10V6.09C15.84,6.57 18,9.03 18,12C18,14.97 15.84,17.43 13,17.91V19.93C16.95,19.44 20,16.08 20,12C20,7.92 16.95,4.56 13,4.07M7.1,18.32C8.26,19.22 9.61,19.76 11,19.93V17.9C10.13,17.75 9.29,17.41 8.54,16.87L7.1,18.32M6.09,13H4.07C4.24,14.39 4.79,15.73 5.69,16.89L7.1,15.47C6.58,14.72 6.23,13.88 6.09,13M7.11,8.53L5.7,7.11C4.8,8.27 4.24,9.61 4.07,11H6.09C6.23,10.13 6.58,9.28 7.11,8.53Z" />
</svg>`;
      editor.appendChild(buttonRotateLeft);
      let buttonRotateRight = document.createElement("button");
      buttonRotateRight.className = "cropper-controls";
      buttonRotateRight.style.left = "64px";
      buttonRotateRight.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M16.89,15.5L18.31,16.89C19.21,15.73 19.76,14.39 19.93,13H17.91C17.77,13.87 17.43,14.72 16.89,15.5M13,17.9V19.92C14.39,19.75 15.74,19.21 16.9,18.31L15.46,16.87C14.71,17.41 13.87,17.76 13,17.9M19.93,11C19.76,9.61 19.21,8.27 18.31,7.11L16.89,8.53C17.43,9.28 17.77,10.13 17.91,11M15.55,5.55L11,1V4.07C7.06,4.56 4,7.92 4,12C4,16.08 7.05,19.44 11,19.93V17.91C8.16,17.43 6,14.97 6,12C6,9.03 8.16,6.57 11,6.09V10L15.55,5.55Z" />
</svg>`;
      editor.appendChild(buttonRotateRight);
      let buttonCrop = document.createElement("button");
      buttonCrop.className = "cropper-controls active-control";
      buttonCrop.style.left = "104px";
      buttonCrop.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M7,17V1H5V5H1V7H5V17A2,2 0 0,0 7,19H17V23H19V19H23V17M17,15H19V7C19,5.89 18.1,5 17,5H9V7H17V15Z" />
</svg>`;
      editor.appendChild(buttonCrop);
      let buttonZoomPlus = document.createElement("button");
      buttonZoomPlus.className = "cropper-controls";
      buttonZoomPlus.style.left = "144px";
      buttonZoomPlus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
</svg>`;
      editor.appendChild(buttonZoomPlus);
      let buttonZoomMinus = document.createElement("button");
      buttonZoomMinus.className = "cropper-controls";
      buttonZoomMinus.style.left = "184px";
      buttonZoomMinus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
</svg>`;
      editor.appendChild(buttonZoomMinus);
      let buttonLeftRight = document.createElement("button");
      buttonLeftRight.className = "cropper-controls";
      buttonLeftRight.style.left = "224px";
      buttonLeftRight.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
</svg>`;
      editor.appendChild(buttonLeftRight);
      let buttonUpDown = document.createElement("button");
      buttonUpDown.className = "cropper-controls";
      buttonUpDown.style.left = "264px";
      buttonUpDown.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z" />
</svg>`;
      editor.appendChild(buttonUpDown);
      buttonConfirm.addEventListener("click", () => {
        let canvas = cropper.getCroppedCanvas();
        let newFile = this.dataURItoBlob(canvas.toDataURL(file.type));
        newFile.cropped = true;
        newFile.name = file.name;
        this.$refs.uploadType0.dropzone.removeAllFiles();
        this.$refs.uploadType0.dropzone.addFile(newFile);
        this.$refs.uploadType0.dropzone.processQueue();
        this.$refs.uploadType0.dropzone[
            "previewsContainer"
            ].children[1].children[0].children[0].src =  canvas.toDataURL(file.type);
        this.$refs.uploadType0.dropzone[
            "previewsContainer"
            ].children[1].children[0].style.borderRadius = 0;
        this.$refs.uploadType0.dropzone[
            "previewsContainer"
            ].children[1].children[1].style.background = "none";
        this.setPreviewImage(false);
        document.body.removeChild(editor);
        cropper.destroy();
      });
      buttonRotateRight.addEventListener("click", () => {
        if (!buttonRotateRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateRight.classList.add("active-control");
        }
        cropper.rotate(45);
      });
      buttonRotateLeft.addEventListener("click", () => {
        if (!buttonRotateLeft.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateLeft.classList.add("active-control");
        }
        cropper.rotate(-45);
      });

      buttonCrop.addEventListener("click", () => {
        if (!buttonCrop.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonCrop.classList.add("active-control");
        }
        cropper.setDragMode("crop");
      });
      buttonZoomPlus.addEventListener("click", () => {
        if (!buttonZoomPlus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomPlus.classList.add("active-control");
        }
        cropper.zoom(0.1);
      });
      buttonZoomMinus.addEventListener("click", () => {
        if (!buttonZoomMinus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomMinus.classList.add("active-control");
        }
        cropper.zoom(-0.1);
      });
      buttonLeftRight.addEventListener("click", () => {
        if (!buttonLeftRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonLeftRight.classList.add("active-control");
        }
        if (cropper.getData().scaleX === 1) {
          cropper.scaleX(-1);
        } else {
          cropper.scaleX(1);
        }
      });
      buttonUpDown.addEventListener("click", () => {
        if (!buttonUpDown.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonUpDown.classList.add("active-control");
        }
        if (cropper.getData().scaleY === 1) {
          cropper.scaleY(-1);
        } else {
          cropper.scaleY(1);
        }
      });
      let image = new Image();
      image.src = URL.createObjectURL(file);
      editor.appendChild(image);
      this.setPreviewImage(true);
      let cropper = new Cropper(image, {
        preview: ".intro_preview",
        aspectRatio: 0.63157894736,
      });
    },
    closeCrop(){
      this.$refs.uploadType0.dropzone.removeAllFiles();
    },
    async saveImgTopPro(file){
      file.cropped = true;
      this.$refs.uploadTopPro.dropzone.removeAllFiles();
      this.$refs.uploadTopPro.dropzone.addFile(file);
      this.$refs.uploadTopPro.dropzone.processQueue();
      this.$refs.uploadTopPro.dropzone[
          "previewsContainer"
          ].children[1].children[0].children[0].src = await this.blobToDataURL(file);
      this.$refs.uploadTopPro.dropzone[
          "previewsContainer"
          ].children[1].children[0].style.borderRadius = 0;
      this.$refs.uploadTopPro.dropzone[
          "previewsContainer"
          ].children[1].children[1].style.background = "none";
    },
    closeCropTopPro(){
      this.$refs.uploadTopPro.dropzone.removeAllFiles();
    },
    openEditorTopPro(file){
      let editor = document.createElement("div");
      editor.style.position = "fixed";
      editor.style.left = 0;
      editor.style.right = 0;
      editor.style.top = 0;
      editor.style.bottom = 0;
      editor.style.width = "66%";
      editor.style.zIndex = 9999;
      editor.style.backgroundColor = "#000";
      document.body.appendChild(editor);
      if (this.introBanner.pro[this.landing.current_lang].top_img) {
        this.$refs.uploadTopPro.dropzone.removeFile(file);
      }
      let buttonConfirm = document.createElement("button");
      buttonConfirm.style.position = "absolute";
      buttonConfirm.style.right = "10px";
      buttonConfirm.style.top = "10px";
      buttonConfirm.style.zIndex = 9999;
      buttonConfirm.textContent = "Save image";
      buttonConfirm.className =
          "v-btn v-size--small confirm-control";
      editor.appendChild(buttonConfirm);
      let buttonCancel = document.createElement("button");
      buttonCancel.style.position = "absolute";
      buttonCancel.style.right = "135px";
      buttonCancel.style.top = "10px";
      buttonCancel.style.zIndex = 9999;
      buttonCancel.textContent = "Cancel";
      buttonCancel.className =
          "v-btn v-size--small confirm-control";
      editor.appendChild(buttonCancel);
      buttonCancel.addEventListener("click", () => {
        this.$refs.uploadTopPro.dropzone.removeAllFiles();
        document.body.removeChild(editor);
        cropper.destroy();
      });
      let buttonRotateLeft = document.createElement("button");
      buttonRotateLeft.className = "cropper-controls";
      buttonRotateLeft.style.left = "24px";
      buttonRotateLeft.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M13,4.07V1L8.45,5.55L13,10V6.09C15.84,6.57 18,9.03 18,12C18,14.97 15.84,17.43 13,17.91V19.93C16.95,19.44 20,16.08 20,12C20,7.92 16.95,4.56 13,4.07M7.1,18.32C8.26,19.22 9.61,19.76 11,19.93V17.9C10.13,17.75 9.29,17.41 8.54,16.87L7.1,18.32M6.09,13H4.07C4.24,14.39 4.79,15.73 5.69,16.89L7.1,15.47C6.58,14.72 6.23,13.88 6.09,13M7.11,8.53L5.7,7.11C4.8,8.27 4.24,9.61 4.07,11H6.09C6.23,10.13 6.58,9.28 7.11,8.53Z" />
</svg>`;
      editor.appendChild(buttonRotateLeft);
      let buttonRotateRight = document.createElement("button");
      buttonRotateRight.className = "cropper-controls";
      buttonRotateRight.style.left = "64px";
      buttonRotateRight.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M16.89,15.5L18.31,16.89C19.21,15.73 19.76,14.39 19.93,13H17.91C17.77,13.87 17.43,14.72 16.89,15.5M13,17.9V19.92C14.39,19.75 15.74,19.21 16.9,18.31L15.46,16.87C14.71,17.41 13.87,17.76 13,17.9M19.93,11C19.76,9.61 19.21,8.27 18.31,7.11L16.89,8.53C17.43,9.28 17.77,10.13 17.91,11M15.55,5.55L11,1V4.07C7.06,4.56 4,7.92 4,12C4,16.08 7.05,19.44 11,19.93V17.91C8.16,17.43 6,14.97 6,12C6,9.03 8.16,6.57 11,6.09V10L15.55,5.55Z" />
</svg>`;
      editor.appendChild(buttonRotateRight);
      let buttonCrop = document.createElement("button");
      buttonCrop.className = "cropper-controls active-control";
      buttonCrop.style.left = "104px";
      buttonCrop.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M7,17V1H5V5H1V7H5V17A2,2 0 0,0 7,19H17V23H19V19H23V17M17,15H19V7C19,5.89 18.1,5 17,5H9V7H17V15Z" />
</svg>`;
      editor.appendChild(buttonCrop);
      let buttonZoomPlus = document.createElement("button");
      buttonZoomPlus.className = "cropper-controls";
      buttonZoomPlus.style.left = "144px";
      buttonZoomPlus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
</svg>`;
      editor.appendChild(buttonZoomPlus);
      let buttonZoomMinus = document.createElement("button");
      buttonZoomMinus.className = "cropper-controls";
      buttonZoomMinus.style.left = "184px";
      buttonZoomMinus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
</svg>`;
      editor.appendChild(buttonZoomMinus);
      let buttonLeftRight = document.createElement("button");
      buttonLeftRight.className = "cropper-controls";
      buttonLeftRight.style.left = "224px";
      buttonLeftRight.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
</svg>`;
      editor.appendChild(buttonLeftRight);
      let buttonUpDown = document.createElement("button");
      buttonUpDown.className = "cropper-controls";
      buttonUpDown.style.left = "264px";
      buttonUpDown.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z" />
</svg>`;
      editor.appendChild(buttonUpDown);
      buttonConfirm.addEventListener("click", () => {
        let canvas = cropper.getCroppedCanvas();
        let newFile = this.dataURItoBlob(canvas.toDataURL(file.type));
        newFile.cropped = true;
        newFile.name = file.name;
        this.$refs.uploadTopPro.dropzone.removeAllFiles();
        this.$refs.uploadTopPro.dropzone.addFile(newFile);
        this.$refs.uploadTopPro.dropzone.processQueue();
        this.$refs.uploadTopPro.dropzone[
            "previewsContainer"
            ].children[1].children[0].children[0].src =  canvas.toDataURL(file.type);
        this.$refs.uploadTopPro.dropzone[
            "previewsContainer"
            ].children[1].children[0].style.borderRadius = 0;
        this.$refs.uploadTopPro.dropzone[
            "previewsContainer"
            ].children[1].children[1].style.background = "none";
        this.setPreviewTop(false);
        document.body.removeChild(editor);
        cropper.destroy();
      });
      buttonRotateRight.addEventListener("click", () => {
        if (!buttonRotateRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateRight.classList.add("active-control");
        }
        cropper.rotate(45);
      });
      buttonRotateLeft.addEventListener("click", () => {
        if (!buttonRotateLeft.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateLeft.classList.add("active-control");
        }
        cropper.rotate(-45);
      });

      buttonCrop.addEventListener("click", () => {
        if (!buttonCrop.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonCrop.classList.add("active-control");
        }
        cropper.setDragMode("crop");
      });
      buttonZoomPlus.addEventListener("click", () => {
        if (!buttonZoomPlus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomPlus.classList.add("active-control");
        }
        cropper.zoom(0.1);
      });
      buttonZoomMinus.addEventListener("click", () => {
        if (!buttonZoomMinus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomMinus.classList.add("active-control");
        }
        cropper.zoom(-0.1);
      });
      buttonLeftRight.addEventListener("click", () => {
        if (!buttonLeftRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonLeftRight.classList.add("active-control");
        }
        if (cropper.getData().scaleX === 1) {
          cropper.scaleX(-1);
        } else {
          cropper.scaleX(1);
        }
      });
      buttonUpDown.addEventListener("click", () => {
        if (!buttonUpDown.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonUpDown.classList.add("active-control");
        }
        if (cropper.getData().scaleY === 1) {
          cropper.scaleY(-1);
        } else {
          cropper.scaleY(1);
        }
      });
      let image = new Image();
      image.src = URL.createObjectURL(file);
      editor.appendChild(image);
      this.setPreviewTop(true);
      let cropper = new Cropper(image, {
        preview: ".intro_top_img_preview",
        aspectRatio: 360/200,
      });
    },
    async saveImgTopLogo(file){
      file.cropped = true;
      this.$refs.uploadTopLogo.dropzone.removeAllFiles();
      this.$refs.uploadTopLogo.dropzone.addFile(file);
      this.$refs.uploadTopLogo.dropzone.processQueue();
      this.$refs.uploadTopLogo.dropzone[
          "previewsContainer"
          ].children[1].children[0].children[0].src = await this.blobToDataURL(file);
      this.$refs.uploadTopLogo.dropzone[
          "previewsContainer"
          ].children[1].children[0].style.borderRadius = 0;
      this.$refs.uploadTopLogo.dropzone[
          "previewsContainer"
          ].children[1].children[1].style.background = "none";
    },
    closeCropTopLogo(){
      this.$refs.uploadTopLogo.dropzone.removeAllFiles();
    },
    openEditorTopLogo(file){
      let editor = document.createElement("div");
      editor.style.position = "fixed";
      editor.style.left = 0;
      editor.style.right = 0;
      editor.style.top = 0;
      editor.style.bottom = 0;
      editor.style.width = "66%";
      editor.style.zIndex = 9999;
      editor.style.backgroundColor = "#000";
      document.body.appendChild(editor);
      if (this.introBanner.pro[this.landing.current_lang].logo_img) {
        this.$refs.uploadTopLogo.dropzone.removeFile(file);
      }
      let buttonConfirm = document.createElement("button");
      buttonConfirm.style.position = "absolute";
      buttonConfirm.style.right = "10px";
      buttonConfirm.style.top = "10px";
      buttonConfirm.style.zIndex = 9999;
      buttonConfirm.textContent = "Save image";
      buttonConfirm.className =
          "v-btn v-size--small confirm-control";
      editor.appendChild(buttonConfirm);
      let buttonCancel = document.createElement("button");
      buttonCancel.style.position = "absolute";
      buttonCancel.style.right = "135px";
      buttonCancel.style.top = "10px";
      buttonCancel.style.zIndex = 9999;
      buttonCancel.textContent = "Cancel";
      buttonCancel.className =
          "v-btn v-size--small confirm-control";
      editor.appendChild(buttonCancel);
      buttonCancel.addEventListener("click", () => {
        this.$refs.uploadTopLogo.dropzone.removeAllFiles();
        document.body.removeChild(editor);
        cropper.destroy();
      });
      let buttonRotateLeft = document.createElement("button");
      buttonRotateLeft.className = "cropper-controls";
      buttonRotateLeft.style.left = "24px";
      buttonRotateLeft.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M13,4.07V1L8.45,5.55L13,10V6.09C15.84,6.57 18,9.03 18,12C18,14.97 15.84,17.43 13,17.91V19.93C16.95,19.44 20,16.08 20,12C20,7.92 16.95,4.56 13,4.07M7.1,18.32C8.26,19.22 9.61,19.76 11,19.93V17.9C10.13,17.75 9.29,17.41 8.54,16.87L7.1,18.32M6.09,13H4.07C4.24,14.39 4.79,15.73 5.69,16.89L7.1,15.47C6.58,14.72 6.23,13.88 6.09,13M7.11,8.53L5.7,7.11C4.8,8.27 4.24,9.61 4.07,11H6.09C6.23,10.13 6.58,9.28 7.11,8.53Z" />
</svg>`;
      editor.appendChild(buttonRotateLeft);
      let buttonRotateRight = document.createElement("button");
      buttonRotateRight.className = "cropper-controls";
      buttonRotateRight.style.left = "64px";
      buttonRotateRight.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M16.89,15.5L18.31,16.89C19.21,15.73 19.76,14.39 19.93,13H17.91C17.77,13.87 17.43,14.72 16.89,15.5M13,17.9V19.92C14.39,19.75 15.74,19.21 16.9,18.31L15.46,16.87C14.71,17.41 13.87,17.76 13,17.9M19.93,11C19.76,9.61 19.21,8.27 18.31,7.11L16.89,8.53C17.43,9.28 17.77,10.13 17.91,11M15.55,5.55L11,1V4.07C7.06,4.56 4,7.92 4,12C4,16.08 7.05,19.44 11,19.93V17.91C8.16,17.43 6,14.97 6,12C6,9.03 8.16,6.57 11,6.09V10L15.55,5.55Z" />
</svg>`;
      editor.appendChild(buttonRotateRight);
      let buttonCrop = document.createElement("button");
      buttonCrop.className = "cropper-controls active-control";
      buttonCrop.style.left = "104px";
      buttonCrop.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M7,17V1H5V5H1V7H5V17A2,2 0 0,0 7,19H17V23H19V19H23V17M17,15H19V7C19,5.89 18.1,5 17,5H9V7H17V15Z" />
</svg>`;
      editor.appendChild(buttonCrop);
      let buttonZoomPlus = document.createElement("button");
      buttonZoomPlus.className = "cropper-controls";
      buttonZoomPlus.style.left = "144px";
      buttonZoomPlus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
</svg>`;
      editor.appendChild(buttonZoomPlus);
      let buttonZoomMinus = document.createElement("button");
      buttonZoomMinus.className = "cropper-controls";
      buttonZoomMinus.style.left = "184px";
      buttonZoomMinus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
</svg>`;
      editor.appendChild(buttonZoomMinus);
      let buttonLeftRight = document.createElement("button");
      buttonLeftRight.className = "cropper-controls";
      buttonLeftRight.style.left = "224px";
      buttonLeftRight.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
</svg>`;
      editor.appendChild(buttonLeftRight);
      let buttonUpDown = document.createElement("button");
      buttonUpDown.className = "cropper-controls";
      buttonUpDown.style.left = "264px";
      buttonUpDown.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z" />
</svg>`;
      editor.appendChild(buttonUpDown);
      buttonConfirm.addEventListener("click", () => {
        let canvas = cropper.getCroppedCanvas();
        let newFile = this.dataURItoBlob(canvas.toDataURL(file.type));
        newFile.cropped = true;
        newFile.name = file.name;
        this.$refs.uploadTopLogo.dropzone.removeAllFiles();
        this.$refs.uploadTopLogo.dropzone.addFile(newFile);
        this.$refs.uploadTopLogo.dropzone.processQueue();
        this.$refs.uploadTopLogo.dropzone[
            "previewsContainer"
            ].children[1].children[0].children[0].src =  canvas.toDataURL(file.type);
        this.$refs.uploadTopLogo.dropzone[
            "previewsContainer"
            ].children[1].children[0].style.borderRadius = 0;
        this.$refs.uploadTopLogo.dropzone[
            "previewsContainer"
            ].children[1].children[1].style.background = "none";
        this.setPreviewLogo(false);
        document.body.removeChild(editor);
        cropper.destroy();
      });
      buttonRotateRight.addEventListener("click", () => {
        if (!buttonRotateRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateRight.classList.add("active-control");
        }
        cropper.rotate(45);
      });
      buttonRotateLeft.addEventListener("click", () => {
        if (!buttonRotateLeft.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateLeft.classList.add("active-control");
        }
        cropper.rotate(-45);
      });

      buttonCrop.addEventListener("click", () => {
        if (!buttonCrop.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonCrop.classList.add("active-control");
        }
        cropper.setDragMode("crop");
      });
      buttonZoomPlus.addEventListener("click", () => {
        if (!buttonZoomPlus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomPlus.classList.add("active-control");
        }
        cropper.zoom(0.1);
      });
      buttonZoomMinus.addEventListener("click", () => {
        if (!buttonZoomMinus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomMinus.classList.add("active-control");
        }
        cropper.zoom(-0.1);
      });
      buttonLeftRight.addEventListener("click", () => {
        if (!buttonLeftRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonLeftRight.classList.add("active-control");
        }
        if (cropper.getData().scaleX === 1) {
          cropper.scaleX(-1);
        } else {
          cropper.scaleX(1);
        }
      });
      buttonUpDown.addEventListener("click", () => {
        if (!buttonUpDown.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonUpDown.classList.add("active-control");
        }
        if (cropper.getData().scaleY === 1) {
          cropper.scaleY(-1);
        } else {
          cropper.scaleY(1);
        }
      });
      let image = new Image();
      image.src = URL.createObjectURL(file);
      editor.appendChild(image);
      this.setPreviewLogo(true);
      let cropper = new Cropper(image, {
        preview: ".intro_logo_img_preview",
        aspectRatio: +this.introBanner.pro.logo_width/+this.introBanner.pro.logo_height,
      });
    },
    mountType0(){
      let elements =
          this.$refs.uploadType0.$el.getElementsByClassName("dz-preview");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      if(this.introBanner.image[this.landing.current_lang]){
        let mockFile = {
          accepted: true,
          cropped:true,
          kind: "image",
        };
        this.$refs.uploadType0.manuallyAddFile(mockFile, this.introBanner.image[this.landing.current_lang]);
        this.$refs.uploadType0.dropzone.emit("thumbnail", mockFile, this.introBanner.image[this.landing.current_lang]);
        this.$refs.uploadType0.dropzone.emit("complete", mockFile);
        this.$refs.uploadType0.dropzone['previewsContainer'].children[1].children[0].children[0].src = this.introBanner.image[this.landing.current_lang];
        this.$refs.uploadType0.dropzone['previewsContainer'].children[1].children[0].style.borderRadius = 0;
        this.$refs.uploadType0.dropzone['previewsContainer'].children[1].children[1].style.background = 'none';
      }
    },
    mountTopPro(){
      let elements =
        this.$refs.uploadTopPro.$el.getElementsByClassName("dz-preview");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      if(this.introBanner.pro[this.landing.current_lang].top_img){
        let mockFile = {
          accepted: true,
          cropped:true,
          kind: "image",
        };
        this.$refs.uploadTopPro.manuallyAddFile(mockFile, this.introBanner.pro[this.landing.current_lang].top_img);
        this.$refs.uploadTopPro.dropzone.emit("thumbnail", mockFile, this.introBanner.pro[this.landing.current_lang].top_img);
        this.$refs.uploadTopPro.dropzone.emit("complete", mockFile);
        this.$refs.uploadTopPro.dropzone['previewsContainer'].children[1].children[0].children[0].src = this.introBanner.pro[this.landing.current_lang].top_img;
        this.$refs.uploadTopPro.dropzone['previewsContainer'].children[1].children[0].style.borderRadius = 0;
        this.$refs.uploadTopPro.dropzone['previewsContainer'].children[1].children[1].style.background = 'none';
      }
    },
    mountTopLogo(){
      let elements =
          this.$refs.uploadTopLogo.$el.getElementsByClassName("dz-preview");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      if(this.introBanner.pro[this.landing.current_lang].logo_img){
        let mockFile = {
          accepted: true,
          cropped:true,
          kind: "image",
        };
        this.$refs.uploadTopLogo.manuallyAddFile(mockFile, this.introBanner.pro[this.landing.current_lang].logo_img);
        this.$refs.uploadTopLogo.dropzone.emit("thumbnail", mockFile, this.introBanner.pro[this.landing.current_lang].logo_img);
        this.$refs.uploadTopLogo.dropzone.emit("complete", mockFile);
        this.$refs.uploadTopLogo.dropzone['previewsContainer'].children[1].children[0].children[0].src = this.introBanner.pro[this.landing.current_lang].logo_img;
        this.$refs.uploadTopLogo.dropzone['previewsContainer'].children[1].children[0].style.borderRadius = 0;
        this.$refs.uploadTopLogo.dropzone['previewsContainer'].children[1].children[1].style.background = 'none';
      }
    },
    customEvent(_file, xhr,formData){
      xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.state.token}`
      );
      formData.append('image',_file);
      formData.append('lending_settings_id',this.landing.settings_id);
    },
    handleRemove(){
      this.introBanner.image[this.landing.current_lang] = "";
    },
    handleSuccess(file){
      this.introBanner.image[this.landing.current_lang] = file.xhr.response;
    },
    handleRemoveTopPro(){
      this.introBanner.pro[this.landing.current_lang].top_img = "";
    },
    handleSuccessTopPro(file){
      this.introBanner.pro[this.landing.current_lang].top_img = file.xhr.response;
    },
    handleRemoveTopLogo(){
      this.introBanner.pro[this.landing.current_lang].logo_img= "";
    },
    handleSuccessTopLogo(file){
      this.introBanner.pro[this.landing.current_lang].logo_img = file.xhr.response;
    },
    async onFileChange(file){
      try{
        if(file){
          let formData = new FormData();
          formData.append('lending_settings_id',this.landing.settings_id);
          formData.append('image',file);
          formData.append('file',file);
          const response = await this.$axios.post('upload-image',formData);
          this.introBanner.video[this.landing.current_lang] = response.data;
        }
      }catch(e){
        console.log(e);
      }
    },
    changeSelectedIntro(){
      this.setIntroBanner(this.selectOption);
      if(this.$refs.uploadType0){
        this.mountType0();
      }
      if(this.$refs.uploadTopPro){
        this.mountTopPro();
      }
      if(this.$refs.uploadTopLogo){
        this.mountTopLogo();
      }
    }
  },
  async mounted(){
    await this.getIntroBanners(this.$route.params.id);
    if(!this.$route.query.introID){
      if(this.introList.length){
        this.setIntroBanner(this.introList[0]);
        this.selectOption = this.introList[0];
      }
    }
    else{
      const candidate = this.introList.find(x => x.id === +this.$route.query.introID);
      if(candidate){
        this.setIntroBanner(candidate);
        this.selectOption = candidate;
      }else{
        this.setIntroBanner(this.introList[0]);
        this.selectOption = this.introList[0];
      }
    }
    const candidate = this.selected_langs.find(x => x === this.landing.current_lang);

    if(candidate){
      this.selected_lang = this.selected_langs.indexOf(candidate);
    }
  }
}
</script>
<style lang="scss">

</style>
