<template>
  <main class="constructor__main" style="height: 100%">
    <div class="loading" v-if="loading">
      <img
        class="loading__icon"
        src="@/assets/img/logo__loader.svg"
        alt=""
        style="height: 100px; width: 100px"
      />
    </div>
    <div class="constructor__header" :style="`padding: ${isMobile?'0 5px 10px;':'30px 15px 10px;'}`">
      <v-row align="center">
        <v-col cols="12">
          <h2 class="constructor__heading" style="margin-bottom: 0;">
            {{ $t('constructor["Menu items"]') }}
          </h2>
          <p class="constructor__text">
            Create and customize your menu items.
          </p>
        </v-col>
      </v-row>
    </div>
    <div class="constructor__content" :style="`height: ${$store.state.isMobile?'auto':'calc(100% - 97px)'}`">
        <div class="constructor__subtabs tabs__border" v-if="!is_layout" style="height:100%;display:flex;">
        <div
          style="
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            border-right: 1px solid #e4e4e4;
          "
          v-if="!isMobile"
        >
          <div
            :class="{ active_tab: activeMenu(item) }"
            v-for="item in noAuthMenu"
            :key="item.id"
            @click="changeType(item, item.type - 1)"
            class="tab"
          >
            {{
              item.text[landing.current_lang].length > 15
                ? item.text[landing.current_lang].slice(0, 12) + "..."
                : item.text[landing.current_lang]
            }}
          </div>
          <v-icon
            @click="addNewButton"
            style="
              cursor: pointer;
              padding: 10px;
              border: 1px solid rgb(238, 238, 238);
              border-radius: 50%;
              display: block;
              margin: 0 auto;
            "
            >mdi-plus</v-icon
          >
        </div>
        <div
          style="flex: 0 1 100%;overflow:hidden;" :style="`padding:${isMobile?'0':'15px'}`"
          v-if="selectedMenuItem&&$store.state.renderComponent"
        >
          <div style="display:flex;align-items:center;justify-content:space-between; padding: 15px;height:70px;">
            <div style="display:flex;align-items:center;">
              <div style="margin-right:15px;">
                <label class="label" style="margin:0">{{
                    $t('constructor["Turn on page"]')
                  }}</label>
              </div>
              <div>
                <div class="control">
                  <v-switch color="#6ab64a" style="margin:0" hide-details
                            v-model="selectedMenuItem.active"
                            @change="changeInMenuActive"
                  ></v-switch>
                </div>
              </div>
            </div>
            <div style="display: flex; "
                 v-if="
                    !isMobile&&
                    selectedMenuItem.page !== 'cart' &&
                    selectedMenuItem.page !== 'reviews' &&
                    selectedMenuItem.page !== 'favorite' &&
                    selectedMenuItem.page !== 'profile' &&
                    selectedMenuItem.page !== 'quests'
                  ">
              <v-spacer></v-spacer>
              <v-menu offset-y open-on-hover bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark outlined small v-bind="attrs" v-on="on">
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link style="cursor: pointer" @click="openStyles(selectedMenuItem)">
                    <v-list-item-title
                    >Open button styles</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link @click="openMenuTemplates">
                    <v-list-item-title>Templates</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="openClearData(selectedMenuItem.text[landing.current_lang])">
                    <v-list-item-title style="cursor: pointer"
                    >Clear data</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link
                      v-if="landing.advanced.default_lang !== landing.current_lang"
                      @click="openCopy">
                    <v-list-item-title style="display:flex;align-items:center;"
                    >
                      Copy from {{landing.advanced.default_lang.toUpperCase()}}
                    <img
                        :src="`https://guesteco.com/3x2/${changeFormatLangPic(
                    landing.advanced.default_lang
                  )}.svg`"
                        style="width: 25px; height: 25px; margin-left: 10px"
                        :alt="landing.advanced.default_lang"
                    /></v-list-item-title
                      >
                  </v-list-item>
                  <v-list-item link @click="openInternalStyles" v-if="typeof selectedMenuItem.content[landing.current_lang][selectedMenuItem.type-1].styleSettings !== 'undefined' &&!buttonSettings&&selectedMenuItem.type!==2&&selectedMenuItem.type!==1&&selectedMenuItem.type!==5&&selectedMenuItem.type!==6&&selectedMenuItem.type!==3">
                    <v-list-item-title>
                      {{selectedMenuItem.content[landing.current_lang][selectedMenuItem.type-1].styleSettings?'Close content styles':'Open content styles'}}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="openInternalStyles" v-if="typeof selectedMenuItem.content[landing.current_lang][selectedMenuItem.type-1].styleSettings !== 'undefined' &&!buttonSettings&&selectedMenuItem.type===5">
                    <v-list-item-title>
                      {{selectedMenuItem.content[landing.current_lang][selectedMenuItem.type-1].styleSettings?'Close button content styles':'Open button content styles'}}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link style="cursor: pointer" @click="openDeletePopupMenu(selectedMenuItem)">
                    <v-list-item-title
                    >Delete button</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="form constructor-menu__turn" style="padding: 15px">
            <div class="field" style="margin-bottom:15px;" v-if="!selectedMenuItem.active">
              <div style="border:1px solid #c5c5c5;padding:15px;">
                The section is in draft mode, it is not shown in the mobile application. <br> If you want to see it in the app, please check the box above
              </div>
            </div>
            <div class="field field_half" v-if="landing.advanced.global.auth">
              <label class="label"><v-icon>mdi-lock</v-icon></label>
            </div>
            <div class="field field_half" v-if="landing.advanced.global.auth">
              <div class="control">
                <v-switch color="#6ab64a"
                  v-model="selectedMenuItem.auth_switch"
                  @change="changeInMenuAuth"
                ></v-switch>
              </div>
            </div>
            <div
              v-if="!landing.free && selectedMenuItem.page === 'reviews'"
            >
              <div class="control" style="margin-top: 30px">
                To customize the review form,
                <span
                  @click="openReviews"
                  style="color: #007db5; cursor: pointer"
                  >click here</span
                >
              </div>
            </div>
            <div
              v-if="selectedMenuItem.page === 'reviews' && landing.free"
            >
              <div style="display: flex; padding: 20px 0">
                <v-icon color="#cccccc" size="60px" style="margin-right: 20px"
                  >mdi-lock</v-icon
                >
                <h1
                  class="constructor__heading"
                  style="font-weight: normal; font-size: 16px"
                >
                  This feature is available on Pro plans.
                  <br />To activate the tariff
                  <a @click="billing">click here</a>
                </h1>
              </div>
            </div>
            <div
              class="form constructor-menu__turn field"
              v-if="selectedMenuItem.page === 'favorite'"
            >
              <div class="field">
                <label class="label" style="margin-bottom: 30px"
                  >Choose your listing style</label
                >
                <div class="socials-style" :style="`flex-direction:${$store.state.isMobile?'column-reverse':'row'};`">
                  <div
                      class="socials-style__item socials-style__item_default socials-style__listing__vertical"
                      style="height: 110px; width: 100px"
                      :class="{
                        active:  landing.advanced.global.favorite_btn.styles.displayType === 'default',
                      }"
                      @click="
                      changeListingStyle(
                        landing.advanced.global.favorite_btn.styles,
                        'default'
                      )
                    "
                  >
                    <img src="@/assets/icons/default.png" alt="" />
                  </div>
                  <div
                      class="socials-style__item socials-style__item_default socials-style__listing__inside"
                      style="height: 110px; width: 100px"
                      :class="{
              active:  landing.advanced.global.favorite_btn.styles.displayType === 'inside',
            }"
                      @click="
                      changeListingStyle(
                        landing.advanced.global.favorite_btn.styles,
                        'inside'
                      )
                    "
                  >
                    <img src="@/assets/icons/inside.png" alt="" />
                  </div>
                  <div class="socials-style" style="margin: 0">
                    <div
                        style="width: 240px"
                        class="socials-style__item socials-style__item_default socials-style__listing__horizontal"
                        :class="{
                active:  landing.advanced.global.favorite_btn.styles.displayType === 'horizontal',
              }"
                        @click="
                      changeListingStyle(
                        landing.advanced.global.favorite_btn.styles,
                        'horizontal'
                      )
                    "
                    >
                      <img src="@/assets/icons/horizontal.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="field"
                style="display: flex; align-items: center; margin-bottom: 30px"
              >
                <label
                  class="label"
                  style="margin-bottom: 0; margin-right: 15px; min-width: 200px"
                  >Change name color</label
                >
                <v-menu
                  top
                  nudge-left="16"
                  nudge-bottom="98"
                  :close-on-content-click="false"
                  v-model="favoriteNameColor"
                >
                  <template v-slot:activator="{ on }">
                    <div
                      class="constructor-layout--color"
                      :style="`background-color:${landing.advanced.global.favorite_btn.name_color}`"
                      v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                        v-model="
                          landing.advanced.global.favorite_btn.name_color
                        "
                        flat
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
              <div
                class="field"
                style="display: flex; align-items: center; margin-bottom: 30px"
              >
                <label
                  class="label"
                  style="margin-bottom: 0; min-width: 200px; margin-right: 15px"
                  >Change description color</label
                >
                <v-menu
                  top
                  nudge-left="16"
                  nudge-bottom="98"
                  :close-on-content-click="false"
                  v-model="favoriteDescColor"
                >
                  <template v-slot:activator="{ on }">
                    <div
                      class="constructor-layout--color"
                      :style="`background-color:${landing.advanced.global.favorite_btn.descr_color}`"
                      v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                        v-model="
                          landing.advanced.global.favorite_btn.descr_color
                        "
                        flat
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>

              <div class="field">
                <label class="label"
                  >Change listing block height
                  <b>{{ landing.advanced.global.favorite_btn.height }}</b>
                  px</label
                >
                <div class="slider">
                  <v-slider
                    hide-details
                    :min="90"
                    :max="500"
                    v-model="landing.advanced.global.favorite_btn.height"
                  ></v-slider>
                </div>
              </div>
              <div class="field">
                <label class="label"
                  >Change listing image height
                  <b>{{
                    landing.advanced.global.favorite_btn.styles
                      .listing_img_height
                  }}</b>
                  px</label
                >
                <div class="slider">
                  <v-slider
                    hide-details
                    :min="90"
                    :max="300"
                    v-model="
                      landing.advanced.global.favorite_btn.styles
                        .listing_img_height
                    "
                  ></v-slider>
                </div>
              </div>

              <div
                class="field"
                v-if="
                  landing.advanced.global.favorite_btn.styles.displayType ===
                  'horizontal'
                "
              >
                <label class="label"
                  >Change listing image width
                  <b>{{
                    landing.advanced.global.favorite_btn.styles
                      .listing_img_width
                  }}</b>
                  px</label
                >
                <div class="slider">
                  <v-slider
                    hide-details
                    :min="50"
                    :max="300"
                    v-model="
                      landing.advanced.global.favorite_btn.styles
                        .listing_img_width
                    "
                  ></v-slider>
                </div>
              </div>

              <v-tabs class="langs__tabs" v-model="activeLang">
                <v-tab
                  v-for="(selected_lang, i) in selected_langs"
                  :key="i"
                  @change="changeLang(selected_lang)"
                >
                  <img
                    :src="
                      landing.advanced.langs.find(
                        (x) => x.value === selected_lang
                      ).flag
                    "
                    alt=""
                    style="width: 20px; height: 15px; margin-right: 5px"
                  />
                  {{ selected_lang }}
                </v-tab>
                <v-tab-item
                  v-for="(selected_lang, i) in selected_langs"
                  :key="i"
                >
                  <div class="field">
                    <label class="label">Favorites title</label>
                    <div class="control mb-4">
                      <Editor
                        api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                        :init="options"
                        v-model="
                          landing.advanced.global.favorite_btn[selected_lang]
                            .title
                        "
                      >
                      </Editor>
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">No favorites text</label>
                    <div class="control mb-4">
                      <Editor
                        api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                        :init="options"
                        v-model="
                          landing.advanced.global.favorite_btn[selected_lang]
                            .no_title
                        "
                      >
                      </Editor>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs>
            </div>
            <div
              class="form constructor-menu__turn field"
              v-if="selectedMenuItem.page === 'cart'"
            >
              <v-tabs class="langs__tabs" v-model="activeLang">
                <v-tab
                  v-for="selected_lang in selected_langs"
                  :key="selected_lang"
                  @change="changeLang(selected_lang)"
                >
                  <img
                    :src="
                      landing.advanced.langs.find(
                        (x) => x.value === selected_lang
                      ).flag
                    "
                    alt=""
                    style="width: 20px; height: 15px; margin-right: 5px"
                  />
                  {{ selected_lang }}
                </v-tab>
                <v-tab-item
                  v-for="selected_lang in selected_langs"
                  :key="selected_lang"
                >
                  <div class="control" style="margin-bottom: 30px">
                    <label class="label">Cart empty data</label>
                    <Editor
                      api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                      :init="options"
                      v-model="
                        landing.order_form.content[selected_lang].emptyCart
                      "
                    >
                    </Editor>
                  </div>
                  <div
                    style="
                      display: flex;
                      align-items: flex-end;
                      margin-bottom: 15px;
                      justify-content: flex-start;
                    "
                  >
                    <label class="label" style="width: 200px; margin: 0">
                      Cart payments
                      {{
                        landing.advanced.global.cartPayments.show
                          ? "enabled"
                          : "disabled"
                      }}
                    </label>
                    <v-switch color="#6ab64a"
                      hide-details
                      v-model="landing.advanced.global.cartPayments.show"
                    ></v-switch>
                  </div>
                  <div
                      style="margin-bottom:15px;"
                    class="control"
                    v-if="!landing.advanced.global.cartPayments.show"
                  >
                    <label class="label">Cart disable pay text</label>
                    <Editor
                      api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                      :init="options"
                      v-model="
                        landing.advanced.global.cartPayments.text[selected_lang]
                      "
                    >
                    </Editor>
                  </div>
                  <div
                      class="control"
                      style="margin-bottom:15px;"
                  >
                    <label class="label">Cart top text order list</label>
                    <Editor
                        api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                        :init="options"
                        v-model="
                        landing.advanced.global.cartPayments.top_text[selected_lang]
                      "
                    >
                    </Editor>
                  </div>
                  <div
                      class="control"
                  >
                    <label class="label">Cart top text detail order</label>
                    <Editor
                        api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                        :init="options"
                        v-model="
                        landing.advanced.global.cartPayments.top_text_detail[selected_lang]
                      "
                    >
                    </Editor>
                  </div>
                </v-tab-item>
              </v-tabs>
            </div>
            <div class="field" v-if="selectedMenuItem.page === 'profile'">
              Profile settings
            </div>
            <div class="field" v-if="selectedMenuItem.page === 'quests'">
              <Quests :item="selectedMenuItem" />
            </div>
            <v-tabs
              class="langs__tabs"
              v-if="
                selectedMenuItem.page !== 'reviews' &&
                selectedMenuItem.page !== 'favorite' &&
                selectedMenuItem.page !== 'cart' &&
                selectedMenuItem.page !== 'profile' &&
                selectedMenuItem.page !== 'quests'
              "
              v-model="activeLang"
            >
              <v-tab
                v-for="selected_lang in selected_langs"
                :key="selected_lang"
                @change="changeLang(selected_lang)"
              >
                <img
                  :src="
                    landing.advanced.langs.find(
                      (x) => x.value === selected_lang
                    ).flag
                  "
                  alt=""
                  style="width: 20px; height: 15px; margin-right: 5px"
                />
                {{ selected_lang }}
              </v-tab>
              <v-tab-item
                v-for="selected_lang in selected_langs"
                :key="selected_lang"
              >
                <div class="field" v-if="landing.advanced.global.auth">
                  <label class="label">No authorizated users text</label>
                  <div class="control mb-4">
                    <Editor
                      api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                      :init="options"
                      v-model="selectedMenuItem.auth_text[selected_lang]"
                    >
                    </Editor>
                  </div>
                </div>
                <div class="control" style="margin-top: 25px">
                  <div v-if="!selectedMenuItem.content[selected_lang][
                    selectedMenuItem.type - 1
                        ].styleSettings&&!$store.state.dynamicFlipped" style="display:flex;">
                    <div
                         style="
                      max-width: 150px;
                      top: 1px;
                      text-align:center;
                      border-top-right-radius: 5px;
                      border-top-left-radius: 5px;
                      position: relative;
                      padding: 10px 30px;
                      cursor:pointer;
                      border:1px solid rgb(238,238,238);
                    "
                         @click="buttonSettingsTrigger(false)"
                         :style="`border-bottom:${!buttonSettings?'none':'1px solid rgb(238,238,238)'};background:${!buttonSettings?'#fff':'rgb(246,246,246)'};`"
                    >
                      <label class="label" style="cursor:pointer;padding:0;margin:0;">Content</label>
                    </div>
                    <div
                        style="
                      max-width: 150px;
                      cursor:pointer;
                      top: 1px;
                      border-top-right-radius: 5px;
                      border-top-left-radius: 5px;
                      position: relative;
                      padding: 10px 30px;
                      border:1px solid rgb(238,238,238);
                    "
                      @click="buttonSettingsTrigger(true)"
                      :style="`border-bottom:${buttonSettings?'none':'1px solid rgb(238,238,238)'};background:${buttonSettings?'#fff':' rgb(246,246,246)'};`"
                    >
                      <label class="label" style="cursor:pointer;padding:0;margin:0;">Button</label>
                    </div>
                  </div>

                  <div v-if="!buttonSettings&&!selectedMenuItem.content[selected_lang][
                    selectedMenuItem.type - 1
                        ].styleSettings&&$store.state.dynamicFlipped"
                       style="
                      max-width: 350px;
                      top: 1px;
                      border-top-right-radius: 5px;
                      border-top-left-radius: 5px;
                      position: relative;
                      background: #fff;
                      padding: 30px 15px;
                      border: 1px solid rgb(238, 238, 238);
                      border-bottom: none;
                    "
                  >
                    <label class="label">{{
                        $t('constructor["Content type"]')
                      }}</label>
                    <v-select
                        dense
                        outlined
                        hide-details
                        :items="dynamicTypes"
                        item-text="label"
                        style="padding-left: 15px; max-width: 300px"
                        item-value="value"
                        max-height="auto"
                        @change="
                        (val) =>
                        changeTypeDynamic(dynamicTypes.indexOf(val))
                      "
                        :value="dynamicTypes[selectedMenuItem.content[landing.current_lang][11].content[$store.state.dynamicIndex].type]"
                    >
                      <template v-slot:selection="{ item }">
                        <div>
                          {{ item.text }}
                        </div>
                      </template>
                      <template v-slot:item="{ item }">
                        <div :style="`color:${types[selectedMenuItem.type-1].id === item.id&&!flipped || flipped&&subMenuTypes[selectedMenu.type - 1].id === item.id?'#007db5':'#000'}`">
                          {{ item.text }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                  <div style="max-width: 350px;
                      top: 1px;
                      border-top-right-radius: 5px;
                      border-top-left-radius: 5px;
                      position: relative;
                      background: #fff;
                      padding: 30px 15px;
                      border: 1px solid rgb(238, 238, 238);
                      border-bottom: none;" v-if="!buttonSettings&&selectedMenuItem.content[selected_lang][
                    selectedMenuItem.type - 1
                        ].styleSettings&&selectedMenuItem.type === 5"
                  >
                    <v-btn outlined small color="primary" @click="changeSubmenuStyle(null)"><v-icon>mdi-chevron-left</v-icon>Back to submenu list</v-btn>
                  </div>
                  <div v-if="buttonSettings" style="
                      border: 1px solid #eee;
                      border-radius: 5px;
                      padding-top: 15px;">
                    <edit-menu-item :menuItem="landing.menu.list.find(x => x.id === selectedMenuItem.id)" />
                  </div>
                  <div v-if="!buttonSettings"
                    class="field"
                    style="
                      border: 1px solid #eee;
                      border-radius: 5px;
                      padding-top: 15px;
                    "
                  >
                    <div v-if="!flipped&&!selectedMenuItem.content[selected_lang][
                    selectedMenuItem.type - 1
                        ].styleSettings&&!$store.state.dynamicFlipped"
                         style="margin-bottom:15px;display:flex;align-items:center;
                    "
                    >
                      <label class="label" style="margin:0 15px 0 0;">{{
                          $t('constructor["Content type"]')
                        }} {{flipped ?'of ' + selectedMenu.name:''}}</label>
                      <v-select
                          dense
                          outlined
                          hide-details
                          :items="flipped && !selectedMenuItem.content[selected_lang][
                    selectedMenuItem.type - 1
                        ].styleSettings?subMenuTypes:types"
                          item-text="label"
                          style="max-width: 300px"
                          item-value="value"
                          @change="
                        (val) =>
                          flipped && !selectedMenuItem.content[selected_lang][
                    selectedMenuItem.type - 1
                        ].styleSettings?
                                changeTypeSub(
                                  selectedMenuItem,
                                  selectedMenu,
                                  val.id-1
                                ):changeType(selectedMenuItem, val.id-1)
                      "
                          :value="flipped?types.find(x => x.id === +selectedMenu.type):types.find(x => x.id === +selectedMenuItem.type)"
                      >
                        <template v-slot:selection="{ item }">
                          <div>
                            {{ item.text }}
                          </div>
                        </template>
                        <template v-slot:item="{ item }">
                          <div :style="`color:${+selectedMenuItem.type === item.id&&!flipped || flipped&&+selectedMenu.type === item.id?'#007db5':'#000'}`">
                            {{ item.text }}
                          </div>
                        </template>
                      </v-select>
                    </div>

                    <template v-if="selectedMenuItem.type === 1">
                      <typeHtml
                        :item="selectedMenuItem"
                        :content="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ]
                        "
                      />
                    </template>
                    <template v-if="selectedMenuItem.type === 2">
                      <typeLink
                        :content="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ]
                        "
                      />
                    </template>
                    <template v-if="selectedMenuItem.type === 3">
                      <typePhone
                        :content="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ]
                        "
                      />
                    </template>
                    <template v-if="selectedMenuItem.type === 4">
                      <typeListing
                        :item="selectedMenuItem"
                        :content="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ]
                        "
                        :menuType="'menu'"
                      />
                    </template>
                    <template v-if="selectedMenuItem.type === 5">
                      <div v-if="!flipped && !selectedMenuItem.content[selected_lang][
                    selectedMenuItem.type - 1
                        ].styleSettings">
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            margin-bottom: 15px;
                          "
                        >
                          <v-btn
                            style="margin-left: auto; display: flex"
                            class="mr-4"
                            color="primary"
                            outlined
                            small
                            @click="
                              addMenuButton(
                                selectedMenuItem.content[selected_lang][
                                  selectedMenuItem.type - 1
                                ].content.menu
                              )
                            "
                          >
                            + Add Menu Button
                          </v-btn>
                        </div>
                        <div  style="display:flex;align-items:center;margin-bottom:15px;">
                            <label class="label" style="display:block;margin-bottom:0;margin-right:15px">Add your top text</label>
                              <v-switch color="#6ab64a"
                                style="margin:0;padding:0;"
                                hide-details
                                v-model="
                                  selectedMenuItem.content[selected_lang][
                                    selectedMenuItem.type - 1
                                  ].topTextShow
                                "
                              ></v-switch>
                        </div>
                        <div
                          class="content"
                          v-if="
                            selectedMenuItem.content[selected_lang][
                              selectedMenuItem.type - 1
                            ].topTextShow
                          "
                          style="margin-bottom: 15px"
                        >
                          <label class="label">Write your top text</label>
                          <Editor
                            api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                            :init="options"
                            v-model="
                              selectedMenuItem.content[selected_lang][
                                selectedMenuItem.type - 1
                              ].topText
                            "
                          >
                          </Editor>
                        </div>
                        <div
                          v-for="(listingMenu, i) in selectedMenuItem.content[
                            selected_lang
                          ][selectedMenuItem.type - 1].content.menu"
                          :key="listingMenu.id + '_' + i"
                          style="
                            border: 1px solid #ccc;
                            padding: 15px;
                            border-radius: 15px;
                            margin-bottom: 15px;
                          "
                        >
                          <div v-if="!listingMenu.flipped">
                            <div
                              class="content"
                              style="display: flex; align-items: center"
                              :style="`justify-content:space-between;`"
                            >
                              <div v-if="!listingMenu.hidden"></div>
                              <div v-if="listingMenu.hidden" style="cursor:pointer;"
                                   @click="
                                    openListingMenu(
                                      selectedMenuItem.content[selected_lang][
                                        selectedMenuItem.type - 1
                                      ].content.menu,
                                      listingMenu
                                    )
                                  ">
                                <v-avatar
                                  size="50"
                                  rounded
                                  v-if="
                                    listingMenu.icon && listingMenu.show_icon
                                  "
                                  style="margin-right: 15px"
                                >
                                  <img :src="listingMenu.icon" alt="" />
                                </v-avatar>
                                <v-avatar
                                  size="50"
                                  rounded
                                  v-if="
                                    listingMenu.background &&
                                    !listingMenu.show_icon
                                  "
                                  style="margin-right: 15px"
                                >
                                  <img :src="listingMenu.background" alt="" />
                                </v-avatar>

                                {{ listingMenu.name }}
                              </div>
                              <div>
                                <v-btn
                                  v-if="!listingMenu.hidden"
                                  class="listing__settings"
                                  depressed
                                  small
                                  icon
                                  @click="listingMenu.hidden = true"
                                >
                                  <v-icon> mdi-minus</v-icon>
                                </v-btn>
                                <v-btn
                                  v-if="listingMenu.hidden"
                                  class="listing__settings"
                                  depressed
                                  small
                                  icon
                                  @click="
                                    openListingMenu(
                                      selectedMenuItem.content[selected_lang][
                                        selectedMenuItem.type - 1
                                      ].content.menu,
                                      listingMenu
                                    )
                                  "
                                >
                                  <v-icon> mdi-plus</v-icon>
                                </v-btn>
                                <v-menu offset-y open-on-hover bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      color="rgba(0, 0, 0, 0.54)"
                                      style="cursor: pointer"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-cog</v-icon
                                    >
                                  </template>
                                  <v-list>
                                    <v-list-item>
                                      <v-list-item-title
                                        style="cursor: pointer"
                                        @click="
                                          changeView(
                                            listingMenu,
                                            selectedMenuItem,
                                            i
                                          )
                                        "
                                        >Content Settings</v-list-item-title
                                      >
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-title
                                          style="cursor: pointer"
                                          @click="
                                          openButtonSettings(
                                            listingMenu
                                          )
                                        "
                                      >Button Settings</v-list-item-title
                                      >
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-title
                                        style="cursor: pointer"
                                        @click="
                                          openListingDelete(
                                            i,
                                            selectedMenuItem.id
                                          )
                                        "
                                        >Delete</v-list-item-title
                                      >
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </div>
                            </div>
                            <div v-if="!listingMenu.hidden">
                              <div>
                                <div style="padding: 15px">
                                  <div
                                    style="display: flex; align-items: center"
                                  >
                                    <label
                                      class="label"
                                      style="
                                        width: 150px;
                                        margin-right: 15px;
                                        margin-bottom: 0;
                                      "
                                    >
                                      Name
                                    </label>
                                    <div>
                                      <v-text-field
                                        outlined
                                        style="max-width: 250px"
                                        hide-details
                                        dense
                                        v-model="listingMenu.name"
                                      ></v-text-field>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                style="
                                  display: flex;
                                  align-items: center;
                                  padding: 15px;
                                "
                              >
                                <label
                                  class="label"
                                  style="
                                    width: 150px;
                                    margin-right: 15px;
                                    margin-bottom: 0;
                                  "
                                  v-if="listingMenu.show_icon"
                                  >{{
                                    $t('constructor["Иконка кнопки"]')
                                  }}</label
                                >
                                <div class="control" style="position: relative">
                                  <v-select
                                    style="z-index: 21; width: 100px"
                                    outlined
                                    solo
                                    hide-details
                                    flat
                                    dense
                                    :menu-props="{
                                      contentClass: 'my-awesome-list',
                                    }"
                                    :items="icons.data.map((item) => item.url)"
                                    :value="listingMenu.icon"
                                    v-model="listingMenu.icon"
                                    @click="showIcons = !showIcons"
                                  >
                                    <template v-slot:selection="{ item }">
                                      <img class="icon" :src="item" />
                                    </template>
                                    <template v-slot:item="{ item }">
                                      <img class="icon" :src="item" />
                                    </template>
                                  </v-select>
                                  <div
                                    v-if="showIcons"
                                    style="
                                      width: 15vw;
                                      height: 150px;
                                      background: #fff;
                                      border: 1px solid #ccc;
                                      position: absolute;
                                      top: 42px;
                                      z-index: 100;
                                      display: flex;
                                      flex-wrap: wrap;
                                      overflow-y: scroll;
                                      overflow-x: hidden;
                                    "
                                  >
                                    <div
                                      v-for="icon in icons.data.map(
                                        (item) => item.url
                                      )"
                                      :key="icon"
                                      style="
                                        height: 40px;
                                        width: 40px;
                                        padding: 5px;
                                      "
                                      :style="`${
                                        listingMenu.icon === icon
                                          ? 'border:2px solid #007db5;background:#ddd;'
                                          : ''
                                      };`"
                                      @click="handleChange(listingMenu, icon)"
                                    >
                                      <img
                                        :src="icon"
                                        :alt="icon"
                                        style="
                                          height: 100%;
                                          width: 100%;
                                          cursor: pointer;
                                        "
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                style="
                                  display: flex;
                                  padding: 15px;
                                "
                                :style="`flex-direction:${isMobile?'column':'row'};
                                  align-items: ${isMobile?'flex-start':'center'};`"
                              >
                                <v-btn
                                  outlined
                                  small
                                  color="primary"
                                  @click="
                                    changeView(listingMenu, selectedMenuItem, i)
                                  "
                                  >Content settings</v-btn
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="
                            selectedMenuItem.content[selected_lang][
                              selectedMenuItem.type - 1
                            ].content.menu.length === 0
                          "
                          style="text-align: center; margin: 50px 0 100px 0"
                        >
                          You don't have any menu buttons, it is time to
                          <span
                            style="color: #007aff; cursor: pointer"
                            @click="
                              addMenuButton(
                                selectedMenuItem.content[selected_lang][
                                  selectedMenuItem.type - 1
                                ].content.menu
                              )
                            "
                            >create them.</span
                          >
                        </div>
                      </div>
                      <div
                        v-if="flipped && !selectedMenuItem.content[selected_lang][
                    selectedMenuItem.type - 1
                        ].styleSettings"
                        style="margin-bottom: 15px"
                      >
                        <div style="display:flex;align-items:center;justify-content:flex-end;width:100%;">
                        <v-btn
                          style="
                            margin-right: 10px;
                            margin-top: 15px;
                            display: flex;
                          "
                          class="mb-4"
                          color="primary"
                          depressed
                          outlined
                          @click="changeView(selectedMenu, selectedMenuItem, 0)"
                          small
                        >
                          <v-icon>mdi-chevron-left</v-icon>Back to menu list
                        </v-btn>
                        </div>
                        <div
                            v-if="flipped && !selectedMenuItem.content[selected_lang][
                    selectedMenuItem.type - 1
                        ].styleSettings">
                          <label class="label">Selected menu: {{selectedMenu.name}}</label>
                        </div>
                        <div v-if="!selectedMenuItem.content[selected_lang][
                    selectedMenuItem.type - 1
                        ].styleSettings&&!$store.state.dynamicFlipped"
                                   style="margin-bottom:15px;display:flex;align-items:center;
                    "
                      >
                        <label class="label" style="margin:0 15px 0 0;">{{
                            $t('constructor["Content type"]')
                          }} of {{ selectedMenu.name }}</label>
                        <v-select
                            dense
                            outlined
                            hide-details
                            :items="subMenuTypes"
                            item-text="label"
                            style="max-width: 300px"
                            item-value="value"
                            @change="
                              (val) =>
                                      changeTypeSub(
                                        selectedMenuItem,
                                        selectedMenu,
                                        val.id
                                      )
                            "
                            :value="subMenuTypes.find(x => x.id === +selectedMenu.type)"
                        >
                          <template v-slot:selection="{ item }">
                            <div>
                              {{ item.text }}
                            </div>
                          </template>
                          <template v-slot:item="{ item }">
                            <div :style="`color:${+selectedMenuItem.type === item.id&&!flipped || flipped&&+selectedMenu.type === item.id?'#007db5':'#000'}`">
                              {{ item.text }}
                            </div>
                          </template>
                        </v-select>
                      </div>
                        <template v-if="selectedMenu.type === 1">
                          <typeHtml
                            style="
                            "
                            :item="selectedMenu"
                            :content="selectedMenu.content[0]"
                          />
                        </template>
                        <template v-if="selectedMenu.type === 2">
                          <typeLink
                            style="
                            "
                            :content="selectedMenu.content[1]"
                          />
                        </template>
                        <template v-if="selectedMenu.type === 3">
                          <typePhone
                            style="
                            "
                            :content="selectedMenu.content[2]"
                          />
                        </template>
                        <template v-if="selectedMenu.type === 4">
                          <typeListing
                            style="
                            "
                            :item="selectedMenu"
                            :content="selectedMenu.content[3]"
                            :menuType="'submenu'"
                          />
                        </template>
                        <template v-if="selectedMenu.type === 5">
                          <typeFaq
                            :item="selectedMenu"
                            :content="selectedMenu.content[4]"
                          />
                        </template>
                        <template v-if="selectedMenu.type === 6">
                          <typeContact
                            :item="selectedMenu"
                            style="
                            "
                            :content="selectedMenu.content[5]"
                          />
                        </template>
                        <template v-if="selectedMenu.type === 7">
                          <div
                            style="
                              position: relative;
                              z-index: 0;
                            "
                          >
                            <typeEvents :item="selectedMenu" :content="selectedMenu.content[6]" />
                            <SliderMenu
                              :slider_menu="selectedMenu.content[6].slider"
                              :active_slider="selectedMenu.content[6]"
                              :menu="menu"
                            />
                          </div>
                        </template>
                        <template v-if="selectedMenu.type === 8">
                          <div
                            style="
                              position: relative;
                              z-index: 0;
                            "
                          >
                            <ProductMenu
                              :key="selectedMenuItem.id"
                              :selectedMenuID="selectedMenuItem.id"
                              :item="selectedMenu"
                              :content="selectedMenu.content[7]"
                            />
                          </div>
                        </template>
                        <template v-if="selectedMenu.type === 9">
                          <div
                            style="
                            "
                          >
                            <typeWeather
                              :item="selectedMenu"
                              :content="selectedMenu.content[8].content"
                              :slider_menu="selectedMenu.content[8].slider"
                              :active_slider="selectedMenu.content[8]"
                              :menu="menu"
                            />
                          </div>
                        </template>
                        <template v-if="selectedMenu.type === 10">
                          <div
                            style="
                              position: relative;
                              z-index: 0;
                            "
                          >
                            <typeMapPoints
                              :item="selectedMenu"
                              :selectedMenuID="selectedMenuItem.id"
                              :content="selectedMenu.content[9]"
                            />
                          </div>
                        </template>
                        <template v-if="selectedMenu.type === 11">
                          <div
                            style="
                              position: relative;
                              z-index: 0;
                            "
                          >
                            <typeDynamic
                              :item="selectedMenu"
                              :content="selectedMenu.content[10]"
                            />
                          </div>
                        </template>
                        <template v-if="selectedMenu.type === 12">
                          <div
                            style="
                            "
                          >
                            <typeGallery
                              :item="selectedMenu"
                              :content="selectedMenu.content[11]"
                            />
                          </div>
                        </template>
                        <template v-if="selectedMenu.type === 13">
                          <TypeTrails
                            :item="selectedMenu"
                            :selectedMenuID="selectedMenuItem.id"
                            :content="selectedMenu.content[12]"
                          />
                        </template>
                      </div>
                      <div v-if="selectedMenuItem.content[selected_lang][
                    selectedMenuItem.type - 1
                        ].styleSettings">
                        <SubMenuStyles
                          :selectedMenu="selectedSubMenuStyles"
                          :item="selectedMenuItem"
                          :list="
                            selectedMenuItem.content[selected_lang][
                              selectedMenuItem.type - 1
                            ].content.menu
                          "
                          :globalSettings="selectedMenuItem.content[selected_lang][
                              selectedMenuItem.type - 1
                            ]"
                        >
                          <div v-if="!buttonSettings&&selectedMenuItem.content[selected_lang][
                    selectedMenuItem.type - 1
                        ].styleSettings&&selectedMenuItem.type === 5">
                            <label class="label"> Select button name to change styles </label>
                            <v-select
                                v-model="selectedItem"
                                @change="changeSelectedMenu"
                                class="canvas__select"
                                style="max-width: 250px"
                                :items="selectList"
                                return-object
                                item-text="name"
                                item-value="id"
                                placeholder="Select Menu Button"
                                outlined
                                dense
                                flat
                            >
                              <template v-slot:selection="{item}">
                                {{ item.name }}
                              </template>
                              <template v-slot:item="{item}">
                                {{ item.name }}
                              </template>
                            </v-select>
                          </div>
                        </SubMenuStyles>
                      </div>
                    </template>
                    <template v-if="selectedMenuItem.type === 6">
                      <typeFaq
                        :item="selectedMenuItem"
                        :content="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ]
                        "
                      />
                    </template>
                    <template v-if="selectedMenuItem.type === 7">
                      <typeContact
                        :content="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ]
                        "
                        :item="selectedMenuItem"
                      />
                    </template>
                    <template v-if="selectedMenuItem.type === 8">
                      <typeEvents
                          :item="selectedMenuItem"
                        :content="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ]
                        "
                      />
                      <SliderMenu
                        :slider_menu="content.slider"
                        :active_slider="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ]
                        "
                        :menu="menu"
                      />
                    </template>
                    <template v-if="selectedMenuItem.type === 9">
                      <ProductMenu
                          :key="selectedMenuItem.id"
                        :item="selectedMenuItem"
                        :content="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ]
                        "
                      />
                    </template>
                    <template v-if="selectedMenuItem.type === 10">
                      <typeWeather
                        :item="selectedMenuItem"
                        :content="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ].content
                        "
                        :slider_menu="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ].slider
                        "
                        :active_slider="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ]
                        "
                        :menu="menu"
                      />
                    </template>
                    <template v-if="selectedMenuItem.type === 11">
                      <typeMapPoints
                        :item="selectedMenuItem"
                        :content="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ]
                        "
                      />
                    </template>
                    <template v-if="selectedMenuItem.type === 12">
                      <typeDynamic
                        :item="selectedMenuItem"
                        :content="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ]
                        "
                      />
                    </template>
                    <template v-if="selectedMenuItem.type === 13">
                      <TypeGallery
                        :item="selectedMenuItem"
                        :content="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ]
                        "
                      />
                    </template>
                    <template v-if="selectedMenuItem.type === 14">
                      <TypeTrails
                        :item="selectedMenuItem"
                        :content="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ]
                        "
                      />
                    </template>
                    <template v-if="selectedMenuItem.type === 15">
                      <TypeRadio
                        :item="selectedMenuItem"
                        :content="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ]
                        "
                      />
                    </template>
                      <template v-if="selectedMenuItem.type === 16">
                          <TouristListing
                                  :item="selectedMenuItem"
                                  :content="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ]
                        "
                          />
                      </template>
                      <template v-if="selectedMenuItem.type === 17">
                          <typeRealEstate
                                  :item="selectedMenuItem"
                                  :content="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ]
                        "
                          />
                      </template>
                      <template v-if="selectedMenuItem.type === 18">
                          <typeTouristSpaces :item="selectedMenuItem" :content="
                          selectedMenuItem.content[selected_lang][
                            selectedMenuItem.type - 1
                          ]" />
                      </template>
                    <div style="margin:15px 0;display: flex;align-items: center;justify-content: space-between"
                         v-if="!selectedMenuItem.content[landing.current_lang][selectedMenuItem.type-1].styleSettings&&selectedMenuItem.type !== 2 && selectedMenuItem.type !== 3
                      && selectedMenuItem.page !== 'reviews' && landing.free && !buttonSettings ||
                      !selectedMenuItem.content[landing.current_lang][selectedMenuItem.type-1].styleSettings&&selectedMenuItem.type !== 2 && selectedMenuItem.type !== 3 &&!landing.free && !buttonSettings&&
                      selectedMenuItem.type !== 2 && selectedMenuItem.type !== 3&& selectedMenuItem.page !== 'reviews'">
                      <label class="label" style="display:block;margin-bottom:0;">Show socials buttons
                      </label>
                      <v-switch color="#6ab64a" v-if="selectedMenu"
                                style="padding:0;margin:0;"
                                v-model="selectedMenu.show_socials"
                                hide-details
                                @change="changeInMenuSocials"
                      ></v-switch>
                      <v-switch color="#6ab64a" v-if="!selectedMenu"
                                style="padding:0;margin:0;"
                                v-model="selectedMenuItem.show_socials"
                                hide-details
                                @change="changeInMenuSocials"
                      ></v-switch>
                    </div>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs>
          </div>
          <slot></slot>
        </div>
        <div style="    flex: 0 1 100%;padding: 15px" v-if="!selectedMenuItem&&!isMobile">
          <div style="padding: 15px;min-height:600px;">
            <p style="font-weight: 600; font-size: 20px">Welcome to menu</p>
            <p class="constructor__text">
              {{ $t('constructor["Select item for edit or turn on/off."]') }}
            </p>
          </div>
        </div>
        <div style="margin-top:15px;flex: 0 1 100%;" v-if="isMobile&&!selectedMenuItem">
          <div style="min-height:600px;display:flex;flex-wrap:wrap;">
            <ul
                class="canvas-menu"
                :style="`flex-direction:row;`"
            >
            <li
                @click="changeType(item, item.type - 1)"
                style="list-style: none"
                class="canvas-menu__item canvas-menu-custom"
                v-for="item in landing.menu.list"
                :key="item.id"
                :style="
                  `margin-bottom:${+landing.menu.shadowSize + 10}px;` +
                  customMenuStyles(landing.menu, item)
                "
            >
              <a
                  class="canvas-menu__link"
                  href="#"
                  :style="
                    ` height:${item.styles.height}px;color: ${item.styles.color};
                    border-radius: ${landing.menu.radius}px;box-shadow:0 ${landing.menu.shadowSize}px 6px ${landing.menu.shadowColor};` +
                    customMenuLinkStyles(landing.menu, item)
                  "
              >
                <div
                    :style="`height:${item.styles.lining_height}px;width:${
                      item.styles.lining_width
                    }%;padding:${
                      item.styles.lining_padding
                    }px;background-color:${
                      item.styles.main_menu_lining
                    };display:flex;align-items:center;justify-content:center;flex-direction:${
                      item.styles.show_icon_up ? 'column' : 'row'
                    };`+checkRadius(item,landing.menu)"
                >
                  <img
                      alt="image"
                      v-if="
                        item.styles.show_icon && item.icon && item.styles.disable_icon_color && !item.icon[landing.current_lang].includes('none.png')
                      "
                      :src="item.icon[landing.current_lang]"
                      class="canvas-menu__icon"
                      :style="`height:${item.styles.icon_size}px;width:${
                        item.styles.icon_size
                      }px;margin-right:${
                        !item.styles.show_icon_up ? '15px' : '0'
                      }`"
                  />
                  <div
                      v-if="
                        item.styles.show_icon && item.icon && !item.styles.disable_icon_color &&!item.icon[landing.current_lang].includes('none.png')
                      "
                      class="canvas-menu__icon"
                      :style="`margin-right:${
                        !item.styles.show_icon_up ? '15px' : '0'
                      };height:${item.styles.icon_size}px;width:${
                        item.styles.icon_size
                      }px;background-color: ${
                        item.styles.icon_color
                      };mask: url(${
                        item.icon[landing.current_lang]
                      }) no-repeat center / contain;-webkit-mask: url(${
                        item.icon[landing.current_lang]
                      }) no-repeat center / contain;`"
                  ></div>

                  <div style="text-align: center;font-size:13px;">
                    {{ item.text[landing.current_lang] }}
                  </div>
                </div>
              </a>
            </li>
              <li style="display:flex;width:calc(33.33% - 10px);justify-content: center;align-items: center;">
                <v-icon
                    @click="addNewButton"
                    style="
              cursor: pointer;
              padding: 10px;
              border: 1px solid rgb(238, 238, 238);
              border-radius: 50%;
              display: block;
              margin: 0 auto;
            "
                >mdi-plus</v-icon
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="dialog_order_free" max-width="700px">
      <template>
        <div style="display: flex; padding: 20px 0">
          <v-icon color="#cccccc" size="60px" style="margin-right: 20px"
            >mdi-lock</v-icon
          >
          <h3
            class="constructor__heading"
            style="font-weight: normal; font-size: 16px"
          >
            This feature is available on Pro plans. <br />To
            activate the tariff <a @click="billing">click here</a>
          </h3>
        </div></template
      >
    </v-dialog>
    <PopupTariff ref="PopupTariff" />
    <PopupDelete
      ref="PopupDeleteListing"
      @confirm="deleteListingMenu()"
      :title="'Are you sure about delete?'"
    />
    <PopupDelete
      ref="PopupDeleteMenuItem"
      @confirm="deleteMenuItem()"
      :title="`Are you sure button: ${deleteItemId?deleteItemId.text[landing.current_lang]:''} delete?`"
    />
    <PopupConfirm ref="PopupConfirm" :title="`Are you sure about copy content from ${defaultLangName} to ${currentLangName}`" @confirm="copyLangContent"/>
    <PopupMenuTemplate :show="menuTemplate" @closeModal="closeMenuTemplates"/>
    <PopupConfirm
        ref="PopupConfirmClear"
        @confirm="confirmClear"
        :title="`Are you sure about clear ${clearDataName} data?`"
    />
    <PopupConfirm
        ref="PopupConfirmCopy"
        @confirm="confirmCopy"
        :title="`Are you sure about copy content from ${defaultLangName} to ${currentLangName}`"
    />
  </main>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import MediaService, { entities } from "../../../services/media.service";
import "../../../../node_modules/cropperjs/dist/cropper.min.css";
import defaults, {
  defaultContentMenuItem,
  defaultEventStyles,
  defaultFormMenuItem,
  defaultListingStyles, defaultProductStyles,
  defaultStyles
} from "@/store/landing.js";
import { createMenuItem } from "@/store/landing.js";

export default {
  name: "ConstructorMenu",
  components: {
    PopupMenuTemplate:() =>
      import("@/components/Popup/PopupMenuTemplate"),
    SubMenuStyles: () =>
      import("@/components/Constructor/Menu/SubMenu/SubMenuStyles"),
    TypeGallery: () => import("@/components/Constructor/Menu/typeGallery"),
    SliderMenu: () => import("./SliderMenu"),
    PopupTariff: () => import("../../Popup/PopupTariff"),
    EditMenuItem: () => import("../EditMenuItem"),
    PopupDelete: () => import("../../Popup/PopupDelete"),
    ProductMenu: () => import("./ProductMenu/ProductMenu.vue"),
    typeHtml: () => import("./typeHtml.vue"),
    typeLink: () => import("./typeLink.vue"),
    typePhone: () => import("./typePhone.vue"),
    typeListing: () => import("./typeListing.vue"),
    typeFaq: () => import("./typeFaq.vue"),
    typeContact: () => import("./typeContact.vue"),
    typeWeather: () => import("./typeWeather.vue"),
    typeEvents: () => import("./typeEvents.vue"),
    typeMapPoints: () => import("./typeMapPoints.vue"),
    typeDynamic: () => import("./typeDynamic.vue"),
    TypeTrails: () => import("@/components/Constructor/Menu/typeTrails"),
    Quests: () => import("@/components/Constructor/Menu/Quests/Quests.vue"),
    TypeRadio: () => import("@/components/Constructor/Menu/typeRadio"),
    PopupConfirm:() => import("@/components/Popup/PopupConfirm"),
    TouristListing: () => import("@/components/Constructor/Menu/TouristListing"),
    typeRealEstate:() => import("./typeRealEstate"),
    typeTouristSpaces: () => import("./typeTouristSpace/typeTouristSpaces")
  },
  created(){
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
  data() {
    return {
      menuTemplate:false,
      isMobile:false,
      dynamicTypes: [
        {
          id: 1,
          type: "html",
          text: "Simple page",
        },
        {
          id: 2,
          type: "link",
          text: this.$t('constructor["Link"]'),
        },
        {
          id: 3,
          type: "listing",
          text: "Listing",
        },
        {
          id: 4,
          type: "gallery",
          text: "Gallery",
        },
      ],
      selectedItem: {name:"All",id:999},
        selectedSubMenuStyles:null,
      renderComponent:true,
      show_font_all_color: false,
      show_lining_all_color: false,
      show_background_all_color: false,
      background_color: "rgba(255,255,255,1)",
      lining_color: "rgba(255,255,255,1)",
      font_color: "rgba(0,0,0,1)",
      submenuStyle: false,
      showIcons: false,
      loading: false,
      deleteMenuId: null,
      selectedMenu: null,
      dropzoneOptionsMenuImg: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        maxFiles: 1,
        addRemoveLinks: true,
      },
      listingGroupIndex: 0,
      listingGroupItemIndex: 0,
      login_click: false,
      favorite: false,
      show_payment_active_color: false,
      show_payment_color: false,
      show_payment_bg: false,
      show_payment_bg_active: false,
      favoriteNameColor: false,
      favoriteDescColor: false,
      show_faq_sub_color: false,
      show_faq_color: false,
      delete_form_group_index: null,
      active_menu_tab: 1,
      active_menu_obj: {
        id: 1,
        type: "html",
        text: "HTML",
      },
      show_icon_color_sub: false,
      show_icon_color: false,
      indexFormItemId: null,
      dialogFaqNew: false,
      dialogFaqDelete: false,
      clickedIndex: -1,
      radioTypes: [
        { id: 0, value: "radio", label: "Radio" },
        { id: 1, value: "number", label: "Number" },
        { id: 2, value: "text", label: "Text" },
        { id: 3, value: "date", label: "Date" },
        { id: 4, value: "time", label: "Time" },
      ],
      editedItem: {
        question: "default question",
        answer: "default answer",
      },
      addItemFaq: {
        question: "default question",
        answer: "default answer",
      },
      editedIndex: -1,
      defaultItem: {
        question: "default question",
        answer: "default answer",
      },
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFiles: 1,
        destroyDropzone: false,
      },
      buttonSettings:false,
      flipped: false,
      active_tab: 0,
      show_name_color: false,
      show_descr_color: false,
      show_btn_name_color: false,
      show_btn_bg_color: false,
      show_btn_border_color: false,
      show_btn_name_color1: false,
      show_btn_bg_color1: false,
      show_btn_border_color1: false,
      show_btn_name_color2: false,
      show_btn_bg_color2: false,
      show_btn_border_color2: false,
      is_layout: false,
      radius_min: 0,
      radius_max: 32,
      show_shadow_color: false,
      dialog_order_free: false,
      defaultStyles: {
        width: "50",
        color: "#000",
        height: "150",
        position: "center center",
        show_icon: true,
        show_background: false,
        background: null,
        fontSize: "16",
        fontWeight: "400",
        fontStyle: "normal",
        fontFamily: "Inter",
        displayType: "default",
      },
      defaultListingMenu: {
        width: "50",
        color: "#000",
        height: "150",
        position: "center center",
        show_icon: true,
        show_background: false,
        background: null,
        fontSize: "16",
        fontWeight: "400",
        fontStyle: "normal",
        fontFamily: "Inter",
        displayType: "default",
        flipped: false,
        show_icon_up: true,
        name: "default",
        page: "default",
        listing: {
          type: 5,
          content: {
            listing: [
              {
                img: [],
                video_link: null,
                name: null,
                descr: null,
                main_img: "",
                full_descr: null,
                price: null,
                show_price: true,
                show_descr: true,
                id: 0,
              },
            ],
            form: {
              input_1: null,
              input_2: null,
              input_3: null,
              textarea: null,
              email: null,
            },
          },
        },
      },
      deleteItemId: null,
      positionTypes: [
        { id: 0, valueVertical: "flex-start", valueHorizontal: "flex-start" },
        { id: 1, valueVertical: "flex-start", valueHorizontal: "center" },
        { id: 2, valueVertical: "flex-start", valueHorizontal: "flex-end" },
        { id: 3, valueVertical: "center", valueHorizontal: "flex-start" },
        { id: 4, valueVertical: "center", valueHorizontal: "center" },
        { id: 5, valueVertical: "center", valueHorizontal: "flex-end" },
        { id: 6, valueVertical: "flex-end", valueHorizontal: "flex-start" },
        { id: 7, valueVertical: "flex-end", valueHorizontal: "center" },
        { id: 8, valueVertical: "flex-end", valueHorizontal: "flex-end" },
      ],
      fontStyleTypes: [
        { id: 1, value: "normal", label: "Normal" },
        { id: 0, value: "italic", label: "Italic" },
      ],
      OrderFormTypes: [
        { id: 0, value: "lite", label: "Lite" },
        { id: 1, value: "pro", label: "Pro" },
      ],
      indexGroupFaq: null,
      arrGroupFaq: null,
      menuNameTypes: [
        { id: 0, value: "inside", label: "Inside" },
        { id: 1, value: "bottom", label: "Bottom" },
        { id: 2, value: "top", label: "Top" },
      ],
      namePosition: "inside",
      fontFamilyTypes: [
        { id: 0, value: "Arial", label: "Arial" },
        { id: 1, value: "SF UI Text", label: "SF UI Text" },
        { id: 2, value: "Proxima Nova Bl", label: "Proxima Nova Bl" },
        { id: 3, value: "Myriad Pro", label: "Myriad Pro" },
        { id: 4, value: "HelveticaNeueCyr", label: "HelveticaNeueCyr" },
        { id: 5, value: "Gotham Pro", label: "Gotham Pro" },
        { id: 6, value: "Geometria", label: "Geometria" },
        { id: 7, value: "DIN Pro", label: "DIN Pro" },
        { id: 8, value: "Circe", label: "Circe" },
        { id: 9, value: "Bebas Neue", label: "Bebas Neue" },
        { id: 10, value: "Acrom", label: "Acrom" },
        { id: 11, value: "Inter", label: "Inter" },
      ],
      addItemForm: {
        id: 0,
        radio: "radio",
        label: "default label",
        label_color: "#000000",
        price: "0",
        formDialogEdit: false,
        count: 1,
        price_color: "#000000",
      },
      countries: [
        { value: "AF", label: "Afghanistan" },
        { value: "AX", label: "Åland Islands" },
        { value: "AL", label: "Albania" },
        { value: "DZ", label: "Algeria" },
        { value: "AS", label: "American Samoa" },
        { value: "AD", label: "Andorra" },
        { value: "AO", label: "Angola" },
        { value: "AI", label: "Anguilla" },
        { value: "AQ", label: "Antarctica" },
        { value: "AG", label: "Antigua and Barbuda" },
        { value: "AR", label: "Argentina" },
        { value: "AM", label: "Armenia" },
        { value: "AW", label: "Aruba" },
        { value: "AU", label: "Australia" },
        { value: "AT", label: "Austria" },
        { value: "AZ", label: "Azerbaijan" },
        { value: "BS", label: "Bahamas" },
        { value: "BH", label: "Bahrain" },
        { value: "BD", label: "Bangladesh" },
        { value: "BB", label: "Barbados" },
        { value: "BY", label: "Belarus" },
        { value: "BE", label: "Belgium" },
        { value: "BZ", label: "Belize" },
        { value: "BJ", label: "Benin" },
        { value: "BM", label: "Bermuda" },
        { value: "BT", label: "Bhutan" },
        { value: "BO", label: "Bolivia, Plurinational State of" },
        { value: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
        { value: "BA", label: "Bosnia and Herzegovina" },
        { value: "BW", label: "Botswana" },
        { value: "BV", label: "Bouvet Island" },
        { value: "BR", label: "Brazil" },
        { value: "IO", label: "British Indian Ocean Territory" },
        { value: "BN", label: "Brunei Darussalam" },
        { value: "BG", label: "Bulgaria" },
        { value: "BF", label: "Burkina Faso" },
        { value: "BI", label: "Burundi" },
        { value: "KH", label: "Cambodia" },
        { value: "CM", label: "Cameroon" },
        { value: "CA", label: "Canada" },
        { value: "CV", label: "Cape Verde" },
        { value: "KY", label: "Cayman Islands" },
        { value: "CF", label: "Central African Republic" },
        { value: "TD", label: "Chad" },
        { value: "CL", label: "Chile" },
        { value: "CN", label: "China" },
        { value: "CX", label: "Christmas Island" },
        { value: "CC", label: "Cocos (Keeling) Islands" },
        { value: "CO", label: "Colombia" },
        { value: "KM", label: "Comoros" },
        { value: "CG", label: "Congo" },
        { value: "CD", label: "Congo, the Democratic Republic of the" },
        { value: "CK", label: "Cook Islands" },
        { value: "CR", label: "Costa Rica" },
        { value: "CI", label: "Côte d'Ivoire" },
        { value: "HR", label: "Croatia" },
        { value: "CU", label: "Cuba" },
        { value: "CW", label: "Curaçao" },
        { value: "CY", label: "Cyprus" },
        { value: "CZ", label: "Czech Republic" },
        { value: "DK", label: "Denmark" },
        { value: "DJ", label: "Djibouti" },
        { value: "DM", label: "Dominica" },
        { value: "DO", label: "Dominican Republic" },
        { value: "EC", label: "Ecuador" },
        { value: "EG", label: "Egypt" },
        { value: "SV", label: "El Salvador" },
        { value: "GQ", label: "Equatorial Guinea" },
        { value: "ER", label: "Eritrea" },
        { value: "EE", label: "Estonia" },
        { value: "ET", label: "Ethiopia" },
        { value: "FK", label: "Falkland Islands (Malvinas)" },
        { value: "FO", label: "Faroe Islands" },
        { value: "FJ", label: "Fiji" },
        { value: "FI", label: "Finland" },
        { value: "FR", label: "France" },
        { value: "GF", label: "French Guiana" },
        { value: "PF", label: "French Polynesia" },
        { value: "TF", label: "French Southern Territories" },
        { value: "GA", label: "Gabon" },
        { value: "GM", label: "Gambia" },
        { value: "GE", label: "Georgia" },
        { value: "DE", label: "Germany" },
        { value: "GH", label: "Ghana" },
        { value: "GI", label: "Gibraltar" },
        { value: "GR", label: "Greece" },
        { value: "GL", label: "Greenland" },
        { value: "GD", label: "Grenada" },
        { value: "GP", label: "Guadeloupe" },
        { value: "GU", label: "Guam" },
        { value: "GT", label: "Guatemala" },
        { value: "GG", label: "Guernsey" },
        { value: "GN", label: "Guinea" },
        { value: "GW", label: "Guinea-Bissau" },
        { value: "GY", label: "Guyana" },
        { value: "HT", label: "Haiti" },
        { value: "HM", label: "Heard Island and McDonald Mcdonald Islands" },
        { value: "VA", label: "Holy See (Vatican City State)" },
        { value: "HN", label: "Honduras" },
        { value: "HK", label: "Hong Kong" },
        { value: "HU", label: "Hungary" },
        { value: "IS", label: "Iceland" },
        { value: "IN", label: "India" },
        { value: "ID", label: "Indonesia" },
        { value: "IR", label: "Iran, Islamic Republic of" },
        { value: "IQ", label: "Iraq" },
        { value: "IE", label: "Ireland" },
        { value: "IM", label: "Isle of Man" },
        { value: "IL", label: "Israel" },
        { value: "IT", label: "Italy" },
        { value: "JM", label: "Jamaica" },
        { value: "JP", label: "Japan" },
        { value: "JE", label: "Jersey" },
        { value: "JO", label: "Jordan" },
        { value: "KZ", label: "Kazakhstan" },
        { value: "KE", label: "Kenya" },
        { value: "KI", label: "Kiribati" },
        { value: "KP", label: "Korea, Democratic People's Republic of" },
        { value: "KR", label: "Korea, Republic of" },
        { value: "KW", label: "Kuwait" },
        { value: "KG", label: "Kyrgyzstan" },
        { value: "LA", label: "Lao People's Democratic Republic" },
        { value: "LV", label: "Latvia" },
        { value: "LB", label: "Lebanon" },
        { value: "LS", label: "Lesotho" },
        { value: "LR", label: "Liberia" },
        { value: "LY", label: "Libya" },
        { value: "LI", label: "Liechtenstein" },
        { value: "LT", label: "Lithuania" },
        { value: "LU", label: "Luxembourg" },
        { value: "MO", label: "Macao" },
        { value: "MK", label: "Macedonia, the Former Yugoslav Republic of" },
        { value: "MG", label: "Madagascar" },
        { value: "MW", label: "Malawi" },
        { value: "MY", label: "Malaysia" },
        { value: "MV", label: "Maldives" },
        { value: "ML", label: "Mali" },
        { value: "MT", label: "Malta" },
        { value: "MH", label: "Marshall Islands" },
        { value: "MQ", label: "Martinique" },
        { value: "MR", label: "Mauritania" },
        { value: "MU", label: "Mauritius" },
        { value: "YT", label: "Mayotte" },
        { value: "MX", label: "Mexico" },
        { value: "FM", label: "Micronesia, Federated States of" },
        { value: "MD", label: "Moldova, Republic of" },
        { value: "MC", label: "Monaco" },
        { value: "MN", label: "Mongolia" },
        { value: "ME", label: "Montenegro" },
        { value: "MS", label: "Montserrat" },
        { value: "MA", label: "Morocco" },
        { value: "MZ", label: "Mozambique" },
        { value: "MM", label: "Myanmar" },
        { value: "NA", label: "Namibia" },
        { value: "NR", label: "Nauru" },
        { value: "NP", label: "Nepal" },
        { value: "NL", label: "Netherlands" },
        { value: "NC", label: "New Caledonia" },
        { value: "NZ", label: "New Zealand" },
        { value: "NI", label: "Nicaragua" },
        { value: "NE", label: "Niger" },
        { value: "NG", label: "Nigeria" },
        { value: "NU", label: "Niue" },
        { value: "NF", label: "Norfolk Island" },
        { value: "MP", label: "Northern Mariana Islands" },
        { value: "NO", label: "Norway" },
        { value: "OM", label: "Oman" },
        { value: "PK", label: "Pakistan" },
        { value: "PW", label: "Palau" },
        { value: "PS", label: "Palestine, State of" },
        { value: "PA", label: "Panama" },
        { value: "PG", label: "Papua New Guinea" },
        { value: "PY", label: "Paraguay" },
        { value: "PE", label: "Peru" },
        { value: "PH", label: "Philippines" },
        { value: "PN", label: "Pitcairn" },
        { value: "PL", label: "Poland" },
        { value: "PT", label: "Portugal" },
        { value: "PR", label: "Puerto Rico" },
        { value: "QA", label: "Qatar" },
        { value: "RE", label: "Réunion" },
        { value: "RO", label: "Romania" },
        { value: "RU", label: "Russian Federation" },
        { value: "RW", label: "Rwanda" },
        { value: "BL", label: "Saint Barthélemy" },
        { value: "SH", label: "Saint Helena, Ascension and Tristan da Cunha" },
        { value: "KN", label: "Saint Kitts and Nevis" },
        { value: "LC", label: "Saint Lucia" },
        { value: "MF", label: "Saint Martin (French part)" },
        { value: "PM", label: "Saint Pierre and Miquelon" },
        { value: "VC", label: "Saint Vincent and the Grenadines" },
        { value: "WS", label: "Samoa" },
        { value: "SM", label: "San Marino" },
        { value: "ST", label: "Sao Tome and Principe" },
        { value: "SA", label: "Saudi Arabia" },
        { value: "SN", label: "Senegal" },
        { value: "RS", label: "Serbia" },
        { value: "SC", label: "Seychelles" },
        { value: "SL", label: "Sierra Leone" },
        { value: "SG", label: "Singapore" },
        { value: "SX", label: "Sint Maarten (Dutch part)" },
        { value: "SK", label: "Slovakia" },
        { value: "SI", label: "Slovenia" },
        { value: "SB", label: "Solomon Islands" },
        { value: "SO", label: "Somalia" },
        { value: "ZA", label: "South Africa" },
        { value: "GS", label: "South Georgia and the South Sandwich Islands" },
        { value: "SS", label: "South Sudan" },
        { value: "ES", label: "Spain" },
        { value: "LK", label: "Sri Lanka" },
        { value: "SD", label: "Sudan" },
        { value: "SR", label: "Suriname" },
        { value: "SJ", label: "Svalbard and Jan Mayen" },
        { value: "SZ", label: "Swaziland" },
        { value: "SE", label: "Sweden" },
        { value: "CH", label: "Switzerland" },
        { value: "SY", label: "Syrian Arab Republic" },
        { value: "TW", label: "Taiwan, Province of China" },
        { value: "TJ", label: "Tajikistan" },
        { value: "TZ", label: "Tanzania, United Republic of" },
        { value: "TH", label: "Thailand" },
        { value: "TL", label: "Timor-Leste" },
        { value: "TG", label: "Togo" },
        { value: "TK", label: "Tokelau" },
        { value: "TO", label: "Tonga" },
        { value: "TT", label: "Trinidad and Tobago" },
        { value: "TN", label: "Tunisia" },
        { value: "TR", label: "Turkey" },
        { value: "TM", label: "Turkmenistan" },
        { value: "TC", label: "Turks and Caicos Islands" },
        { value: "TV", label: "Tuvalu" },
        { value: "UG", label: "Uganda" },
        { value: "UA", label: "Ukraine" },
        { value: "AE", label: "United Arab Emirates" },
        { value: "GB", label: "United Kingdom" },
        { value: "US", label: "United States" },
        { value: "UM", label: "United States Minor Outlying Islands" },
        { value: "UY", label: "Uruguay" },
        { value: "UZ", label: "Uzbekistan" },
        { value: "VU", label: "Vanuatu" },
        { value: "VE", label: "Venezuela, Bolivarian Republic of" },
        { value: "VN", label: "Viet Nam" },
        { value: "VG", label: "Virgin Islands, British" },
        { value: "VI", label: "Virgin Islands, U.S." },
        { value: "WF", label: "Wallis and Futuna" },
        { value: "EH", label: "Western Sahara" },
        { value: "YE", label: "Yemen" },
        { value: "ZM", label: "Zambia" },
        { value: "ZW", label: "Zimbabwe" },
      ],
      defaultFormPro: {
        id: null,
        formDialog: false,
        title: "default title",
        addItem: false,
        content: [
          {
            id: 0,
            radio: "radio",
            label: "default label",
            label_color: "#000000",
            price: "0",
            count: 1,
            formDialogEdit: false,
            price_color: "#000000",
          },
        ],
      },
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: "jpg,svg,webp,gif,png",
        images_upload_handler: async (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), {
            lastModified: new Date().getTime(),
            type: blobInfo.blob().type,
          });
          formData.append(
            "lending_settings_id",
            this.$store.state.landing.settings_id
          );
          formData.append("image", _file);
          formData.append("file", _file);
          const { data } = await this.$axios.post(
            `${process.env.VUE_APP_API_URL}upload-image`,
            formData
          );
          success(data);
        },
        plugins:
          "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: "jpg,svg,webp,gif,png",
          images_upload_handler: async (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), {
              lastModified: new Date().getTime(),
              type: blobInfo.blob().type,
            });
            formData.append(
              "lending_settings_id",
              this.$store.state.landing.settings_id
            );
            formData.append("image", _file);
            formData.append("file", _file);
            const { data } = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}upload-image`,
              formData
            );
            success(data);
          },
          plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,
        min_height: 200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 300,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

        setup: function (editor) {
          editor.ui.registry.addButton("custom_button", {
            text: "Add Button",
            onAction: function () {
              // Open a Dialog
              editor.windowManager.open({
                title: "Add custom button",
                body: {
                  type: "panel",
                  items: [
                    {
                      type: "input",
                      name: "button_label",
                      label: "Button label",
                      flex: true,
                    },
                    {
                      type: "input",
                      name: "button_href",
                      label: "Button href",
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_target",
                      label: "Target",
                      items: [
                        { text: "None", value: "" },
                        { text: "New window", value: "_blank" },
                        { text: "Self", value: "_self" },
                        { text: "Parent", value: "_parent" },
                      ],
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_rel",
                      label: "Rel",
                      items: [
                        { text: "No value", value: "" },
                        { text: "Alternate", value: "alternate" },
                        { text: "Help", value: "help" },
                        { text: "Manifest", value: "manifest" },
                        { text: "No follow", value: "nofollow" },
                        { text: "No opener", value: "noopener" },
                        { text: "No referrer", value: "noreferrer" },
                        { text: "Opener", value: "opener" },
                      ],
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_style",
                      label: "Style",
                      items: [
                        { text: "Success", value: "success" },
                        { text: "Info", value: "info" },
                        { text: "Warning", value: "warning" },
                        { text: "Error", value: "error" },
                      ],
                      flex: true,
                    },
                  ],
                },
                onSubmit: function (api) {
                  var html =
                    '<a href="' +
                    api.getData().button_href +
                    '" class="v-btn v-btn--outlined theme--light v-size--small ' +
                    api.getData().button_style +
                    '--text" rel="' +
                    api.getData().button_rel +
                    '" target="' +
                    api.getData().button_target +
                    '">' +
                    api.getData().button_label +
                    "</a>";

                  // insert markup
                  editor.insertContent(html);

                  // close the dialog
                  api.close();
                },
                buttons: [
                  {
                    text: "Close",
                    type: "cancel",
                    onclick: "close",
                  },
                  {
                    text: "Insert",
                    type: "submit",
                    primary: true,
                    enabled: false,
                  },
                ],
              });
            },
          });
        },
        content_css: ["/custom_tinymce.css"],
      },
      show_text_color: false,
      content: "<h1>Initial Content</h1>",
      emailRules: [
        (v) => !!v || this.$t('landing.valid["E-mail обязателен"]'),
        (v) =>
          /.+@.+\..+/.test(v) ||
          this.$t('landing.valid["E-mail должен быть валидным"]'),
      ],
      subMenuDeleteBtnIndex: 0,
      subMenuDeletePageID: 0,
      styleSubMenuId: null,
      clearDataName:""
    };
  },
  mounted() {
    for (let i = 0; i < this.landing.advanced.selected_langs.length; i++) {
      const lang = this.landing.advanced.selected_langs[i];
      if (!this.landing.order_form.content[lang]) {
        this.landing.order_form.content[lang] = JSON.parse(
          JSON.stringify(this.landing.order_form.content["en"])
        );
      }
    }
  },
  destroyed() {
  this.setSelectedMenuItem(null);
  },
  computed: {
    ...mapGetters("landing", ["reviewsSocials", "reviews", "current_lang"]),
    ...mapState("landing", [
      "icons",
      "menu",
      "selected_langs",
      "profi",
      "settings_id",
    ]),
    ...mapState("menu", ["selectedMenuItem"]),
    ...mapState("mainPageStyles", ["list", "globalSettings"]),
    currentLangName(){
      return this.landing.advanced.langs.find(x => x.value === this.landing.current_lang).name
    },
    selectList(){
      const arr = [{name:"All",id:999},...this.selectedMenuItem.content[this.landing.current_lang][
      this.selectedMenuItem.type - 1
          ].content.menu.reduce((acc,item) => {
        acc.push({name:item.name,id:item.id});
        return acc;
      },[])]
      if(!arr){
        return []
      }
      return arr;
    },
    defaultLangName(){
      return this.landing.advanced.langs.find(x => x.value === this.landing.advanced.default_lang).name
    },
    authMenu() {
      return this.$store.state.menu.list.filter((item) => item.auth_switch);
    },
    noAuthMenu() {
      return this.$store.state.menu.list.filter((item) => !item.auth_switch);
    },
    activeLang: {
      set: function (val) {},
      get: function () {
        return this.landing.advanced.selected_langs.indexOf(
          this.landing.current_lang
        );
      },
    },
    ...mapState(["landing"]),
    subMenuTypes() {
      return [
        {
          id: 6,
          type: "contact",
          text: "Contact Form",
        },
        {
          id: 5,
          type: "faq",
          text: "FAQ",
        },
        {
          id: 2,
          type: "link",
          text: 'Link or file',
        },
        {
          id: 4,
          type: "listing",
          text: "Listing",
        },
        {
          id: 10,
          type: "points",
          text: "Map Points",
        },
        {
          id: 12,
          type: "gallery",
          text: "Page with gallery",
        },
        {
          id: 3,
          type: "phone",
          text: this.$t('constructor["Phone"]'),
        },
        {
          id: 1,
          type: "html",
          text: "Simple page",
        },
        {
          id: 9,
          type: "weather",
          text: "Weather",
        },
      ];
    },
    entities() {
      return entities;
    },
    types() {
      return [
        {
          id: 7,
          type: "contact",
          text: "Contact Form",
        },
        {
          id: 6,
          type: "faq",
          text: "FAQ",
        },
        {
          id: 2,
          type: "link",
          text: 'Link or file',
        },
        {
          id: 4,
          type: "listing",
          text: "Listing",
        },
        {
          id: 11,
          type: "points",
          text: "Map Points",
        },
        {
          id: 13,
          type: "gallery",
          text: "Page with gallery",
        },
        {
          id: 3,
          type: "phone",
          text: this.$t('constructor["Phone"]'),
        },
        {
          id: 1,
          type: "html",
          text: "Simple page",
        },
        {
          id: 5,
          type: "listingMenu",
          text: "Submenu",
        },
        // {
        //   id: 8,
        //   type: "event",
        //   text: "Events",
        // },
        // {
        //   id: 9,
        //   type: "product",
        //   text: "Products",
        // },
        // {
        //   id: 14,
        //   type: "trails",
        //   text: "Trails",
        // },
        {
          id: 10,
          type: "weather",
          text: "Weather",
        },
        // {
        //   id: 12,
        //   type: "dynamic",
        //   text: "Dynamic",
        // },
        // {
        //   id: 15,
        //   type: "radio",
        //   text: "Radio player",
        // },
        // {
        //   id:16,
        //   type:'tourist_listing',
        //   text:"Tourist places"
        // },
        // {
        //   id:17,
        //   type:'real_estates',
        //   text:"Property listing"
        // },
        // {
        //   id:18,
        //   type:'tourist_spaces',
        //   text:"Tourist property"
        // }
      ];
    },
  },
  methods: {
    ...mapMutations("menu", ["setSelectedMenuItem", "setMenuList"]),
    openButtonSettings(item){
      if(item.id !== 999){
        this.selectedItem = this.selectedMenuItem.content[this.landing.current_lang][4].content.menu.find(x => x.id === item.id);
      }else{
        this.selectedItem = {name:"All",id:999};
      }
      if(this.selectedItem.id !== 999){
        this.selectedSubMenuStyles = this.selectedMenuItem.content[this.landing.current_lang][4].content.menu.find(x => x.id === this.selectedItem.id);
      }else{
        this.selectedSubMenuStyles = null;
      }
      this.openInternalStyles();
    },
    openInternalStyles(){
      this.selectedMenuItem.content[this.landing.current_lang][this.selectedMenuItem.type-1].styleSettings = !this.selectedMenuItem.content[this.landing.current_lang][this.selectedMenuItem.type-1].styleSettings
    },
    changeFormatLangPic(lang) {
      const langArr = lang.split("-");
      if (langArr[1]) {
        const candidate = this.countries.find(
            (x) => x.value === langArr[1].toUpperCase()
        );
        if (candidate) {
          return candidate.value;
        }
      } else {
        if(lang === 'en'){
          return 'GB';
        }
        return lang.toUpperCase();
      }
    },
    confirmCopy() {
      this.$store.state.menu.selectedMenuItem.content[this.landing.current_lang] = this.$store.state.menu.selectedMenuItem.content[this.landing.advanced.default_lang];
      this.forceRerender();
    },
    openCopy() {
      this.$refs.PopupConfirmCopy.open();
    },
    openClearData(name) {
      this.clearDataName = name;
      this.$refs.PopupConfirmClear.open();
    },
    confirmClear() {
      this.$store.state.menu.selectedMenuItem.content[this.landing.current_lang][this.$store.state.menu.selectedMenuItem.type-1].content = defaultContentMenuItem[this.$store.state.menu.selectedMenuItem.type-1].content;
      this.forceRerender();
    },
    buttonSettingsTrigger(val){
      this.buttonSettings = val
    },
    openMenuTemplates(){
      this.menuTemplate = true;
    },
    closeMenuTemplates(){
      this.menuTemplate = false;
    },
    checkRadius(item,menu){
      if(+item.styles.lining_width === 100){
        if(item.styles.position === 'flex-start flex-start' || item.styles.position === 'flex-end flex-start' || item.styles.position === 'center flex-start'){
          return `border-top-right-radius:${menu.radius}px;border-top-left-radius:${menu.radius}px;`
        }
        if(item.styles.position === 'flex-start flex-end' || item.styles.position === 'flex-end flex-end' || item.styles.position === 'center flex-end'){
          return `border-bottom-right-radius:${menu.radius}px;border-bottom-left-radius:${menu.radius}px;`
        }
      }else{
        return `border-radius:${menu.radius}px;`
      }
    },
    customMenuLinkStyles(menu, item) {
      let styles = `
      height: 150px;
      color: rgb(0, 0, 0);
      border-radius": 10px;
      box-shadow: rgb(0 0 0 / 10%) 0px 3px 6px;
      background-color: rgb(255, 255, 255);
      text-align: unset;
      justify-content: center;
      align-items: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    `
      return styles;
    },
    displayType(item, listing) {
      if (item.displayType === "horizontal") {
        return "flex-direction:row;align-items:normal;";
      } else if (item.displayType === "inside") {
        let bg = "";
        if (listing.preview) {
          bg = "transparent";
        } else {
          bg = `url(${listing.main_img}) no-repeat center / 100%`;
        }
        return `background:${bg};
                background-size:100%;
                overflow:hidden;
                text-align:center;
                height:200px;
                border-radius:10px;
                background-position: center center;
                background-repeat:no-repeat;`;
      } else {
        return "flex-direction:column;";
      }
    },
    customMenuStyles(menu, item) {
      return `color: ${item.styles.color};font-family:'${item.styles.fontFamily}',Inter,sans-serif;font-style:${item.styles.fontStyle};font-weight:${item.styles.fontWeight};font-size:${item.styles.fontSize}px;width:calc(33.33% - 10px);margin-right: 10px;`;
    },
    openStyles(menu){
      this.$store.state.selectBrandingMenu = menu;
      this.$store.commit('menu/setSelectedMenuItem',null);
      this.$store.state.selectedPage = "branding";
      this.$store.state.active_branding_id = 15;
      this.$store.state.tab = 11;
    },
    changeTypeDynamic(type){
      this.selectedMenuItem.content[this.landing.current_lang][11].content[this.$store.state.dynamicIndex].type = type;
    },
    changeSelectedMenu() {
      if(this.selectedItem.id !== 999){
        this.selectedSubMenuStyles = this.selectedMenuItem.content[this.landing.current_lang][4].content.menu.find(x => x.id === this.selectedItem.id);
      }else{
        this.selectedSubMenuStyles = null;
      }
      this.forceRerender();
    },
    openCopyLang(){
      this.$refs.PopupConfirm.open();
    },
    copyLangContent(){
      this.selectedMenuItem.content[this.landing.current_lang][4] = JSON.parse(JSON.stringify(this.selectedMenuItem.content[this.landing.advanced.default_lang][4]));
      this.forceRerender();
    },
    forceRerender() {
      // Removing my-component from the DOM
      this.$store.state.renderComponent = false;

      this.$nextTick(() => {
        // Adding the component back in
        this.$store.state.renderComponent = true;
      });
    },
    setFontColor() {
      for (let i = 0; i < this.menu.list.length; i++) {
        this.menu.list[i].styles.color = this.font_color;
      }
      this.setMenuList(this.menu.list);
    },
    setLiningBackground() {
      for (let i = 0; i < this.menu.list.length; i++) {
        this.menu.list[i].styles.main_menu_lining = this.lining_color;
      }
      this.setMenuList(this.menu.list);
    },
    setMenuBackground() {
      for (let i = 0; i < this.menu.list.length; i++) {
        this.menu.list[i].styles.background_main_color = this.background_color;
      }
      this.setMenuList(this.menu.list);
    },
    changeSubmenuStyle(id) {
      if (id === 0 || id) {
        this.styleSubMenuId = id;
      } else {
        this.styleSubMenuId = null;
        this.selectedMenuItem.content[this.landing.current_lang][
        this.selectedMenuItem.type - 1
            ].styleSettings = false;
      }
      this.submenuStyle = !this.submenuStyle;
    },
    handleChange(menu, value) {
      this.showIcons = false;
      menu.icon = value;
    },
    changeInMenuAuth() {
      const candidate = this.menu.list.find(
        (item) => item.id === this.selectedMenuItem.id
      );
      if (candidate) {
        candidate.auth_switch = this.selectedMenuItem.auth_switch;
      }
    },
    changeInMenuSocials() {
      const candidate = this.menu.list.find(
        (item) => item.id === this.selectedMenuItem.id
      );
      if (candidate) {
        candidate.show_social = this.selectedMenuItem.show_social;
      }
    },
    changeInMenuActive() {
      const candidate = this.menu.list.find(
        (item) => item.id === this.selectedMenuItem.id
      );
      if (candidate) {
        candidate.active = this.selectedMenuItem.active;
      }
    },
    activeMenu(menu) {
      return menu.id === this.selectedMenuItem?.id;
    },
    openDeletePopupMenu(item) {
      this.deleteItemId = item;
      this.$refs.PopupDeleteMenuItem.open();
    },
    deleteMenuItem() {
      const candidate = this.$store.state.menu.list.find(
        (x) => x.id === this.deleteItemId.id
      );
      if (candidate) {
        const candidateIndex = this.$store.state.menu.list.indexOf(candidate);
        this.$store.state.menu.list.splice(candidateIndex, 1);
        this.deleteItemId = null;
      }
      this.$store.state.deleteMenuItemTrigger = false;
      this.setSelectedMenuItem(null);
    },
    addNewButton() {
      const button = createMenuItem(this.landing.menu.list.length);
      this.landing.advanced.selected_langs.forEach((lang) => {
        if (!button.content[lang]) {
          button.content[lang] = button.content["en"];
        }
      });
      this.landing.menu.list.push(button);
    },
    openListingMenu(menuArr, menu) {
      const candidate = menuArr.find((x) => x.hidden === false);
      if (candidate) {
        candidate.hidden = true;
        candidate.flipped = false;
      }
      menu.hidden_settings = true;
      menu.hidden = false;
    },
    mountBgMenu(i, menu) {
      if (menu.background) {
        let mockFile = {
          accepted: true,
          cropped: true,
          kind: "image",
          ...menu,
        };
        this.$refs[`uploadBG_${i}`][0].manuallyAddFile(
          mockFile,
          menu.background
        );
        this.$refs[`uploadBG_${i}`][0].dropzone[
          "previewsContainer"
        ].children[1].children[0].children[0].src = menu.background;
        this.$refs[`uploadBG_${i}`][0].dropzone[
          "previewsContainer"
        ].children[1].children[0].style.borderRadius = 0;
        this.$refs[`uploadBG_${i}`][0].dropzone[
          "previewsContainer"
        ].children[1].children[1].style.background = "none";
        this.$refs[`uploadBG_${i}`][0].dropzone.emit("complete", mockFile);
      }
    },
    showSettings(menuArr, menu) {
      menu.hidden_settings = !menu.hidden_settings;
    },
    getFileExtension(filename) {
      if (filename) {
        const extension =
          filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
          filename;
        return extension;
      } else {
        return "";
      }
    },
    mountDropzoneMenu(i, menu) {
      if (menu.icon) {
        if (this.getFileExtension(menu.icon) === "svg") {
          return;
        } else {
          let mockFile = {
            accepted: true,
            cropped: true,
            kind: "image",
            ...menu,
          };
          this.$refs[`dropzoneMenu_${i}`][0].manuallyAddFile(
            mockFile,
            menu.icon
          );
          this.$refs[`dropzoneMenu_${i}`][0].dropzone[
            "previewsContainer"
          ].children[1].children[0].children[0].src = menu.icon;
          this.$refs[`dropzoneMenu_${i}`][0].dropzone[
            "previewsContainer"
          ].children[1].children[0].style.borderRadius = 0;
          this.$refs[`dropzoneMenu_${i}`][0].dropzone[
            "previewsContainer"
          ].children[1].children[1].style.background = "none";
          this.$refs[`dropzoneMenu_${i}`][0].dropzone.emit(
            "complete",
            mockFile
          );
        }
      }
    },
    menuIconChange(file, menu) {
      menu.icon = file.xhr.response;
    },
    menuIconRemove(menu) {
      menu.icon = "";
    },
    maxFileSexceeded(file) {
      this.$refs["dropzoneFile"][0].removeAllFiles();
      this.$refs["dropzoneFile"][0].addFile(file);
    },
    mountDropzoneFile(img) {
      if (img) {
        const file = {
          name: "Icon",
          type: "image/jpeg",
          dataURL: process.env.VUE_APP_LINK + "/file.png",
        };
        this.$refs["dropzoneFile"][0].manuallyAddFile(
          file,
          process.env.VUE_APP_LINK + "/file.png"
        );
        this.$refs["dropzoneFile"][0].dropzone.emit(
          "thumbnail",
          file,
          file.dataURL
        );
        this.$refs["dropzoneFile"][0].dropzone.emit("complete", file);
      }
    },
    async setDataFromOutside(menu, listing) {
      let link = menu.outsideLink;
      if (menu.outsideCount) {
        link += "&count=" + menu.outsideCount;
      }
      const response = await this.$axios.get(link);
      listing.listing[0].content = [];
      for (let i = 0; i < response.data.length; i++) {
        let item = {
          img: [],
          video_link: null,
          name: "default name",
          descr: "default description",
          main_img: "",
          preview: false,
          full_descr: "default full description",
          price: "100",
          show_price: true,
          show_descr: true,
          name_color: "#000000",
          position: "center center",
          background_color: "rgba(255,255,255,1)",
          descr_color: "#000000",
          show_name_color: false,
          show_descr_color: false,
          id: 1,
          form: {
            input_1: null,
            input_2: null,
            input_3: null,
            textarea: null,
            email: null,
            btn_name: "Send",
            btn_color: "#000000",
            btn_bg: "rgba(255,255,255,0.5)",
            btn_border: "#000000",
            show: false,
            type: "pro",
            flip: false,
            pro: [
              {
                id: 0,
                title: "default title",
                addItem: false,
                formDialog: false,
                content: [
                  {
                    id: 0,
                    radio: "radio",
                    label: "default label",
                    count: 1,
                    price: "0",
                    text: "",
                    formDialogEdit: false,
                  },
                ],
              },
            ],
          },
        };
        if (response.data[i].images) {
          item.img = response.data[i].images;
        }
        if (response.data[i].image) {
          item.main_img = response.data[i].image;
        }
        if (response.data[i].content) {
          response.data[i].content = response.data[i].content.replace(
            "font-family: Arial;",
            ""
          );
          let text = new DOMParser()
            .parseFromString(response.data[i].content, "text/xml")
            .getElementsByTagName("p")[0]
            .getElementsByTagName("span")[0]
            .getAttribute("data-sheets-value");
          item.descr = text.slice(12, 100) + "...";
          item.full_descr = response.data[i].content;
          item.full_descr += `<a href=${response.data[i].link_to_detail} target="_blank">Open Tourist</a>`;
        }

        if (response.data[i].title) {
          item.name = response.data[i].title;
        }
        item.id = i;
        listing.listing[0].content.push(item);
      }
    },
    setListingIndexes(ind, grp, d) {
      this.listingGroupIndex = ind;
      this.listingGroupItemIndex = grp;
      if (d === "desc") {
        this.changeListingPage(
          this.$store.state.menu.list[this.active_tab - 1],
          grp,
          ind
        );
      }
    },
    changeListingPage(page, index, listingIndex) {
      this.$store.commit("setListingPage", {
        index: listingIndex,
        page: page,
        listingGroup: index,
      });
    },
    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    handleSuccessFile(file, content) {
      let link = file.xhr.response.replaceAll("\\", "").replaceAll('"', "");
      content.content = link;
      document.getElementsByClassName("dz-image")[0].style.background =
        "transparent";
      document
        .getElementsByClassName("dz-image")[0]
        .getElementsByTagName("img")[0].src =
        process.env.VUE_APP_LINK + "/file.png";
      document.getElementsByClassName("dz-details")[0].style.background =
        "transparent";
    },
    handleSuccessMain(file) {
      this.$store.state.menu.list[this.active_tab - 1].content[
        this.landing.current_lang
      ][3].content.listing[this.listingGroupIndex].content[
        this.listingGroupItemIndex
      ].main_img = file.xhr.response;
    },
    handleSuccess(file, i, index) {
      this.$store.state.menu.list[this.active_tab - 1].content[
        this.landing.current_lang
      ][3].content.listing[i].content[index].img.splice(
        this.$store.state.menu.list[this.active_tab - 1].content[
          this.landing.current_lang
        ][3].content.listing[i].content[index].img.indexOf("1"),
        1,
        file.xhr.response
      );
      let item =
        this.$store.state.menu.list[this.active_tab - 1].content[
          this.landing.current_lang
        ][3].content.listing[i].content[index];
      let elements =
        this.$refs[`upload_detail_${i}_${index}`][0].$el.getElementsByClassName(
          "dz-preview"
        );
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      this.$refs[`upload_detail_${i}_${index}`][0].dropzone.files = [];
      for (let imgInd = 0; imgInd < item.img.length; imgInd++) {
        let mockFile = {
          accepted: true,
          cropped: true,
          imgRemove: imgInd,
          notRemove: true,
          kind: "image",
          ...item,
        };
        if (this.$refs[`upload_detail_${i}_${index}`][0].dropzone) {
          if (
            !this.$refs[
              `upload_detail_${i}_${index}`
            ][0].dropzone.files.includes(item.img[imgInd])
          ) {
            if (
              this.$refs[`upload_detail_${i}_${index}`][0].dropzone.files
                .length !== item.img.length
            ) {
              this.$refs[`upload_detail_${i}_${index}`][0].manuallyAddFile(
                mockFile,
                item.img[imgInd]
              );
              this.$refs[`upload_detail_${i}_${index}`][0].dropzone[
                "previewsContainer"
              ].children[imgInd + 1].children[0].children[0].src =
                item.img[imgInd];
              this.$refs[`upload_detail_${i}_${index}`][0].dropzone[
                "previewsContainer"
              ].children[imgInd + 1].children[0].style.borderRadius = 0;
              this.$refs[`upload_detail_${i}_${index}`][0].dropzone[
                "previewsContainer"
              ].children[imgInd + 1].children[1].style.background = "none";
              this.$refs[`upload_detail_${i}_${index}`][0].dropzone.emit(
                "complete",
                mockFile
              );
            }
          }
        }
      }
      this.$store.commit(
        "setPage",
        this.$store.state.menu.list[this.active_tab - 1].page
      );
    },
    handleRemoveMain(file, i, listingIndex) {
      this.$store.state.menu.list[this.active_tab - 1].content[
        this.landing.current_lang
      ][3].content.listing[i].content[listingIndex].main_img = "";
    },
    handleRemoveFile(file, content) {
      content.content = "";
    },
    openReviews() {
      this.$store.state.tab = 3;
      this.changePage("reviews");
    },
    billing() {
      let obj = this.$store.state.objects.list.find(
        (x) => +x.id === +this.$route.params.id
      );
      this.$refs.PopupTariff.open(obj);
    },
    saveEdit(item) {
      item.answer = this.editedItem.answer;
      item.question = this.editedItem.question;
      item.edit = false;
    },
    addListingGroup(arr) {
      let defaultListingGroup = {
        title: "default listing title",
        hide: false,
        content: [
          {
            img: [],
            video_link: null,
            name: "default name",
            descr: "default description",
            main_img: "",
            full_descr: "default full description",
            price: "100",
            show_price: true,
            show_descr: true,
            name_color: "#000000",
            position: "center center",
            background_color: "rgba(255,255,255,1)",
            descr_color: "#000000",
            show_name_color: false,
            show_descr_color: false,
            id: 1,
            form: {
              input_1: null,
              input_2: null,
              input_3: null,
              textarea: null,
              email: null,
              btn_name: "Send",
              btn_color: "#000000",
              btn_bg: "#ffffff",
              btn_border: "#000000",
              show: false,
              type: "pro",
              flip: false,
              pro: [
                {
                  id: 0,
                  title: "default title",
                  addItem: false,
                  formDialog: false,
                  content: [
                    {
                      id: 0,
                      radio: "radio",
                      label: "default label",
                      count: 1,
                      price: "0",
                      text: "",
                      formDialogEdit: false,
                    },
                  ],
                },
              ],
            },
          },
        ],
      };
      arr.push(defaultListingGroup);
      this.listingGroupIndex = arr.length - 1;
      this.listingGroupItemIndex = 0;
    },
    deleteListingGroup(i, arr) {
      arr.splice(i, 1);
    },
    deleteFormGroupOpen(index) {
      this.delete_form_group_index = index;
      this.$refs["PopupDeleteFormPro"][0].open();
    },
    deleteSubFormPro(id, arr) {
      const candidate = arr.find((x) => x.id === id);
      arr.splice(arr.indexOf(candidate), 1);
    },
    saveFormPro(arr) {
      let obj = JSON.parse(JSON.stringify(this.addItemForm));
      obj.id = arr.content.length ? arr.content.length : 0;
      arr.content.push(obj);
      arr.formDialog = false;
      this.$refs["PopupSuccess"][0].open();
      setTimeout(() => {
        this.addItemForm.id = null;
        this.addItemForm.radio = "radio";
        this.addItemForm.label = "default label";
        this.addItemForm.label_color = "#000000";
        this.addItemForm.price = "0";
        this.addItemForm.price_color = "#000000";
      }, 1000);
    },
    deleteFormPro(arr) {
      arr.splice(this.delete_form_group_index, 1);
      this.delete_form_group_index = null;
    },
    addFormGroup(arr) {
      const newArr = JSON.parse(JSON.stringify(this.defaultFormPro));
      newArr.id = arr.length ? arr.length : 0;
      arr.push(newArr);
      this.$refs["PopupSuccess"][0].open();
    },
    deleteFaqGroup() {
      this.arrGroupFaq.splice(this.indexGroupFaq, 1);
      this.indexGroupFaq = null;
      this.arrGroupFaq = null;
    },
    openFaqGroupDelete(index, arr) {
      this.indexGroupFaq = index;
      this.arrGroupFaq = arr;
      this.$refs["openFaqGroupDeleteRef"][0].open();
    },
    addSocial(arr, id) {
      arr.forEach((social) => {
        if (social.id === id) {
          social.active = !social.active;
        }
      });
    },
    openListingDelete(i, id) {
      this.subMenuDeleteBtnIndex = i;
      this.subMenuDeletePageID = id;
      this.$refs.PopupDeleteListing.open();
    },
    openFaqDelete(id) {
      this.deleteItemId = id;
      this.$refs["PopupDeleteFaq"][0].open();
    },
    saveFaq(faq) {
      const item = {
        question: this.addItemFaq.question,
        answer: this.addItemFaq.answer,
        id: faq.content.length ? faq.content.length : 0,
        edit: false,
      };
      faq.content.push(item);
      faq.dialogFaqNew = false;
      this.addItemFaq.question = "default question";
      this.addItemFaq.answer = "default answer";
    },
    deleteFaqItem(content) {
      if (this.deleteItemId !== content.length - 1) {
        for (let i = 0; i < content.length; i++) {
          if (i > this.deleteItemId) {
            content[i].id = content[i].id - 1;
          }
        }
        content.splice(this.deleteItemId, 1);
        this.deleteItemId = null;
      } else {
        content.splice(this.deleteItemId, 1);
        this.deleteItemId = null;
      }
    },
    closeFaqDeleteConfirm() {
      this.dialogFaqDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    addFaq(content) {
      const defaultFaq = {
        title: "default group title",
        dialogFaqNew: false,
        content: [
          {
            question: "default question",
            answer: "default answer",
            id: 0,
            edit: false,
          },
        ],
      };
      content.push(defaultFaq);
    },
    removeBackground(menu) {
      menu.background = null;
    },
    customEventFile(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append("hotel_id", this.$route.params.id);
      formData.append("file", _file);
    },
    customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append(
        "lending_settings_id",
        this.$store.state.landing.settings_id
      );
      formData.append("image", _file);
    },
    afterCompleteImage(file, menu) {
      menu.background = file.xhr.response;
    },
    addMenuButton(menuArr) {
      let listing =
      {
        hidden: false,
            width: "50",
          styles: JSON.parse(JSON.stringify(defaults.defaultStyles)),
          hidden_settings: false,
          color: "#000000",
          height: "150",
          position: "center center",
          show_background: false,
          background: null,
          fontSize: "16",
          fontWeight: "400",
          fontStyle: "normal",
          fontFamily: "Inter",
          displayType: "horizontal",
          horizontal_background: "rgba(255, 255, 255, 0.5)",
          horizontal_color: "#000000",
          horizontal_active_background: "rgba(255, 255, 255, 0.5)",
          horizontal_active_color: "#007db5",
          name: "default",
          flipped: false,
          show_text_color: false,
          show_icon_up: true,
          show_icon: true,
          icon_color: "#000000",
          disable_icon_color: true,
          show_icon_color_sub: false,
          show_name_color: false,
          show_descr_color: false,
          listing_img_height: "110",
          listing_img_width: "90",
          detail_listing_img_height: "150",
          content: [
        {
          type: 1,
          content:
              "<p>Blank page, but you can add something interesting to it!</p>",
          slider: [
            {
              id: 0,
              title: "Example title",
              slider_view_all: {
                show: false,
                text: "View all",
                link: "",
              },
              hide: false,
              show_type: "bottom",
              border_color: "#cccccc",
              type: "horizontal",
              settings: false,
              vertical: {
                height: "220",
                width: "150",
                img_height: "150",
              },
              horizontal: {
                height: "150",
                width: "250",
                img_width: "100",
              },
              background: "rgba(255,255,255,1)",
              bg_group: "rgba(255,255,255,1)",
              radius: 0,
              slides: [
                {
                  img: require("@/assets/none.png"),
                  text: "Example first block",
                  text_link: null,
                  useLink: false,
                  link: null,
                },
              ],
            },
          ],
          active_slider: false,
          form: JSON.parse(JSON.stringify(defaults.defaultFormMenuItem)),
        },
        {
          type: 2,
          content: "",
        },
        {
          type: 3,
          content: "",
        },
        {
          type: 4,
          content: {
            listing: [
              {
                title: "default listing title",
                hide: true,
                content: [
                  {
                    img: [],
                    video_link: null,
                    name: "default name",
                    descr: "default description",
                    main_img: "",
                    preview: false,
                    full_descr: "default full description",
                    price: "100",
                    hide: true,
                    show_price: true,
                    show_descr: true,
                    name_color: "#000000",
                    lining_padding: 15,
                    position: "center center",
                    background_color: "rgba(255,255,255,1)",
                    descr_color: "#000000",
                    show_name_color: false,
                    show_descr_color: false,
                    id: 1,
                    form: JSON.parse(
                        JSON.stringify(defaults.defaultFormMenuItem)
                    ),
                  },
                ],
              },
            ],
          },
          styles: JSON.parse(
              JSON.stringify(defaultListingStyles)
          ),
          active_slider: false,
          slider: [
            {
              id: 0,
              title: "Example title",
              slider_view_all: {
                show: false,
                text: "View all",
                link: "",
              },
              settings: false,
              hide: false,
              show_type: "bottom",
              border_color: "#cccccc",
              type: "horizontal",
              vertical: {
                height: "220",
                width: "150",
                img_height: "150",
              },
              horizontal: {
                height: "150",
                width: "250",
                img_width: "100",
              },
              background: "rgba(255,255,255,1)",
              bg_group: "rgba(255,255,255,1)",
              radius: 0,
              slides: [
                {
                  img: require("@/assets/none.png"),
                  text: "Example first block",
                  text_link: null,
                  useLink: false,
                  link: null,
                },
              ],
            },
          ],
        },
        {
          type: 5,
          horizontal: false,
          style: "tile",
          radius: 10,
          active_tab: 0,
          icon_size: 36,
          shadowColor: "#0000001A",
          shadowSize: "3",
          horizontal_background: "rgba(255, 255, 255, 0.5)",
          horizontal_color: "#000000",
          horizontal_active_background:
              "rgba(255, 255, 255, 0.5)",
          horizontal_active_color: "#007db5",
          motivation: JSON.parse(
              JSON.stringify(defaults.defaultMotivationText)
          ),
          color: "#007db5",
          slider: [
            {
              id: 0,
              title: "Example title",
              slider_view_all: {
                show: false,
                text: "View all",
                link: "",
              },
              settings: false,
              hide: false,
              show_type: "bottom",
              border_color: "#cccccc",
              type: "horizontal",
              vertical: {
                height: "220",
                width: "150",
                img_height: "150",
              },
              horizontal: {
                height: "150",
                width: "250",
                img_width: "100",
              },
              background: "rgba(255,255,255,1)",
              bg_group: "rgba(255,255,255,1)",
              radius: 0,
              slides: [
                {
                  img: require("@/assets/none.png"),
                  text: "Example first block",
                  text_link: null,
                  useLink: false,
                  link: null,
                },
              ],
            },
          ],
          active_slider: false,
          content: [
            {
              title: "default group title",
              dialogFaqNew: false,
              content: [
                {
                  id: 0,
                  question: "default question",
                  answer: "default answer",
                  edit: false,
                },
              ],
            },
          ],
        },
        {
          type: 6,
          style: "default",
          radius: 32,
          width: "100",
          text: "<p ><strong>Contact us</strong></p><p>If you have any questions or queries will always be happy to help.</p>",
          content: [
            {
              id: 1,
              text: "Whatsapp",
              icon:
                  '<svg version="1.1" id="Capa_1" height="24" width="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n' +
                  '\t viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;" xml:space="preserve">\n' +
                  "<g>\n" +
                  '\t<path style="fill:#2CB742;" d="M0,58l4.988-14.963C2.457,38.78,1,33.812,1,28.5C1,12.76,13.76,0,29.5,0S58,12.76,58,28.5\n' +
                  '\t\tS45.24,57,29.5,57c-4.789,0-9.299-1.187-13.26-3.273L0,58z"/>\n' +
                  '\t<path style="fill:#FFFFFF;" d="M47.683,37.985c-1.316-2.487-6.169-5.331-6.169-5.331c-1.098-0.626-2.423-0.696-3.049,0.42\n' +
                  "\t\tc0,0-1.577,1.891-1.978,2.163c-1.832,1.241-3.529,1.193-5.242-0.52l-3.981-3.981l-3.981-3.981c-1.713-1.713-1.761-3.41-0.52-5.242\n" +
                  "\t\tc0.272-0.401,2.163-1.978,2.163-1.978c1.116-0.627,1.046-1.951,0.42-3.049c0,0-2.844-4.853-5.331-6.169\n" +
                  "\t\tc-1.058-0.56-2.357-0.364-3.203,0.482l-1.758,1.758c-5.577,5.577-2.831,11.873,2.746,17.45l5.097,5.097l5.097,5.097\n" +
                  '\t\tc5.577,5.577,11.873,8.323,17.45,2.746l1.758-1.758C48.048,40.341,48.243,39.042,47.683,37.985z"/>\n' +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "</svg>",
              color: "#30D24C",
              active: false,
              link: "",
            },

            {
              id: 2,
              text: "Viber",
              icon:
                  '<svg version="1.1" height="24" width="24" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n' +
                  '\t viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">\n' +
                  '<path style="fill:#665CAC;" d="M307.207,0H204.794C105.969,0,25.571,80.398,25.571,179.223v76.81\n' +
                  "\tc0,69.381,40.014,132.344,102.413,161.961v85.537c0,7.427,9.291,11.31,14.569,6.035l74.309-74.31h90.344\n" +
                  '\tc98.824,0,179.223-80.398,179.223-179.223v-76.81C486.429,80.398,406.03,0,307.207,0z"/>\n' +
                  '<path style="fill:#523494;" d="M153.587,417.993c-62.399-29.618-102.413-92.58-102.413-161.961v-76.81\n' +
                  "\tC51.174,80.398,131.573,0,230.397,0h-25.603C105.969,0,25.571,80.398,25.571,179.223v76.81c0,69.381,40.014,132.344,102.413,161.961\n" +
                  '\tv85.537c0,7.427,9.291,11.31,14.569,6.035l11.034-11.034V417.993z"/>\n' +
                  '<path style="fill:#FFFFFF;" d="M384.85,318.101l-7.673-41.566c-0.635-3.434-3.295-6.135-6.719-6.82l-60.347-12.069\n' +
                  "\tc-2.803-0.558-5.691,0.317-7.71,2.334l-24.517,24.518c-41.308-18.509-64.234-41.485-84.406-84.566l24.438-24.437\n" +
                  "\tc2.017-2.018,2.893-4.912,2.334-7.71l-12.069-60.348c-0.685-3.423-3.386-6.085-6.82-6.719l-41.566-7.672\n" +
                  "\tc-1.138-0.209-2.31-0.187-3.44,0.07c-10.975,2.493-20.989,8.023-28.96,15.996c-13.802,13.802-19.077,33.942-15.253,58.243\n" +
                  "\tc6.419,40.806,26.411,97.594,63.296,134.627l0.504,0.5c37.004,36.859,93.793,56.853,134.601,63.273\n" +
                  "\tc5.141,0.81,10.092,1.211,14.84,1.211c17.695,0,32.52-5.582,43.405-16.465c7.971-7.972,13.503-17.986,15.995-28.959\n" +
                  '\tC385.036,320.411,385.06,319.241,384.85,318.101z"/>\n' +
                  "<g>\n" +
                  '\t<polygon style="fill:#E5E5E5;" points="209.571,183.839 217.915,175.494 217.924,175.485 209.57,183.838 \t"/>\n' +
                  "\t\n" +
                  '\t\t<rect x="292.235" y="264.183" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -99.4675 288.2322)" style="fill:#E5E5E5;" width="11.916" height="0.002"/>\n' +
                  '\t<path style="fill:#E5E5E5;" d="M341.473,350.871c-4.748,0-9.699-0.401-14.84-1.211c-40.807-6.42-97.596-26.414-134.601-63.273\n' +
                  "\t\tl-0.504-0.5c-36.885-37.033-56.877-93.821-63.296-134.627c-3.089-19.633-0.233-36.548,8.256-49.601\n" +
                  "\t\tc-3.251,2.169-6.302,4.657-9.097,7.452c-13.802,13.802-19.077,33.942-15.253,58.243c6.419,40.806,26.411,97.594,63.296,134.627\n" +
                  "\t\tl0.504,0.5c37.004,36.859,93.793,56.853,134.601,63.273c5.141,0.81,10.092,1.211,14.84,1.211c17.695,0,32.52-5.581,43.405-16.465\n" +
                  '\t\tc2.794-2.795,5.282-5.845,7.451-9.096C366.598,347.672,354.858,350.871,341.473,350.871z"/>\n' +
                  "</g>\n" +
                  "<g>\n" +
                  '\t<path style="fill:#FFFFFF;" d="M273.069,145.085c-4.713,0-8.534,3.821-8.534,8.534s3.821,8.534,8.534,8.534\n' +
                  "\t\tc18.824,0,34.138,15.314,34.138,34.138c0,4.65,3.884,8.534,8.534,8.534s8.534-3.884,8.534-8.534\n" +
                  '\t\tC324.276,168.055,301.305,145.085,273.069,145.085z"/>\n' +
                  '\t<path style="fill:#FFFFFF;" d="M273.069,110.947c-3.157,0-6.343,0.175-9.471,0.52c-4.685,0.517-8.064,4.734-7.547,9.42\n' +
                  "\t\ts4.743,8.056,9.42,7.547c2.508-0.277,5.064-0.418,7.598-0.418c37.647,0,68.275,30.628,68.275,68.275\n" +
                  "\t\tc0,2.535-0.141,5.091-0.418,7.599c-0.51,4.621,2.924,8.911,7.547,9.42c4.622,0.509,8.909-2.924,9.42-7.547\n" +
                  '\t\tc0.345-3.128,0.52-6.314,0.52-9.472C358.413,149.233,320.128,110.947,273.069,110.947z"/>\n' +
                  '\t<path style="fill:#FFFFFF;" d="M273.069,76.81c-3.05,0-6.139,0.116-9.179,0.347c-4.7,0.356-8.222,4.456-7.866,9.155\n' +
                  "\t\tc0.355,4.7,4.469,8.222,9.155,7.866c2.613-0.199,5.268-0.299,7.89-0.299c56.471,0,102.413,45.942,102.413,102.413\n" +
                  "\t\tc0,7.99-0.922,15.944-2.74,23.64c-1.043,4.41,1.673,8.994,6.046,10.192c4.601,1.26,9.469-1.625,10.565-6.266\n" +
                  '\t\tc2.122-8.981,3.198-18.255,3.198-27.566C392.551,130.409,338.952,76.81,273.069,76.81z"/>\n' +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "</svg>",
              color: "#6F5DEA",
              active: false,
              link: "",
            },

            {
              id: 3,
              text: "Custom",
              icon:
                  '<svg version="1.1" id="Layer_1"  xmlns="http://www.w3.org/2000/svg" height="24" width="24" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n' +
                  '\t viewBox="0 0 416 416" style="enable-background:new 0 0 416 416;" xml:space="preserve">\n' +
                  "<g>\n" +
                  "\t<g>\n" +
                  "\t\t<g>\n" +
                  '\t\t\t<path d="M256,160h-12c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h12c4.4,0,8-3.6,8-8C264,163.6,260.4,160,256,160z"/>\n' +
                  '\t\t\t<path d="M208,160h-60c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h60c4.4,0,8-3.6,8-8C216,163.6,212.4,160,208,160z"/>\n' +
                  '\t\t\t<path d="M336,112H148c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h188c4.4,0,8-3.6,8-8C344,115.6,340.4,112,336,112z"/>\n' +
                  '\t\t\t<path d="M376,32H104c-22,0-40,18-40,40v88H24c-13.2,0-24,10.8-24,24v128c0,13.2,10.8,24,24,24h40v40c0,3.2,2,6,4.8,7.2\n' +
                  "\t\t\t\tc1.2,0.4,2,0.8,3.2,0.8c2,0,4-0.8,5.2-2l49.6-44l0.4-0.4c1.2-1.2,2.8-1.6,5.2-1.6h52c13.2,0,24-10.8,24-24v-56h7.2\n" +
                  "\t\t\t\tc6,0,11.6,2,16,6.4l74.8,69.2c1.6,1.2,3.6,2,5.6,2c1.2,0,2-0.4,3.2-0.8c2.8-1.2,4.8-4,4.8-7.2V256h56c22,0,40-18,40-40V72\n" +
                  "\t\t\t\tC416,50,398,32,376,32z M192.4,312c0,4.4-3.6,8-8,8h-52c-6.4,0-12,2-16.4,6l-36,32v-30c0-4.4-3.6-8-8-8H24c-4.4,0-8-3.6-8-8V184\n" +
                  "\t\t\t\tc0-4.4,3.6-8,8-8h40v40c0,22,18,40,40,40h88.4V312z M400,216c0,13.2-10.8,24-24,24h-56v-24c0-4.4-3.6-8-8-8c-4.4,0-8,3.6-8,8\n" +
                  "\t\t\t\tv91.2l-61.2-56.8c-7.2-6.8-16.8-10.4-27.2-10.4H104c-13.2,0-24-10.8-24-24V72c0-13.2,10.8-24,24-24h272c13.2,0,24,10.8,24,24V216\n" +
                  '\t\t\t\tz"/>\n' +
                  "\t\t</g>\n" +
                  "\t</g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "</svg>\n",
              color: "#3b5998",
              active: false,
              link: "",
            },
            {
              id: 4,
              text: "Telegram",
              icon:
                  '<svg width="24" height="24" viewBox="0 0 256 256" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid">\n' +
                  "\t\t<g>\n" +
                  '\t\t\t\t<path d="M128,0 C57.307,0 0,57.307 0,128 L0,128 C0,198.693 57.307,256 128,256 L128,256 C198.693,256 256,198.693 256,128 L256,128 C256,57.307 198.693,0 128,0 L128,0 Z" fill="#40B3E0"></path>\n' +
                  '\t\t\t\t<path d="M190.2826,73.6308 L167.4206,188.8978 C167.4206,188.8978 164.2236,196.8918 155.4306,193.0548 L102.6726,152.6068 L83.4886,143.3348 L51.1946,132.4628 C51.1946,132.4628 46.2386,130.7048 45.7586,126.8678 C45.2796,123.0308 51.3546,120.9528 51.3546,120.9528 L179.7306,70.5928 C179.7306,70.5928 190.2826,65.9568 190.2826,73.6308" fill="#FFFFFF"></path>\n' +
                  '\t\t\t\t<path d="M98.6178,187.6035 C98.6178,187.6035 97.0778,187.4595 95.1588,181.3835 C93.2408,175.3085 83.4888,143.3345 83.4888,143.3345 L161.0258,94.0945 C161.0258,94.0945 165.5028,91.3765 165.3428,94.0945 C165.3428,94.0945 166.1418,94.5735 163.7438,96.8115 C161.3458,99.0505 102.8328,151.6475 102.8328,151.6475" fill="#D2E5F1"></path>\n' +
                  '\t\t\t\t<path d="M122.9015,168.1154 L102.0335,187.1414 C102.0335,187.1414 100.4025,188.3794 98.6175,187.6034 L102.6135,152.2624" fill="#B5CFE4"></path>\n' +
                  "\t\t</g>\n" +
                  "</svg>",
              color: "#2CA3D7",
              active: false,
              link: "",
            },
            {
              id: 5,
              text: "Email",
              icon:
                  '<svg version="1.1"  height="24" width="24" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n' +
                  '\t viewBox="0 0 496 496" style="enable-background:new 0 0 496 496;" xml:space="preserve">\n' +
                  "<g>\n" +
                  "\t<g>\n" +
                  "\t\t<g>\n" +
                  '\t\t\t<path d="M457.84,157.712c3.944-11.88,6.16-24.528,6.16-37.712C464,53.832,410.168,0,344,0c-36.272,0-68.768,16.24-90.792,41.76\n' +
                  "\t\t\t\tL248,38.808L0,179.336V496h496V179.336L457.84,157.712z M344,16c57.344,0,104,46.656,104,104s-46.656,104-104,104\n" +
                  "\t\t\t\tc-18.528,0-36.832-5.048-52.92-14.592l-2.96-1.76l-44.472,12.704l12.704-44.472l-1.76-2.96C245.048,156.832,240,138.528,240,120\n" +
                  "\t\t\t\tC240,62.656,286.656,16,344,16z M238.752,62.432C229.36,79.536,224,99.152,224,120c0,20.24,5.208,40.24,15.096,58.048\n" +
                  "\t\t\t\tl-18.744,65.6l65.6-18.744C303.76,234.792,323.76,240,344,240c47.28,0,88.144-27.552,107.688-67.384L473.544,185L248,357.92\n" +
                  "\t\t\t\tL22.456,185L238.752,62.432z M16,200.216l165.472,126.856L16,470.48V200.216z M29.448,480L194.44,337.016L248,378.08\n" +
                  '\t\t\t\tl53.56-41.064L466.552,480H29.448z M480,470.48L314.528,327.072L480,200.216V470.48z"/>\n' +
                  '\t\t\t<path d="M325.16,206.008c6.264,1.328,12.56,1.984,18.816,1.984c12.112,0,24.056-2.48,35.232-7.368l-6.408-14.656\n' +
                  "\t\t\t\tc-13.88,6.072-29.208,7.584-44.328,4.384c-28.576-6.048-50.88-29.512-55.504-58.392c-3.584-22.408,3.096-44.648,18.336-61.016\n" +
                  "\t\t\t\tc15.24-16.36,36.904-24.576,59.224-22.656c32.68,2.864,59.872,28.464,64.672,60.856c1.168,7.936,1.064,15.864-0.336,23.568\n" +
                  "\t\t\t\tC412.84,143.888,402.928,152,391.28,152c-4.016,0-7.28-3.264-7.28-7.28V112c0-22.056-17.944-40-40-40c-22.056,0-40,17.944-40,40\n" +
                  "\t\t\t\tv16c0,22.056,17.944,40,40,40h24v-16h-24c-13.232,0-24-10.768-24-24v-16c0-13.232,10.768-24,24-24s24,10.768,24,24v32.72\n" +
                  "\t\t\t\tc0,12.832,10.448,23.28,23.28,23.28c19.4,0,35.936-13.64,39.328-32.44c1.704-9.416,1.84-19.096,0.416-28.76\n" +
                  "\t\t\t\tc-5.864-39.632-39.136-70.944-79.096-74.456c-27.44-2.416-53.712,7.688-72.344,27.688c-18.624,20-26.8,47.144-22.424,74.456\n" +
                  '\t\t\t\tC262.832,169.856,290.152,198.592,325.16,206.008z"/>\n' +
                  '\t\t\t<rect x="240" y="400" width="16" height="16"/>\n' +
                  '\t\t\t<rect x="272" y="400" width="16" height="16"/>\n' +
                  '\t\t\t<rect x="208" y="400" width="16" height="16"/>\n' +
                  "\t\t</g>\n" +
                  "\t</g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "</svg>\n",
              color: "#000000",
              active: false,
              link: "",
            },
            {
              id: 6,
              text: "Telephone",
              icon:
                  '<svg version="1.1"  height="24" width="24" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n' +
                  '\t viewBox="0 0 181.352 181.352" style="enable-background:new 0 0 181.352 181.352;" xml:space="preserve">\n' +
                  "<g>\n" +
                  "\t<g>\n" +
                  "\t\t<g>\n" +
                  '\t\t\t<path style="fill:#010002;" d="M169.393,167.37l-14.919,9.848c-9.604,6.614-50.531,14.049-106.211-53.404\n' +
                  "\t\t\t\tC-5.415,58.873,9.934,22.86,17.134,14.555L29.523,1.678c2.921-2.491,7.328-2.198,9.839,0.811l32.583,38.543l0.02,0.02\n" +
                  "\t\t\t\tc2.384,2.824,2.306,7.22-0.83,9.868v0.029l-14.44,10.415c-5.716,5.667-0.733,14.587,5.11,23.204l27.786,32.808\n" +
                  "\t\t\t\tc12.926,12.477,20.009,18.241,26.194,14.118l12.008-13.395c2.941-2.472,7.328-2.169,9.839,0.821l32.603,38.543v0.02\n" +
                  '\t\t\t\tC172.607,160.316,172.519,164.703,169.393,167.37z"/>\n' +
                  "\t\t</g>\n" +
                  "\t</g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "<g>\n" +
                  "</g>\n" +
                  "</svg>\n",
              color: "#dedede",
              active: false,
              link: "",
            },
          ],
          second_text: "<p >We are here. Find us on map</p>",
          active_slider: false,
          map: {
            show: false,
            lat: 42.6507,
            lng: 18.0944,
            lat_btn: 42.6507,
            lng_btn: 18.0944,
            zoom: 15,
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            enableButton: false,
            fullscreenControl: false,
          },
          slider: [
            {
              id: 0,
              title: "Example title",
              slider_view_all: {
                show: false,
                text: "View all",
                link: "",
              },
              settings: false,
              hide: false,
              show_type: "bottom",
              border_color: "#cccccc",
              type: "horizontal",
              vertical: {
                height: "220",
                width: "150",
                img_height: "150",
              },
              horizontal: {
                height: "150",
                width: "250",
                img_width: "100",
              },
              background: "rgba(255,255,255,1)",
              bg_group: "rgba(255,255,255,1)",
              radius: 0,
              slides: [
                {
                  img: require("@/assets/none.png"),
                  text: "Example first block",
                  text_link: null,
                  useLink: false,
                  link: null,
                },
              ],
            },
          ],
        },
        {
          type: 7,
          text: "<p ><strong>Events</strong></p><p >Check some new events in our hotel.</p>",
          content: [],
          styles: JSON.parse(
              JSON.stringify(defaults.defaultEventStyles)
          ),
          active_slider: false,
          slider: [
            {
              id: 0,
              title: "Example title",
              slider_view_all: {
                show: false,
                text: "View all",
                link: "",
              },
              settings: false,
              hide: false,
              show_type: "bottom",
              border_color: "#cccccc",
              type: "horizontal",
              vertical: {
                height: "220",
                width: "150",
                img_height: "150",
              },
              horizontal: {
                height: "150",
                width: "250",
                img_width: "100",
              },
              background: "rgba(255,255,255,1)",
              bg_group: "rgba(255,255,255,1)",
              radius: 0,
              slides: [
                {
                  img: require("@/assets/none.png"),
                  text: "Example first block",
                  text_link: null,
                  useLink: false,
                  link: null,
                },
              ],
            },
          ],
        },
        {
          type: 8,
          styles: JSON.parse(
              JSON.stringify(defaults.defaultProductStyles)
          ),
          active_slider: false,
          enable_for_table: false,
          emails: [],
          emails_table: [],
          telegrams: [],
          telegrams_table: [],
          slider: [
            {
              id: 0,
              title: "Example title",
              slider_view_all: {
                show: false,
                text: "View all",
                link: "",
              },
              settings: false,
              hide: false,
              show_type: "bottom",
              border_color: "#cccccc",
              type: "horizontal",
              vertical: {
                height: "220",
                width: "150",
                img_height: "150",
              },
              horizontal: {
                height: "150",
                width: "250",
                img_width: "100",
              },
              background: "rgba(255,255,255,1)",
              bg_group: "rgba(255,255,255,1)",
              radius: 0,
              slides: [
                {
                  img: require("@/assets/none.png"),
                  text: "Example first block",
                  text_link: null,
                  useLink: false,
                  link: null,
                },
              ],
            },
          ],
        },
        {
          type: 9,
          content: {
            textColor: "#000000",
            iconColor: "#000000",
            mainIconSize: "100",
            weekIconSize: "30",
            font: "Inter",
            top_content: "",
            bottom_content: "",
            icon_type: 0,
          },
          active_slider: false,
          slider: [
            {
              id: 0,
              title: "Example title",
              slider_view_all: {
                show: false,
                text: "View all",
                link: "",
              },
              settings: false,
              hide: false,
              show_type: "bottom",
              border_color: "#cccccc",
              type: "horizontal",
              vertical: {
                height: "220",
                width: "150",
                img_height: "150",
              },
              horizontal: {
                height: "150",
                width: "250",
                img_width: "100",
              },
              background: "rgba(255,255,255,1)",
              bg_group: "rgba(255,255,255,1)",
              radius: 0,
              slides: [
                {
                  img: require("@/assets/none.png"),
                  text: "Example first block",
                  text_link: null,
                  useLink: false,
                  link: null,
                },
              ],
            },
          ],
        },
        {
          type: 10,
          styles: {
            bg_color: "rgba(255,255,255,05)",
            radius: "5",
            block_height: "200",
            block_width: "200",
            image_height: "100",
            image_width: "100",
            title_color: "rgb(0,0,0)",
            marker_icon_color: "#000",
            marker_shape_color: "#007db5",
            marker_shape: "SQUARE_PIN",
            marker_icon: "restaurant",
            border_color: "#000",
          },
          active_slider: false,
          slider: [
            {
              id: 0,
              title: "Example title",
              slider_view_all: {
                show: false,
                text: "View all",
                link: "",
              },
              settings: false,
              hide: false,
              show_type: "bottom",
              border_color: "#cccccc",
              type: "horizontal",
              vertical: {
                height: "220",
                width: "150",
                img_height: "150",
              },
              horizontal: {
                height: "150",
                width: "250",
                img_width: "100",
              },
              background: "rgba(255,255,255,1)",
              bg_group: "rgba(255,255,255,1)",
              radius: 0,
              slides: [
                {
                  img: require("@/assets/none.png"),
                  text: "Example first block",
                  text_link: null,
                  useLink: false,
                  link: null,
                },
              ],
            },
          ],
        },
        {
          type: 11,
          default: 0,
          content: [
            {
              filter: [],
              type: 0,
              content: [
                {
                  type: 1,
                  content:
                      "<p>Blank page, but you can add something interesting to it!</p>",
                  slider: [
                    {
                      id: 0,
                      title: "Example title",
                      slider_view_all: {
                        show: false,
                        text: "View all",
                        link: "",
                      },
                      hide: false,
                      show_type: "bottom",
                      border_color: "#cccccc",
                      type: "horizontal",
                      settings: false,
                      vertical: {
                        height: "220",
                        width: "150",
                        img_height: "150",
                      },
                      horizontal: {
                        height: "150",
                        width: "250",
                        img_width: "100",
                      },
                      background: "rgba(255,255,255,1)",
                      bg_group: "rgba(255,255,255,1)",
                      radius: 0,
                      slides: [
                        {
                          img: require("@/assets/none.png"),
                          text: "Example first block",
                          text_link: null,
                          useLink: false,
                          link: null,
                        },
                      ],
                    },
                  ],
                  active_slider: false,
                  form: JSON.parse(
                      JSON.stringify(defaults.defaultFormMenuItem)
                  ),
                },
                {
                  type: 2,
                  content: "",
                },
                {
                  type: 3,
                  styles: JSON.parse(
                      JSON.stringify(defaultListingStyles)
                  ),
                  content: {
                    listing: [
                      {
                        title: "default listing title",
                        hide: true,
                        content: [
                          {
                            img: [],
                            video_link: null,
                            name: "default name",
                            descr: "default description",
                            main_img: "",
                            preview: false,
                            full_descr:
                                "default full description",
                            price: "100",
                            show_price: true,
                            show_descr: true,
                            hide: true,
                            name_color: "#000000",
                            lining_padding: 15,
                            position: "center center",
                            background_color:
                                "rgba(255,255,255,1)",
                            descr_color: "#000000",
                            show_name_color: false,
                            show_descr_color: false,
                            id: 1,
                            form: JSON.parse(
                                JSON.stringify(
                                    defaults.defaultFormMenuItem
                                )
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  active_slider: false,
                  slider: [
                    {
                      id: 0,
                      title: "Example title",
                      slider_view_all: {
                        show: false,
                        text: "View all",
                        link: "",
                      },
                      settings: false,
                      hide: false,
                      show_type: "bottom",
                      border_color: "#cccccc",
                      type: "horizontal",
                      vertical: {
                        height: "220",
                        width: "150",
                        img_height: "150",
                      },
                      horizontal: {
                        height: "150",
                        width: "250",
                        img_width: "100",
                      },
                      background: "rgba(255,255,255,1)",
                      bg_group: "rgba(255,255,255,1)",
                      radius: 0,
                      slides: [
                        {
                          img: require("@/assets/none.png"),
                          text: "Example first block",
                          text_link: null,
                          useLink: false,
                          link: null,
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 4,
                  img: [],
                  video_link: null,
                  preview: false,
                  full_desc: "default description",
                  slider: [
                    {
                      id: 0,
                      title: "Example title",
                      slider_view_all: {
                        show: false,
                        text: "View all",
                        link: "",
                      },
                      settings: false,
                      hide: false,
                      show_type: "bottom",
                      border_color: "#cccccc",
                      type: "horizontal",
                      vertical: {
                        height: "220",
                        width: "150",
                        img_height: "150",
                      },
                      horizontal: {
                        height: "150",
                        width: "250",
                        img_width: "100",
                      },
                      background: "rgba(255,255,255,1)",
                      bg_group: "rgba(255,255,255,1)",
                      radius: 0,
                      slides: [
                        {
                          img: require("@/assets/none.png"),
                          text: "Example first block",
                          text_link: null,
                          useLink: false,
                          link: null,
                        },
                      ],
                    },
                  ],
                  styles: {
                    img_height: 200,
                    border_color: "#eee",
                    border_size: 0,
                    position: "center center",
                    shadow_size: 0,
                    shadow_color: "#eee",

                    fontFamily: "Inter",
                  },
                  form: JSON.parse(
                      JSON.stringify(defaults.defaultFormMenuItem)
                  ),
                },
              ],
            },
          ],
        },
        {
          type: 12,
          img: [],
          video_link: null,
          preview: false,
          full_desc: "default description",
          slider: [
            {
              id: 0,
              title: "Example title",
              slider_view_all: {
                show: false,
                text: "View all",
                link: "",
              },
              settings: false,
              hide: false,
              show_type: "bottom",
              border_color: "#cccccc",
              type: "horizontal",
              vertical: {
                height: "220",
                width: "150",
                img_height: "150",
              },
              horizontal: {
                height: "150",
                width: "250",
                img_width: "100",
              },
              background: "rgba(255,255,255,1)",
              bg_group: "rgba(255,255,255,1)",
              radius: 0,
              slides: [
                {
                  img: require("@/assets/none.png"),
                  text: "Example first block",
                  text_link: null,
                  useLink: false,
                  link: null,
                },
              ],
            },
          ],
          styles: {
            img_height: 200,
          },
          form: JSON.parse(JSON.stringify(defaults.defaultFormMenuItem)),
        },
        {
          type: 13,
          styles: {
            trail: {
              top_text: {
                en: "",
              },
              width: "200",
              height: "200",
              nameColor: "#000",
              nameFontWeight: "500",
              nameFontStyle: "normal",
              nameFontFamily: "Inter",
              nameFontSize: "16",
              vector: "vertical",
              position: "center center",
              displayType: "default",
              img_width: "100",
              img_height: "200",
              img_detail_width: "300",
              img_detail_height: "200",
            },
            map: {
              strokeColor: "#000",
              strokeWeight: 2,
              img_height: "100",
              img_width: "100",
            },
            points: {
              list_img_height: "350",
              listing_item_img_height: "200",
              textColor: "#000",
              textFontWeight: "500",
              textFontStyle: "normal",
              textFontFamily: "Inter",
              textFontSize: "16",
            },
          },
        },
      ],
          icon_size: 36,
          icon:
        process.env.VUE_APP_STORAGE_URL +
        "default-landing-icons/Icons_13-13.svg",
            type: 1,
          page: "default",
          id: 1,
          motivation: JSON.parse(
          JSON.stringify(defaults.defaultMotivationText)
      ),
          horizontal: false,
          form: JSON.parse(JSON.stringify(defaults.defaultFormMenuItem)),
      }
      ;
      if (menuArr.length) {
        listing.id = menuArr[menuArr.length - 1].id + 1;
        const candidate = menuArr.find((x) => x.hidden === false);
        if (candidate) {
          candidate.hidden = true;
          candidate.flipped = false;
        }
      } else {
        listing.id = 1;
      }

      menuArr.push(listing);
    },
    deleteListingMenu() {
      this.selectedMenuItem.content[this.landing.current_lang][4].content.menu.splice(this.subMenuDeleteBtnIndex,1)
      this.subMenuDeletePageI = 0;
      this.subMenuDeleteBtnIndex = 0;
    },
    hidePrice(listing) {
      listing.show_price = !listing.show_price;
    },
    hideSmallDescription(listing) {
      listing.show_descr = !listing.show_descr;
    },
    changeListingStyle(styles, style) {
      styles.displayType = style;
    },
    changeSubListingStyle(item, style) {
      item.displayType = style;
    },
    changeLang(lang) {
      this.$store.commit("landing/setCurrentLang", lang);
    },
    openStyle() {
      if (this.is_layout) {
        for (let i = 0; i < this.list.length; i++) {
          for (let key in this.list[i].styles) {
            this.landing.menu.list[i].styles[key] = this.list[i].styles[key];
          }
        }
        this.landing.menu.shadowSize = this.globalSettings.shadow_size;
        this.landing.menu.shadowColor = this.globalSettings.shadow_color;
        this.landing.menu.radius = this.globalSettings.radius;
        this.landing.menu.icon_size = this.globalSettings.icon_size;
        this.$store.commit('mainPageStyles/setStylesOpen',false);
        this.$store.commit("setPage", "home");
        this.is_layout = false;
      } else {
        this.$store.commit("mainPageStyles/setGlobalRadius", this.landing.menu.radius);
        this.$store.commit(
          "mainPageStyles/setGlobalShadowSize",
            this.landing.menu.shadowSize
        );
        this.$store.commit(
          "mainPageStyles/setGlobalShadowColor",
            this.landing.menu.shadowColor
        );
        this.$store.commit(
          "mainPageStyles/setGlobalIconSize",
            this.landing.menu.icon_size
        );
        this.$store.commit("mainPageStyles/setList", JSON.parse(JSON.stringify(this.landing.menu.list)));
        this.$store.commit("setPage", "menuStyles");
        this.$store.commit('mainPageStyles/setStylesOpen',true);
        this.is_layout = true;
      }
    },
    changeStyle(style) {
      if (style === "default") {
        for (let i = 0; i < this.menu.list.length; i++) {
          this.menu.list[i].styles.width = "100";
          this.menu.list[i].styles.show_icon_up = false;
        }
      }
      if (style === "custom") {
        for (let i = 0; i < this.menu.list.length; i++) {
          this.menu.list[i].styles.width = "50";
          this.menu.list[i].styles.show_icon_up = true;
        }
      }
      this.menu.style = style;
      this.setMenuList(this.menu.list);
    },
    removePage() {
      this.active_tab = 0;
    },
    changePage(page) {
      this.$store.commit("setPage", page);
    },
    changeTypeSub(item, submenu, ind) {
      submenu.type = ind;
      if (
        submenu.type !== 1 &&
        submenu.type !== 4 &&
        submenu.type !== 5 &&
        submenu.type !== 6 &&
        submenu.type !== 7 &&
        submenu.type !== 8 &&
        submenu.type !== 9 &&
        submenu.type !== 10 &&
        submenu.type !== 11 &&
        submenu.type !== 12 &&
        submenu.type !== 13
      ) {
        this.$store.commit("setPage", item.page);
      } else {
        this.$store.commit("setPage", item.page + "/" + submenu.id);
      }
    },
    changeType(content, ind) {
      this.$store.state.selectedMenuTrigger = true;
      if (content.page === "reviews") {
        this.landing.reviews.tab = 0;
        this.$store.commit("setPage",'reviews');
      }
      this.menu.active_tab = 1;
      content.type = ind + 1;
      this.active_menu_tab = ind + 1;
      if (content.type === 2 || content.type === 3) {
        this.$store.commit("setPage", "home");
      }
      else if (content.type === 5) {
        let item = this.$store.state.menu.list.find((x) => x.id === content.id);
        content.content[this.landing.current_lang][4].content.menu.map((x) => {
          x.hidden = true;
          x.flipped = false;
        });
        const candidate = content.content[
          this.landing.current_lang
        ][4].content.menu.find((x) => x.hidden === false);
        if (candidate) {
          if (candidate.flipped) {
            this.flipped = true;
            this.selectedMenu = candidate;
            this.$store.commit("setPage", item.page + "/" + candidate.id);
          } else {
            this.$store.commit("setPage", item.page);
          }
        } else {
          this.$store.commit("setPage", item.page);
        }
      } else {
        this.$store.commit("setPage", content.page);
      }
      if (!this.selectedMenuItem) {
        this.flipped = false;
        this.setSelectedMenuItem(JSON.parse(JSON.stringify(content)));
      } else {
        this.flipped = false;
        const candidate = this.$store.state.menu.list.find(
          (item) => item.id === this.selectedMenuItem.id
        );
        if (candidate) {
          const candidateIndex = this.$store.state.menu.list.indexOf(candidate);
          if (candidateIndex >= 0) {
            this.$store.state.menu.list.splice(
              candidateIndex,
              1,
              JSON.parse(JSON.stringify(this.selectedMenuItem))
            );
          }
        }
        this.setSelectedMenuItem(JSON.parse(JSON.stringify(content)));
        this.forceRerender();
      }
      this.$store.commit('back',true);
    },
    changeView(menu, content, selectMenu) {
      if (!menu.flipped) {
        this.selectedMenu = menu;
        this.flipped = true;
        this.$store.commit("setPage", content.page + "/" + menu.id);
      } else {
        this.flipped = false;
        this.selectedMenu = null;
        this.$store.commit("setPage", content.page);
      }
      content.content[this.current_lang][4].selectedMenu = selectMenu;
      menu.flipped = !menu.flipped;
    },
    strip(html) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText;
    },
    addListing(arr) {
      let listingDefault = {
        img: [],
        video_link: null,
        name: "default name",
        descr: "default description",
        main_img: "",
        full_descr: "default full description",
        price: "100",
        show_price: true,
        show_descr: true,
        name_color: "#000000",
        position: "center center",
        background_color: "rgba(255,255,255,1)",
        descr_color: "#000000",
        show_name_color: false,
        show_descr_color: false,
        id: null,
        form: {
          input_1: null,
          input_2: null,
          input_3: null,
          textarea: null,
          email: null,
          btn_name: "Send",
          btn_color: "#000000",
          btn_bg: "#ffffff",
          btn_border: "#000000",
          show: false,
          type: "pro",
          flip: false,
          pro: [
            {
              id: 0,
              title: "default title",
              addItem: false,
              formDialog: false,
              content: [
                {
                  id: 0,
                  radio: "radio",
                  label: "default label",
                  count: 1,
                  price: "0",
                  text: "",
                  formDialogEdit: false,
                },
              ],
            },
          ],
        },
      };
      if (arr.length) {
        listingDefault.id = arr[arr.length - 1].id + 1;
      } else {
        listingDefault.id = 1;
      }
      this.listingGroupItemIndex = arr.length - 1;
      arr.push(listingDefault);
    },
    async deleteListing(listing, i, options, images) {
      await Promise.all(
        images.map((item) =>
          MediaService.deleteMedia({ ...options, listing_img_id: item.id })
        )
      );
      listing.splice(i, 1);
    },
    deleteSubListing(listing, i) {
      listing.splice(i, 1);
    },
  },
  watch: {
    "$store.state.trigger"(val){
      console.log(val)
      if(!val){
        if(!this.$store.state.menu.selectedMenuItem){
          this.$store.state.tab = 0;
          this.$store.state.selectedPage = 'start';
          this.$store.commit("setPage", 'start');
        }
        else{
          this.setSelectedMenuItem(null);
          this.$store.state.trigger = true;
        }
        this.forceRerender();
      }
    },
    "$store.state.deleteMenuItemTrigger"(val){
      if(val){
        this.openDeletePopupMenu(this.selectedMenuItem);
      }
    },
    "$store.state.styleMenu"(val){
      if(val){
        this.openStyles(this.selectedMenuItem);
      }
    },
    dialogFaqNew(val) {
      val || this.closeFaq();
    },
    dialogFaqDelete(val) {
      val || this.closeFaqDeleteConfirm();
    },
  },
};
</script>

<style lang="scss">
.v-input--selection-controls {
  margin-top: 9px;
}
.v-slide-group__next,
.v-slide-group__prev {
  min-width: 20px;
}
.constructor-layout {
  padding: 30px;
  background: #fff;
  & .label {
    display: inline-block;
    margin-bottom: 20px;
  }
  &--color {
    type: hex;
    cursor: pointer;
    height: 30px;
    width: 30px;
    border: 1px solid #000;
  }
}

.socials-style {
  display: flex;
  margin-bottom: 30px;
}



::v-deep .constructor-menu__turn {
  .v-input--switch {
    margin-top: 0 !important;
  }
}
.constructor-menu__turn {
  & .socials-style__listing__vertical {
    display: flex;
    flex-direction: column;
    height: 150px;
    padding: 10px;
    &-img {
      padding: 25px;
    }
    &-title {
      padding: 6px;
      margin-bottom: 4px;
    }
    &-description {
      padding: 6px;
      margin-top: 0;
    }
  }

  & .socials-style__listing__inside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #e9eaed;
    height: 150px;
    &-title {
      padding: 6px 40px;
      margin-bottom: 14px;
      background: #7f7f7f;
    }
    &-description {
      padding: 20px 40px;
      margin-top: 0;
      background: #7f7f7f;
    }
  }

  & .socials-style__listing__horizontal {
    width: 320px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    &__left {
      flex: 1;
      margin-right: 5px;
      &-img {
        padding: 5px;
      }
    }
    &__right {
      flex: 4;
      background: #fff;
      &-title {
        padding: 6px;
        margin-bottom: 4px;
      }
      &-description {
        padding: 6px;
        margin-top: 0;
      }
    }
  }
}
.listing {
  position: relative;
  z-index: 0;
  padding: 10px;
  margin: 0 5px;
  border: 1px solid #eee;
}
.colorpicker {
  type: hex;
  cursor: pointer;
  border-radius: 5px;
  height: 30px;
  width: 30px;
  border: 1px solid #000;
  margin-top: 5px;
  margin-left: 5px;
}
.front,
.back {
  width: 100%;
  padding: 10px;
}
.v-input--selection-controls {
  margin-top: 0;
}
.v-input--selection-controls .v-input__slot,
.v-input--selection-controls .v-radio {
  margin-bottom: 0;
}
.v-dialog  > .v-card > .v-card__actions {
  padding: 0 34px;
}
.v-dialog  > .v-card > .v-card__text {
  padding: 0 24px;
}
.v-slide-group__next--disabled,
.v-slide-group__prev--disabled {
  display: none;
}
.flipper {
  border: 1px solid #eee;
  border-radius: 5px;
}
.flip-container {
  margin-bottom: 10px;
}
.tab__menu__active {
  background: #007db5;
  color: #fff !important;
  border: 1px solid #eee;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  top: 2px;
}
.tabs__border > .v-tabs-bar {
  border-right: 1px solid #e4e4e4;
}
.back {
  transform: rotateY(180deg) translateZ(1px); /* added 1px in z */
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.v-icon.v-icon::after {
  background-color: transparent;
}
.margin__tabs .v-tabs-bar {
  margin: 0 !important;
}
.buttons_turn_soc {
  display: flex;
  align-items: center;
}
.buttons_turn_soc_switch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.menu__tabs.theme--light.v-tabs .v-tab--active:hover::before,
.menu__tabs.theme--light.v-tabs .v-tab--active::before,
.menu__tabs.theme--light.v-tabs .v-tab:focus::before {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.menu__tabs .theme--light.v-tabs .v-tab:hover::before {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.menu__tabs .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.menu__tabs
  .theme--light.v-tabs
  > .v-tabs-bar
  .v-tab:not(.v-tab--active)
  > .v-icon,
.menu__tabs
  .theme--light.v-tabs
  > .v-tabs-bar
  .v-tab:not(.v-tab--active)
  > .v-btn,
.menu__tabs .theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.menu__tabs .theme--light.v-tabs .v-tab:focus::before {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.menu__tabs .v-ripple__container {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.menu__tabs.theme--light.v-tabs .v-tab:hover::before {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.constructor__tabs.theme--light.v-tabs .v-tab--active:hover::before,
.constructor__tabs.theme--light.v-tabs .v-tab--active::before,
.constructor__tabs.theme--light.v-tabs .v-tab:focus::before {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.constructor__tabs.theme--light.v-tabs .v-tab:hover::before {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.constructor__tabs .theme--light.v-tabs .v-tab:hover::before {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.constructor__tabs
  .theme--light.v-tabs
  > .v-tabs-bar
  .v-tab:not(.v-tab--active),
.constructor__tabs
  .theme--light.v-tabs
  > .v-tabs-bar
  .v-tab:not(.v-tab--active)
  > .v-icon,
.constructor__tabs
  .theme--light.v-tabs
  > .v-tabs-bar
  .v-tab:not(.v-tab--active)
  > .v-btn,
.constructor__tabs .theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.constructor__tabs .theme--light.v-tabs .v-tab:focus::before {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.constructor__tabs .v-ripple__container {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0;
}
.theme--light.v-list-item.v-list-item--highlighted::before {
  opacity: 0;
}
.list_title {
  text-align: center;
  font-size: 14px;
  border-bottom: 1px solid;
  color: rgba(197, 197, 197);
  padding: 5px 0;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  &__icon {
    animation: 1s linear 0s normal none infinite running circle;
  }
  @keyframes circle {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.tab {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0 1 auto;
  font-size: 0.875rem;
  justify-content: flex-start;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  line-height: normal;
  overflow:hidden;
  max-width: 360px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 48px;
  color: rgba(0, 0, 0, 0.54);

  &:hover {
    background: #eee;
  }
}
.active_tab {
  border-left: 1px solid #007db5;
  color: #007db5;
  caret-color: #007db5;
  &:hover::before,
  &::before {
    opacity: 0.12;
  }
}
.custom__menu--color {
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  border: 1px solid #000;
}
.hotel-feedback__form .hotel-reviews__submit {
  width: 100%;
  font-size: 18px;
}
.faq > .faq-wrapper > .accordion {
  border: 1px solid #d4e1e1;
}
.faq > .faq-wrapper > .accordion > .accordion__item {
  border-bottom: 1px solid #d4e1e1;
}
.preview {
  position: sticky;
  padding-bottom: 30px;
  top: 0;
}
.preview__header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
}

.preview__content {
  display: flex;
  margin-bottom: 75px;
  flex-direction: column;
  padding: 40px 20px;
  min-height: 700px;
  height: 100%;
  align-items: center;
  // background-color: rgba($color: $primary, $alpha: 0.12);
  // background-color: #C8E3EF;
}

.preview__btn {
  width: 48px !important;
  height: 48px !important;
  .v-icon {
    margin: 0 !important;
  }
}

.preview__btn_active {
  background-color: rgba($color: $primary, $alpha: 0.12) !important;
}

.canvas {
  position: relative;
  flex: 1;
  scroll-behavior: smooth;
  max-width: 360px;
  width: 100%;
  max-height: 640px;
  height: 100%;
  margin: 0 auto;
  //padding: 20px 20px 0;

  border-radius: 15px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  overflow-y: auto;

  &__success {
    text-align: center;
  }

  &__links {
    text-align: center;
    font-size: 2em;
    margin: 0.67em 0;
    margin-top: 1em;
  }
}

.canvas_lang {
}

.canvas__lang__lite {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}
.listing__descr p {
  margin-bottom: 0;
}
.canvas__lang__pro {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}

.canvas__select {
  &.v-input {
    color: rgba(0, 0, 0, 0.54);
  }
  &.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
    color: rgba(0, 0, 0, 0.54);
  }
  .v-input__slot:hover fieldset {
    color: rgba(0, 0, 0, 0.54) !important;
  }
  .v-icon {
    color: rgba(0, 0, 0, 0.54);
  }
}

.canvas_web {
  max-width: 100%;
  padding: 40px 60px;
  border-radius: 0;
}

.canvas_center {
  .canvas__header {
    top: 0;
    left: 0;
    margin: 0;
  }
  .canvas__logo {
    position: absolute;
    z-index: 1;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.canvas_left {
  .canvas__header {
    top: 0;
    left: 0;
    margin: 0;
  }
  .canvas__logo {
    position: absolute;
    z-index: 1;
    top: 100px;
    left: 20px;
    margin: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.canvas__logo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
}

.canvas__header {
  display: flex;
  //   align-items: flex-start;
  justify-content: center;
  //margin: 0 -20px 20px -20px;
  overflow: hidden;
  width: 100%;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
.v-application ul,
.v-application ol {
  padding: 0;
}
.canvas-menu {
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 5px;
}
.canvas-menu-drag {
  display: flex;
  flex-wrap: wrap;
}

.canvas-menu_tile {
  flex-wrap: wrap;
  .canvas-menu__item {
    width: 50%;
    margin-bottom: 0;
    padding: 0 5px 10px;
  }
  .canvas-menu__link {
    flex-direction: column;
  }
  .canvas-menu__icon {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.canvas-menu-custom {
  width: 100%;
  & .canvas-menu__item {
    margin-bottom: 0;
    padding: 0 5px 10px;
  }
  & .canvas-menu__link {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .canvas-menu__icon {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.canvas-menu__item {
  margin-bottom: 10px;
}

.canvas-menu__link {
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
  text-decoration: none;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  color: inherit;
}

.canvas-menu__icon {
  width: 36px;
  height: 36px;
  margin-right: 10px;
  &.v-icon {
    // margin-bottom: 10px;
    margin-right: 10px;
    font-size: 36px;
  }
}

.canvas-menu__text {
  color: inherit;
  text-align: center;
}

.canvas-socials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 45px;
  .canvas-socials__icon {
    margin-left: 20px;
    margin-right: 10px;
  }
}

.canvas-socials__item {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 5px 0;
  padding: 10px;
  border-radius: inherit;
  text-decoration: none;
  font-size: 14px;
  color: #fff !important;
  transition: all 0.3s;
  cursor: pointer;
}

.canvas-socials_tile {
  flex-direction: row;
  flex-wrap: wrap;
  .canvas-socials__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    display: flex;
    margin: 3px;
    font-size: 0;
  }
  .canvas-socials__icon {
    margin: 0;
    height: 30px;
    width: 30px;
    svg {
      height: 30px;
      width: 30px;
    }
  }
}

.canvas-socials__icon {
  width: 24px;
  height: 24px;
}

.canvas__banner {
  position: relative;
  display: block;
  img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

.canvas-footer {
}

.canvas-footer__title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
}

.canvas-footer__text {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
}

.canvas__submit {
  display: block;
  margin: 10px auto 20px;
}

.canvas__textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-top: 10px;
  resize: none;
  border-radius: 8px;
  border: 1px solid;
  outline: none;
}

.canvas-reviews__item {
  margin-bottom: 20px;
}

.listing__list {
  background: #fff;
  padding: 8px;
  border-radius: 5px;
}

.listing {
  border-radius: 5px;
  &__play_video {
    cursor: pointer;
    height: 80px;
    width: 80px;
    background: url(~@/assets/icons/play_button.svg);
    &:hover {
      background: url(~@/assets/icons/play_button_hover.svg);
    }
    &_with_img {
      position: absolute;
      top: 150px;
      left: calc(50% - 40px);
    }
    &_without_img {
      margin: 0 auto 20px;
    }
  }
}

.listing__item {
  display: flex;
  cursor: pointer;
  align-items: center;

  & .v-window__prev,
  & .v-window__next {
    display: none;
    top: 50%;
    transform: translateY(-50%);

    & .v-btn {
      width: 30px;
      height: 30px;
    }

    @media (min-width: 800px) {
      display: block;
    }
  }
}

.listing__img {
  max-width: 100%;
  height: 90px !important;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 16px;
}

.listing__descr {
  width: 100%;
  margin-left: 10px;
  padding-top: 5px;
}

.listing__separator {
  border-bottom: 1px solid;
  opacity: 0.25;
}

.listing__name {
  font-weight: 500;
}

.listing__text {
  // padding-bottom: 16px;
  opacity: 0.7;
}

.listing__full-descr {
  margin-top: 10px;
}

.listing .listing-full__img {
  border-radius: 8px;
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.listing-full__name {
  font-size: 24px;
  font-weight: 600;
}

.listing-full__text {
  margin-bottom: 20px !important;
  opacity: 0.7;
}

.listing-full__price {
  margin-bottom: 10px !important;
}

.listing-full__descr {
  margin-bottom: 20px !important;
  opacity: 0.7;
}

.canvas__back {
  border-radius: 8px;
  position: absolute;
  top: 21px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  svg {
    max-width: 100%;
    max-height: 100%;
  }
}

.banner__modal--shadow {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
}
.banner__modal {
  position: absolute;
  z-index: 5;
  .banner__modal--close {
    color: #fff;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
  }
  &--main {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &--type2 {
      display: block;
    }
  }

  & .play-video {
    cursor: pointer;
    -webkit-mask-image: url(~@/assets/icons/play_button.svg);
    mask-image: url(~@/assets/icons/play_button.svg);
    background-color: var(--color);
    position: absolute;
    &:hover {
      background-color: var(--color-hover);
    }
    &_with_img {
      position: absolute;
      top: 150px;
      left: calc(50% - 40px);
    }
  }
}
.hotel-feedback__form {
  padding: 0 10px;
}
/*.v-timeline-item__divider .v-timeline-item__dot--large {*/
/*  width: 60px;*/
/*  height: 60px;*/
/*}*/
/*.v-timeline-item__divider*/
/*  .v-timeline-item__dot--large*/
/*  .v-timeline-item__inner-dot {*/
/*  width: 50px;*/
/*  height: 50px;*/
/*}*/
/*.v-timeline-item__divider*/
/*  .v-timeline-item__dot--large*/
/*  .v-timeline-item__inner-dot {*/
/*  flex-direction: column;*/
/*  font-size: 11px;*/
/*}*/
/*.canvas .last--v-timeline::before {*/
/*  height: 50%;*/
/*}*/
/*.canvas .first--v-timeline::before {*/
/*  height: 50%;*/
/*  top: 50%;*/
/*}*/
/*.canvas .v-timeline {*/
/*  padding-top: 12px;*/
/*  & .v-timeline-item {*/
/*    padding-bottom: 12px;*/
/*  }*/
/*}*/
.introPreviews {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  .intro--close {
    color: #fff;
    position: absolute;
    right: 5px;
    z-index: 7;
    top: 5px;
    cursor: pointer;
  }
  img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.canvas__fill {
  path {
    fill: #fff;
  }
}
.theme--light.v-tabs-items {
  background: none;
}
.copied {
  position: fixed;
  left: 50%;
  margin-left: -65px;
  top: 5px;
  z-index: 10;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.slide_listing .listing_tab {
  border-color: #eee;
  color: #000;
  &:hover {
    border-color: #007db5;
    color: #007db5;
    caret-color: #007db5;
    background-color: rgba(255, 255, 255, 0.5);
  }
}
.slide_listing .active_listing_tab {
  border-color: #007db5;
  color: #007db5;
  caret-color: #007db5;
}
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 0;
}
.slide_listing.v-slide-group.v-item-group > .v-slide-group__next,
.slide_listing.v-slide-group.v-item-group > .v-slide-group__prev {
  display: none;
}
.accordion .accordion__title-text {
  margin-bottom: 0;
  margin-right: 0;
}
</style>
