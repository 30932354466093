<template>
  <div class="constructor__main" v-if="order_form">
    <div class="constructor__header" v-if="!landing.free">
      <h2 class="constructor__heading">Order</h2>
    </div>
    <div class="constructor__header" v-else>
      <div style="display: flex;padding:20px 0;">
        <v-icon color="#cccccc" size="60px" style="margin-right: 20px;"
          >mdi-lock</v-icon
        >
        <h1
          class="constructor__heading"
          style="font-weight: normal;font-size: 16px;"
        >
          This feature is available on Pro plans. <br />To
          activate the tariff <a @click="billing">click here</a>
        </h1>
      </div>
    </div>
    <div class="constructor__content" v-if="!landing.free">
      <v-tabs
        class="constructor__subtabs tabs__border order_tabs"
        vertical
        :value="order.tab"
        @change="handleChangeTab"
      >
        <v-tab>Order Form</v-tab>
        <v-tab>{{ $t('constructor["Success Page"]') }}</v-tab>
        <v-tab>Styles</v-tab>
        <v-tab>
          Api Order
        </v-tab>
        <v-tab>
          Email notifications
        </v-tab>
        <v-tab>
          Cart empty
        </v-tab>
        <v-tab>
          Telegram Users
        </v-tab>
        <v-tab-item>
          <v-tabs
            class="langs__tabs"

            v-model="activeLang"
          >
            <v-tab
              v-for="(selected_lang, i) in selected_langs"
              :key="i"
              @change="changeLang(selected_lang)"
            >
            <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
              {{ selected_lang }}
            </v-tab>
            <v-tab-item
              v-for="(selected_lang, i) in selected_langs"
              :key="i"
              style="padding: 0 15px;"
            >
              <div style="margin-top: 10px;">
                <label class="label">Order Form Title</label>
                <Editor
                  api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                  :init="options"
                  v-model="order_form.content[selected_lang].title"
                >
                </Editor>
              </div>
              <div
                v-if="order_form.content[selected_lang].payment"
                style="margin-top: 10px;"
              >
                <label class="label">Payment method label</label>
                <Editor
                  api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                  :init="options"
                  v-model="
                    order_form.content[selected_lang].payment_label
                  "
                >
                </Editor>
              </div>
              <label class="label" style="margin: 15px 0;">Features</label>
              <v-switch color="#6ab64a"
                v-model="order_form.content[selected_lang].delivery"
                label="Enable delivery time"
              ></v-switch>
              <v-switch color="#6ab64a"
                v-model="order_form.content[selected_lang].guest"
                label="Enable guest or resident of hotel"
              ></v-switch>
              <v-switch color="#6ab64a"
                v-if="order_form.content[selected_lang].guest"
                v-model="order_form.content[selected_lang].payment"
                label="Enable payment method"
              ></v-switch>

              <label class="label">
                Contact Details
              </label>
              <v-tabs
                class="no_margin"
                v-if="order_form.content[selected_lang].guest"
                v-model="order_form.content[selected_lang].person_type"
              >
                <v-tab
                  active-class="tab__menu__active"
                  @click="
                    order_form.content[selected_lang].person_type = 0
                  "
                >
                  Resident
                </v-tab>
                <v-tab
                  active-class="tab__menu__active"
                  @click="
                    order_form.content[selected_lang].person_type = 1
                  "
                  >Guest</v-tab
                >
                <v-tab-item
                  style="padding:15px;border-radius:16px;border:1px solid #eee;margin-bottom: 30px;"
                >
                  <div
                    v-if="order_form.content[selected_lang].payment"
                    style="margin-bottom: 30px;"
                  >
                    <div
                      v-for="radio in order_form.content[selected_lang]
                        .payment_methods_resident"
                      :key="radio.id"
                      style="display:flex;flex-direction: column"
                    >
                      <v-checkbox
                        v-model="radio.value"
                        :value="radio.value"
                        type="checkbox"
                        :label="radio.name"
                        hide-details
                      ></v-checkbox>
                    </div>
                  </div>
                  <div class="control" style="margin-top: 30px;">
                    <v-text-field
                      outlined
                      solo
                      flat
                      dense
                      :append-outer-icon="
                        order_form.content[selected_lang]
                          .hotel_input_require
                          ? 'mdi-checkbox-marked-circle'
                          : 'mdi-radiobox-blank'
                      "
                      @click:append-outer="
                        order_form.content[
                          selected_lang
                        ].hotel_input_require = !order_form.content[
                          selected_lang
                        ].hotel_input_require
                      "
                      v-model="
                        order_form.content[selected_lang].hotel_input
                      "
                      :append-icon="
                        order_form.content[selected_lang]
                          .hotel_input_show
                          ? 'mdi-eye'
                          : 'mdi-eye-off'
                      "
                      @click:append="
                        order_form.content[
                          selected_lang
                        ].hotel_input_show = !order_form.content[
                          selected_lang
                        ].hotel_input_show
                      "
                    ></v-text-field>
                  </div>
                  <div class="control">
                    <v-text-field
                      outlined
                      solo
                      flat
                      dense
                      :append-outer-icon="
                        order_form.content[selected_lang]
                          .hotel_first_name_input_require
                          ? 'mdi-checkbox-marked-circle'
                          : 'mdi-radiobox-blank'
                      "
                      @click:append-outer="
                        order_form.content[
                          selected_lang
                        ].hotel_first_name_input_require = !order_form
                          .content[selected_lang].hotel_first_name_input_require
                      "
                      v-model="
                        order_form.content[selected_lang]
                          .hotel_first_name_input
                      "
                      :append-icon="
                        order_form.content[selected_lang]
                          .hotel_first_name_input_show
                          ? 'mdi-eye'
                          : 'mdi-eye-off'
                      "
                      @click:append="
                        order_form.content[
                          selected_lang
                        ].hotel_first_name_input_show = !order_form
                          .content[selected_lang].hotel_first_name_input_show
                      "
                    ></v-text-field>
                  </div>
                  <div class="control">
                    <v-text-field
                      outlined
                      solo
                      flat
                      dense
                      :append-outer-icon="
                        order_form.content[selected_lang]
                          .hotel_last_name_input_require
                          ? 'mdi-checkbox-marked-circle'
                          : 'mdi-radiobox-blank'
                      "
                      @click:append-outer="
                        order_form.content[
                          selected_lang
                        ].hotel_last_name_input_require = !order_form
                          .content[selected_lang].hotel_last_name_input_require
                      "
                      v-model="
                        order_form.content[selected_lang]
                          .hotel_last_name_input
                      "
                      :append-icon="
                        order_form.content[selected_lang]
                          .hotel_last_name_input_show
                          ? 'mdi-eye'
                          : 'mdi-eye-off'
                      "
                      @click:append="
                        order_form.content[
                          selected_lang
                        ].hotel_last_name_input_show = !order_form
                          .content[selected_lang].hotel_last_name_input_show
                      "
                    ></v-text-field>
                  </div>
                  <div class="control">
                    <v-text-field
                      outlined
                      solo
                      flat
                      dense
                      placeholder="Email"
                      :append-outer-icon="
                        order_form.content[selected_lang]
                          .hotel_phone_input_require
                          ? 'mdi-checkbox-marked-circle'
                          : 'mdi-radiobox-blank'
                      "
                      @click:append-outer="
                        order_form.content[
                          selected_lang
                        ].hotel_phone_input_require = !order_form
                          .content[selected_lang].hotel_phone_input_require
                      "
                      v-model.number="
                        order_form.content[selected_lang]
                          .hotel_phone_input
                      "
                      :append-icon="
                        order_form.content[selected_lang]
                          .hotel_phone_input_show
                          ? 'mdi-eye'
                          : 'mdi-eye-off'
                      "
                      @click:append="
                        order_form.content[
                          selected_lang
                        ].hotel_phone_input_show = !order_form.content[
                          selected_lang
                        ].hotel_phone_input_show
                      "
                    ></v-text-field>
                  </div>
                  <div class="control">
                    <v-text-field
                      outlined
                      solo
                      flat
                      dense
                      placeholder="Email"
                      :append-outer-icon="
                        order_form.content[selected_lang]
                          .email_resident_require
                          ? 'mdi-checkbox-marked-circle'
                          : 'mdi-radiobox-blank'
                      "
                      @click:append-outer="
                        order_form.content[
                          selected_lang
                        ].email_resident_require = !order_form.content[
                          selected_lang
                        ].email_resident_require
                      "
                      v-model="
                        order_form.content[selected_lang].email_resident
                      "
                      :append-icon="
                        order_form.content[selected_lang]
                          .email_resident_show
                          ? 'mdi-eye'
                          : 'mdi-eye-off'
                      "
                      @click:append="
                        order_form.content[
                          selected_lang
                        ].email_resident_show = !order_form.content[
                          selected_lang
                        ].email_resident_show
                      "
                    ></v-text-field>
                  </div>
                  <div class="control">
                    <v-text-field
                      outlined
                      solo
                      flat
                      dense
                      placeholder="Email"
                      :append-outer-icon="
                        order_form.content[selected_lang]
                          .hotel_special_request_require
                          ? 'mdi-checkbox-marked-circle'
                          : 'mdi-radiobox-blank'
                      "
                      @click:append-outer="
                        order_form.content[
                          selected_lang
                        ].hotel_special_request_require = !order_form
                          .content[selected_lang].hotel_special_request_require
                      "
                      v-model="
                        order_form.content[selected_lang]
                          .hotel_special_request
                      "
                      :append-icon="
                        order_form.content[selected_lang]
                          .hotel_special_request_show
                          ? 'mdi-eye'
                          : 'mdi-eye-off'
                      "
                      @click:append="
                        order_form.content[
                          selected_lang
                        ].hotel_special_request_show = !order_form
                          .content[selected_lang].hotel_special_request_show
                      "
                    ></v-text-field>
                  </div>
                </v-tab-item>
                <v-tab-item
                  style="padding:15px;border-radius:16px;border:1px solid #eee;margin-bottom: 30px;"
                >
                  <v-switch color="#6ab64a"
                    v-if="order_form.content[selected_lang].guest"
                    v-model="
                      order_form.content[selected_lang].only_resident
                    "
                    label="Disable guest"
                  ></v-switch>
                  <div
                    v-if="
                      order_form.content[selected_lang].payment &&
                        !order_form.content[selected_lang].only_resident
                    "
                    style="margin-bottom: 30px;"
                  >
                    <div
                      v-for="radio in order_form.content[selected_lang]
                        .payment_methods_guest"
                      :key="radio.id"
                      style="display:flex;flex-direction: column"
                    >
                      <v-checkbox
                        v-model="radio.value"
                        :value="radio.value"
                        type="checkbox"
                        :label="radio.name"
                        hide-details
                      ></v-checkbox>
                    </div>
                  </div>
                  <div
                    class="control"
                    style="margin-top: 30px;"
                    v-if="
                      !order_form.content[selected_lang].only_resident
                    "
                  >
                    <v-text-field
                      outlined
                      solo
                      flat
                      dense
                      :append-outer-icon="
                        order_form.content[selected_lang]
                          .guest_phone_require
                          ? 'mdi-checkbox-marked-circle'
                          : 'mdi-radiobox-blank'
                      "
                      @click:append-outer="
                        order_form.content[
                          selected_lang
                        ].guest_phone_require = !order_form.content[
                          selected_lang
                        ].guest_phone_require
                      "
                      v-model="
                        order_form.content[selected_lang].guest_phone
                      "
                      :append-icon="
                        order_form.content[selected_lang]
                          .guest_phone_show
                          ? 'mdi-eye'
                          : 'mdi-eye-off'
                      "
                      @click:append="
                        order_form.content[
                          selected_lang
                        ].guest_phone_show = !order_form.content[
                          selected_lang
                        ].guest_phone_show
                      "
                    ></v-text-field>
                  </div>
                  <div
                    class="control"
                    v-if="
                      !order_form.content[selected_lang].only_resident
                    "
                  >
                    <v-text-field
                      outlined
                      solo
                      flat
                      dense
                      :append-outer-icon="
                        order_form.content[selected_lang]
                          .email_guest_require
                          ? 'mdi-checkbox-marked-circle'
                          : 'mdi-radiobox-blank'
                      "
                      @click:append-outer="
                        order_form.content[
                          selected_lang
                        ].email_guest_require = !order_form.content[
                          selected_lang
                        ].email_guest_require
                      "
                      v-model="
                        order_form.content[selected_lang].email_guest
                      "
                      :append-icon="
                        order_form.content[selected_lang]
                          .email_guest_show
                          ? 'mdi-eye'
                          : 'mdi-eye-off'
                      "
                      @click:append="
                        order_form.content[
                          selected_lang
                        ].email_guest_show = !order_form.content[
                          selected_lang
                        ].email_guest_show
                      "
                    ></v-text-field>
                  </div>
                  <div
                    class="control"
                    v-if="
                      !order_form.content[selected_lang].only_resident
                    "
                  >
                    <v-text-field
                      outlined
                      solo
                      flat
                      dense
                      :append-outer-icon="
                        order_form.content[selected_lang]
                          .guest_special_request_require
                          ? 'mdi-checkbox-marked-circle'
                          : 'mdi-radiobox-blank'
                      "
                      @click:append-outer="
                        order_form.content[
                          selected_lang
                        ].guest_special_request_require = !order_form
                          .content[selected_lang].guest_special_request_require
                      "
                      v-model="
                        order_form.content[selected_lang]
                          .guest_special_request
                      "
                      :append-icon="
                        order_form.content[selected_lang]
                          .guest_special_request_show
                          ? 'mdi-eye'
                          : 'mdi-eye-off'
                      "
                      @click:append="
                        order_form.content[
                          selected_lang
                        ].guest_special_request_show = !order_form
                          .content[selected_lang].guest_special_request_show
                      "
                    ></v-text-field>
                  </div>
                </v-tab-item>
              </v-tabs>
              <div v-if="!order_form.content[selected_lang].guest">
                <div class="control">
                  <v-text-field
                    outlined
                    solo
                    flat
                    dense
                    v-model="order_form.content[selected_lang].input_1"
                  ></v-text-field>
                </div>
                <div class="control">
                  <v-text-field
                    outlined
                    solo
                    flat
                    dense
                    v-model="order_form.content[selected_lang].input_2"
                  ></v-text-field>
                </div>
                <div class="control">
                  <v-text-field
                    outlined
                    solo
                    flat
                    dense
                    v-model="order_form.content[selected_lang].input_3"
                  ></v-text-field>
                </div>
                <div class="control">
                  <v-textarea
                    outlined
                    solo
                    flat
                    dense
                    v-model="order_form.content[selected_lang].textarea"
                  ></v-textarea>
                </div>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-tab-item>
        <v-tab-item>
          <v-tabs
            class="langs__tabs"

            v-model="activeLang"
          >
            <v-tab
              v-for="(selected_lang, i) in selected_langs"
              :key="i"
              @change="changeLang(selected_lang)" style="border-bottom:1px solid #fafafa"
            >
            <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
              {{ selected_lang }}
            </v-tab>
            <v-tab-item
              v-for="(selected_lang, i) in selected_langs"
              :key="i"
              style="padding: 0 15px;"
            >
              <div style="display: flex;">
              <label class="label" style="margin: 15px 0;">Success page for type items</label>
               <v-spacer></v-spacer>
              <v-icon :color="$store.state.orderSuccessPageShow === 'items' ? 'rgba(0,0,0,.54)' : 'rgba(0,0,0,.24)'" @click="$store.state.orderSuccessPageShow = 'items'">mdi-eye</v-icon>
              </div>
              <div style="margin-bottom: 30px;">
                <Editor
                  v-if="order.success_page[selected_lang]"
                  api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                  :init="options"
                  v-model="order.success_page[selected_lang].content"
                >
                </Editor>
              </div>
              <div style="display: flex;">
              <label class="label" style="margin: 15px 0;">Success page for type request</label>
              <v-spacer></v-spacer>
              <v-icon :color="$store.state.orderSuccessPageShow === 'request' ? 'rgba(0,0,0,.54)' : 'rgba(0,0,0,.24)'" @click="$store.state.orderSuccessPageShow = 'request'">mdi-eye</v-icon>
              </div>
              <div style="margin-bottom: 30px;">
                <Editor
                    v-if="order.success_page[selected_lang]"
                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                    :init="options"
                    v-model="order.success_page[selected_lang].second_content"
                >
                </Editor>
              </div>
              <div style="display: flex;">
                <label class="label" style="margin: 15px 0;">Success page for type table</label>
                <v-spacer></v-spacer>
                <v-icon :color="$store.state.orderSuccessPageShow === 'table' ? 'rgba(0,0,0,.54)' : 'rgba(0,0,0,.24)'" @click="$store.state.orderSuccessPageShow = 'table'">mdi-eye</v-icon>
              </div>
              <div style="margin-bottom: 30px;">
                <Editor
                        v-if="order.success_page[selected_lang]"
                        api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                        :init="options"
                        v-model="order.success_page[selected_lang].table_content"
                >
                </Editor>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-tab-item>
        <v-tab-item>
          <v-tabs
            class="langs__tabs"

            v-model="activeLang"
          >
            <v-tab
              v-for="(selected_lang, i) in selected_langs"
              :key="i"
              @change="changeLang(selected_lang)" style="border-bottom:1px solid #fafafa"
            >
            <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
              {{ selected_lang }}
            </v-tab>
            <v-tab-item v-for="(selected_lang, i) in selected_langs" :key="i">
              <div class="control field">
                <label class="label">Cart button</label>
                <v-text-field
                  style="width:300px"
                  outlined
                  solo
                  flat
                  dense
                  v-model="order_form.content[selected_lang].btn_name"
                ></v-text-field>
                <label class="label">Second Order Button Name</label>
                <v-text-field
                  outlined
                  solo
                  style="width:300px"
                  flat
                  dense
                  v-model="
                    order_form.content[selected_lang].btn_second_name
                  "
                ></v-text-field>
              </div>
            </v-tab-item>
          </v-tabs>

          <div class="control field" style="border-top:1px solid rgb(238,238,238);">
            <div style="display: flex;align-items: center;margin:10px 0;">
              <label
                class="label"
                style="width:250px;margin-bottom: 0;margin-right: 15px;"
                >Background Payment Color</label
              >
              <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="show_payment_bg"
              >
                <template v-slot:activator="{ on }">
                  <div
                    class="colorpicker"
                    :style="
                      `background-color:${order_form.styles.payment_bg}`
                    "
                    v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                      v-model="order_form.styles.payment_bg"
                      flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div style="display: flex;align-items: center;margin:10px 0;">
              <label
                class="label"
                style="width:250px;margin-bottom: 0;margin-right: 15px;"
                >Tab Color</label
              >
              <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="show_btn_tab_color"
              >
                <template v-slot:activator="{ on }">
                  <div
                    class="colorpicker"
                    :style="
                      `background-color:${order_form.styles.tabs_color}`
                    "
                    v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                      v-model="order_form.styles.tabs_color"
                      flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div style="display: flex;align-items: center;margin:10px 0;">
              <label
                class="label"
                style="width:250px;margin-bottom: 0;margin-right: 15px;"
                >Background Active Payment Color</label
              >
              <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="show_payment_bg_active"
              >
                <template v-slot:activator="{ on }">
                  <div
                    class="colorpicker"
                    :style="
                      `background-color:${order_form.styles.payment_bg_active}`
                    "
                    v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                      v-model="order_form.styles.payment_bg_active"
                      flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div style="display: flex;align-items: center;margin:10px 0;">
              <label
                class="label"
                style="width:250px;margin-bottom: 0;margin-right: 15px;"
                >Payment Text Color</label
              >
              <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="show_payment_color"
              >
                <template v-slot:activator="{ on }">
                  <div
                    class="colorpicker"
                    :style="
                      `background-color:${order_form.styles.payment_color}`
                    "
                    v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                      v-model="order_form.styles.payment_color"
                      flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div style="display: flex;align-items: center;margin:10px 0;">
              <label
                class="label"
                style="width:250px;margin-bottom: 0;margin-right: 15px;"
                >Active Payment Text Color</label
              >
              <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="show_payment_active_color"
              >
                <template v-slot:activator="{ on }">
                  <div
                    class="colorpicker"
                    :style="
                      `background-color:${order_form.styles.payment_active_color}`
                    "
                    v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                      v-model="order_form.styles.payment_active_color"
                      flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>

            <div style="display: flex;align-items: center;margin:10px 0;">
              <label
                class="label"
                style="width:250px;margin-bottom: 0;margin-right: 15px;"
                >Pro group title color</label
              >
              <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="show_btn_title_color"
              >
                <template v-slot:activator="{ on }">
                  <div
                    class="colorpicker"
                    :style="
                      `background-color:${order_form.styles.title_color}`
                    "
                    v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                      v-model="order_form.styles.title_color"
                      flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div style="display: flex;align-items: center;margin:10px 0;">
              <label
                class="label"
                style="width:250px;margin-bottom: 0;margin-right: 15px;"
                >Pro group label color</label
              >
              <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="show_btn_label_color"
              >
                <template v-slot:activator="{ on }">
                  <div
                    class="colorpicker"
                    :style="
                      `background-color:${order_form.styles.label_color}`
                    "
                    v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                      v-model="order_form.styles.label_color"
                      flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div style="display: flex;align-items: center;margin:10px 0;">
              <label
                class="label"
                style="width:250px;margin-bottom: 0;margin-right: 15px;"
                >Pro group price color</label
              >
              <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="show_btn_price_color"
              >
                <template v-slot:activator="{ on }">
                  <div
                    class="colorpicker"
                    :style="
                      `background-color:${order_form.styles.price_color}`
                    "
                    v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                      v-model="order_form.styles.price_color"
                      flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="field ">
            <div class="form">
              <div class="field">
                <label class="label"
                  >Add your API link for send order form</label
                >
                <v-text-field
                  class="input input_dense"
                  outlined
                  solo
                  flat
                  dense
                  placeholder="Write your API link"
                  v-model="landing.advanced.global.order_api"
                  hide-details
                ></v-text-field>
                <div style="margin-top:15px;">
                  <label class="label">Test fields</label>
                </div>
                <div class="control">
                  <v-switch color="#6ab64a"
                    :label="
                      custom_api ? 'Disable test mode' : 'Active test mode'
                    "
                    v-model="custom_api"
                  ></v-switch>
                </div>
                <div v-if="custom_api">
                  <label class="label label_testApi">Room</label>
                  <v-text-field
                    class="input input_dense"
                    outlined
                    solo
                    flat
                    dense
                    placeholder="room"
                    v-model="testApiData.room"
                    hide-details
                  ></v-text-field>
                  <label class="label label_testApi">First Name</label>
                  <v-text-field
                    class="input input_dense"
                    outlined
                    solo
                    flat
                    dense
                    placeholder="First Name"
                    v-model="testApiData.firstName"
                    hide-details
                  ></v-text-field>
                  <label class="label label_testApi">Last Name</label>
                  <v-text-field
                    class="input input_dense"
                    outlined
                    solo
                    flat
                    dense
                    placeholder="Last Name"
                    v-model="testApiData.lastName"
                    hide-details
                  ></v-text-field>
                  <label class="label label_testApi">Phone</label>
                  <v-text-field
                    class="input input_dense"
                    outlined
                    solo
                    flat
                    dense
                    placeholder="Phone"
                    v-model="testApiData.phone"
                    hide-details
                  ></v-text-field>
                  <label class="label label_testApi">Email</label>
                  <v-text-field
                    class="input input_dense"
                    outlined
                    solo
                    flat
                    dense
                    placeholder="Email"
                    v-model="testApiData.email"
                    hide-details
                  ></v-text-field>
                  <label class="label label_testApi">Special Request</label>
                  <v-textarea
                    class="input input_dense"
                    outlined
                    solo
                    flat
                    dense
                    placeholder="Special Request"
                    v-model="testApiData.specialRequest"
                    hide-details
                  ></v-textarea>
                  <label class="label label_testApi">Payment Method</label>
                  <v-text-field
                    class="input input_dense"
                    outlined
                    solo
                    flat
                    dense
                    placeholder="Payment Method"
                    v-model="testApiData.paymentMethod"
                    hide-details
                  ></v-text-field>
                  <label class="label label_testApi">Deliver</label>
                  <v-text-field
                    class="input input_dense"
                    outlined
                    solo
                    flat
                    dense
                    placeholder="deliver"
                    v-model="testApiData.deliverTime"
                    hide-details
                  ></v-text-field>
                  <label class="label label_testApi">Default type text</label>
                  <v-text-field
                    class="input input_dense"
                    outlined
                    solo
                    flat
                    dense
                    placeholder="Write your API link"
                    v-model="defaultText"
                    hide-details
                  ></v-text-field>
                  <label class="label label_testApi">Default type number</label>
                  <v-text-field
                    class="input input_dense"
                    outlined
                    solo
                    flat
                    dense
                    placeholder="0"
                    v-model="defaultNumber"
                    hide-details
                    type="number"
                  ></v-text-field>
                  <div style="display:flex;align-items:center;">
                    <label
                      class="label label_testApi"
                      style="margin-right:15px;"
                      >Default type radio</label
                    >
                    <v-checkbox
                      class="input input_dense"
                      outlined
                      solo
                      flat
                      dense
                      v-model="defaultRadio"
                      hide-details
                    ></v-checkbox>
                  </div>
                  <label class="label label_testApi">Default type date</label>
                  <v-text-field
                    class="input input_dense"
                    outlined
                    solo
                    flat
                    dense
                    placeholder="Date"
                    v-model="defaultDate"
                    hide-details
                  ></v-text-field>
                  <label class="label label_testApi">Default type time</label>
                  <v-text-field
                    class="input input_dense"
                    outlined
                    solo
                    flat
                    dense
                    placeholder="Time"
                    v-model="defaultTime"
                    hide-details
                  ></v-text-field>
                </div>
                <v-btn
                  style="margin-top:15px;"
                  outlined
                  small
                  color="primary"
                  @click="testAPI"
                  >Test API</v-btn
                >
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <v-tabs
            class="langs__tabs"

            v-model="activeLang"
          >
            <v-tab
              v-for="(selected_lang, i) in selected_langs"
              :key="i"
              @change="changeLang(selected_lang)" style="border-bottom:1px solid #fafafa"
            >
            <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
              {{ selected_lang }}
            </v-tab>
            <v-tab-item
              v-for="(selected_lang, i) in selected_langs"
              :key="i"
              style="padding: 0 15px;"
            >
              <div style="margin-top: 10px;">
                <label class="label">For hotel staff</label>
                <div class="field" style="margin-bottom: 15px;">
                  <label class="label">Email subject</label>
                  <v-text-field
                    class="input input_dense"
                    outlined
                    solo
                    flat
                    dense
                    placeholder="Write your email subject"
                    v-model="order_form.content[selected_lang].subject"
                    hide-details
                  ></v-text-field>
                </div>
                <div class="field" style="margin-bottom: 15px;">
                  <label class="label">Email message title</label>
                  <Editor
                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                    :init="options"
                    v-model="
                      order_form.content[selected_lang].message_header
                    "
                  >
                  </Editor>
                </div>
              </div>
              <div class="field">
                <v-switch color="#6ab64a"
                  v-model="order_form.content[selected_lang].send_guest"
                  label="Send success message to guest"
                ></v-switch>
              </div>
              <div
                style="margin-top: 10px;"
                v-if="order_form.content[selected_lang].send_guest"
              >
                <label class="label">For hotel guests</label>
                <div class="field" style="margin-bottom: 15px;">
                  <label class="label">Email subject</label>
                  <v-text-field
                    class="input input_dense"
                    outlined
                    solo
                    flat
                    dense
                    placeholder="Write your email subject"
                    v-model="
                      order_form.content[selected_lang].subject_guest
                    "
                    hide-details
                  ></v-text-field>
                </div>
                <div class="field" style="margin-bottom: 15px;">
                  <label class="label">Email message title</label>
                  <Editor
                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                    :init="options"
                    v-model="
                      order_form.content[selected_lang]
                        .message_header_guest
                    "
                  >
                  </Editor>
                </div>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-tab-item>
        <v-tab-item>
          <v-tabs
            class="langs__tabs"

            v-model="activeLang"
          >
            <v-tab
              v-for="(selected_lang, i) in selected_langs"
              :key="i"
              @change="changeLang(selected_lang)" style="border-bottom:1px solid #fafafa"
            >
            <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
              {{ selected_lang }}
            </v-tab>
            <v-tab-item v-for="(selected_lang, i) in selected_langs" :key="i">
              <div class="control field">
                <label class="label">Cart empty data</label>
                  <Editor
                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                    :init="options"
                    v-model="
                      order_form.content[selected_lang].emptyCart
                    "
                  >
                  </Editor>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-tab-item>
        <v-tab-item>
            <div class="field">
                <TelegramUsers/>
            </div>
        </v-tab-item>
      </v-tabs>
    </div>
    <PopupTariff ref="PopupTariff" />
    <v-dialog :fullscreen="$store.state.isMobile"
      v-model="dialog_test_api"
      width="600px"
      class="room__add"
      @click:outside="closeDialogApi"
    >
      <v-card
        style="padding-bottom: 20px;"
        v-if="!error_msg"
        :loading="loading"
      >
        <v-btn class="popup__close" icon @click="closeDialogApi">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title style="margin-bottom: 15px;">
          <span class="text-h5">Test data response</span>
        </v-card-title>
        <v-card-text> Room: {{ data_test_api.room }} </v-card-text>
        <v-card-text> First Name: {{ data_test_api.firstName }} </v-card-text>
        <v-card-text> Last Name: {{ data_test_api.lastName }} </v-card-text>
        <v-card-text> Phone: {{ data_test_api.phone }} </v-card-text>
        <v-card-text> Email: {{ data_test_api.email }} </v-card-text>
        <v-card-text>
          Special request: {{ data_test_api.specialRequest }}
        </v-card-text>
        <v-card-text> Deliver: {{ data_test_api.deliverTime }} </v-card-text>
        <v-card-text>Selected Items</v-card-text>
        <v-card-text> Default label text: {{ defaultText }} </v-card-text>
        <v-card-text> Default label number: {{ defaultNumber }} </v-card-text>
        <v-card-text v-if="defaultRadio">
          Default label checkbox
        </v-card-text>
        <v-card-text> Default label date: {{ defaultDate }} </v-card-text>
        <v-card-text> Default label time: {{ defaultTime }} </v-card-text>
      </v-card>
      <v-card style="padding-bottom: 20px;" v-else :loading="loading">
        <v-btn class="popup__close" icon @click="closeDialogApi">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title style="margin-bottom: 15px;">
          <span class="text-h5">Test Error</span>
        </v-card-title>
        <v-card-text> Error: {{ error_msg }} </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import  { mapMutations,mapGetters, mapState } from "vuex";
import PopupTariff from "../Popup/PopupTariff";

import TelegramUsers from "./Order/TelegramUsers.vue"
export default {
  name: "ConstructorOrder",
  data() {
    return {
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
          "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 200,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

          setup:function(editor){
              editor.ui.registry.addButton('custom_button', {
                  text: 'Add Button',
                  onAction: function() {
                      // Open a Dialog
                      editor.windowManager.open({
                          title: 'Add custom button',
                          body: {
                              type: 'panel',
                              items: [{
                                  type: 'input',
                                  name: 'button_label',
                                  label: 'Button label',
                                  flex: true
                              },{
                                  type: 'input',
                                  name: 'button_href',
                                  label: 'Button href',
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_target',
                                  label: 'Target',
                                  items: [
                                      {text: 'None', value: ''},
                                      {text: 'New window', value: '_blank'},
                                      {text: 'Self', value: '_self'},
                                      {text: 'Parent', value: '_parent'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_style',
                                  label: 'Style',
                                  items: [
                                      {text: 'Success', value: 'success'},
                                      {text: 'Info', value: 'info'},
                                      {text: 'Warning', value: 'warning'},
                                      {text: 'Error', value: 'error'}
                                  ],
                                  flex: true
                              }]
                          },
                          onSubmit: function (api) {

                              var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                              // insert markup
                              editor.insertContent(html);

                              // close the dialog
                              api.close();
                          },
                          buttons: [
                              {
                                  text: 'Close',
                                  type: 'cancel',
                                  onclick: 'close'
                              },
                              {
                                  text: 'Insert',
                                  type: 'submit',
                                  primary: true,
                                  enabled: false
                              }
                          ]
                      });
                  }
              });
          },
      content_css: ["/custom_tinymce.css"],
      },
      error_msg: "",
      data_test_api: {
        firstName: "",
        lastName: "",
        deliverTime: "",
        room: "",
        phone: "",
        email: "",
        specialRequest: "",
        paymentMethod: "",
      },
      dialog_test_api: false,
      loading: false,
      custom_api: false,
      defaultText: "default text",
      defaultNumber: "1",
      defaultRadio: false,
      defaultDate: "29.03.1999",
      defaultTime: "00:00",
      testApiData: {
        firstName: "Agent",
        lastName: "Smith",
        deliverTime: "29.03.1999 00:00",
        room: "707",
        phone: "+999999999",
        email: "smith@agent.com",
        specialRequest: "Neo",
        paymentMethod: "Pay",
        selectedItems: [
          "default label text: default text",
          "default label number: 1",
          "default label checkbox",
          "default label date: 29.03.1999",
          "default label time: 00:00",
        ],
      },
      show_btn_border_color: false,
      show_btn_name_color: false,
      show_btn_tab_color: false,
      show_btn_bg_color: false,
      show_btn_title_color: false,
      show_btn_label_color: false,
      show_btn_price_color: false,
      show_payment_active_color: false,
      show_payment_color: false,
      show_payment_bg: false,
      show_payment_bg_active: false,
      active_tab: 0,
      emailRules: [
        (v) => !!v || this.$t('landing.valid["E-mail обязателен"]'),
        (v) =>
          /.+@.+\..+/.test(v) ||
          this.$t('landing.valid["E-mail должен быть валидным"]'),
      ],
    };
  },
  components: {
    PopupTariff,
    TelegramUsers
  },
  mounted() {
    this.setOrderForm(JSON.parse(JSON.stringify(this.landing.order_form)));
    this.setOrder(JSON.parse(JSON.stringify(this.landing.order)));
  },
  computed: {
    ...mapState("landing", ["selected_langs", "current_lang"]),
    ...mapState(["landing"]),
    ...mapState('orders',['order_form','order']),
    activeLang:{
      set:function(val){

      },
      get:function(){
        return this.landing.advanced.selected_langs.indexOf(this.landing.current_lang)
      }
    },
  },
  methods: {
    ...mapMutations('orders',['setOrderForm','setOrder']),
    closeDialogApi() {
      this.dialog_test_api = false;
      this.loading = false;
    },
    testAPI() {
      this.loading = true;
      this.dialog_test_api = true;
      try {
        if (!this.custom_api) {
          this.$axios
            .post(this.landing.advanced.global.order_api, this.testApiData)
            .then((data) => {
              this.data_test_api = data.data;
              this.loading = false;
            })
            .catch((e) => {
              this.error_msg = e.message;
              this.loading = false;
            });
        } else {
          this.testApiData.selectedItems = [];
          this.testApiData.selectedItems.push(
            `Default time label: ${this.defaultTime}`
          );
          this.testApiData.selectedItems.push(
            `Default date label: ${this.defaultDate}`
          );
          if (this.defaultRadio) {
            this.testApiData.selectedItems.push(
              `Default checkbox label: ${this.defaultRadio}`
            );
          }
          this.testApiData.selectedItems.push(
            `Default number label: ${this.defaultNumber}`
          );
          this.testApiData.selectedItems.push(
            `Default text label: ${this.defaultText}`
          );

          this.$axios
            .post(this.landing.advanced.global.order_api, this.testApiData)
            .then((data) => {
              this.data_test_api = data.data;
              this.loading = false;
            })
            .catch((e) => {
              this.error_msg = e.message;
              this.loading = false;
            });
        }
      } catch (e) {
        this.error_msg = e.message;
        this.loading = false;
      }
    },
    handleChangeTab(val) {
      let header_tabs = document.getElementById('header_slider');
      this.order.tab = val;
    },
    billing() {
      let obj = this.$store.state.objects.list.find(
        (x) => +x.id === +this.$route.params.id
      );
      this.$refs.PopupTariff.open(obj);
    },
    changeLang(lang) {
      this.$store.commit("landing/setCurrentLang", lang);
    },
  },
};
</script>

<style lang="scss">
.label_testApi {
  margin-top: 15px;
}
.no_margin .v-tabs-bar {
  margin: 0;
}
.order_tabs .v-tab{
  justify-content: flex-start;
}
</style>
