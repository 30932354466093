<template>
  <div class="constructor__main" style="height:100%;">
    <div class="constructor__header" :style="`padding: ${isMobile?'0 5px 10px;':'30px 15px 10px;'};`" v-if="localPage === -1">
      <v-row align="center">
        <v-col cols="12">
          <h2 class="constructor__heading" style="margin-bottom: 0;">
              Advanced Options
          </h2>
          <p class="constructor__text">
            Have more control over your campaign with these advanced options.
          </p>
        </v-col>
      </v-row>
    </div>
    <div class="constructor__content" :style="`height: ${$store.state.isMobile?'auto':'calc(100% - 97px)'}`" >
      <div style="display:flex;height: 100%;">
        <div v-if="!isMobile"
            style="
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            border-right: 1px solid #e4e4e4;
          "
        >
          <div
              :class="{ active_tab: activeMenu(item.id) }"
              v-for="item in advanced_pages"
              :key="item.id"
              class="tab"
              @click="changeAdvancedTab(item)"
          >
            {{item.name}}
          </div>
        </div>
        <div style="margin-top:15px;width:100%;" v-if="localPage===-1&&isMobile">
          <div style="display:flex;flex-wrap:wrap;">
            <ul
                class="canvas-menu"
                :style="`flex-direction:row;`"
            >
              <li
                  @click="changeAdvancedTab(item,true)"
                  style="list-style: none"
                  class="canvas-menu__item canvas-menu-custom"
                  v-for="item in advanced_pages"
                  :key="item.id"
                  :style="`list-style: none;height:150px; margin-bottom: 13px; color: rgb(0, 0, 0); font-family: Inter, Inter, sans-serif; font-style: normal; font-weight: 400; font-size: 16px; width: calc(33.33% - 10px); margin-right: 10px;`
                "
              >
                <a
                    class="canvas-menu__link"
                    href="#"
                    :style="
                    ` height: 150px; color: rgb(0, 0, 0); border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px; background-color: rgb(255, 255, 255); text-align: unset; justify-content: center; align-items: center; background-repeat: no-repeat; background-size: cover; background-position: center center;`
                  "
                >
                  <div
                      :style="`height: 100px; width: 100%; padding: 15px; background-color: rgb(255, 255, 255); display: flex; align-items: center; justify-content: center; flex-direction: column;`"
                  >
                    <v-icon
                        alt="image"
                        class="canvas-menu__icon"
                        :style="`height: 36px; width: 36px; margin-right: 0px;`"
                    >{{item.icon?item.icon:'mdi-check'}}</v-icon>

                    <div style="text-align: center;font-size:13px;">
                      {{ item.name }}
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div :style="`padding:${isMobile?0:'15px'};flex: ${isMobile?'1':'0 1 100%;'};width:${isMobile?'100%':'auto'};overflow:hidden;`">
          <div v-if="showAdvanced(0)">
            <InfoPage />
          </div>
          <div v-if="showAdvanced(1)">
            <label style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;" class="label">Language settings</label>
            <div class="form">
              <div class="field">
                <div style="display:flex;align-items: center;justify-content: space-between;">
                  <label class="label" style="margin-bottom:0;margin-right:15px;">{{
                      $t('constructor["Default Language"]')
                    }}</label>
                  <v-autocomplete
                      solo
                      flat
                      outlined
                      dense

                      placeholder="Select default language"
                      :items="landing.advanced.langs.filter(x => x.active)"
                      item-value="value"
                      item-text="name"
                      hide-details
                      style="max-width:50%;margin-right:5px;"
                      v-model="advanced.default_lang"
                      @change="setToCanvas"
                  >
                    <template v-slot:selection="data">
                      <img
                          style="height: 20px;margin-right: 10px;"
                          :src="data.item.flag"
                          :alt="data.item.name"
                      />
                      {{ data.item.name }}
                    </template>
                    <template v-slot:item="data">
                      <img
                          style="height: 20px;margin-right: 10px;"
                          :src="data.item.flag"
                          :alt="data.item.name"
                      />
                      {{ data.item.name }}
                    </template>
                  </v-autocomplete>
                </div>
              </div>
            </div>
            <div class="form" style="margin-top: 15px;">
              <div class="field" style="display:flex;align-items:center;margin-bottom: 15px;">
                <label class="label" style="display:block;margin-bottom:0;flex:1;">Check user language</label>
                <v-switch color="#6ab64a" style="flex:1;"
                          hide-details
                          v-model="landing.advanced.check_user_lang"
                ></v-switch>
              </div>
              <div class="field" style="display:flex;align-items:center;margin-bottom: 15px;">
                <label class="label" style="display:block;margin-bottom:0;flex:1;">{{
                    $t('constructor["Show Language Bar"]')
                  }}</label>
                <v-switch color="#6ab64a" style="flex:1;"
                    hide-details
                    v-model="landing.advanced.show_lang"
                ></v-switch>
              </div>
              <div class="field">
                <label class="label">{{
                    $t('constructor["Select languages"]')
                  }}:</label>
                <v-checkbox
                    v-for="(lang, i) in advanced.langs"
                    :key="i"
                    :disabled="advanced.langs.filter(x => x.active).length === 1 && lang.active"
                    v-model="selected_langs"
                    :checked="lang.active"
                    :value="lang.value"
                    @change="changeLang(lang.value)"
                >
                  <template v-slot:label>
                    <img :src="lang.flag" alt="" style="width:30px;height:20px;margin-right:10px;"> {{ lang.name }} {{ advanced.default_lang === lang.value?'(default)':''}}
                  </template>
                </v-checkbox>
              </div>
            </div>
            <div class="form">
              <LangsJson/>
            </div>
          </div>
          <div v-if="showAdvanced(2)">
            <label style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;" class="label">Time settings</label>
            <div class="field">
              <div class="form" style="display:flex;flex-direction: column;">
<!--                <div class="control">-->
<!--                  <label class="label">Change your currency value</label>-->
<!--                </div>-->
<!--                <div class="control" style="margin-bottom:15px;">-->
<!--                  <v-autocomplete-->
<!--                      solo-->
<!--                      flat-->
<!--                      outlined-->
<!--                      dense-->
<!--                      placeholder="Select default currency"-->
<!--                      :items="currency"-->
<!--                      item-value="value"-->
<!--                      item-text="name"-->
<!--                      hide-details-->
<!--                      style="max-width:400px;margin-right:5px;"-->
<!--                      v-model="advanced.global.currency"-->
<!--                      @change="setToCanvas"-->
<!--                  >-->
<!--                    <template v-slot:selection="{item}">-->
<!--                      {{ item.name }}-->
<!--                    </template>-->
<!--                    <template v-slot:item="{item}">-->
<!--                      {{ item.name }}-->
<!--                    </template>-->
<!--                  </v-autocomplete>-->
<!--                </div>-->
                <div>
                  <label class="label" style="display:block;margin-bottom:15px;">Choose your Timezone</label>
                  <v-select
                      solo
                      outlined
                      flat
                      dense
                      placeholder="Timezone"
                      :items="timezones"
                      item-value="name"
                      item-text="offset"
                      v-model="landing.advanced.global.timezone"
                      return-object
                  >
                    <template v-slot:selection="{item}">
                      {{ item.offset }} ({{item.name}})
                    </template>
                    <template v-slot:item="{item}">
                      {{ item.offset }} ({{item.name}})
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showAdvanced(3)">
            <GlobalPage />
          </div>
          <div v-if="showAdvanced(4)">
            <label style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;" class="label">Icons</label>
            <label class="label" style="display:block;margin-bottom:15px;">You can add icons to use in button names:</label>
            <upload />
          </div>
          <div v-if="showAdvanced(5)">
            <DomainPage />
          </div>
          <div v-if="showAdvanced(6)">
            <WeatherTimePage />
          </div>
          <div v-if="showAdvanced(7)">
            <PWA />
          </div>
          <div v-if="showAdvanced(8)">
            <CustomJsPage />
          </div>
          <div v-if="showAdvanced(9)">
            <Page404/>
          </div>
          <div v-if="showAdvanced(10)">
            <div class="field ">
              <div class="form">
                <div class="field">
                  <label class="label" style="display: block;margin-bottom:15px;">Add Emails to send statistics</label>
                  <v-data-table
                      class="table rooms__table"
                      :headers="headers"
                      no-data-text="No Events"
                      :items="landing.advanced.global.emails"
                      hide-default-footer
                      :items-per-page="999"
                  >
                    <template v-slot:top>
                          <v-icon
                              color="primary"
                              dark
                              class="mb-2"
                              large
                              style="left: 90%;top:5px;position:relative;"
                              @click="dialogAddEmail = true"
                          >
                            mdi-plus
                          </v-icon>
                    </template>
                    <template v-slot:body="{ items }">
                      <tbody v-if="items.length">
                      <tr v-for="(item, i) in items" :key="i">
                        <td class="d-block d-sm-table-cell">
                          <v-layout justify-center>
                            {{ item }}
                          </v-layout>
                        </td>
                        <td class="d-block d-sm-table-cell">
                          <v-layout justify-center>
                            <v-icon
                                color="#007db5"
                                style="margin-right: 5px;"
                                @click="sendStatistics(item)"
                            >mdi-send</v-icon
                            >
                            <v-icon
                                color="primary"
                                style="margin-right: 5px;"
                                @click="showEdit(i)"
                            >
                              mdi-pencil
                            </v-icon>
                            <v-icon color="#7F8385" @click="openDelete(i)"
                            >mdi-delete</v-icon
                            >
                            <PopupDelete
                                ref="PopupDelete"
                                title="Are you sure about delete Email?"
                                @confirm="deleteEmail()"
                            ></PopupDelete>
                          </v-layout>
                        </td>
                      </tr>
                      </tbody>
                      <tbody v-else>
                      <tr>
                        <td colspan="2" style="text-align:center;">You have not added an email to send statistics</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                  <v-dialog :fullscreen="$store.state.isMobile" v-model="dialogAddEmail" max-width="700px">
                    <v-card>
                      <v-icon
                          style="top:10px;right: 10px;position:absolute;"
                          class="close__modal"
                          @click="
                            dialogAddEmail = false;
                            addEmail = '';
                          "
                      >mdi-close</v-icon
                      >
                      <v-card-title>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <span class="text-h5">New Email</span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <label class="label" style="display: block;margin-bottom:15px;">Email</label>
                              <v-text-field
                                  :rules="emailRules"
                                  outlined
                                  dense
                                  v-model="email"
                                  ref="email"
                                  placeholder="Write your email here"
                                  no-resize
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions style="padding: 0 36px 20px 36px;">
                        <v-spacer></v-spacer>
                        <v-btn
                            class="object__submit btn btn_primary"
                            color="primary"
                            outlined
                            small
                            @click="addEmail"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog :fullscreen="$store.state.isMobile" v-model="dialogEditEmail" max-width="700px">
                    <v-card>
                      <v-icon
                          style="top:10px;right: 10px;position:absolute;"
                          class="close__modal"
                          @click="
                        dialogEditEmail = false;
                        addEmail = '';
                      "
                      >mdi-close</v-icon
                      >
                      <v-card-title>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <span class="text-h5">Edit Email</span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <label class="label">Email</label>
                              <v-text-field
                                  outlined
                                  v-model="editEmail"
                                  placeholder="Write your email here"
                                  no-resize
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions style="padding: 0 36px 20px 36px;">
                        <v-spacer></v-spacer>
                        <v-btn
                            class="object__submit btn btn_primary"
                            color="primary"
                            outlined
                            small
                            @click="saveEdit()"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showAdvanced(11)">
            <Snapshots />
          </div>
          <div v-if="showAdvanced(12)">
            <under-constructor />
          </div>
          <div v-if="showAdvanced(13)">
            <management />
          </div>
          <div v-if="showAdvanced(14)">
            <SubscribeNotifications />
          </div>
          <div v-if="showAdvanced(15)">
                <ClientRating/>
            </div>
          <div v-if="showAdvanced(16)">
            <FirebaseNotifications/>
          </div>
          <div v-if="showAdvanced(17)">
            <PasscodePage/>
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
    <PopupError :title="errorMessage" ref="PopupError"></PopupError>
    <div class="loading_advanced" v-if="loading" style="display:flex;flex-direction:column;justify-content: center;align-items:center;">
      <img
          class="loading_advanced__icon"
          :src="require('@/assets/img/logo__loader.svg')"
          alt=""
          style="height: 75px; width: 75px;margin-bottom:30px;"
      />
      <div>
        We {{ typeOfEvent }} language...
      </div>
    </div>
  </div>
</template>

<script>

import { mapMutations, mapState } from "vuex";
import LangService from "../../../services/lang.service";
import FirebaseNotifications from "./FirebaseNotifications";
export default {
  components: {
    FirebaseNotifications,
    LangsJson:() => import("@/components/Constructor/Advanced/LangsJson"),
    SubscribeNotifications:() => import("./SubscribeNotifications"),
    Snapshots:() => import("./Snapshots"),
    management:() => import("./Management"),
    InfoPage:() => import("./InfoPage"),
    Upload:() => import("../../shared/UploadIcon.vue"),
    GlobalPage:() => import("./GlobalPage"),
    DomainPage:() => import("./DomainPage"),
    WeatherTimePage:() => import("./WeatherTimePage"),
    CustomJsPage:() => import("./CustomJsPage"),
    PopupDelete:() => import("../../Popup/PopupDelete"),
    PopupError:() => import("../../Popup/PopupError"),
    PWA:() => import("./PWAPage"),
    UnderConstructor:() => import("./UnderConstructor.vue"),
    Page404:() => import("./Page404.vue"),
    ClientRating:() => import("./ClientRating"),
    PasscodePage:() => import("./PasscodePage")
  },
  name: "ConstructorAdvanced",
  data() {
    return {
      localPage:-1,
      isMobile:false,
      active_advanced_id:0,
      advanced_pages:[
        {
          id:0,page:'advanced',name:'Info',
        },
        {
          id:1,page:'advanced',name:'Language',
        },
        {
          id:2,page:'advanced',name:'Time',
        },
        {
          id:3,page:'advanced',name:'Title & favicon',
        },
        {
          id:4,page:'advanced',name:'Icons',
        },
        {
          id:5,page:'advanced',name:'Domain',
        },
        {
          id:6,page:'advanced',name:'Weather & time',
        },
        {
          id:7,page:'advanced',name:'Pwa & app',
        },
        // {
        //   id:8,page:'advanced',name:'Custom scripts',
        // },
        {
          id:9,page:'advanced',name:'404 Page',
        },
        // {
        //   id:10,page:'advanced',name:'Emails',
        // },
        {
          id:11,page:'advanced',name:'Snapshots',
        },
        {
          id:12,page:'advanced',name:'Under Constructor',
        },
        {
          id:13,page:'advanced',name:'management',
        },
        // {
        //   id:14,page:'advanced',name:'Email list',
        // },
        // {
        //   id:15,page:'advanced',name:"Clients rating"
        // },
        // {
        //   id:16,page:'advanced',name:"Notifications"
        // },
        {
          id:17,page:'passcode',name:"Passcode"
        }
      ],
      typeOfEvent:"add",
      loading:false,
      timezones:[
        {
          "offset": "GMT-12:00",
          "name": "Etc/GMT-12"
        },
        {
          "offset": "GMT-11:00",
          "name": "Etc/GMT-11"
        },
        {
          "offset": "GMT-10:00",
          "name": "America/Adak"
        },
        {
          "offset": "GMT-09:00",
          "name": "Pacific/Gambier"
        },
        {
          "offset": "GMT-08:00",
          "name": "America/Ensenada"
        },
        {
          "offset": "GMT-07:00",
          "name": "America/Denver"
        },
        {
          "offset": "GMT-06:00",
          "name": "America/Belize"
        },
        {
          "offset": "GMT-05:00",
          "name": "America/New_York"
        },
        {
          "offset": "GMT-04:30",
          "name": "America/Caracas"
        },
        {
          "offset": "GMT-04:00",
          "name": "America/La_Paz"
        },
        {
          "offset": "GMT-03:00",
          "name": "America/Sao_Paulo"
        },
        {
          "offset": "GMT-03:30",
          "name": "America/St_Johns"
        },
        {
          "offset": "GMT-02:00",
          "name": "America/Noronha"
        },
        {
          "offset": "GMT-01:00",
          "name": "Atlantic/Cape_Verde"
        },
        {
          "offset": "GMT",
          "name": "Europe/Belfast"
        },
        {
          "offset": "GMT+01:00",
          "name": "Africa/Algiers"
        },
        {
          "offset": "GMT+02:00",
          "name": "Africa/Cairo"
        },
        {
          "offset": "GMT+03:00",
          "name": "Europe/Minsk"
        },
        {
          "offset": "GMT+03:30",
          "name": "Asia/Tehran"
        },
        {
          "offset": "GMT+04:00",
          "name": "Europe/Moscow"
        },
        {
          "offset": "GMT+04:30",
          "name": "Asia/Kabul"
        },
        {
          "offset": "GMT+05:00",
          "name": "Asia/Tashkent"
        },
        {
          "offset": "GMT+05:30",
          "name": "Asia/Kolkata"
        },
        {
          "offset": "GMT+05:45",
          "name": "Asia/Katmandu"
        },
        {
          "offset": "GMT+06:00",
          "name": "Asia/Dhaka"
        },
        {
          "offset": "GMT+06:00",
          "name": "Asia/Yekaterinburg"
        },
        {
          "offset": "GMT+06:30",
          "name": "Asia/Rangoon"
        },
        {
          "offset": "GMT+07:00",
          "name": "Asia/Bangkok"
        },
        {
          "offset": "GMT+07:00",
          "name": "Asia/Novosibirsk"
        },
        {
          "offset": "GMT+08:00",
          "name": "Etc/GMT+8"
        },
        {
          "offset": "GMT+08:45",
          "name": "Australia/Eucla"
        },
        {
          "offset": "GMT+09:00",
          "name": "Asia/Irkutsk"
        },
        {
          "offset": "GMT+09:30",
          "name": "Pacific/Marquesas"
        },
        {
          "offset": "GMT+10:00",
          "name": "Asia/Yakutsk"
        },
        {
          "offset": "GMT+10:30",
          "name": "Australia/Lord_Howe"
        },
        {
          "offset": "GMT+11:00",
          "name": "Asia/Vladivostok"
        },
        {
          "offset": "GMT+11:30",
          "name": "Pacific/Norfolk"
        },
        {
          "offset": "GMT+12:00",
          "name": "Etc/GMT+12"
        },
      ],
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
          "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 200,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",
         setup:function(editor){
              editor.ui.registry.addButton('custom_button', {
                  text: 'Add Button',
                  onAction: function() {
                      // Open a Dialog
                      editor.windowManager.open({
                          title: 'Add custom button',
                          body: {
                              type: 'panel',
                              items: [{
                                  type: 'input',
                                  name: 'button_label',
                                  label: 'Button label',
                                  flex: true
                              },{
                                  type: 'input',
                                  name: 'button_href',
                                  label: 'Button href',
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_target',
                                  label: 'Target',
                                  items: [
                                      {text: 'None', value: ''},
                                      {text: 'New window', value: '_blank'},
                                      {text: 'Self', value: '_self'},
                                      {text: 'Parent', value: '_parent'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_style',
                                  label: 'Style',
                                  items: [
                                      {text: 'Success', value: 'success'},
                                      {text: 'Info', value: 'info'},
                                      {text: 'Warning', value: 'warning'},
                                      {text: 'Error', value: 'error'}
                                  ],
                                  flex: true
                              }]
                          },
                          onSubmit: function (api) {

                              var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                              // insert markup
                              editor.insertContent(html);

                              // close the dialog
                              api.close();
                          },
                          buttons: [
                              {
                                  text: 'Close',
                                  type: 'cancel',
                                  onclick: 'close'
                              },
                              {
                                  text: 'Insert',
                                  type: 'submit',
                                  primary: true,
                                  enabled: false
                              }
                          ]
                      });
                  }
              });
          },
      content_css: ["/custom_tinymce.css"],
      },
      currency: [
        { id: 0, value: "$", name: "Dollar $" },
        { id: 1, value: "€", name: "Euro €" },
        { id: 2, value: "Kn", name: "Kuna Kn" },
      ],
      show_lang_color: false,
      dialogAddEmail: false,
      errorMessage: "",
      AddEmail: "",
      editEmail: "",
      indexEmail: 0,
      dialogEditEmail: false,
      headers: [
        { text: "Mail", value: "mail", align: "center", sortable: true },
        { text: "Options", align: "center", sortable: false },
      ],
      email: "",
      selected_langs: ["en"],
      defaults_langs: [{ value: "en", name: "English" }],
      active_tab: 0,
      deleteIndex: null,
      emailRules: [
        (v) => !!v || this.$t('landing.valid["E-mail обязателен"]'),
        (v) =>
          /.+@.+\..+/.test(v) ||
          this.$t('landing.valid["E-mail должен быть валидным"]'),
      ],
    };
  },
  computed: {
    ...mapState(["landing"]),
    activeLang:{
      set:function(val){

      },
      get:function(){
        return this.landing.advanced.selected_langs.indexOf(this.landing.current_lang)
      }
    },
    swatchFontStyle() {
      const { menu } = this;
      return {
        backgroundColor: this.landing.advanced.global.lang_border_color,
        type: "hex",
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        border: "1px solid #000",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    advanced() {
      return JSON.parse(JSON.stringify(this.landing.advanced));
    },
  },
  created() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
  mounted() {
    this.active_advanced_id = this.landing.advanced.tab;
    if (this.$route.params.tab === "email") {
      this.active_tab = 8;
    }
    if(this.$route.params.tab === 'weather'){
      this.active_tab = 6;
    }
    let selected_langs = ['en'];
    let defaults_langs = [];
    this.advanced.langs.forEach((lang) => {
      if (lang.active) {
        selected_langs.push(lang.value);
        defaults_langs.push(lang);
      }
    });
    this.selected_langs = selected_langs;
    this.defaults_langs = defaults_langs;
    this.active_tab = this.landing.advanced.tab;
  },
  watch: {
    "landing.advanced.tab": function(val) {
      this.active_tab = val;
    },
    "$store.state.trigger"(val){
      if(!val){
        if(this.localPage === -1){
            this.$store.state.tab = 0;
            this.$store.state.selectedPage = 'start';
            if (this.$store.state.menu.selectedMenuItem) {
              const candidate = this.$store.state.menu.list.find(
                  (item) => item.id === this.selectedMenuItem.id
              );
              if (candidate) {
                const candidateIndex = this.$store.state.menu.list.indexOf(candidate);
                if (candidateIndex >= 0) {
                  this.$store.state.menu.list.splice(
                      candidateIndex,
                      1,
                      JSON.parse(JSON.stringify(this.$store.state.menu.selectedMenuItem))
                  );
                }
              }
            }
            this.$store.commit('menu/setSelectedMenuItem',null);
            this.$store.commit("setPage", 'start');
        }
        else{
          this.localPage = -1;
          this.landing.advanced.tab = 1;
          this.$store.state.trigger = true;
        }
      }
    },
  },
  methods: {
    ...mapMutations(["setMenuActiveTab"]),
    showAdvanced(id){
      return this.landing.advanced.tab  === id && !this.isMobile || this.landing.advanced.tab  === id && this.localPage === id && this.isMobile
    },
    changeAdvancedTab(item,localPage){
      this.active_advanced_id = item.id;
      this.landing.advanced.tab = item.id;
      this.changePage(item.page);
      if(localPage){
        this.localPage = item.id
      }
      this.$store.commit('back',true);
    },
    activeMenu(id){
      return id === this.active_advanced_id
    },
    closeEdit() {
      this.dialogEditEmail = false;
      this.editEmail = "";
    },
    changePage(page) {
      this.$store.commit("setPage", page);
    },
    saveEdit() {
      this.advanced.global.emails[this.indexEmail] = (
        " " + this.editEmail
      ).slice(1);
      this.editEmail = "";
      this.dialogEditEmail = false;
      this.setToCanvas();
      this.indexEmail = 0;
    },
    showEdit(index) {
      this.indexEmail = index;
      this.dialogEditEmail = true;
      this.editEmail = (" " + this.landing.advanced.global.emails[index]).slice(
        1
      );
    },
    openDelete(index) {
      this.deleteIndex = index;
      this.$refs["PopupDelete"][0].open();
    },
    deleteEmail() {
      this.landing.advanced.global.emails.splice(this.deleteIndex, 1);
      this.deleteIndex = null;
    },
    addEmail() {
      if (this.email) {
        const testEmail = /.+@.+\..+/.test(this.email);
        if (testEmail) {
          this.landing.advanced.global.emails.push(this.email);
          this.email = "";
          this.dialogAddEmail = false;
        } else {
          this.errorMessage = "Email must be valid";
          this.$refs.PopupError.open();
        }
      } else {
        this.errorMessage = "Email is required";
        this.$refs.PopupError.open();
      }
    },
    setToCanvas() {
      this.$store.commit("landing/setAdvanced", this.advanced);
    },
    async changeLang(value) {
      let langs = [];
      const deleteLang = this.advanced.selected_langs.includes(value);

      this.selected_langs = [];
      this.advanced.langs.forEach((lang) => {

        if (lang.value === value) {
          lang.active = !lang.active;
        }
        if(lang.active){
          this.selected_langs.push(lang.value);
        }
      });
      this.defaults_langs = langs;
      if (!this.selected_langs.length) {
        this.selected_langs.push("en");
        this.advanced.default_lang = "en";
      }
      if (this.selected_langs.length === 1) {
        this.advanced.default_lang = this.selected_langs[0];
      }
      if (!this.defaults_langs.length) {
        this.defaults_langs.push({ value: "en", name: "English" });
      }
      this.advanced.defaults_langs = this.defaults_langs;
      this.advanced.selected_langs = this.selected_langs;
      if(deleteLang){
          this.typeOfEvent = 'remove'
        this.loading = true;
          const payload = {
              hotel_id:+this.$route.params.id,
              lang:value
          };
          await LangService.removeLanguageProducts(payload);
          await LangService.removeLanguageEvents(payload);
          await LangService.removeLanguagePoints(payload);
          await LangService.removeLanguageRoutes(payload);
      }else{
        this.typeOfEvent = 'add'
        this.loading = true;
          const payload = {
              hotel_id:+this.$route.params.id,
              lang:this.advanced.selected_langs
          };
          await LangService.addLanguageProducts(payload);
          await LangService.addLanguageEvents(payload);
          await LangService.addLanguagePoints(payload);
          await LangService.addLanguageRoute(payload);
      }
      this.$store.commit("landing/setAdvanced", this.advanced);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.constructor-menu__turn {
  .v-input--switch {
    margin-top: 0 !important;
  }
}
.advanced_tabs .v-tab{
  justify-content: flex-start;
}
.loading_advanced {
  position: fixed;
  top: calc(50% - 100px);
  left: calc(50% - 150px);
  width: 300px;
  height: 200px;
  background: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  &__icon {
    animation: 1s linear 0s normal none infinite running circle;
  }
  @keyframes circle {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
