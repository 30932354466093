<template>
  <div class="upload-banner">
    <label class="label" style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;">Bottom banner settings</label>
    <div v-if="bottomBannersList.length">
      <div style="display: flex;align-items: center;margin-bottom:15px;">
        <label class="label" style="display: block;margin-bottom:0;margin-right:23px;width:200px;">Choose banner</label>
        <v-select outlined
                  style="max-width:250px;"
                  hide-details dense :items="bottomBannersList" item-value="id" v-model="selectOption" @change="changeSelectedBanner" return-object>
          <template v-slot:selection="{ item }">
            {{item.name}}
          </template>
          <template v-slot:item="{ item }">
            {{item.name}}
          </template>
        </v-select>
      </div>
      <div v-if="bottomBanner">
        <div style="display: flex;align-items: center;">
          <label class="label" style="display: block;margin-bottom:0;margin-right:23px;width:200px;">Name</label>
          <v-text-field
              outlined
              dense
              style="max-width:250px;"
              hide-details
              v-model="bottomBanner.name"
          ></v-text-field>
        </div>
        <v-tabs v-model="selected_lang">
          <v-tab v-for="(selected_lang, i) in selected_langs" :key="i" @click="changeLang(selected_lang)">
            <img
                :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                alt=""
                style="width: 20px; height: 15px; margin-right: 5px"
            />
            {{ selected_lang }}
          </v-tab>
          <v-tab-item v-for="lang in selected_langs" :key="lang" style="padding-top:15px;">
          </v-tab-item>
        </v-tabs>
          <div v-if="bottomBanner.type === 3">
            <div style="padding:15px 0;">
              <div style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="display: block;width:200px;margin-bottom:0;margin-right:23px;">Upload video</label>
                <v-file-input
                    accept="video/*"
                    style="max-width:250px;"
                    show-size
                    ref="videoUpload"
                    prepend-icon=""
                    outlined
                    hide-details
                    dense
                    @change="onFileChange"
                ></v-file-input></div>
            </div>
          </div>
        <div style="padding:15px 0;" v-if="bottomBanner.type !== 3">
          <div style="">
            <label class="label" style="display:block;margin-bottom:15px;">Image</label>
            <div style="margin-bottom: 15px;">
              <vue-dropzone
                  id="upload"
                  ref="upload"
                  :options="dropzoneOptions"
                  :maxFiles="1"
                  :destroyDropzone="false"
                  @vdropzone-mounted="mountDropzone"
                  @vdropzone-sending="customEvent"
                  @vdropzone-removed-file="handleRemove"
                  @vdropzone-success="handleSuccess"
              >
              </vue-dropzone>
            </div>
          </div>
        </div>
        <div v-if="bottomBanner.type === 1">
          <div style="padding:15px 0;">
            <div style="display: flex;align-items: center;">
              <label class="label" style="display: block;margin-bottom:0;margin-right:23px;width:200px;">Link</label>
              <v-text-field
                  outlined
                  dense
                  style="max-width:250px;"
                  hide-details
                  v-model="bottomBanner.link[landing.current_lang]"
              ></v-text-field>
            </div>
          </div>
        </div>
        <div v-if="bottomBanner.type === 2">
          <div style="padding:15px 0;">
            <div style="display: flex;align-items: center;">
              <label class="label" style="display: block;margin-bottom:0;margin-right:23px;width:200px;">Video Link</label>
              <v-text-field
                  outlined
                  style="max-width:250px;"
                  dense
                  hide-details
                  v-model="bottomBanner.video_link[landing.current_lang]"
              ></v-text-field>
            </div>
          </div>
        </div>
        <div v-if="bottomBanner.type === 2">
          <div>
            <label class="label" style="font-weight:bold;">Video Icon Settings:</label>
          </div>
          <div style="padding:15px;">
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="width:200px;margin-bottom:0;">Size <b>{{bottomBanner.styles.banner_video_icon_size}} px</b></label>
              <div class="control" style="width:70%">
                <div class="slider">
                  <v-slider
                      hide-details
                      :min="10"
                      :max="100"
                      v-model="bottomBanner.styles.banner_video_icon_size"
                  ></v-slider>
                </div>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px">Color</label>
              <v-menu
                  v-model="bottomBannerIconColor"
                  top
                  nudge-bottom="98"
                  nudge-left="16"
                  :close-on-content-click="false"
                  z-index="212"
              >
                <template v-slot:activator="{ on }">
                  <div :style="`background-color:${bottomBanner.styles.banner_video_icon_color}`" class="color" v-on="on" />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                        v-model="bottomBanner.styles.banner_video_icon_color"
                        flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px">Color Hover</label>
              <v-menu
                  v-model="bottomBannerIconColorHover"
                  top
                  nudge-bottom="98"
                  nudge-left="16"
                  :close-on-content-click="false"
                  z-index="212"
              >
                <template v-slot:activator="{ on }">
                  <div :style="`background-color:${bottomBanner.styles.banner_video_icon_color_hover}`" class="color" v-on="on" />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                        v-model="bottomBanner.styles.banner_video_icon_color_hover"
                        flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>

          </div>
        </div>
        <div>
          <div>
            <label class="label" style="font-weight:bold;">General Settings:</label>
          </div>
          <div style="padding:15px;">
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="width:200px;margin-bottom:0;">Width <b>{{bottomBanner.styles.width}} %</b></label>
              <div class="control" style="width:70%">
                <div class="slider">
                  <v-slider
                      hide-details
                      :min="10"
                      :max="100"
                      v-model="bottomBanner.styles.width"
                  ></v-slider>
                </div>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="width:200px;margin-bottom:0;">Height <b>{{bottomBanner.styles.height}} px</b></label>
              <div class="control" style="width:70%">
                <div class="slider">
                  <v-slider
                      hide-details
                      :min="30"
                      :max="500"
                      v-model="bottomBanner.styles.height"
                  ></v-slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      You don`t have any bottom banners, to create them <router-link :to="`/banners?id=${$route.params.id}`">go here</router-link>.
    </div>
    <PopupCrop title='Change image before upload?' ref="PopupCrop" @confirm="openEditor" @save="saveImg" @close="closeCrop"/>
  </div>
</template>

<script>
import {mapActions,mapMutations,mapState} from "vuex";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Compressor from "compressorjs";
import Cropper from "cropperjs";
import "../../../../node_modules/cropperjs/dist/cropper.min.css";
export default {
  name: "BottomBanner",
  props:['lang'],
  components:{
    vueDropzone:() => import("vue2-dropzone"),
    PopupCrop:() => import("@/components/Popup/PopupCrop"),
  },
  data(){
    return{
      selectOption:null,
      bottomBannerIconColorHover:false,
      bottomBannerIconColor:false,
      selected_lang:0,
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 3,
        maxFiles: 1,
        destroyDropzone: false,
        dictFileTooBig:"File is too big ({{filesize}}mb). Max file size: 3mb",
        autoProcessQueue: false,
        thumbnail: (file, dataUrl) => {
          if (file.cropped) {
            return;
          }else{
            this.compress(file)
          }
        },
      },
    }
  },
  computed:{
    ...mapState('bottomBanner',['bottomBannersList','bottomBanner','preview']),
    ...mapState(['landing']),
    ...mapState('landing',['selected_langs']),
  },
  methods:{
    ...mapActions('bottomBanner',['getBottomBanners']),
    ...mapMutations('bottomBanner',['setBottomBanner','setPreview']),
    changeLang(lang){
      this.landing.current_lang = lang;
      this.mountDropzone();
    },
    async onFileChange(file){
      try{
        if(file){
          let formData = new FormData();
          formData.append('lending_settings_id',this.landing.settings_id);
          formData.append('image',file);
          formData.append('file',file);
          const response = await this.$axios.post('upload-image',formData);
          this.bottomBanner.video[this.landing.current_lang] = response.data;
        }
      }catch(e){
        console.log(e);
      }
    },
    compress(file){
      new Compressor(file, {
        quality: 0.6,
        mimeType:'image/webp',
        convertSize:0,
        convertTypes:['image/jpeg','image/png','image/jpg'],
        success:(result) => {
          if(this.$store.state.isMobile){
            this.saveImg(result)
          }else{
            this.$refs.PopupCrop.open(result)
          }
        },
        error(e) {
          console.log(e);
        }
      })
    },
    blobToDataURL(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = _e => resolve(reader.result);
        reader.onerror = _e => reject(reader.error);
        reader.onabort = _e => reject(new Error("Read aborted"));
        reader.readAsDataURL(blob);
      });
    },
    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    async saveImg(file){
      file.cropped = true;
      this.$refs.upload.dropzone.removeAllFiles();
      this.$refs.upload.dropzone.addFile(file);
      this.$refs.upload.dropzone.processQueue();
      this.$refs.upload.dropzone[
          "previewsContainer"
          ].children[1].children[0].children[0].src = await this.blobToDataURL(file);
      this.$refs.upload.dropzone[
          "previewsContainer"
          ].children[1].children[0].style.borderRadius = 0;
      this.$refs.upload.dropzone[
          "previewsContainer"
          ].children[1].children[1].style.background = "none";
    },
    openEditor(file){
      let editor = document.createElement("div");
      editor.style.position = "fixed";
      editor.style.left = 0;
      editor.style.right = 0;
      editor.style.top = 0;
      editor.style.bottom = 0;
      editor.style.width = "66%";
      editor.style.zIndex = 9999;
      editor.style.backgroundColor = "#000";
      document.body.appendChild(editor);
      if (this.bottomBanner.image[this.landing.current_lang]) {
        this.$refs.upload.dropzone.removeFile(file);
      }
      let buttonConfirm = document.createElement("button");
      buttonConfirm.style.position = "absolute";
      buttonConfirm.style.right = "10px";
      buttonConfirm.style.top = "10px";
      buttonConfirm.style.zIndex = 9999;
      buttonConfirm.textContent = "Save image";
      buttonConfirm.className =
          "v-btn v-size--small confirm-control";
      editor.appendChild(buttonConfirm);
      let buttonCancel = document.createElement("button");
      buttonCancel.style.position = "absolute";
      buttonCancel.style.right = "135px";
      buttonCancel.style.top = "10px";
      buttonCancel.style.zIndex = 9999;
      buttonCancel.textContent = "Cancel";
      buttonCancel.className =
          "v-btn v-size--small confirm-control";
      editor.appendChild(buttonCancel);
      buttonCancel.addEventListener("click", () => {
        this.$refs.upload.dropzone.removeAllFiles();
        document.body.removeChild(editor);
        cropper.destroy();
      });
      let buttonRotateLeft = document.createElement("button");
      buttonRotateLeft.className = "cropper-controls";
      buttonRotateLeft.style.left = "24px";
      buttonRotateLeft.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M13,4.07V1L8.45,5.55L13,10V6.09C15.84,6.57 18,9.03 18,12C18,14.97 15.84,17.43 13,17.91V19.93C16.95,19.44 20,16.08 20,12C20,7.92 16.95,4.56 13,4.07M7.1,18.32C8.26,19.22 9.61,19.76 11,19.93V17.9C10.13,17.75 9.29,17.41 8.54,16.87L7.1,18.32M6.09,13H4.07C4.24,14.39 4.79,15.73 5.69,16.89L7.1,15.47C6.58,14.72 6.23,13.88 6.09,13M7.11,8.53L5.7,7.11C4.8,8.27 4.24,9.61 4.07,11H6.09C6.23,10.13 6.58,9.28 7.11,8.53Z" />
</svg>`;
      editor.appendChild(buttonRotateLeft);
      let buttonRotateRight = document.createElement("button");
      buttonRotateRight.className = "cropper-controls";
      buttonRotateRight.style.left = "64px";
      buttonRotateRight.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M16.89,15.5L18.31,16.89C19.21,15.73 19.76,14.39 19.93,13H17.91C17.77,13.87 17.43,14.72 16.89,15.5M13,17.9V19.92C14.39,19.75 15.74,19.21 16.9,18.31L15.46,16.87C14.71,17.41 13.87,17.76 13,17.9M19.93,11C19.76,9.61 19.21,8.27 18.31,7.11L16.89,8.53C17.43,9.28 17.77,10.13 17.91,11M15.55,5.55L11,1V4.07C7.06,4.56 4,7.92 4,12C4,16.08 7.05,19.44 11,19.93V17.91C8.16,17.43 6,14.97 6,12C6,9.03 8.16,6.57 11,6.09V10L15.55,5.55Z" />
</svg>`;
      editor.appendChild(buttonRotateRight);
      let buttonCrop = document.createElement("button");
      buttonCrop.className = "cropper-controls active-control";
      buttonCrop.style.left = "104px";
      buttonCrop.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M7,17V1H5V5H1V7H5V17A2,2 0 0,0 7,19H17V23H19V19H23V17M17,15H19V7C19,5.89 18.1,5 17,5H9V7H17V15Z" />
</svg>`;
      editor.appendChild(buttonCrop);
      let buttonZoomPlus = document.createElement("button");
      buttonZoomPlus.className = "cropper-controls";
      buttonZoomPlus.style.left = "144px";
      buttonZoomPlus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
</svg>`;
      editor.appendChild(buttonZoomPlus);
      let buttonZoomMinus = document.createElement("button");
      buttonZoomMinus.className = "cropper-controls";
      buttonZoomMinus.style.left = "184px";
      buttonZoomMinus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
</svg>`;
      editor.appendChild(buttonZoomMinus);
      let buttonLeftRight = document.createElement("button");
      buttonLeftRight.className = "cropper-controls";
      buttonLeftRight.style.left = "224px";
      buttonLeftRight.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
</svg>`;
      editor.appendChild(buttonLeftRight);
      let buttonUpDown = document.createElement("button");
      buttonUpDown.className = "cropper-controls";
      buttonUpDown.style.left = "264px";
      buttonUpDown.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z" />
</svg>`;
      editor.appendChild(buttonUpDown);
      buttonConfirm.addEventListener("click", () => {
        let canvas = cropper.getCroppedCanvas();
        let newFile = this.dataURItoBlob(canvas.toDataURL(file.type));
        newFile.cropped = true;
        newFile.name = file.name;
        this.$refs.upload.dropzone.removeAllFiles();
        this.$refs.upload.dropzone.addFile(newFile);
        this.$refs.upload.dropzone.processQueue();
        this.$refs.upload.dropzone[
            "previewsContainer"
            ].children[1].children[0].children[0].src =  canvas.toDataURL(file.type);
        this.$refs.upload.dropzone[
            "previewsContainer"
            ].children[1].children[0].style.borderRadius = 0;
        this.$refs.upload.dropzone[
            "previewsContainer"
            ].children[1].children[1].style.background = "none";
        this.setPreview(false);
        document.body.removeChild(editor);
        cropper.destroy();
      });
      buttonRotateRight.addEventListener("click", () => {
        if (!buttonRotateRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateRight.classList.add("active-control");
        }
        cropper.rotate(45);
      });
      buttonRotateLeft.addEventListener("click", () => {
        if (!buttonRotateLeft.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateLeft.classList.add("active-control");
        }
        cropper.rotate(-45);
      });

      buttonCrop.addEventListener("click", () => {
        if (!buttonCrop.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonCrop.classList.add("active-control");
        }
        cropper.setDragMode("crop");
      });
      buttonZoomPlus.addEventListener("click", () => {
        if (!buttonZoomPlus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomPlus.classList.add("active-control");
        }
        cropper.zoom(0.1);
      });
      buttonZoomMinus.addEventListener("click", () => {
        if (!buttonZoomMinus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomMinus.classList.add("active-control");
        }
        cropper.zoom(-0.1);
      });
      buttonLeftRight.addEventListener("click", () => {
        if (!buttonLeftRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonLeftRight.classList.add("active-control");
        }
        if (cropper.getData().scaleX === 1) {
          cropper.scaleX(-1);
        } else {
          cropper.scaleX(1);
        }
      });
      buttonUpDown.addEventListener("click", () => {
        if (!buttonUpDown.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonUpDown.classList.add("active-control");
        }
        if (cropper.getData().scaleY === 1) {
          cropper.scaleY(-1);
        } else {
          cropper.scaleY(1);
        }
      });
      let image = new Image();
      image.src = URL.createObjectURL(file);
      editor.appendChild(image);
      this.setPreview(true);
      let cropper = new Cropper(image, {
        preview: ".preview_bottom_banner",
        aspectRatio: ((360/100)*this.bottomBanner.styles.width) / this.bottomBanner.styles.height,
      });
    },
    closeCrop(){
      this.$refs.upload.dropzone.removeAllFiles();
    },
    customEvent(_file, xhr,formData){
      xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.state.token}`
      );
      formData.append('image',_file);
      formData.append('lending_settings_id',this.landing.settings_id);
    },
    handleRemove(){
      this.bottomBanner.image[this.landing.current_lang] = "";
    },
    handleSuccess(file){
      this.bottomBanner.image[this.landing.current_lang] = file.xhr.response;
    },
    mountDropzone(){
      let elements =
          this.$refs.upload.$el.getElementsByClassName("dz-preview");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      if(this.bottomBanner.image[this.landing.current_lang]){
        let mockFile = {
          accepted: true,
          cropped:true,
          kind: "image",
        };
        this.$refs.upload.manuallyAddFile(mockFile, this.bottomBanner.image[this.landing.current_lang]);
        this.$refs.upload.dropzone.emit("thumbnail", mockFile, this.bottomBanner.image[this.landing.current_lang]);
        this.$refs.upload.dropzone.emit("complete", mockFile);
        this.$refs.upload.dropzone['previewsContainer'].children[1].children[0].children[0].src = this.bottomBanner.image[this.landing.current_lang];
        this.$refs.upload.dropzone['previewsContainer'].children[1].children[0].style.borderRadius = 0;
        this.$refs.upload.dropzone['previewsContainer'].children[1].children[1].style.background = 'none';
      }
    },
    changeSelectedBanner() {
      this.setBottomBanner(this.selectOption);
      if (this.$refs.upload) {
        this.mountDropzone();
      }
    }
  },
  async mounted(){
    await this.getBottomBanners(this.$route.params.id);
    if(!this.$route.query.bottomBannerID){
      if(this.bottomBannersList.length){
        this.setBottomBanner(this.bottomBannersList[0]);
        this.selectOption = this.bottomBannersList[0];
      }
    }
    else{
      const candidate = this.bottomBannersList.find(x => x.id === +this.$route.query.introID);
      if(candidate){
        this.setBottomBanner(candidate);
        this.selectOption = candidate;
      }else{
        this.setBottomBanner(this.bottomBannersList[0]);
        this.selectOption = this.bottomBannersList[0];
      }
    }
    // let canvas = document.getElementsByClassName('canvas');
    // if(canvas[0]){
    //   canvas[0].scrollTop = canvas[0].scrollHeight;
    // }
    const candidate = this.selected_langs.find(x => x === this.landing.current_lang);

    if(candidate){
      this.selected_lang = this.selected_langs.indexOf(candidate);
    }
  }
}
</script>

<style scoped lang="scss">

</style>
