<template>
<div class="field">
  <PopupTemplates :id="$store.state.objects.item.id" />
</div>
</template>

<script>
import HotelService from "@/services/hotel.service";
import PopupTemplates from '../../Popup/PopupTemplates';
import {
  authIcon,
  defaultIcon,
  defaultProductStyles,
  defaultStyles,
  defaultFormLite,
  defaultFormStyles,
  defaultStylesFavorite,
  defaultShareOpinions,
  defaultMenuItems,
  defaultIntro
} from "@/store/landing";
import {mapState} from "vuex";

export default {
  name: "Templates",
  data(){
    return{
      loading:true,
      successMessage:"",
      selected_category:'All',
      swiperOptions:{
        slidesPerView: 'auto',
        spaceBetween: 10,
      },
      swiperImageOptions:{
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
      templates:[],
      blank_template:{
        name:"Blank template",
        description:"Blank template description",
        images:['https://guesteco.com/templates/blank_template.png','https://guesteco.com/templates/blank_template_1.png'],
        content:{
          created_at: "",
          version: "",
          updated_at: "",
          rooms: [],
          order_form: {
            content: JSON.parse(JSON.stringify(defaultFormLite)),
            styles: JSON.parse(JSON.stringify(defaultFormStyles)),
          },
          icons: {
            loading: false,
            data: [],
          },
          banner_types: {
            loading: false,
            data: [],
          },
          settings_id: null,
          current_lang: "en",
          selected_langs: ["en"],
          isValid: true,
          isDirty: false,
          isAbleToWatch: false,
          domain: null,
          qrcode: null,
          pdf: null,
          branding: {
            segment_products_styles:JSON.parse(JSON.stringify(defaultProductStyles)),
            loading_langs:{
              icon:process.env.VUE_APP_LINK+"/loaders/loader11.svg",
              icon_color:"#ccc",
              background:"#fff",
              size:50,
              text:"<p style='text-align: center;'>Please wait a bit</p>",
            },
            sidebar_menu: {
              background: "rgba(255,255,255,1)",
              border_color: "rgba(204, 204, 204,1)",
              show_icons:true,
              icon_colors:"rgba(0,0,0,1)",
            },
            header_preview: false,
            emailList: {
              activeEmail: "",
              show: false,
              bottom_text: {
                en: "",
              },
              top_text: {
                en: "",
              },
              bg: null,
              radius: 0,
              preview: false,
              bg_color: "rgba(255,255,255,1)",
              btn_bg: "rgba(255,255,255,1)",
              btn_color: "#007db5",
              height: "150",
              width: "100",
              btn_text: {
                en: "Send",
              },
            },
            background_preview: false,
            topMenu: {
              styles: {
                size: "25",
                color: "#ffffff",
                background: "rgba(0,0,0,.34)",
                radius: "38",
              },
            },
            bottomMenu: {
              show: false,
              active: false,
              styles: {
                color: "#999999",
                background: "#ffffff",
                fontSize: "11",
                iconSize: "27",
                fontWeight: "10",
                activeColor: "#ffffff",
                activeBackground: "#007aff",
              },
              items: [
                {
                  icon: defaultIcon,
                  link: "",
                  text: {
                    en: "button",
                  },
                },
                {
                  icon: defaultIcon,
                  link: "",
                  text: {
                    en: "button",
                  },
                },
                {
                  icon: defaultIcon,
                  link: "",
                  text: {
                    en: "button",
                  },
                },
                {
                  icon: defaultIcon,
                  link: "",
                  text: {
                    en: "button",
                  },
                },
              ],
            },
            active_tab: 0,
            intro: {
              en: JSON.parse(JSON.stringify(defaultIntro)),
            },
            footer_img: null,
            footer_size: {
              width: "100",
              height: "200",
              radius: "0",
            },
            header_size: {
              radius: "0",
              width: "100",
            },
            slider: {
              en: [
                {
                  id: 0,
                  title: "Example title",
                  slider_view_all: {
                    show: false,
                    text: "View all",
                    link: "",
                  },
                  hide: false,
                  show_type: "bottom",
                  border_color: "#cccccc",
                  type: "horizontal",
                  vertical: {
                    height: "220",
                    width: "150",
                    img_height: "150",
                  },
                  horizontal: {
                    height: "150",
                    width: "250",
                    img_width: "100",
                  },
                  settings: false,
                  background: "rgba(255,255,255,1)",
                  bg_group: "rgba(255,255,255,1)",
                  radius: 0,
                  slides: [
                    {
                      img: require("@/assets/none.png"),
                      text: "Example first block",
                      text_link: null,
                      useLink: false,
                      link: null,
                    },
                  ],
                },
              ],
            },
            event_main_page: {
              active: false,
              show_type: "bottom",
              border_color: "#cccccc",
              type: "vertical",
              all_events:{
                text:{
                  en:"View all"
                },
                link:{
                  en:""
                },
                styles:{
                  color: "#000",
                  fontWeight: "500",
                  fontStyle: "normal",
                  fontFamily: "Inter",
                  fontSize: "16",
                }
              },
              title:{
                en:""
              },
              vertical: {
                height: "220",
                width: "150",
                img_height: "150",
              },
              horizontal: {
                height: "150",
                width: "250",
                img_width: "100",
              },
              background: "rgba(255,255,255,1)",
              bg_group: "rgba(255,255,255,1)",
              radius: 0,
              autoplay:4
            },
            bg_color: "#ffffff",
            bg_image: null,
            font_color: "#000000",
            lining_radius: "16",
            bg_lining_img: null,
            lining_margin: true,
            bg_lining_color: "#fff",
            is_lining_bg: false,
            footer_text: {
              en: null,
            },
            footer_title: {
              en: null,
            },
            header_image: null,
            is_bg: true,
            layout: "center",
            logo: null,
            logo_size: {
              height: 100,
              width: 100,
              radius:0
            },
            title: {
              en: "",
            },
            preview_title: "title",
            title_no_auth: {
              en: null,
            },
          },
          menu: {
            style: "tile",
            radius: 10,
            icon_size: 36,
            active_tab: 0,
            list: [...defaultMenuItems],
            shadowColor: "#0000001A",
            shadowSize: "3",
          },
          socials: {
            is_show: true,
            style: "default",
            width: "100",
            radius: 32,
            list: {
              en: [
                {
                  id: 1,
                  text: "Facebook",
                  icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="23.9" viewBox="0 0 24 23.9"><g transform="translate(0 -0.349)"><path d="M12,.349a11.993,11.993,0,0,0-2,23.82V14.853H7.1V11.5H10V9.028C10,6.16,11.751,4.6,14.31,4.6a23.89,23.89,0,0,1,2.585.132v3H15.119c-1.391,0-1.66.661-1.66,1.631V11.5h3.32l-.433,3.353H13.46v9.4A11.994,11.994,0,0,0,12,.349Z" transform="translate(0 0)" fill="#385893"/></g></svg>',
                  color: "#3b5998",
                  active: false,
                  link: "https://www.facebook.com/",
                },
                {
                  id: 2,
                  text: "Instagram",
                  icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.377,0H6.623A6.631,6.631,0,0,0,0,6.623V17.377A6.631,6.631,0,0,0,6.623,24H17.377A6.631,6.631,0,0,0,24,17.377V6.623A6.631,6.631,0,0,0,17.377,0Zm4.494,17.377a4.5,4.5,0,0,1-4.494,4.494H6.623a4.5,4.5,0,0,1-4.494-4.494V6.623A4.5,4.5,0,0,1,6.623,2.129H17.377a4.5,4.5,0,0,1,4.494,4.494V17.377Z" fill="#ad447d"/><path d="M47.152,40.97a6.184,6.184,0,1,0,6.184,6.184A6.191,6.191,0,0,0,47.152,40.97Zm0,10.239a4.055,4.055,0,1,1,4.055-4.055A4.059,4.059,0,0,1,47.152,51.209Z" transform="translate(-35.152 -35.154)" fill="#ad447d"/><path d="M120.483,28.251a1.561,1.561,0,1,0,1.1.457A1.568,1.568,0,0,0,120.483,28.251Z" transform="translate(-102.039 -24.24)" fill="#ad447d"/></svg>',
                  color: "#c13584",
                  active: false,
                  link: "https://www.instagram.com/",
                },
                {
                  id: 3,
                  text: "Twitter",
                  icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="19.5" viewBox="0 0 24 19.5"><path d="M24,50.309a10.258,10.258,0,0,1-2.835.777,4.893,4.893,0,0,0,2.165-2.72,9.832,9.832,0,0,1-3.12,1.191A4.92,4.92,0,0,0,11.7,52.922a5.066,5.066,0,0,0,.114,1.122A13.927,13.927,0,0,1,1.671,48.9a4.922,4.922,0,0,0,1.512,6.576A4.859,4.859,0,0,1,.96,54.867v.054A4.943,4.943,0,0,0,4.9,59.756a4.911,4.911,0,0,1-1.29.162,4.351,4.351,0,0,1-.932-.084,4.967,4.967,0,0,0,4.6,3.427,9.886,9.886,0,0,1-6.1,2.1A9.216,9.216,0,0,1,0,65.292,13.852,13.852,0,0,0,7.548,67.5a13.907,13.907,0,0,0,14-14c0-.217-.008-.427-.018-.636A9.816,9.816,0,0,0,24,50.309Z" transform="translate(0 -48)" fill="#009fff"/></svg>',
                  color: "#1da1f2",
                  active: false,
                  link: "https://twitter.com/",
                },
                {
                  id: 4,
                  text: "LinkedIn",
                  icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21.829,0H2.171A2.171,2.171,0,0,0,0,2.171V21.829A2.171,2.171,0,0,0,2.171,24H21.829A2.171,2.171,0,0,0,24,21.829V2.171A2.171,2.171,0,0,0,21.829,0ZM7.427,20.723a.632.632,0,0,1-.632.632H4.105a.632.632,0,0,1-.632-.632V9.449a.632.632,0,0,1,.632-.632H6.795a.632.632,0,0,1,.632.632ZM5.45,7.755A2.555,2.555,0,1,1,8.005,5.2,2.555,2.555,0,0,1,5.45,7.755ZM21.481,20.774a.581.581,0,0,1-.581.581H18.014a.581.581,0,0,1-.581-.581V15.486c0-.789.231-3.457-2.062-3.457-1.779,0-2.139,1.826-2.212,2.646v6.1a.581.581,0,0,1-.581.581H9.788a.581.581,0,0,1-.581-.581V9.4a.581.581,0,0,1,.581-.581h2.791a.581.581,0,0,1,.581.581v.984a3.962,3.962,0,0,1,3.726-1.754c4.621,0,4.595,4.317,4.595,6.689v5.456Z" fill="#0077b7"/></svg>',
                  color: "#0976b4",
                  active: false,
                  link: "https://linkedin.com/",
                },
                {
                  id: 5,
                  text: "Messenger",
                  icon: '<svg xmlns="http://www.w3.org/2000/svg" width="23.999" height="24" viewBox="0 0 23.999 24"><path d="M0,11.111a10.8,10.8,0,0,0,4.471,8.652V24l4.086-2.242A12.919,12.919,0,0,0,12,22.223c6.627,0,12-4.974,12-11.111S18.627,0,12,0,0,4.974,0,11.111ZM10.734,8l3.13,3.259L19.751,8l-6.56,6.962L10.136,11.7,4.173,14.962Z" fill="#2196f3"/></svg>',
                  color: "#0084ff",
                  active: false,
                  link: "https://www.messenger.com/",
                },
                {
                  id: 6,
                  text: "VKontakte",
                  icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20,24H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0H20a4,4,0,0,1,4,4V20A4.005,4.005,0,0,1,20,24Zm-6.189-9.466c.244,0,.69.115,1.744,1.111.354.347.621.639.836.874.512.559.746.814,1.213.814h1.75a.674.674,0,0,0,.564-.208.573.573,0,0,0,.042-.522,12.262,12.262,0,0,0-2.416-2.978c-.149-.149-.246-.247-.266-.274-.258-.325-.187-.469,0-.764a21.148,21.148,0,0,0,2.359-3.955.507.507,0,0,0-.028-.46.594.594,0,0,0-.5-.174h-1.75a.753.753,0,0,0-.76.487A14.426,14.426,0,0,1,14.448,12c-.416.409-.6.527-.816.527-.113,0-.28-.131-.28-.493v-3.4c0-.455-.138-.633-.493-.633h-2.75a.417.417,0,0,0-.446.394.657.657,0,0,0,.209.412,1.909,1.909,0,0,1,.486,1.265V12.6c0,.559-.1.657-.324.657-.58,0-2.03-2.118-2.893-4.591C6.976,8.216,6.816,8,6.35,8H4.6c-.532,0-.6.26-.6.487,0,.506.655,2.8,2.766,5.7A6.827,6.827,0,0,0,12.1,17.334c1.16,0,1.25-.277,1.25-.668,0-.293,0-.544,0-.766-.007-.811-.01-1.146.123-1.281A.459.459,0,0,1,13.811,14.534Z" fill="#56789d"/></svg>',
                  color: "#56789C",
                  active: false,
                  link: "https://vk.com/",
                },
                {
                  id: 7,
                  text: "TikTok",
                  icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20,24H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0H20a4,4,0,0,1,4,4V20A4.005,4.005,0,0,1,20,24ZM10.011,10.1A4.984,4.984,0,0,0,6.246,11.78a4.911,4.911,0,0,0,.221,6.77,5.256,5.256,0,0,0,.473.412A4.99,4.99,0,0,0,10.011,20a5.251,5.251,0,0,0,.753-.055,4.98,4.98,0,0,0,2.789-1.4,4.893,4.893,0,0,0,1.467-3.481L15.007,9.23a6.633,6.633,0,0,0,4.051,1.376v-2.6h-.005a4.047,4.047,0,0,1-3.971-3.25A4.112,4.112,0,0,1,15,4H12.391v7.138l0,3.91a2.368,2.368,0,0,1-2.37,2.363c-.044,0-.088,0-.132,0a2.373,2.373,0,0,1-2.237-2.325,2.366,2.366,0,0,1,2.369-2.4,2.38,2.38,0,0,1,.746.12V10.152A5.254,5.254,0,0,0,10.011,10.1Z"/></svg>',
                  color: "#010101",
                  active: false,
                  link: "https://www.tiktok.com/",
                },
              ],
            },
          },
          order: {
            tab: 0,
            success_page: {
              en: {
                content: "<p >Thank you. We will contact you.</p>",
                show_socials: true,
                second_content: "<p >Thank you. We will contact you.</p>",
                table_content: "<p>Thank you. We will contact you.</p>",
              },
            },
            socials: JSON.parse(JSON.stringify(defaultShareOpinions)),
          },
          reviews: {
            en: {
              questions: [
                {
                  id: 1,
                  name: "default question",
                },
              ],
              text_questions: [
                {
                  id: 1,
                  name: "default text question",
                },
              ],
              header_text:
                  "Your opinion is very important to us. Please take a couple of minutes and answer the questions below. Your answers will make us even better!",
              btn_name: "Send",
              btn_color: "#000000",
              btn_bg: "rgba(255,255,255,0.5)",
              btn_border: "#000000",
              pro: [
                {
                  id: 0,
                  questions: [
                    {
                      id: 0,
                      name: "default question",
                      value: false,
                      type: "radio",
                    },
                  ],
                  header: "default header",
                },
              ],
              show: false,
            },
            socials: JSON.parse(JSON.stringify(defaultShareOpinions)),
            tab: 0,
            active_name: "default question",
            active_stars: 3,
            emails: [],
            type: "pro",
            star_count: 3,
            show_success: true,
            styles: {
              btn_name: "Send",
              btn_color: "#000",
              btn_bg: "#fff",
              btn_border: "#000",
            },
            unsuccess_page: {
              en: {
                content: "<p >Thank you.</p>",
                show_socials: true,
                second_content: "<h2>Your link to leave a review on</h2>",
              },
            },
            success_page: {
              en: {
                content: "<p >Thank you. Your link to leave a review on:</p>",
                second_content: "Your link to leave a review on",
                show_socials: true,
              },
            },
            emailNotification: {
              header: "New review has been added by guest ",
              text: "<p>Guest left a new review about your hotel:</p>",
              email: "",
            },
          },
          advanced: {
            auth: {
              name: true,
              last_name: true,
              birthday: true,
              room: false,
              text_color: "#000000",
              login_btn_text_color: "#ffffff",
              login_btn_bg_color: "#007db5",
              logo_text: {
                en: "",
              },
              logo_img: {
                show: false,
                src: null,
                width: "100",
                height: "100",
              },
              required_auth: false,
              background: {
                color: "#ffffff",
                url: null,
                show: false,
              },
            },
            langs: [
              {
                value: "en",
                name: "English",
                active: true,
                flag: "https://guesteco.com/3x2/US.svg",
              },
              {
                value: "es",
                name: "Spanish",
                active: false,
                flag: "https://guesteco.com/3x2/ES.svg",
              },
              {
                value: "fr",
                name: "French",
                active: false,
                flag: "https://guesteco.com/3x2/FR.svg",
              },
              {
                value: "hr",
                name: "Croation",
                active: false,
                flag: "https://guesteco.com/3x2/HR.svg",
              },
              {
                value: "de",
                name: "Deutsch",
                active: false,
                flag: "https://guesteco.com/3x2/DE.svg",
              },
              {
                value: "it",
                name: "Italian",
                active: false,
                flag: "https://guesteco.com/3x2/IT.svg",
              },
              {
                value: "pl",
                name: "Polish",
                active: false,
                flag: "https://guesteco.com/3x2/PL.svg",
              },
              {
                value: "pt",
                name: "Portuguese",
                active: false,
                flag: "https://guesteco.com/3x2/PT.svg",
              },
              {
                value: "ro",
                name: "Romanian",
                active: false,
                flag: "https://guesteco.com/3x2/RO.svg",
              },
              {
                value: "ru",
                name: "Russian",
                active: false,
                flag: "https://guesteco.com/3x2/RU.svg",
              },
              {
                value: "si",
                name: "Slovenian",
                active: false,
                flag: "https://guesteco.com/3x2/SI.svg",
              },
              {
                value: "zh",
                name: "Chinese",
                active: false,
                flag: "https://guesteco.com/3x2/CN.svg",
              },
              {
                value: "cs",
                name: "Czech",
                active: false,
                flag: "https://guesteco.com/3x2/CZ.svg",
              },
              {
                value: "bs",
                name: "Bosnian",
                active: false,
                flag: "https://guesteco.com/3x2/BA.svg",
              },
              {
                value: "sr",
                name: "Serbian",
                active: false,
                flag: "https://guesteco.com/3x2/RS.svg",
              },
              {
                value: "uk",
                name: "Ukrainian",
                active: false,
                flag: "https://guesteco.com/3x2/UA.svg",
              },
            ],
            global: {
              cartPayments: {
                show: true,
                text: {
                  en: "",
                },
              },
              managers: [],
              slider_positioning: "bottom",
              login_btn: {
                auth: 0,
                no_auth_name: {
                  en: "Login me",
                },
                auth_name: {
                  en: "My profile",
                },
                no_auth_link: "login",
                auth_link: "profile",
                no_auth_icon: authIcon,
                auth_icon: authIcon,
                styles: JSON.parse(JSON.stringify(defaultStyles)),
              },
              underConstructor: {
                show: false,
                height: "200",
                width: "200",
                img: null,
                text: {
                  en: "<p>The e-menu and concierge are not available at the moment. We will definitely be back up and running soon.</p>",
                },
              },
              favorite_btn: {
                show: true,
                show_socials: true,
                name_color: "#000000",
                lining_padding: 15,
                position: "center center",
                background_color: "rgba(255,255,255,1)",
                descr_color: "#000000",
                width: "100",
                en: {
                  title: "<p >Your favorites</p>",
                  no_title: "<p >You don`t have any favorites</p>",
                },
                height: "150",
                styles: JSON.parse(JSON.stringify(defaultStylesFavorite)),
              },
              owner_email: "",
              page: 0,
              auth: false,
              enable_text: false,
              page_404: {
                en: {
                  content: "<p>Sorry...</p><p>We can`t find this page</p>",
                },
              },
              pwa: false,
              pwa_link: null,
              manifest: {
                name: "Application name",
                short_name: "App",
                theme_color: "#ffd900",
                icons: [
                  {
                    src:
                        process.env.VUE_APP_LINK +
                        "/img/icons/android-chrome-192x192.png",
                    sizes: "192x192",
                    type: "image/png",
                  },
                  {
                    src:
                        process.env.VUE_APP_LINK +
                        "/img/icons/android-chrome-512x512.png",
                    sizes: "512x512",
                    type: "image/png",
                  },
                ],
                start_url: null,
                display: "standalone",
                background_color: "#ffffff",
                scope: null,
              },
              active_header_inside: false,
              title: "default",
              white_bg: false,
              favicon_url: `${process.env.VUE_APP_LINK}/favicon.png`,
              order_api: "https://api-guest.herokuapp.com/order/create",
              currency: "€",
              listing_img_height: "110",
              listing_img_width: "90",
              detail_listing_img_height: "150",
              active_intro: false,
              active_logo_inside: false,
              weather_icon_type: 0,
              show_intro_seconds: 5,
              show_again_intro: false,
              menu_icon_color: "#000000",
              active_slider: false,
              slider_title_color: "#000000",
              active_icon_color: true,
              lang_border_color: "#000000",
              lang_style: "pro",
              emails: [],
              active_banner: [],
              slider_title: {
                en: "Slider Title",
              },
              events: {
                en: [],
              },
              show_locations: "London",
              city_id: {
                id: 2643743,
                name: "London",
                state: "",
                country: {
                  id: 23700,
                  code: "GB",
                  name: "United Kingdom",
                  created_at: null,
                  updated_at: null,
                },
                created_at: null,
                updated_at: null,
                lon: "-0.12574",
                lat: "51.50853",
              },
              timezone: {
                offset: "GMT",
                name: "Europe/Belfast",
              },
              show_weather: false,
              show_widget: true,
              show_time: true,
              weather: {
                location: null,
                cloudiness: null,
                windSpeed: null,
                temperatureHigh: null,
                temperatureLow: null,
                temperatureValue: null,
                humidity: null,
                week: null,
                style: "default",
                font_color: "#000000",
                border_radius:5
              },
              custom_scripts: "",
            },
            selected_langs: ["en"],
            defaults_langs: [
              {
                value: "en",
                name: "English",
              },
            ],
            default_lang: "en",
            show_lang: false,
            check_user_lang: false,
            tab: 0,
            success_page: {
              en: {
                content: "<p >Thank you. Your link to leave a review on:</p>",
                second_content: "Your link to leave a review on",
                show_socials: true,
              },
            },
          },
          profi: false,
          free: false,
        }
      }
    }
  },
  computed:{
    ...mapState(['landing']),
    categories(){
      return ['All',...this.templates.reduce((acc,item) => {
        const candidate = acc.find(x => x.id === item.template_category.id);
        if(!candidate){
          acc.push(item.template_category)
        }
        return acc
      },[])]
    },
    categoryTemplates(){
      if(this.selected_category === 'All'){
        return this.templates;
      }
      if(!this.selected_category){
        return []
      }
      return this.templates.reduce((acc,item) => {
        if(item.template_category.id === this.selected_category.id){
          acc.push(item)
        }
        return acc;
      },[])
    }
  },
  methods:{
    forceRerender() {
      // Removing my-component from the DOM
      this.$store.state.renderComponent = false;

      this.$nextTick(() => {
        // Adding the component back in
        this.$store.state.renderComponent = true;
      });
    },
    async installContent(template_id){
      let content = null
      if(template_id === 'blank_template'){
        content = this.blank_template.content;
      }else{
        content = await HotelService.getHotelTemplateContent(template_id);
      }
      if(content.advanced){
        this.landing.advanced = content.advanced;
      }
      if(content.branding){
        this.landing.branding = content.branding;
      }
      if(content.menu){
        this.landing.menu = content.menu;
      }
      if(content.order){
        this.landing.order = content.order;
      }
      if(content.order_form){
        this.landing.order_form = content.order_form;
      }
      if(content.reviews){
        this.landing.reviews = content.reviews;
      }
      if(content.socials){
        this.landing.socials = content.socials;
      }
      this.forceRerender();
      this.successMessage = "Successfuly installed!";
      this.$refs.PopupSuccess.open();
    }
  },
  components:{
    PopupTemplates
  },
  async mounted() {
    this.templates = await HotelService.getHotelTemplates();
    this.loading = false;
  }
}
</script>

<style scoped lang="scss">

.swiper-pagination > .swiper-pagination-bullet{
  height:20px;
  width:20px;
}
.swiper-pagination > .swiper-pagination-bullet-active{
  background: #fff;

}
</style>
