<template>
  <div class="constructor__main">
    <v-tabs
      class="langs__tabs"

      v-model="activeLang"
    >
      <v-tab
        v-for="(selected_lang, i) in selected_langs"
        :key="i"
        @change="changeLang(selected_lang)" style="border-bottom:1px solid #fafafa"
      >
            <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
        {{ selected_lang }}
      </v-tab>
      <v-tab-item v-for="(selected_lang, i) in selected_langs" :key="i">
        <label class="label">{{
          $t('constructor["Add custom HTML to your page."]')
        }}</label>
        <div style="margin-bottom: 15px;">
          <Editor
            v-if="reviews.success_page[selected_lang]"
            api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
            :init="options"
            v-model="reviews.success_page[selected_lang].content"
            style="margin-bottom: 20px;"
          >
          </Editor>
        </div>
        <v-row
          v-if="reviews.success_page[selected_lang]"
          no-gutters
          style="align-items: center;"
        >
          <v-col><label class="label">Show share buttons</label></v-col>
          <v-col class="right" style="align-items: center;"
            ><v-switch color="#6ab64a"
              style="margin: 0; padding: 0"
              v-model="reviews.show_success"
            ></v-switch
          ></v-col>
        </v-row>
        <div
          v-if="reviews.show_success && arrStars.length > 0"
          class="control"
          style="display:flex;margin-bottom:15px;justify-content: space-between;"
        >
          <label
            class="label"
            style="display: flex;flex: 3;align-items: center;"
            >Choose your question</label
          >

          <v-select
            dense
            outlined
            placeholder="choose your question"
            hide-details
            :items="arrStars"
            class="canvas__select"
            style="flex: 2;"
            v-model="reviews.active_name"
          >
            <template v-slot:selection="{ item }">
              <div v-if="item.length < 16">
                {{ item }}
              </div>
              <div v-else>
                {{ item.slice(0, 13) + "..." }}
              </div>
            </template>
            <template v-slot:item="{ item }">
              {{ item }}
            </template>
          </v-select>
        </div>
        <div
          v-if="reviews.show_success && arrStars.length > 0"
          class="control"
          style="display:flex;justify-content: space-between;"
        >
          <label
            class="label"
            style="display: flex;flex: 3;align-items: center;"
            >Star count for show on page</label
          >
          <v-select
            flat
            dense
            outlined
            hide-details
            :items="stars"
            item-text="name"
            item-value="value"
            class="canvas__select"
            v-model.number="reviews.active_stars"
            style="flex: 2;"
          >
            <template v-slot:selection="{ item }">
              <div v-if="item.value === 0 || item.value === 99">
                {{ item.name }}
              </div>
              <div v-else>
                <v-icon v-for="i in arrForStart(item.value)" :key="i">
                  {{ item.name }}
                </v-icon>
              </div>
            </template>
            <template v-slot:item="{ item }">
              <div v-if="item.value === 0 || item.value === 99">
                {{ item.name }}
              </div>
              <div v-else>
                <v-icon v-for="i in arrForStart(item.value)" :key="i">
                  {{ item.name }}
                </v-icon>
              </div>
            </template>
          </v-select>
        </div>
        <div
          class="constructor__content"
          v-if="reviews.show_success"
          style="margin-top: 15px;"
        >
          <div class="form">
            <div class="field">
              <label class="label">{{
                $t('constructor["Choose Social Networks"]')
              }}</label>
              <div class="socials">
                <div
                  class="socials__item"
                  v-for="item in reviewsSocials.list"
                  :key="item.id"
                >
                  <div
                    class="socials__btn"
                    :class="{ active: item.active }"
                    @click="addSocial(item.id)"
                  >
                    <span
                      class="socials__icon"
                      :class="{ active: item.active }"
                      v-html="item.icon"
                    ></span>
                    {{ item.text }}
                  </div>
                  <div class="socials__link" v-if="item.active">
                    <v-text-field
                      outlined
                      solo
                      flat
                      dense
                      hide-details
                      placeholder="Insert link on social network"
                      v-model="item.link"
                      @input="setCanvas"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="constructor__main">
            <div class="constructor__header">
              <h2 class="constructor__heading">
                {{ $t('constructor["Styles"]') }}
              </h2>
            </div>
            <div class="form">
              <div class="field">
                <label class="label">{{
                  $t('constructor["Button Arrangement"]')
                }}</label>
                <p>
                  {{ $t('constructor["Choose a style for the social links"]') }}
                </p>
                <div class="socials-style">
                  <div
                    class="socials-style__item socials-style__item_default"
                    :class="{ active: reviewsSocials.style === 'default' }"
                    @click="changeStyle('default')"
                  >
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div
                    class="socials-style__item socials-style__item_tile"
                    :class="{ active: reviewsSocials.style === 'tile' }"
                    @click="changeStyle('tile')"
                  >
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label"
                  >{{ $t('constructor["Button Radius"]') }}
                  <strong>{{ reviewsSocials.radius }}px</strong></label
                >
                <p>{{ $t('constructor["Choose your social links style"]') }}</p>
                <div class="slider">
                  <v-slider
                    :max="max"
                    :min="min"
                    v-model="reviewsSocials.radius"
                    @end="changeRadius"
                  ></v-slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";


export default {
  components: {

  },
  props:['reviews'],
  data: () => ({
    options: {
      images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
        "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

      mobile: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
          "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
      },
      menu: {
        tc: {
          title: "Comments",
          items: "addcomment showcomments deleteallconversations",
        },
      },
      menubar: "file edit view insert format tools table tc help",
      toolbar:
        "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
      autosave_ask_before_unload: true,min_height:200,
      autosave_interval: "30s",
      autosave_prefix: "{path}{query}-{id}-",
      autosave_restore_when_empty: false,
      autosave_retention: "2m",
      image_advtab: true,
      link_list: [
        { title: "My page 1", value: "https://www.tiny.cloud" },
        { title: "My page 2", value: "http://www.moxiecode.com" },
      ],
      image_list: [
        { title: "My page 1", value: "https://www.tiny.cloud" },
        { title: "My page 2", value: "http://www.moxiecode.com" },
      ],
      image_class_list: [
        { title: "None", value: "" },
        { title: "Some class", value: "class-name" },
      ],
      importcss_append: true,
      templates: [
        {
          title: "New Table",
          description: "creates a new table",
          content:
            '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
        },
        {
          title: "Starting my story",
          description: "A cure for writers block",
          content: "Once upon a time...",
        },
        {
          title: "New list with dates",
          description: "New List with dates",
          content:
            '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
        },
      ],
      template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
      template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
      height: 200,
      image_caption: true,
      quickbars_selection_toolbar:
        "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
      noneditable_noneditable_class: "mceNonEditable",
      toolbar_mode: "sliding",
      content_style: ".mymention{ color: gray; }",
      contextmenu: "link image imagetools table configurepermanentpen",
      skin: "oxide",

          setup:function(editor){
              editor.ui.registry.addButton('custom_button', {
                  text: 'Add Button',
                  onAction: function() {
                      // Open a Dialog
                      editor.windowManager.open({
                          title: 'Add custom button',
                          body: {
                              type: 'panel',
                              items: [{
                                  type: 'input',
                                  name: 'button_label',
                                  label: 'Button label',
                                  flex: true
                              },{
                                  type: 'input',
                                  name: 'button_href',
                                  label: 'Button href',
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_target',
                                  label: 'Target',
                                  items: [
                                      {text: 'None', value: ''},
                                      {text: 'New window', value: '_blank'},
                                      {text: 'Self', value: '_self'},
                                      {text: 'Parent', value: '_parent'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_style',
                                  label: 'Style',
                                  items: [
                                      {text: 'Success', value: 'success'},
                                      {text: 'Info', value: 'info'},
                                      {text: 'Warning', value: 'warning'},
                                      {text: 'Error', value: 'error'}
                                  ],
                                  flex: true
                              }]
                          },
                          onSubmit: function (api) {

                              var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                              // insert markup
                              editor.insertContent(html);

                              // close the dialog
                              api.close();
                          },
                          buttons: [
                              {
                                  text: 'Close',
                                  type: 'cancel',
                                  onclick: 'close'
                              },
                              {
                                  text: 'Insert',
                                  type: 'submit',
                                  primary: true,
                                  enabled: false
                              }
                          ]
                      });
                  }
              });
          },
      content_css: ["/custom_tinymce.css"],
    },
    min: 0,
    max: 32,
    stars: [
      { id: 0, value: 0, name: "Never" },
      { id: 0, value: 1, name: "mdi-star-outline" },
      { id: 0, value: 2, name: "mdi-star-outline" },
      { id: 0, value: 3, name: "mdi-star-outline" },
      { id: 0, value: 4, name: "mdi-star-outline" },
      { id: 0, value: 5, name: "mdi-star-outline" },
      { id: 0, value: 99, name: "Always" },
    ],
  }),
  computed: {
    ...mapGetters("landing", [
      "reviews.success_page",
      "reviewsSocials",
      "selected_langs",
      "current_lang",
    ]),
    ...mapState(["landing"]),

    activeLang:{
      set:function(val){

      },
      get:function(){
        return this.landing.advanced.selected_langs.indexOf(this.landing.current_lang)
      }
    },
    arrStars: function() {
      let arr = [];
      for (
        let i = 0;
        i < this.reviews[this.landing.current_lang].pro.length;
        i++
      ) {
        for (
          let q = 0;
          q <
          this.reviews[this.landing.current_lang].pro[i].questions
            .length;
          q++
        ) {
          if (
            this.reviews[this.landing.current_lang].pro[i].questions[q]
              .type === "star"
          ) {
            arr.push(
              this.reviews[this.landing.current_lang].pro[i].questions[
                q
              ].name
            );
          }
        }
      }
      return arr;
    },
  },
  methods: {
    setCanvas() {
      this.$store.commit("landing/setReviews", this.reviews);
    },
    addSocial(id) {
      this.reviewsSocials.list.forEach((social) => {
        if (social.id === id) {
          social.active = !social.active;
        }
      });
      this.setCanvas();
    },
    changeStyle(style) {
      this.reviewsSocials.style = style;
      this.setCanvas();
    },
    changeRadius() {
      this.setCanvas();
    },
    changeLang(lang) {
      this.$store.commit("landing/setCurrentLang", lang);
    },
    arrForStart(i) {
      const arr = [];
      for (let index = 0; index < i; index++) {
        arr.push(index);
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.right {
  display: flex;
  justify-content: flex-end;
}

.langs__tabs ::v-deep .v-tabs-bar {
  margin: 0;
}
.constructor {
  &__header {
    margin-bottom: 20px;
  }
}

.socials__item {
  width: 100%;
  display: flex;
  margin-bottom: 5px;
}

.socials__btn {
  display: flex;
  align-items: center;
  width: 50%;
  margin-right: 10px;
  padding: 8px 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;

  // .v-icon {
  //     margin-right: 10px;
  // }

  &.active {
    background: $primary;
    color: #fff;
    border: 1px solid $primary;
    ::v-deep .socials__icon svg path {
      fill: #fff;
      // stroke: #fff
      // color: #fff !important;
      // caret-color: #fff !important;
    }
  }
}

.socials__link {
  width: 50%;
}

.socials__icon {
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.socials-style {
  display: flex;
  margin-bottom: 30px;
}

.socials-style__item {
  width: 150px;
  margin-right: 20px;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #ededed;
  cursor: pointer;
  transition: all 0.3s;
  &.active {
    border-color: $primary;
  }
}

.socials-style__item_default {
  div {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    background: #e9eaed;
  }
}

.socials-style__item_tile {
  display: flex;
  flex-wrap: wrap;
  div {
    width: 30px;
    height: 30px;
    margin: 10px 3px;
    padding: 10px;
    border-radius: 5px;
    background: #e9eaed;
  }
}
</style>
