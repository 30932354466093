<template>
  <div class="constructor__main" v-if="reviews" style="height: 100%;">
    <div class="constructor__header" :style="`padding: ${isMobile?'0 5px 10px;':'30px 15px 10px;'};`" v-if="localPage === -1">
      <v-row align="center">
        <v-col cols="12">
          <h2 class="constructor__heading" style="margin-bottom: 0;">
            Reviews
          </h2>
          <p class="constructor__text">
            Setup your reviews using the following options.
          </p>
        </v-col>
      </v-row>
    </div>
    <div class="constructor__header" style="padding: 30px 15px 10px;border:none" v-if="landing.free">
      <div style="display: flex;">
      <v-icon color="#cccccc" size="60px" style="margin-right: 20px;">mdi-lock</v-icon>
      <h1 class="constructor__heading" style="font-weight: normal;font-size: 16px;">This feature is available on Pro plans.
        <br>To activate the tariff <a @click="billing">click here</a></h1>
      </div>
    </div>
    <div class="constructor__content" :style="`height: ${$store.state.isMobile?'auto':'calc(100% - 97px)'}`" v-if="!landing.free">
      <div style="display:flex;height:100%;">
        <div v-if="!isMobile"
             style="
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            border-right: 1px solid #e4e4e4;
          "
        >
          <div
              :class="{ active_tab: activeMenu(item.id) }"
              v-for="item in reviews_pages"
              :key="item.id"
              class="tab"
              @click="handleChangeTab(item)"
          >
            {{item.name}}
          </div>
        </div>
        <div style="flex: 0 1 100%;overflow:hidden;" :style="`padding: ${isMobile?'5px':'15px;'}`">
          <div style="margin-top:15px;flex: 0 1 100%;" v-if="localPage===-1&&isMobile">
            <div style="display:flex;flex-wrap:wrap;">
              <ul
                  class="canvas-menu"
                  :style="`flex-direction:row;`"
              >
                <li
                    @click="handleChangeTab(item,true)"
                    style="list-style: none"
                    class="canvas-menu__item canvas-menu-custom"
                    v-for="item in reviews_pages"
                    :key="item.id"
                    :style="`list-style: none;height:150px; margin-bottom: 13px; color: rgb(0, 0, 0); font-family: Inter, Inter, sans-serif; font-style: normal; font-weight: 400; font-size: 16px; width: calc(33.33% - 10px); margin-right: 10px;`
                "
                >
                  <a
                      class="canvas-menu__link"
                      href="#"
                      :style="
                    ` height: 150px; color: rgb(0, 0, 0); border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px; background-color: rgb(255, 255, 255); text-align: unset; justify-content: center; align-items: center; background-repeat: no-repeat; background-size: cover; background-position: center center;`
                  "
                  >
                    <div
                        :style="`height: 100px; width: 100%; padding: 15px; background-color: rgb(255, 255, 255); display: flex; align-items: center; justify-content: center; flex-direction: column;`"
                    >
                      <v-icon
                          alt="image"
                          class="canvas-menu__icon"
                          :style="`height: 36px; width: 36px; margin-right: 0px;`"
                      >{{item.icon?item.icon:'mdi-check'}}</v-icon>

                      <div style="text-align: center;font-size:13px;">
                        {{ item.name }}
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="showReviews(0)">
            <div class="form"><label style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;margin-left: 15px;" class="label">General settings</label>
              <v-tabs class="langs__tabs"  v-model="activeLang">
                <v-tab
                    v-for="(selected_lang, i) in selected_langs"
                    :key="i"
                    @change="changeLang(selected_lang)" style="border-bottom:1px solid #fafafa;margin-left:15px;"
                >
                  <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
                  {{ selected_lang }}
                </v-tab>
                <v-tab-item v-for="(selected_lang, i) in selected_langs" :key="i" style="margin: 0 15px;">

                  <label class="label" style="display:block;margin-bottom:15px;">Change Questions Header Text</label>
                  <Editor
                      api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                      :init="options"
                      v-model="reviews[selected_lang].header_text"
                      @change="setToCanvas"
                  >
                  </Editor>
                </v-tab-item>
              </v-tabs>
              <div style="display: flex;flex-direction: column;padding-left:15px;">
                <div style="margin:15px 0;" class="label">Review Button </div>
                <div style="padding:0 15px;">
                  <div style="display: flex;align-items: center;">
                    <label
                        class="label"
                        style="width:250px;margin-bottom: 0;margin-right: 15px;"
                    >Name Color</label
                    >
                    <v-menu
                        top
                        nudge-left="16"
                        nudge-bottom="98"
                        :close-on-content-click="false"
                        v-model="show_btn_name_color"
                    >
                      <template v-slot:activator="{ on }">
                        <div
                            class="colorpicker"
                            :style="
                      `background-color:${reviews.styles.btn_color}`
                    "
                            v-on="on"
                        />
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker
                              v-model="reviews.styles.btn_color"
                              flat
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                  <div style="display: flex;align-items: center;margin:10px 0;">
                    <label
                        class="label"
                        style="width:250px;margin-bottom: 0;margin-right: 15px;"
                    >Background Color</label
                    >
                    <v-menu
                        top
                        nudge-left="16"
                        nudge-bottom="98"
                        :close-on-content-click="false"
                        v-model="show_btn_bg_color"
                    >
                      <template v-slot:activator="{ on }">
                        <div
                            class="colorpicker"
                            :style="
                      `background-color:${reviews.styles.btn_bg}`
                    "
                            v-on="on"
                        />
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker
                              v-model="reviews.styles.btn_bg"
                              flat
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                  <div style="display: flex;align-items: center;margin:10px 0;">
                    <label
                        class="label"
                        style="width:250px;margin-bottom: 0;margin-right: 15px;"
                    >Border color</label
                    >
                    <v-menu
                        top
                        nudge-left="16"
                        nudge-bottom="98"
                        :close-on-content-click="false"
                        v-model="show_btn_border_color"
                    >
                      <template v-slot:activator="{ on }">
                        <div
                            class="colorpicker"
                            :style="
                      `background-color:${reviews.styles.btn_border}`
                    "
                            v-on="on"
                        />
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker
                              v-model="reviews.styles.btn_border"
                              flat
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                  <div style="margin:10px 0;display: flex;align-items: center;">
                    <label
                        class="label"
                        style="width:250px;margin-bottom: 0;margin-right: 15px;"
                    >Name</label
                    >
                    <v-text-field
                        style="max-width:250px;"
                        outlined
                        solo
                        flat
                        hide-details
                        dense
                        v-model="reviews.styles.btn_name"
                    ></v-text-field>
                  </div></div>
              </div>
            </div>
          </div>
          <div v-if="showReviews(1)">
            <label style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;" class="label">Questions settings</label>
            <div>
              <v-tabs class="langs__tabs"  v-model="activeLang">
                <v-tab
                    v-for="(selected_lang, i) in selected_langs"
                    :key="i"
                    @change="changeLang(selected_lang)" style="border-bottom:1px solid #fafafa"
                >
                  <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
                  {{ selected_lang }}
                </v-tab>
                <v-tab-item v-for="(selected_lang, i) in selected_langs" :key="i">
                  <div>
                    <div style="display: flex;justify-content: flex-end;">
                      <v-btn
                          color="primary"
                          depressed
                          outlined
                          small
                          style="margin-bottom: 15px;"
                          @click="addQuestionGroupPro(reviews[selected_lang].pro)"
                      >
                        + Add question group
                      </v-btn>
                    </div>
                    <draggable
                        :options="{disabled: isMobile}" v-model="reviews[selected_lang].pro" tag="div" v-if="reviews[selected_lang].pro.length">
                      <div class="control" v-for="(reviewsGroup,reviewsGroupInd) in reviews[selected_lang].pro" :key="reviewsGroup.id" style="border: 1px solid #eee;border-radius:16px;padding:10px;margin-bottom: 15px;">
                        <v-autocomplete
                            outlined
                            solo
                            flat
                            dense
                            placeholder="Choose reviews template"
                            :items="reviews_templates_text"
                            v-model="reviewsGroup.header"
                            @change="reviewSelectTemplate(reviews[selected_lang].pro,reviewsGroupInd)"
                        >
                        </v-autocomplete>
                        <label class="label" style="display:block;margin-bottom:15px">Change your header text reviews</label>
                        <v-text-field outlined dense v-model="reviewsGroup.header"  @click:append="deleteGroupOpenPopup(reviewsGroupInd)" append-icon="mdi-delete"></v-text-field>
                        <label class="label" style="display:block;margin-bottom:15px">Change your questions</label>
                        <draggable
                            :options="{disabled: isMobile}" v-model="reviewsGroup.questions" tag="div" v-if="reviewsGroup.questions.length">
                          <div  v-for="(quest,indexQuest) in reviewsGroup.questions" :key="quest.id">
                            <v-text-field :append-outer-icon="'mdi-text'" v-if="quest.type === 'text'" @click:append-outer="changeQuestType(quest)" append-icon="mdi-delete" @click:append="deleteQuest(indexQuest,reviewsGroup.questions)" outlined dense v-model="quest.name">
                            </v-text-field>
                            <v-text-field :append-outer-icon="'mdi-record-circle-outline'" v-if="quest.type === 'radio'" @click:append-outer="changeQuestType(quest)" append-icon="mdi-delete" @click:append="deleteQuest(indexQuest,reviewsGroup.questions)" outlined dense v-model="quest.name">
                            </v-text-field>
                            <v-text-field :append-outer-icon="'mdi-star'" v-if="quest.type === 'star'" @click:append-outer="changeQuestType(quest)" append-icon="mdi-delete" @click:append="deleteQuest(indexQuest,reviewsGroup.questions)" outlined dense v-model="quest.name">
                            </v-text-field>
                          </div>
                        </draggable>
                        <div style="display: flex;justify-content: flex-end;">
                          <v-btn
                              color="primary"
                              depressed
                              outlined
                              small
                              @click="addQuestionPro(reviewsGroup.questions)"
                          >
                            + {{ $t('constructor["Add question"]') }}
                          </v-btn>
                        </div>
                      </div>
                    </draggable>
                  </div>
                </v-tab-item>
              </v-tabs>
            </div>
          </div>
          <div v-if="showReviews(2)">
            <label style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;" class="label">Happy page settings</label>
            <reviews-success-page :reviews="reviews" />
          </div>
          <div v-if="showReviews(3)">
            <label style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;" class="label">Unhappy page settings</label>
            <reviews-un-success-page :reviews="reviews" />
          </div>
          <div v-if="showReviews(4)">
            <label style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;" class="label">Letters settings</label>
            <letters/>
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
    <PopupDelete ref="PopupDelete" title="Are you sure about delete Question Group?" @confirm="deleteQuestGroup()"></PopupDelete>
    <PopupTariff ref="PopupTariff"/>
    </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import ReviewsSuccessPage from "./Reviews/ReviewsSuccessPage.vue";
import ReviewsUnSuccessPage from './Reviews/ReviewsUnSuccessPage'
import PopupDelete from "../Popup/PopupDelete";
import PopupTariff from "../Popup/PopupTariff";
import letters from "./Reviews/letters.vue";
import draggable from "vuedraggable";

export default {
  name: "ConstructorReviews",
  components: {
    PopupTariff,
    PopupDelete,
    ReviewsUnSuccessPage,
    ReviewsSuccessPage,
    letters,
    draggable
  },
  data() {
    return {
      localPage:-1,
      isMobile:false,
      reviews_pages:[
        {
          id:0,page:'home',name:'General',
        },
        {
          id:1,page:'home',name:'Questions',
        },
        {
          id:2,page:'home',name:"Happy Page",
        },
        {
          id:3,page:'home',name:'Unhappy Page',
        },
        {
          id:4,page:'home',name:'Letters',
        },
      ],
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
          "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 200,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",
          setup:function(editor){
              editor.ui.registry.addButton('custom_button', {
                  text: 'Add Button',
                  onAction: function() {
                      // Open a Dialog
                      editor.windowManager.open({
                          title: 'Add custom button',
                          body: {
                              type: 'panel',
                              items: [{
                                  type: 'input',
                                  name: 'button_label',
                                  label: 'Button label',
                                  flex: true
                              },{
                                  type: 'input',
                                  name: 'button_href',
                                  label: 'Button href',
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_target',
                                  label: 'Target',
                                  items: [
                                      {text: 'None', value: ''},
                                      {text: 'New window', value: '_blank'},
                                      {text: 'Self', value: '_self'},
                                      {text: 'Parent', value: '_parent'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_style',
                                  label: 'Style',
                                  items: [
                                      {text: 'Success', value: 'success'},
                                      {text: 'Info', value: 'info'},
                                      {text: 'Warning', value: 'warning'},
                                      {text: 'Error', value: 'error'}
                                  ],
                                  flex: true
                              }]
                          },
                          onSubmit: function (api) {

                              var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                              // insert markup
                              editor.insertContent(html);

                              // close the dialog
                              api.close();
                          },
                          buttons: [
                              {
                                  text: 'Close',
                                  type: 'cancel',
                                  onclick: 'close'
                              },
                              {
                                  text: 'Insert',
                                  type: 'submit',
                                  primary: true,
                                  enabled: false
                              }
                          ]
                      });
                  }
              });
          },
      content_css: ["/custom_tinymce.css"],
      },
      delete_index:null,
      typeQuest:0,
      selected_review: "",
      polls_questions_length: 1,
      polls_text_questions_length: 1,
      active_tab: 0,
      show_btn_name_color:false,
      show_btn_bg_color:false,
      show_btn_border_color:false,
      reviews_templates_text:[
        "Cleanliness in the room",
        "Friendliness of staff",
        "Rate our hotel according to your comfort"
      ],
      reviews_templates:[
        {
          id: 0,
          questions: [{ id: 0, name: 'Clean', value: false, type: "radio" },{ id: 1, name: 'Fairly clean', value: false, type: "radio" },{ id: 2, name: 'Dirty', value: false, type: "radio" }],
          header: "Cleanliness in the room"
        },

        {
          id: 1,
          questions: [{ id: 0, name: 'The friendliest in the world :)', value: false, type: "radio" },{ id: 1, name: 'Not friendly', value: false, type: "radio" },{ id: 2, name: 'I had gaps in staff', value: false, type: "radio" }],
          header: "Friendliness of staff"
        },

        {
          id: 2,
          questions: [{ id: 0, name: 'The most stars you like it more', value: 0, type: "star" },{ id: 1, name: 'Please write why you have put this number of stars:', value: '', type: "text" }],
          header: "Rate our hotel according to your comfort"
        }
      ]
    };
  },

  async created() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
  computed: {
    ...mapState("landing", ["selected_langs", "profi"]),
    ...mapState(['landing']),
    ...mapState('reviews',['reviews']),
    activeLang:{
      set:function(val){

      },
      get:function(){
        return this.landing.advanced.selected_langs.indexOf(this.landing.current_lang)
      }
    },
  },
  watch: {
    "$store.state.trigger"(val){
      if(!val){
        if(this.localPage === -1){
          this.$store.state.tab = 0;
          this.$store.state.selectedPage = 'start';
          if (this.$store.state.menu.selectedMenuItem) {
            const candidate = this.$store.state.menu.list.find(
                (item) => item.id === this.selectedMenuItem.id
            );
            if (candidate) {
              const candidateIndex = this.$store.state.menu.list.indexOf(candidate);
              if (candidateIndex >= 0) {
                this.$store.state.menu.list.splice(
                    candidateIndex,
                    1,
                    JSON.parse(JSON.stringify(this.$store.state.menu.selectedMenuItem))
                );
              }
            }
          }
          this.$store.commit('menu/setSelectedMenuItem',null);
          this.$store.commit("setPage", 'start');
        }
        else{
          this.localPage = -1;
          this.$store.state.trigger = true;
        }
      }
    },
    "reviews.tab": function (val) {
      this.active_tab = val;
    },
  },
  mounted() {
    this.setReviews(JSON.parse(JSON.stringify(this.landing.reviews)));
    if(this.reviews.type === 'pro'){
      this.typeQuest = 1;
    }
    },
  methods: {
    ...mapMutations(["setMenuActiveTab"]),
    ...mapMutations('reviews',['setReviews']),
    showReviews(id){
      return this.reviews.tab === id && !this.isMobile || this.reviews.tab === id && this.localPage === id && this.isMobile
    },
    activeMenu(id){
      return id === this.reviews.tab
    },
    billing(){
      let obj = this.$store.state.objects.list.find(x => +x.id === +this.$route.params.id)
      this.$refs.PopupTariff.open(obj);
    },
    reviewSelectTemplate(arr,ind){
      arr[ind].questions = JSON.parse(JSON.stringify(this.reviews_templates.find(x => x.header === arr[ind].header).questions));
    },
    deleteGroupOpenPopup(ind){
      this.delete_index = ind;
      this.$refs.PopupDelete.open();
    },
    changeReview(){
    },
    deleteQuestGroup(){
      this.reviews[this.landing.advanced.default_lang].pro.splice(this.delete_index,1);
      this.delete_index = null;
    },
    changeQuestType(item){
      if(item.type === 'radio'){
        item.type = 'text';
        item.value = '';
      }else if(item.type === 'text'){
        item.type = 'star';
        item.value = 0;
      }else{
        item.type = 'radio';
        item.value = false;
      }
    },
    deleteQuest(ind,arr){
      arr.splice(ind,1);
    },
    addQuestionGroupPro(arr){
      let questGroup;
      if(arr.length){
        questGroup = {
          id:arr[arr.length-1].id+1?arr[arr.length-1].id+1:0,
          questions:[{id:1,name:null,value:false,type: "radio"}],
          header:"default header"
        }
      }else{
        questGroup = {
          id:0,
          questions:[{id:1,name:null,value:false,type: "radio"}],
          header:"default header"
        }
      }
      arr.push(questGroup);
    },
    addQuestionPro(arr){
      let quest;
      if(arr.length){
        quest = {id:arr[arr.length-1].id+1?arr[arr.length-1].id+1:0,name:'default question',value:false,type: "radio"}
      }else{
        quest = {id:0,name:'default question',value:false,type: "radio"}
      }
      arr.push(quest);
    },
    setToCanvas() {
      this.$store.commit("landing/setReviews", this.reviews);
    },
    handleChangeTab(item,localPage) {
      if(localPage){
        this.localPage = item.id;
      }
      this.reviews.tab = item.id;
      this.$store.commit('back',true);
    },
    addReview() {
      let maxId = [1];

      this.selected_langs.forEach((lang) => {
        this.reviews[lang].questions.forEach((question) => {
          maxId.push(question.id);
        });

        this.reviews[lang].questions.push({
          id: Math.max(...maxId) + 1,
          name: "default question",
        });

        this.polls_questions_length = this.reviews[lang].questions.length;
      });

      this.setToCanvas();
    },
    deleteReview(id) {
      this.selected_langs.forEach((lang) => {
        this.reviews[lang].questions = this.reviews[lang].questions.filter(
          (question) => question.id !== id
        );
        this.polls_questions_length = this.reviews[lang].questions.length;
      });

      this.setToCanvas();
    },
    addTextReview() {
      this.selected_langs.forEach((lang) => {
        this.reviews[lang].text_questions.push({
          id: 1,
          name: "default question ",
        });
        this.polls_text_questions_length = 1;
      });
      this.setToCanvas();
    },
    deleteTextReview(id) {
      this.selected_langs.forEach((lang) => {
        this.reviews[lang].text_questions = this.reviews[
          lang
        ].text_questions.filter((question) => question.id !== id);
        this.polls_text_questions_length = this.reviews[lang].text_questions;
      });
      this.setToCanvas();
    },
    changeLang(lang) {
      this.$store.commit("landing/setCurrentLang", lang);
    },
  },
};
</script>

<style lang="scss">
.reviews__item {
  width: 100%;
  padding: 0 15px;
}

.reviews__title {
  margin-bottom: 12px !important;
  margin-right: auto;
  font-weight: 500;
  font-size: 16px;
}

.reviews__question {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .v-input {
    width: 100%;
  }
}

.reviews__delete {
  margin-bottom: 12px;
}

.reviews__footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0 15px;
}
.active__tab{
  border:1px solid #eee;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.reviews_tabs .v-tab{
  justify-content: flex-start;
}
</style>
