<template>
  <div class="constructor__main" style="height: 100%;">
    <div class="constructor__header" :style="`padding: ${isMobile?'0 5px 10px;':'30px 15px 10px;'};height:97px;`" v-if="localPage === -1">
      <v-row align="center">
        <v-col cols="12">
          <h2 class="constructor__heading" style="margin-bottom: 0;">
            Design
          </h2>
          <p class="constructor__text">
            Customize your portal using the following options.
          </p>
        </v-col>
      </v-row>
    </div>

    <div class="constructor__content" :style="`height: ${$store.state.isMobile?'auto':'calc(100% - 97px)'}`">
      <div style="display:flex;height:100%;">
        <div v-if="!isMobile"
            style="
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            border-right: 1px solid #e4e4e4;
          "
        >
          <div
              :class="{ active_tab: activeMenu(item.id) }"
              v-for="item in branding_pages"
              :key="item.id"
              class="tab"
              @click="changeBrandingTab(item)"
          >
            {{item.name}}
          </div>
        </div>
        <div style="flex: 0 1 100%;overflow:hidden;" :style="`padding: ${isMobile?'5px':'15px;'}`">
          <div style="margin-top:15px;flex: 0 1 100%;" v-if="localPage===-1&&isMobile">
            <div style="min-height:600px;display:flex;flex-wrap:wrap;">
              <ul
                  class="canvas-menu"
                  :style="`flex-direction:row;`"
              >
                <li
                    @click="changeBrandingTab(item,true)"
                    style="list-style: none"
                    class="canvas-menu__item canvas-menu-custom"
                    v-for="item in branding_pages"
                    :key="item.id"
                    :style="`list-style: none; margin-bottom: 13px; color: rgb(0, 0, 0); font-family: Inter, Inter, sans-serif; font-style: normal; font-weight: 400; font-size: 16px; width: calc(33.33% - 10px); margin-right: 10px;`
                "
                >
                  <a
                      class="canvas-menu__link"
                      href="#"
                      :style="
                    ` height: 150px; color: rgb(0, 0, 0); border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px; background-color: rgb(255, 255, 255); text-align: unset; justify-content: center; align-items: center; background-repeat: no-repeat; background-size: cover; background-position: center center;`
                  "
                  >
                    <div
                        :style="`height: 100px; width: 100%; padding: 15px; background-color: rgb(255, 255, 255); display: flex; align-items: center; justify-content: center; flex-direction: column;`"
                    >
                      <v-icon
                          alt="image"
                          class="canvas-menu__icon"
                          :style="`height: 36px; width: 36px; margin-right: 0px;`"
                      >{{item.icon?item.icon:'mdi-check'}}</v-icon>

                      <div style="text-align: center;font-size:13px;">
                        {{ item.name }}
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="showBranding(0)">
            <HeaderBranding/>
          </div>
          <div v-if="showBranding(1)">
            <LogoBranding/>
          </div>
          <div v-if="showBranding(2)">
            <TextLogoBranding/>
          </div>
          <div v-if="showBranding(3)">
            <FooterBranding/>
          </div>
          <div v-if="showBranding(4)">
            <TextFooterBranding/>
          </div>
          <div v-if="showBranding(5)">
            <BackgroundBranding/>
          </div>
          <div v-if="showBranding(6)">
            <LiningBranding/>
          </div>
          <div v-if="showBranding(7)">
            <Loader/>
          </div>
          <div v-if="showBranding(8)">
            <UploadAdvertisingBanner :lang="'en'"/>
          </div>
          <div v-if="showBranding(9)">
            <BottomBanner :lang="'en'"/>
          </div>
          <div v-if="showBranding(10)">
            <Intro :lang="'en'"/>
          </div>
          <div v-if="showBranding(11)">
            <Slider/>
          </div>
          <div v-if="showBranding(12)">
            <BottomMenu/>
          </div>
          <div v-if="showBranding(13)">
            <TopMenu/>
          </div>
          <div v-if="showBranding(14)">
            <ConstructorSocials ref="socials" />
          </div>
          <div v-if="showBranding(15)">
            <MainPageStyles :setter-menu="$store.state.selectBrandingMenu" />
          </div>
          <div v-if="showBranding(16)">
            <Sidebar />
          </div>
            <div v-if="showBranding(17)">
                <Events />
            </div>
          <div v-if="showBranding(18)">
            <Templates/>
          </div>
          <slot></slot>
        </div>
      </div>
      <div class="constructor-layout" v-show="is_layout">
        <div class="constructor-layout__list">
          <div
            class="constructor-layout__item constructor-layout__item_left"
            :class="{ active: branding.layout === 'left' }"
            @click="changeLayout('left')"
          >
            <div class="constructor-layout__img">
              <div class="constructor-layout__logo"></div>
              <div class="constructor-layout__header"></div>
              <div class="constructor-layout__content">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <span class="constructor-layout__name">{{
              $t('constructor["Left Image"]')
            }}</span>
          </div>
          <div
            class="constructor-layout__item constructor-layout__item_center"
            :class="{ active: branding.layout === 'center' }"
            @click="changeLayout('center')"
          >
            <div class="constructor-layout__img">
              <div class="constructor-layout__logo"></div>
              <div class="constructor-layout__header"></div>
              <div class="constructor-layout__content">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <span class="constructor-layout__name">{{
              $t('constructor["Center Image"]')
            }}</span>
          </div>
          <div
              class="constructor-layout__item constructor-layout__item_right"
              :class="{ active: branding.layout === 'default' }"
              @click="changeLayout('default')"
          >
            <div class="constructor-layout__img">
              <div class="constructor-layout__logo"></div>
              <div class="constructor-layout__header"></div>
              <div class="constructor-layout__content">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <span class="constructor-layout__name">Right Image</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import MediaService, {collections, entities} from "../../../services/media.service";
import UploadAdvertisingBanner from './UploadAdvertisingBanner.vue';
import Intro from './Intro'
import BottomMenu from './BottomMenu.vue';
import TopMenu from './TopMenu.vue'
import HeaderBranding from './Header.vue'
import LogoBranding from './Logo.vue'
import TextLogoBranding from './TextLogo.vue'
import FooterBranding from './Footer.vue'
import TextFooterBranding from './TextFooter.vue'
import BackgroundBranding from './Background.vue'
import LiningBranding from './Lining.vue'
import ConstructorSocials from "@/components/Constructor/ConstructorSocials.vue";
import Loader from "./Loader"
import BottomBanner from "@/components/Constructor/Branding/BottomBanner";
import Templates from "@/components/Constructor/Branding/Templates";

export default {
  name: "ConstructorBranding",
  components: {
    Templates,
    Sidebar: () => import("./Sidebar"),
    BottomBanner,
    Intro,
    Slider: () => import("./Slider"),
    BottomMenu,
    TopMenu,
    HeaderBranding,
    LogoBranding,
    TextLogoBranding,
    FooterBranding,
    TextFooterBranding,
    BackgroundBranding,
    LiningBranding,
    UploadAdvertisingBanner,
    ConstructorSocials,
    Loader,
    Events: () => import('./Events'),
    MainPageStyles: () =>
        import("@/components/Constructor/Menu/MainPageStyles"),
  },
  created(){
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
  destroyed() {
    this.$store.state.active_branding_id = 15;
    this.localPage = -1;
    this.$store.state.styleMenu = false;
    this.$store.state.selectBrandingMenu = null;
  },
  mounted() {
      const candidate = this.branding_pages.find(x => x.id === this.$store.state.active_branding_id);
      if(candidate){
        this.changeBrandingTab(candidate);
        if(this.isMobile&&this.$store.state.selectBrandingMenu){
          this.$store.state.styleMenu = false;
          this.localPage = this.$store.state.active_branding_id
        }
      }
  },
  data: () => ({
    isMobile:false,
    localPage:-1,
    branding_pages:[
      {
        id:15,page:'',name:'Menu Styles'
      },
      {
        id:0,page:'home',name:'Header',
      },
      {
        id:1,page:'home',name:'Logo',
      },
      {
        id:2,page:'home',name:"Text Logo",
      },
      {
        id:3,page:'home',name:'Footer',
      },
      {
        id:4,page:'home',name:'Footer Text',
      },
      {
        id:5,page:'home',name:'Background',
      },
      {
        id:6,page:'lining',name:'Lining',
      },
      {
        id:7,page:'loader',name:'Loader',
      },
      // {
      //   id:8,page:'home',name:'Modal Banner',
      // },
      // {
      //   id:9,page:'home',name:'Bottom Banner',
      // },
      // {
      //   id:10,page:'intro',name:'Intro Banner',
      // },
      {
        id:11,page:'home',name:'Slider',
      },
      {
        id:12,page:'home',name:'Bottom Menu',
      },
      {
        id:13,page:'topMenu',name:"Top Menu",
      },
      {
        id:14,page:'home',name:'Socials'
      },
      {
        id:16,page:'sidebar',name:'Sidebar'
      },
      // {
      //   id:17,page:'home',name:'Events'
      // },
      {
        id:18,page:'home',name:"Templates"
      }
    ],
    show_slider_color:false,
    sliderIndexTab:0,
    is_layout: false,
    menu: false,
    tab:0,
    menu_2: false,
  }),
  computed: {
    ...mapState(["landing",'selected_langs']),
    ...mapGetters('landing',["current_lang"]),
    activeLang:{
      set:function(val){

      },
      get:function(){
        return this.landing.advanced.selected_langs.indexOf(this.landing.current_lang)
      }
    },
    background_templates(){
      let arr = [];
      for(let i = 0;i<34;i++){
        arr.push(i);
      }
      return arr
    },
    logoOptions() {
      return {
        entity_name: entities.LendingSetting,
        entity_id: this.landing.settings_id,
        collection: collections.logo,
      };
    },
    headerOptions() {
      return {
        entity_name: entities.LendingSetting,
        entity_id: this.landing.settings_id,
        collection: collections.header,
      };
    },
    backgroundOptions() {
      return {
        entity_name: entities.LendingSetting,
        entity_id: this.landing.settings_id,
        collection: collections.background,
      };
    },
    footerOptions() {
      return {
        entity_name: entities.LendingSetting,
        entity_id: this.landing.settings_id,
        collection: collections.footer,
      };
    },
    branding() {
      return JSON.parse(JSON.stringify(this.landing.branding));
    },
    selected_langs() {
      return this.landing.selected_langs;
    },
    swatchFontStyle() {
      const { menu } = this;
      // this.setCanvas();
      return {
        backgroundColor: this.branding.font_color,
        type: "hex",
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        border: "1px solid #000",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    swatchBgStyle() {
      const { menu_2 } = this;
      // console.log(this.branding.bg_color)
      // this.setCanvas();
      return {
        backgroundColor: this.branding.bg_color,
        type: "hex",
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu_2 ? "50%" : "4px",
        border: "1px solid #000",
        transition: "border-radius 200ms ease-in-out",
      };
    },
  },
  methods: {
    showBranding(id){
      return this.$store.state.active_branding_id === id && !this.isMobile || this.$store.state.active_branding_id === id && this.localPage === id && this.isMobile
    },
    openStyle() {
        this.$store.commit("mainPageStyles/setGlobalRadius", this.landing.menu.radius);
        this.$store.commit(
            "mainPageStyles/setGlobalShadowSize",
            this.landing.menu.shadowSize
        );
        this.$store.commit(
            "mainPageStyles/setGlobalShadowColor",
            this.landing.menu.shadowColor
        );
        this.$store.commit(
            "mainPageStyles/setGlobalIconSize",
            this.landing.menu.icon_size
        );
        this.$store.commit("mainPageStyles/setList", JSON.parse(JSON.stringify(this.landing.menu.list)));
        this.$store.commit("setPage", "menuStyles");
        this.$store.commit('mainPageStyles/setStylesOpen',true);
    },
    changeBrandingTab(item,localPage){
      this.$store.commit('back',true);
      this.$store.state.branding_active_tab = item.id;
      this.$store.state.active_branding_id = item.id;
      if(item.id === 15){
        this.openStyle()
      }else{
        this.$store.commit('setPage',item.page);
      }
      if(localPage){
        this.localPage = item.id
      }
    },
    activeMenu(id){
      return id === this.$store.state.active_branding_id
    },
    openWeather(){
      this.landing.advanced.global.page = 6;
      this.$store.commit("setPage", 'advanced');
    },
    setTitlePreview(type){
      this.landing.branding.preview_title = type;
      this.setCanvas()
    },
    changeTitle(type){
      if(this.landing.advanced.auth){
        this.landing.branding.preview_title = type;
      }
      this.setCanvas()
    },
    onSuccess(data){
      this.branding.slider[this.current_lang][this.sliderIndexTab].img = data.xhr.response;
      this.setCanvas();
    },
    removeFile(){
      this.branding.slider[this.current_lang][this.sliderIndexTab].img = null;
      this.setCanvas();
    },
    setImgHeader(url){
      if(this.branding.header_image === url){
        this.branding.header_image = null;
        this.setCanvas();
      }else{
        this.branding.header_image = url;
        this.setCanvas();
      }
    },
    setImgLogo(url){
      if(this.branding.logo === url){
        this.branding.logo = null;
        this.setCanvas();
      }else{
        this.branding.logo = url;
        this.setCanvas();
      }
    },
    setImg(url){
      if(this.branding.bg_image === url){
        this.branding.bg_image = null;
        this.setCanvas();
      }else{
        this.branding.bg_image = url;
        this.setCanvas();
      }
    },
    changeType(intro){
      if(this.type === '1'){
        intro.video = null;
      }else{
        intro.pick_url = null;
      }
    },
    async onFileChange(file){
      try{
        let formData = new FormData();
        formData.append('lending_settings_id',this.landing.settings_id);
        formData.append('image',file);
        formData.append('file',file);
        const response = await this.$axios.post('upload-image',formData);
        this.landing.intro[this.current_lang].video = response.data;
      }catch(e){
        console.log(e);
      }
    },
    handleSuccess(file) {
      this.landing.intro[this.current_lang].pic_url = file.xhr.response;
      this.setCanvas();
    },
    changePage(page) {
      this.$store.commit("setPage", page);
    },
    onLogoUploaded(file_url) {
      this.branding.logo = file_url;
      this.setCanvas();
    },
    onFooterUploaded(file_url) {
      this.landing.branding.footer_img = file_url;
      this.setCanvas();
    },
    onFooterRemoved() {
      this.landing.branding.footer_img = null;
      this.setCanvas();
    },
    onHeaderUploaded(file_url) {
      this.branding.header_image = file_url;
      this.setCanvas();
    },
    onBackgroundUploaded(file_url) {
      this.branding.bg_image = file_url;
      this.setCanvas();
    },
    onLogoRemoved() {
      MediaService.deleteMedia(this.logoOptions)
      this.branding.logo = null;
      this.setCanvas();
    },
    onHeaderRemoved() {
      MediaService.deleteMedia(this.headerOptions)
      this.branding.header_image = null;
      this.setCanvas();
    },
    onBackgroundRemoved() {
      MediaService.deleteMedia(this.backgroundOptions)
      this.branding.bg_image = null;
      this.setCanvas();
    },
    openLayout() {
      this.is_layout = !this.is_layout;
    },
    changeLang(lang) {
      this.$store.commit("landing/setCurrentLang", lang);
    },
    setCanvas() {
      this.$store.commit("landing/setBranding", this.branding, true);
    },
    changeLayout(layout) {
      this.branding.layout = layout;
      this.setCanvas();
    },
  },
  watch:{
    "$store.state.trigger"(val){
      console.log(val);
      if(!val){
        if(this.localPage === -1){
          this.$store.state.tab = 0;
          this.$store.state.selectedPage = 'start';
          if (this.$store.state.menu.selectedMenuItem) {
            const candidate = this.$store.state.menu.list.find(
                (item) => item.id === this.selectedMenuItem.id
            );
            if (candidate) {
              const candidateIndex = this.$store.state.menu.list.indexOf(candidate);
              if (candidateIndex >= 0) {
                this.$store.state.menu.list.splice(
                    candidateIndex,
                    1,
                    JSON.parse(JSON.stringify(this.$store.state.menu.selectedMenuItem))
                );
              }
            }
          }
          this.$store.commit('menu/setSelectedMenuItem',null);
          this.$store.commit("setPage", 'start');
        }
        else{
          this.localPage = -1;
          this.$store.state.trigger = true;
        }
      }
    },
  }
};
</script>

<style lang="scss">
.constructor-layout {
  background: #fff;
}

.constructor-layout__list {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 30px 10px;
}

.constructor-layout__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  &.active {
    .constructor-layout__img {
      border-color: $primary;
    }
  }
}

.constructor-layout__img {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #e9eaed;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    border-color: $primary;
    transform: scale(1.01);
  }
}

.constructor-layout__logo {
  height: 50px;
  width: 50px;
  // background: #e9eaed;
  background-color: rgba($color: $primary, $alpha: 0.12);
  border-radius: 5px;
  margin: 10px 0;
}

.constructor-layout__header {
  height: 75px;
  width: 100%;
  // background: #e9eaed;
  background-color: rgba($color: $primary, $alpha: 0.12);
}

.constructor-layout__content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  padding-bottom: 20px;
  div {
    width: 80%;
    height: 30px;
    // background: #e9eaed;
    background-color: rgba($color: $primary, $alpha: 0.12);
    margin-bottom: 10px;
    border-radius: 5px;
  }
}

.constructor-layout__item_default {
}

.constructor-layout__item_left {
  .constructor-layout__logo {
    position: absolute;
    background: #fff;
    top: 50px;
    left: 10%;
    border: 1px solid #e9eaed;
  }
  .constructor-layout__content {
    margin-top: 50px;
  }
}
.constructor-layout__item_right {
  .constructor-layout__logo {
    position: absolute;
    background: #fff;
    top: 50px;
    right: 10%;
    border: 1px solid #e9eaed;
  }
  .constructor-layout__content {
    margin-top: 50px;
  }
}
.constructor-layout__item_center {
  .constructor-layout__logo {
    position: absolute;
    background: #fff;
    top: 50px;
    border: 1px solid #e9eaed;
  }
  .constructor-layout__content {
    margin-top: 50px;
  }
}
.vue-dropzone{
  font-family: sans-serif;
}
.vue-dropzone > .dz-preview .dz-image {
  max-width: 150px;
  max-height: 150px;
  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}

.dz-size {
  display: none;
}
.dz-filename {
  display: none;
}

.active__lining{
  img{
    border:2px solid #66B1D3;
  }
}
.branding_tabs .v-tab{
  justify-content: flex-start;
}
</style>
<style lang="scss" scoped>
.margin__tabs ::v-deep .v-tabs-bar {
  margin: 0;
}
</style>
