<template>
  <div style="width: 100%;padding:15px;">
    <div class="event_header" style="margin-bottom:15px;align-items:center;width:100%;display: flex;justify-content:space-between">
      <label style="display:block;margin-bottom:0;font-weight:bold;font-size:20px;" class="label">Guest settings</label>
      <v-btn v-if="page === 'list'" color="primary" outlined small @click="createGuestSegment"
      >+ Add new segment</v-btn
      >
      <v-btn v-if="page !== 'list'" color="primary" outlined small @click="backToList"
      ><v-icon>mdi-chevron-left</v-icon> Back to list</v-btn
      >
    </div>
    <div v-if="page === 'list'">
      <div  style="display: flex;flex-wrap: wrap;width: 100%;">
        <GuestSegment @editSegment="editSegmentOpen" @deleteSegment="deleteSegmentOpen"  v-for="segment in segments" :key="segment.id" :segment="segment"/>
      </div>
    </div>
    <v-dialog :fullscreen="$store.state.isMobile" @click:outside="closeCreateSegmentModal" v-if="createSegment" eager v-model="createSegmentModal" max-width="700px">
      <v-card style="min-height: 500px">
        <v-icon
            style="top: 10px; right: 10px; position: absolute;z-index:9"
            class="close__modal"
            @click="closeCreateSegmentModal"
        >mdi-close</v-icon
        >
        <v-card-text style="padding: 15px;">
          <CreateGuestSegment :segment="createSegment" @createSuccess="createSuccess" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog :fullscreen="$store.state.isMobile" @click:outside="closeEditSegmentModal" v-if="editSegment" eager v-model="editSegmentModal" max-width="700px">
      <v-card style="min-height: 500px">
        <v-icon
          style="top: 10px; right: 10px; position: absolute;z-index:9"
          class="close__modal"
          @click="closeEditSegmentModal"
      >mdi-close</v-icon
      >
        <v-card-text style="padding: 15px;">
          <EditGuestSegment :segment="editSegment" @editSuccess="editSuccess"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <PopupDelete ref="PopupDelete" @confirm="deleteSegmentConfirm" :title="`Are you sure about delete ${deleteSegmentModel?deleteSegmentModel.name:''}?`"/>
    <PopupSuccess ref="PopupSuccess" title="Success" :content="successMessage" />
  </div>
</template>

<script>
  import titleMixin from "@/mixins/titleMixin";
  import {mapState,mapActions} from 'vuex'
  import store from "@/store"
  import GuestSegment from "@/components/Marketing/GuestSegment";
  import CreateGuestSegment from "@/components/Marketing/CreateGuestSegment";
  import PopupDelete from "@/components/Popup/PopupDelete";
  import PopupSuccess from "@/components/Popup/PopupSuccess";
  import EditGuestSegment from "@/components/Marketing/EditGuestSegment";
  export default {
    components:{
      EditGuestSegment,
      PopupSuccess,
      PopupDelete,
      GuestSegment,
      CreateGuestSegment
    },
    name: "Marketing",
    mixins:[titleMixin],
    title(){
      return 'Upsell'
    },
    data(){
      return {
        successMessage:"",
        createSegment:null,
        createSegmentModal:false,
        editSegmentModal:false,
        editSegment:null,
        deleteSegmentModel:null,
        objectItem: null,
        page:'list',
        defaultSegment:{
          hotel_id:null,
          name:"",
          small_description:"",
          active:true,
          key:"",
          simple_keys:[],
          keys:{
            guest:{
              single:false,
              couple:false,
              group:false,
              childs:false,
              infant:false,
              dogs:false,
              cats:false,
            },
            booking_source:{
              custom_keys:[]
            },
            reservation_type:{
              room_number:[],
              room_prefix:[],
              day_in_week_from:{
                mon:false,
                tue:false,
                wed:false,
                thu:false,
                fri:false,
                sat:false,
                sun:false,
              },
              day_in_week_to:{
                mon:false,
                tue:false,
                wed:false,
                thu:false,
                fri:false,
                sat:false,
                sun:false,
              },

            },
            days_count:{
              less:"",
              equals:"",
              more:""
            },
            days_before_start:""
          }
        },
      }
    },
    computed:{
      ...mapState(['objects']),
      ...mapState('segments',['segments']),
    },
    methods:{
      ...mapActions('segments',['getSegments','deleteSegment']),
      closeCreateSegmentModal(){
        this.createSegmentModal = false;
        this.createSegment = null;
      },
      closeEditSegmentModal(){
        this.editSegmentModal = false;
        this.editSegment = null;
      },
      editSuccess(){
        this.successMessage = 'Segment edited successfully';
        this.editSegmentModal = false;
        this.$refs.PopupSuccess.open();
      },
      createSuccess(){
        this.successMessage = 'New segment created successfully';
        this.createSegmentModal = false;
        this.$refs.PopupSuccess.open();
      },
      editSegmentOpen(segment){
        this.editSegment = JSON.parse(JSON.stringify(segment));
        this.editSegmentModal = true;
      },
      deleteSegmentOpen(segment){
        this.deleteSegmentModel = JSON.parse(JSON.stringify(segment));
        this.$refs.PopupDelete.open();
      },
      async deleteSegmentConfirm(){
        await this.deleteSegment(this.deleteSegmentModel.id);
        this.successMessage = `Segment ${this.deleteSegmentModel?this.deleteSegmentModel.name:''} deleted successfully!`;
        this.$refs.PopupSuccess.open();
      },
      changeHotel(){
        this.defaultSegment.hotel_id = this.objectItem.id;
      },
      createGuestSegment(){
        this.createSegment = JSON.parse(JSON.stringify(this.defaultSegment));
        this.createSegmentModal = true;
      },
      backToList(){
        this.page = 'list';
        this.editSegment = null;
        this.createSegment = null;
      }
    },
    async mounted() {
      this.objectItem = this.objects.list.find(x => x.id === +this.$route.params.id);
      this.defaultSegment.hotel_id = this.objectItem.id;
      await this.getSegments(this.objectItem.id);
    }
  }
</script>

<style scoped lang="scss">
  .event_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .event__textfield {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 15px 0;
  }

  .label {
    margin-bottom: 15px;
  }
  .date_time {
    display: flex;
    width: 100%;
    .date {
      width: 100%;
      margin-bottom: 15px;
      .label {
        display: block;
        margin-bottom: 15px;
      }
    }
    .time {
      width: 200px;
      margin-bottom: 15px;
      .label {
        display: block;
        margin-bottom: 15px;
      }
    }
  }
  .event__img {
    padding: 15px 0;
    .label {
      display: block;
      margin-bottom: 15px;
    }
  }
  .vue-dropzone > .dz-preview .dz-details {
    display: none;
  }
  .vue-dropzone > .dz-preview .dz-image {
    width: 120px;
    height: 120px;
  }
  .v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > .v-card__progress + *:not(.v-btn):not(.v-chip):not(.v-avatar){
    border-radius: 0;
  }
  .theme--light.v-picker__body{
    height:310px;
  }
  .v-picker--time .v-picker__title{
    height: 88px;
  }
  .tox.tox-tinymce{
    width:100%;
  }
  .tariff_obj {
    margin-left:5px;
    display: inline-block;
    padding: 4px 6px;
    text-transform: uppercase;
    border-radius: 0 5px 0 5px;
    background: #007db5;
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
    color: #fff;
  }
</style>
