<template>
<div>
  <div class="constructor__main">
    <div class="constructor__header" :style="`padding: ${isMobile?'0 5px 10px;':'30px 15px 10px;'}`">
    <v-row align="center">
      <v-col cols="12">
        <h2 class="constructor__heading"  style="margin-bottom: 0;" >
         Start
        </h2>
        <p class="constructor__text">
          Welcome to the constructor
        </p>
      </v-col>
    </v-row>
    </div>
    <div v-if="!isMobile" class="constructor__text" :style="`padding: ${isMobile?'10px 5px;':'10px 15px;'}`">
        <p>You will be able to create the best solution for your guests, and give them not new impressions of convenience and service.</p>
      <br>
        <p>The constructor consists of the main sections:</p>
        <br>
      <p><strong>Menu</strong> - allows you to customize menu items and their contents</p>
      <p><strong>Design</strong> - allows you to manage the overall design concept of your property's entire web application</p>
      <p><strong>Reviews</strong> - get feedback from guests and find out their opinion about you</p>
<!--      <p><strong>Order</strong> - allows you to configure the receipt and payment of orders from your guests</p>-->
      <p><strong>Advanced</strong> - tricky settings for your project</p>

    </div>

    <div v-if="isMobile" style="display:flex;flex-wrap:wrap;margin-top:30px;">
      <ul
          class="canvas-menu"
          :style="`flex-direction:row;width:100%;`"
      >
        <li
            @click="changePage('home',2)"
            style="list-style: none"
            class="canvas-menu__item canvas-menu-custom"
            :style="`list-style: none; margin-bottom: 13px; color: rgb(0, 0, 0); font-family: Inter, Inter, sans-serif; font-style: normal; font-weight: 400; font-size: 16px; width: calc(33.33% - 10px); margin-right: 10px;`
                "
        >
          <a
              class="canvas-menu__link"
              href="#"
              :style="
                    ` height: 150px; color: rgb(0, 0, 0); border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px; background-color: rgb(255, 255, 255); text-align: unset; justify-content: center; align-items: center; background-repeat: no-repeat; background-size: cover; background-position: center center;`
                  "
          >
            <div
                :style="`height: 100px; width: 100%; padding: 15px; background-color: rgb(255, 255, 255); display: flex; align-items: center; justify-content: center; flex-direction: column;`"
            >
              <v-icon
                  alt="image"
                  class="canvas-menu__icon"
                  :style="`height: 36px; width: 36px; margin-right: 0px;`"
              >mdi-check</v-icon>

              <div style="text-align: center;font-size:13px;">
                {{ $t('constructor["Menu"]') }}
              </div>
            </div>
          </a>
        </li>
        <li
            @click="changePageBranding()"
            style="list-style: none"
            class="canvas-menu__item canvas-menu-custom"
            :style="`list-style: none; margin-bottom: 13px; color: rgb(0, 0, 0); font-family: Inter, Inter, sans-serif; font-style: normal; font-weight: 400; font-size: 16px; width: calc(33.33% - 10px); margin-right: 10px;`
                "
        >
          <a
              class="canvas-menu__link"
              href="#"
              :style="
                    ` height: 150px; color: rgb(0, 0, 0); border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px; background-color: rgb(255, 255, 255); text-align: unset; justify-content: center; align-items: center; background-repeat: no-repeat; background-size: cover; background-position: center center;`
                  "
          >
            <div
                :style="`height: 100px; width: 100%; padding: 15px; background-color: rgb(255, 255, 255); display: flex; align-items: center; justify-content: center; flex-direction: column;`"
            >
              <v-icon
                  alt="image"
                  class="canvas-menu__icon"
                  :style="`height: 36px; width: 36px; margin-right: 0px;`"
              >mdi-check</v-icon>

              <div style="text-align: center;font-size:13px;">
                Design
              </div>
            </div>
          </a>
        </li>
        <li
            @click="changePage('reviews',3)"
            style="list-style: none"
            class="canvas-menu__item canvas-menu-custom"
            :style="`list-style: none; margin-bottom: 13px; color: rgb(0, 0, 0); font-family: Inter, Inter, sans-serif; font-style: normal; font-weight: 400; font-size: 16px; width: calc(33.33% - 10px); margin-right: 10px;`
                "
        >
          <a
              class="canvas-menu__link"
              href="#"
              :style="
                    ` height: 150px; color: rgb(0, 0, 0); border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px; background-color: rgb(255, 255, 255); text-align: unset; justify-content: center; align-items: center; background-repeat: no-repeat; background-size: cover; background-position: center center;`
                  "
          >
            <div
                :style="`height: 100px; width: 100%; padding: 15px; background-color: rgb(255, 255, 255); display: flex; align-items: center; justify-content: center; flex-direction: column;`"
            >
              <v-icon
                  alt="image"
                  class="canvas-menu__icon"
                  :style="`height: 36px; width: 36px; margin-right: 0px;`"
              >mdi-check</v-icon>

              <div style="text-align: center;font-size:13px;">
                {{ $t('constructor["Reviews"]') }}
              </div>
            </div>
          </a>
        </li>
        <li
            @click="changePage('advanced',5)"
            style="list-style: none"
            class="canvas-menu__item canvas-menu-custom"
            :style="`list-style: none; margin-bottom: 13px; color: rgb(0, 0, 0); font-family: Inter, Inter, sans-serif; font-style: normal; font-weight: 400; font-size: 16px; width: calc(33.33% - 10px); margin-right: 10px;`
                "
        >
          <a
              class="canvas-menu__link"
              href="#"
              :style="
                    ` height: 150px; color: rgb(0, 0, 0); border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px; background-color: rgb(255, 255, 255); text-align: unset; justify-content: center; align-items: center; background-repeat: no-repeat; background-size: cover; background-position: center center;`
                  "
          >
            <div
                :style="`height: 100px; width: 100%; padding: 15px; background-color: rgb(255, 255, 255); display: flex; align-items: center; justify-content: center; flex-direction: column;`"
            >
              <v-icon
                  alt="image"
                  class="canvas-menu__icon"
                  :style="`height: 36px; width: 36px; margin-right: 0px;`"
              >mdi-check</v-icon>

              <div style="text-align: center;font-size:13px;">
                {{ $t('constructor["Advanced"]') }}
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
    </div>
    <PopupSuccess ref="PopupSuccess" :title="`Your hotel successfully recovered from ${$route.query.snapshot}`" @close="deleteSnapshot"/>
</div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex'
import PopupSuccess from "../components/Popup/PopupSuccess";
export default {
  name: "Start",
    components: {PopupSuccess},
    computed: {
      ...mapState("menu", ["selectedMenuItem"]),
    ...mapState(['landing']),
    ...mapGetters('landing',['default']),

  },
  data(){
    return{
      isMobile:false,
      linkTo:[],
    }
  },
    methods:{
      ...mapMutations("menu", ["setMenuList", "setSelectedMenuItem"]),
      changePageBranding() {
        if (this.$store.state.branding_active_tab === 6) {
          this.changePage("lining");
        } else if (this.$store.state.branding_active_tab === 9) {
          this.changePage("intro");
        } else {
          this.changePage("home");
        }
        this.$store.state.selectedPage = "branding";
        this.$store.state.tab = 1;
        this.$store.commit('back',true);
      },
      changePage(page,index) {
        this.$store.state.tab = index;
        this.$store.state.selectedPage = page;
        if (this.selectedMenuItem) {
          const candidate = this.$store.state.menu.list.find(
              (item) => item.id === this.selectedMenuItem.id
          );
          if (candidate) {
            const candidateIndex = this.$store.state.menu.list.indexOf(candidate);
            if (candidateIndex >= 0) {
              this.$store.state.menu.list.splice(
                  candidateIndex,
                  1,
                  JSON.parse(JSON.stringify(this.selectedMenuItem))
              );
            }
          }
        }
        this.setSelectedMenuItem(null);
        this.$store.commit("setPage", page);
        this.$store.commit('back',true);
      },
        deleteSnapshot(){
          location.href = window.location.origin + window.location.pathname;
        },
      // checkBranding(obj){
      //   for(let i in this.default){
      //     // eslint-disable-next-line no-prototype-builtins
      //     if(obj.hasOwnProperty(i)){
      //       if(Array.isArray(this.default[i])){
      //         for(let index = 0;index<this.default[i].length;index++){
      //           if(Array.isArray(this.default[i])){

      //           }else if(typeof this.default[i][index] === 'object'){
      //             // eslint-disable-next-line no-prototype-builtins
      //             if(obj[i].hasOwnProperty(index)){
      //               if(Array.isArray(this.default[i][index])){
      //                 for(let index1 = 0;index1<this.default[i][index].length;index1++){
      //                   if(Array.isArray(this.default[i][index][index1])){
      //                   }else if(typeof this.default[i][index][index1] === 'object'){
      //                     // eslint-disable-next-line no-prototype-builtins
      //                     if(obj[i][index].hasOwnProperty(index1)){

      //                     }else{
      //                       obj[i][index][index1] = this.default[i][index];
      //                     }
      //                   }
      //                 }
      //               }else if(typeof this.default[i] === 'object'){
      //                 for(let index in this.default[i]){

      //                 }
      //               }else{
      //                 obj[i] = this.default[i];
      //               }
      //             }else{
      //               obj[i] = this.default[i];
      //             }
      //           }
      //         }
      //       }else if(typeof this.default[i] === 'object'){
      //         for(let index in this.default[i]){

      //         }
      //       }else{
      //         obj[i] = this.default[i];
      //       }
      //     }else{
      //       obj[i] = this.default[i];
      //     }
      //   }
      // },
    //   checkBranding(obj){
    //     // eslint-disable-next-line no-empty
    //     if(obj['emailList']){

    //     }else{
    //       obj['emailList'] = JSON.parse(JSON.stringify(this.default.emailList));
    //     }
    //   },
    //   recursive(key,data){
    //     if(Array.isArray(data[key])){
    //       for(let i = 0;i<data[key].length;i++){
    //         this.linkTo.push(key);
    //         this.recursive(key,data[key][i]);
    //       }
    //     }else if(typeof data[key] === 'object'){
    //       this.linkTo.push(key);
    //       this.recursive(key,data[key]);
    //     }else{
    //       console.log(this.linkTo);
    //       return key
    //     }
    // },
    },
  created(){
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
  mounted(){
      if(this.$route.query.snapshot){
          this.$refs.PopupSuccess.open();
      }
    // this.check(this.landing)
  }
}
</script>

<style scoped>

</style>
