<template>
  <div class="constructor__main">
    <label class="label" style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;">Social settings</label>
    <div class="constructor__content">
          <div class="form" style="margin-bottom: 15px;">
            <div class="field">
              <label class="label">{{
                $t('constructor["Choose Social Networks"]')
              }}</label>

              <v-tabs
                class="langs__tabs"

                v-model="activeLang"
              >
                <v-tab
                  v-for="(selected_lang, i) in selected_langs"
                  :key="i"
                  @change="changeLang(selected_lang)" style="border-bottom:1px solid #fafafa"
                >
            <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
                  {{ selected_lang }}
                </v-tab>
                <v-tab-item
                  v-for="(selected_lang, i) in selected_langs"
                  :key="i"
                >
                  <div class="socials">
                    <div
                      class="socials__item"
                      v-for="item in landing.socials.list[selected_lang]"
                      :key="item.id+landing.current_lang"
                    >
                      <div
                        class="socials__btn"
                        :class="{ active: item.active }"
                        @click="addSocial(item.id,landing.current_lang)"
                      >
                        <span
                          class="socials__icon"
                          :class="{ active:  item.active }"
                          v-html="item.icon"
                        ></span>
                        {{ item.text }}
                      </div>
                      <div class="socials__link" v-if="item.active">
                        <v-text-field
                          outlined
                          solo
                          flat
                          dense
                          :rules="rules"
                          hide-details="auto"
                          :error-messages="error"
                          :error="!!error"
                          placeholder="Insert link on social network"
                          v-model="item.link"
                          @input="setCanvas"
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs>
            </div>
          </div>
          <div class="form">
            <div class="field">
              <label class="label">{{
                $t('constructor["Button Arrangement"]')
              }}</label>
              <div style="padding:0 15px;">
                <p>
                  {{ $t('constructor["Choose a style for the social links"]') }}
                </p>
                <div class="socials-style">
                  <div
                      class="socials-style__item socials-style__item_default"
                      :class="{ active: socials.style === 'default' }"
                      @click="changeStyle('default')"
                  >
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div
                      class="socials-style__item socials-style__item_tile"
                      :class="{ active: socials.style === 'tile' }"
                      @click="changeStyle('tile')"
                  >
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <div style="padding:0 15px;width:100%;">
              <div class="field" style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="display:block;margin:0;width:200px"
                >{{ $t('constructor["Button Radius"]') }}
                  <strong>{{ socials.radius }}px</strong></label
                >
                <div class="slider" style="width:100%;">
                  <v-slider
                      :max="max"
                      hide-details
                      :min="min"
                      v-model="socials.radius"
                      @end="changeRadius"
                  ></v-slider>
                </div>
              </div>
              <div class="field" v-if="socials.style !== 'tile'" style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="display:block;margin:0;width:200px"
                >Button width <strong>{{ socials.width }}%</strong></label
                >
                <div class="slider" style="width:100%;">
                  <v-slider
                      :max="100"
                      hide-details
                      :min="0"
                      v-model="socials.width"
                      @end="changeRadius"
                  ></v-slider>
                </div>
              </div>
            </div>
          </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ConstructorSocials",
  data: () => ({
    min: 0,
    max: 32,
    error:"",
    showIDs:[],
    rules: [
      (v) => !!v || "Link is required",
      (v) =>
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(
          v
        ) || "Link should started with https://",
    ],
  }),
  computed: {
    ...mapState("landing", ["socials", "selected_langs"]),
    ...mapState(["landing"]),
    activeLang:{
      set:function(val){

      },
      get:function(){
        return this.landing.advanced.selected_langs.indexOf(this.landing.current_lang)
      }
    },
  },
  mounted() {},
  methods: {
    changeLang(lang) {
      this.$store.commit("landing/setCurrentLang", lang);
    },
    setCanvas() {
      this.$store.commit("landing/setSocials", this.socials);
    },
    addSocial(id) {
      this.socials.list[this.landing.current_lang].forEach((social) => {
        if (social.id === id) {
          social.active = !social.active;
          social.active ? this.showIDs.push(social.id+this.landing.current_lang) : this.showIDs.splice(this.showIDs.indexOf(social.id+this.landing.current_lang));
        }
      });
      let objDiv = document.getElementsByClassName('canvas')[0];
      objDiv.scrollTop = objDiv.scrollHeight;
      this.setCanvas();
    },
    changeStyle(style) {
      this.socials.style = style;
      this.setCanvas();
    },
    changeRadius() {
      this.setCanvas();
    },
  },
};
</script>

<style lang="scss">
.socials {
}
.field .langs__tabs .v-tabs-bar {
    margin: 0;
}
.socials__item {
  width: 100%;
  display: flex;
  margin-bottom: 5px;
}

.socials__btn {
  display: flex;
  align-items: center;
  width: 50%;
  margin-right: 10px;
  padding: 8px 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;

  // .v-icon {
  //     margin-right: 10px;
  // }

  &.active {
    background: $primary;
    color: #fff;
    border: 1px solid $primary;
    ::v-deep .socials__icon svg path {
      fill: #fff;
      // stroke: #fff
      // color: #fff !important;
      // caret-color: #fff !important;
    }
  }
}

.socials__link {
  width: 50%;
}

.socials__icon {
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.socials-style {
  display: flex;
  margin-bottom: 30px;
}

.socials-style__item {
  width: 150px;
  margin-right: 20px;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #ededed;
  cursor: pointer;
  transition: all 0.3s;
  &.active {
    border-color: $primary;
  }
}

.socials-style__item_default {
  div {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    background: #e9eaed;
  }
}

.socials-style__item_tile {
  display: flex;
  flex-wrap: wrap;
  div {
    width: 30px;
    height: 30px;
    margin: 10px 3px;
    padding: 10px;
    border-radius: 5px;
    background: #e9eaed;
  }
}
</style>
